import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import { HeadingXSmall, LabelMedium, LabelSmall, LabelXSmall, ParagraphMedium, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import { FormControl } from "baseui/form-control";

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '80px',
                display: 'flex',
                flexGrow: 0,
                justifyContent: 'flex-end',
                alignItems: 'start',
            }),
        },
    },
};

class TransactionDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order_number: this.props.match.params.order_number,
            payment_number: this.props.match.params.payment_number,
            isLoaded: false,
            transaction: null,
            error: null
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/history/" + this.state.order_number + '/' + this.state.payment_number,
            callbackSuccess: (result) => {
                Logger.log(result);
                this.setState({
                isLoaded: true,
                transaction: result,
                ...Utils.clearAllNotifications()
            })},
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    isPartnerPharmacy = () => {
        return this.state.transaction && this.state.transaction.line_items.some(item => item.fulfillment_pharmacy_id);
    };

    renderOrderNumber = () => {
        return <FormControl
            label={() => "Order"}
            overrides={{
                Label: {
                    style: ({$theme}) => ({
                        marginTop: 0,
                        marginBottom: 4
                    })
                }
            }}
        >
            <ParagraphSmall style={{margin: 0}}>{this.state.transaction.order.number}</ParagraphSmall>
        </FormControl>
    };

    renderPaymentNumber = () => {
        return <FormControl
            label={() => "ID"}
            overrides={{
                Label: {
                    style: ({$theme}) => ({
                        marginTop: 0,
                        marginBottom: 4
                    })
                }
            }}
        >
            <ParagraphSmall style={{margin: 0}}>{this.state.transaction.number}</ParagraphSmall>
        </FormControl>
    };

    renderDeliveryAddress = () => {
        if (this.isPartnerPharmacy()) {
            return <FormControl
                label={() => <HeadingXSmall style={{marginBottom: '15px', marginTop: '5px'}}>Delivery address</HeadingXSmall>}
                overrides={{
                    Label: {
                        style: ({$theme}) => ({
                            marginTop: 0,
                            marginBottom: 4
                        })
                    }
                }}
            >
                {this.state.transaction.order && this.state.transaction.order.patient
                    ? <div>
                        <ParagraphSmall style={{margin: 0}}>{Utils.renderAddress(this.state.transaction.order.address)}</ParagraphSmall>
                    </div>
                    : <ParagraphSmall>Address information not found</ParagraphSmall>
                }
            </FormControl>
        }
    };

    renderNextBillDate = (item) => {
        if (item.protocol_subscription && item.protocol_subscription.next_bill_date) {
            return <ParagraphXSmall style={{margin: 0}}>Renews on {Utils.render_mm_dd_yyyy_date(item.protocol_subscription.next_bill_date)}</ParagraphXSmall>;
        }
    };

    renderItems = () => {
        return <div>
            <HeadingXSmall style={{marginBottom: '15px', marginTop: '5px'}}>Items</HeadingXSmall>
            {this.state.transaction.line_items.map((item, idx) => <div key={idx}>
            <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                <FlexGridItem><ParagraphSmall style={{margin: 0}}>{item.name}({Utils.renderDollarAmount(Math.abs(item.price))})</ParagraphSmall></FlexGridItem>
                <FlexGridItem {...narrowItemProps}><ParagraphSmall style={{margin: 0}}>{Utils.renderDollarAmount(Math.abs(item.price * item.quantity))}</ParagraphSmall></FlexGridItem>

                <FlexGridItem>
                    {item.type == 'SubscriptionFeeLineItem'
                        ? <div><ParagraphSmall style={{margin: 0, color: 'grey'}}>Monthly subscription</ParagraphSmall>{this.renderNextBillDate(item)}</div>
                        : <div><ParagraphSmall style={{margin: 0, color: 'grey'}}>Quantity {item.quantity}</ParagraphSmall></div>
                    }
                </FlexGridItem>
                <FlexGridItem {...narrowItemProps}></FlexGridItem>


                <FlexGridItem>
                    {this.renderFulfiller(item)}
                </FlexGridItem>
                <FlexGridItem {...narrowItemProps}></FlexGridItem>

            </FlexGrid>
            {Utils.renderSpace()}
        </div>)}
        </div>
    };

    renderCard = () => {
        if (this.state.transaction.payment_method) {
            return <ParagraphSmall style={{margin: 0}}>{this.state.transaction.payment_method.card_type.toUpperCase() + ' ' + this.state.transaction.payment_method.last_four_digits}</ParagraphSmall>
        } else {
            return <ParagraphSmall>Payment method not found</ParagraphSmall>
        }
    };

    transactionTypeLabel = () => {
        if (this.state.transaction.transaction_type == 'credit') {
            return "credited";
        } else {
            return 'charged';
        }
    }

    renderTotal = () => {
        return <div>
                <HeadingXSmall style={{marginBottom: '15px', marginTop: '5px'}}>Amount {this.transactionTypeLabel()}</HeadingXSmall>
                <FlexGrid flexGridColumnCount={2}>
                    <FlexGridItem>
                        {this.renderCard()}
                    </FlexGridItem>
                    <FlexGridItem {...narrowItemProps}>
                        <ParagraphSmall style={{margin: 0}}>{Utils.renderDollarAmount(this.state.transaction.amount)}</ParagraphSmall>
                    </FlexGridItem>
                </FlexGrid>
            </div>
    };

    renderFulfiller = (line_item) => {
        if (line_item.fulfillment_pharmacy_id) {
            return <ParagraphXSmall style={{margin: 0}}>{line_item.fulfillment_pharmacy.name}, {line_item.fulfillment_pharmacy.address}, {line_item.fulfillment_pharmacy.phone}</ParagraphXSmall>
        }
    };

    getDate = (item) => {
        return <FormControl
            label={() => "Date"}
            overrides={{
                Label: {
                    style: ({$theme}) => ({
                        marginTop: 0,
                        marginBottom: 4
                    })
                }
            }}
        >
            <ParagraphSmall style={{margin: 0}}>{ new Date(item['paid_at'] || item['created_at']).toLocaleString()}</ParagraphSmall>
        </FormControl>
    };

    renderTransaction = () => {
        if (!this.state.isLoaded) {
            return <Spinner />
        } else {
            if (Object.keys(this.state.transaction).length > 0) {
                return <div>
                    {this.getDate(this.state.transaction)}
                    {this.renderPaymentNumber(this.state.transaction)}
                    {this.renderOrderNumber(this.state.transaction)}
                    {this.renderItems()}
                    {this.renderDeliveryAddress()}
                    {this.renderTotal()}
                    {Utils.renderSpace()}
                    {Utils.renderSpace()}
                    <ParagraphSmall style={{margin: 0, textAlign: 'center'}}>Clinic.dev</ParagraphSmall>
                    <ParagraphSmall style={{margin: 0, textAlign: 'center'}}>{Utils.renderCompanyAddress()}</ParagraphSmall>
                </div>;
            } else {
                return <ParagraphSmall>No transaction details found</ParagraphSmall>
            }
        }
    };

    render () {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <div style={{marginTop:'98px'}}>
                            {Utils.renderTitleCenter("Receipt")}
                            {Utils.renderSpace()}
                            {Utils.renderError(this.state.error)}
                            {this.renderTransaction()}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(TransactionDetails);
