class Logger {
}
//GAE is broken and uses production in staging...
if (window.location.hostname != 'clinic.dev') {
  Logger.log = console.log.bind(window.console)
  Logger.error = console.error.bind(window.console)
} else {
  Logger.log = (msg) => {};
  Logger.error = (msg) => {};
}

export default Logger;
