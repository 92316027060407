import React, {Component} from 'react';
import {Table, DIVIDER, SIZE as TableSize} from 'baseui/table-semantic';
import Request from '../../shared/Request';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../shared/Firebase';
import * as ROUTES from '../Constants';
import Logger from '../../shared/Utils/logger';
import Utils from '../../shared/Utils';
import {HeadingSmall } from 'baseui/typography';

class Creds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creds: null
        }
    }

    componentDidMount = () => {
        Request.get({
            firebase: null,
            route: ROUTES.BASE_URL_API + '/physician_credentials',
            callbackSuccess: result => {
                this.setState({creds: result});
            },

            callbackError: error => {
                Logger.log('Error while loading physician creds');
            }
        })
    };

    renderData = () => {
        Logger.log(this.state.creds);

        if (this.state.creds) {
            const COLUMNS = ['Provider name', 'NPI', 'Communities', 'State licenses'];
            let DATA = []

            this.state.creds.forEach(physician => {
                let protocols = []
                if (physician.protocols) {
                    physician.protocols.forEach(protocol => protocol.status == 'active' ? protocols.push(protocol.name) : null);
                }

                protocols = protocols.join(', ');
                if (protocols != '') {
                    DATA.push([
                        (physician.name ? physician.name : ''),
                        (physician.n_p_i ? physician.n_p_i : ''),
                        protocols,
                        (physician.state_licenses ? physician.state_licenses.map(license => license.state + ' (' + license.license_number + ')').join(', ') : ''),
                    ])
                }
            });

            Logger.log(DATA);
            return <Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical}  size={TableSize.compact}/>;
        }

        return null;
    };

    render = () => {
        return <div style={Utils.getContentMaxWidthCss()}>
            {Utils.renderTitleCenter("Provider credentials")}
            {this.renderData()}</div>
    };
}

export default withRouter(withFirebase(Creds));
