import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Button, SIZE, SHAPE, KIND} from 'baseui/button';
import MpsModal from '../../shared/Utils/modal';
import Utils from '../../shared/Utils';
import { FormControl } from "baseui/form-control";
import { PhoneInput, COUNTRIES } from "baseui/phone-input";
import Logger from '../../shared/Utils/logger';
import { ParagraphMedium, LabelMedium } from 'baseui/typography';

class PhoneChange extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            message: null,

            showPhoneChangeModal: false,
            country: COUNTRIES.US,
            phone: ''
        };

        this.recaptcha = null;
    }

    recaptchaCallback = (response) => {
        // https://stackoverflow.com/questions/41132750/uncaught-error-recaptcha-placeholder-element-must-be-an-element-or-id
        // TODO do we need to do anything?
        // TODO clean up: Uncaught (in promise) Timeout (i)
        //      https://github.com/google/recaptcha/issues/269
        Logger.log('------ RECAPTCHA callback called... ------');
        Logger.log(response);
        // TODO remove the recaptcha
    };

    renderRecaptchaContainer = () => {
        return <div id='recaptcha'></div>;
    };

    submitPhoneChange = () => {
        const phone_number = this.state.country.dialCode + this.state.phone;
        Logger.log('Testing phone number: ' + phone_number);
        if (!this.recaptcha) {
            Logger.log('Recaptcha for phone change...');
            this.recaptcha = this.props.firebase.getRecaptchaVerifier('recaptcha', this.recaptchaCallback);
            if (!this.recaptcha){
                this.setState({error: {message: 'Could not initiate recaptcha. Please try again.'}});
            }
        }

        var phoneAuthProvider = this.props.firebase.getPhoneAuthProvider();
        Logger.log('Verifying the phone number...');
        // https://firebase.google.com/docs/reference/unity/namespace/firebase/auth:  UnsupportedTenantOperation
        // https://stackoverflow.com/questions/49942415/how-to-update-user-phone-number-in-firebase-auth-js-ts
        phoneAuthProvider.verifyPhoneNumber(phone_number, this.recaptcha)
        .then((verificationId) => {
            var code = window.prompt('Please enter the verification ' +
                'code that was sent to your mobile device.');
            var cred = this.props.firebase.getPhoneAuthProviderCredential(verification_id, code);
            this.verification_id = verificationId

            this.props.firebase.updatePhoneNumberWithCredential(cred)
            .then(() => {
                this.setState({
                    country: COUNTRIES.US,
                    ...this.defaultState,
                    message: {message: 'Phone changed successfully.'},
                    ...Utils.clearAllNotificationsExceptSuccess()});
            })
            .catch(error => {
                this.setState({ error, ...Utils.clearAllNotificationsExceptError()});
            });
        }).catch((error) => this.setState({ error, ...Utils.clearAllNotificationsExceptError()}));
    };

    openPhoneChangeModal = () => {
        this.setState({showPhoneChangeModal: true, ...Utils.clearAllNotifications()});
    };

    closePhoneChangeModal = () => {
        this.setState({showPhoneChangeModal: false, phone: '', country: COUNTRIES.US, ...Utils.clearAllNotifications()});
    };

    shouldSubmitBeDisabled = () => {
        return this.state.phone == '';
    };

    drawPhoneChangeModal = () => {
        return <MpsModal
            open={this.state.showPhoneChangeModal}
            autoFocus={false}
            callback={this.submitPhoneChange}
            callbackCancel={this.closePhoneChangeModal}
            withFooter={true}
            callbackButton={"Apply"}
            callbackCancelButton={'Close'}
            title={Utils.renderTitleCenter('Update Phone')}
            body={this.phoneChangeForm()}
        />
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications() });
    };

    handleCountryChange = (country) => {
        this.setState({country: country.option, ...Utils.clearAllNotifications()});
    };

    handlePhoneChange = (event) => {
        this.setState({phone: event.currentTarget.value, ...Utils.clearAllNotifications()});
    };

    phoneChangeForm = () => {
        return <div>
            {Utils.renderError(this.state.error)}
            {Utils.renderSuccess(this.state.message)}
            <FormControl label={() => "Enter new phone number"}>
                <PhoneInput
                    country={this.state.country}
                    onCountryChange={this.handleCountryChange}
                    text={this.state.phone}
                    onTextChange={this.handlePhoneChange}
                />
            </FormControl>
        </div>
    };

    renderCurrentPhone = () => {
        return <span><LabelMedium style={{margin: 0}}>Phone</LabelMedium><ParagraphMedium style={{margin: 0, marginTop: '5px'}}>{Utils.renderPhone(this.props.firebase.getPhoneNumber())}</ParagraphMedium></span>
    }

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser => 
                (
                    <div>
                        {Utils.renderError(this.state.error)}
                        {Utils.renderSuccess(this.state.message)}
                        {this.renderCurrentPhone()}
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    );
}
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PhoneChange);
