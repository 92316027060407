import Logger from "../Utils/logger";

class Request {

    static post = (input) => {
        input.method = 'POST';

        this.request(input);
    };

    static put = (input) => {
        input.method = 'PUT';

        this.request(input);
    };

    static get = (input) => {
        input.method = 'GET';

        this.request(input);
    };

    static post_to_new_window = (input) => {
      if (input.firebase) {
        input.firebase.currentUser().getIdToken(false).then((token) => {
          var jwtInput = $('<input type="hidden" name="jwtToken">').val(token);
          $('<form method="post" target="_blank"></form>')
            .attr("action", input.route)
            .append(jwtInput)
            .appendTo('body')
            .submit()
            .remove();
        });
      }
      else {
        var jwtInput = $('<input type="hidden" name="jwtToken">').val('');
        $('<form method="post" target="_blank"></form>')
          .attr("action", input.url)
          .append(jwtInput)
          .append(idInput)
          .appendTo('body')
          .submit()
          .remove();
      }
    }

    static request = (input) => {
        if (input.firebase) {
            input.firebase.currentUser().getIdToken(false).then((token) => {
                input.headers = Object.assign({authorization: `Bearer ${token}`}, input.headers);
                this.request_base(input);
            });
        } else {
            this.request_base(input);
        }
    }

    static request_base = (input) => {
        let status_ok = true;

        let headers = {}
        headers.method = input.method || 'GET';

        if (input.headers) {
            headers.headers = input.headers;
        }

        if (input.body) {
            headers.body = input.body;
        }

        if (input.mode) {
            headers.mode = input.mode;
        }

        fetch(input.route, headers)
        .then(
            result => {
                status_ok = result.ok;

                if (422 == result.status) {
                    status_ok = false;
                    return {message: 'Timeout error. Reload page and try again.'};
                } else if (500 == result.status) {
                    status_ok = false;
                    return {message: 'Server error. Please contact support.'};
                }

                try {
                  if (input.notJson) {
                    return result
                  } else {
                    return result.json()
                  }
                } catch (error) {
                    throw new Error({message: 'Request received bad data'});
                }
            }
        ).then(
            result => {
                if (status_ok && input.callbackSuccess) {
                    input.callbackSuccess(result);
                } else if (!status_ok && input.callbackError) {
                    input.callbackError({error: result});
                }
            }
        ).catch(
            error => {
                Logger.log('Request Get: catch an error');
                Logger.log('Route: ' + input.route);
                Logger.log('Method: ' + input.method);
                Logger.error(error);
                if (input.callbackError) {
                    input.callbackError(error);
                }
            }
        )
    };
}

export default Request;
