import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import { ParagraphXSmall } from 'baseui/typography';
import Logger from '../Utils/logger';

class TypingIndicators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
            socket: this.props.socket,
            show: this.props.show
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    }

    componentDidMount = () => {
        // TODO Create a interval to check on all users that last typed and if it is greater the x seconds then
        // they may be disconnected.
        this.state.socket.on('typing', data => {
            const idx = this.state.indicators.findIndex(indicator => indicator.username == data.username);
            if (idx === -1) {
                this.setState({indicators: this.state.indicators.concat([data])});
            }
        })
    
        this.state.socket.on('stop typing', data => {
            this.setState({indicators: this.state.indicators.filter(indicator => indicator.username != data.username)});
        })

        this.state.socket.on('user left', data => {
            Logger.log('Got a notification that user ' + data.username + ' has left the chat.');
            Logger.log(this.state.indicators);
            const leftUserIdx = this.state.indicators.findIndex(user => user.username === data.username);
            if (leftUserIdx >= 0) {
                Logger.log('Found the index of the user that left the chat');
                Logger.log('deleted index: ' + leftUserIdx);
                const usernamesUpdated = this.state.indicators;
                usernamesUpdated.splice(leftUserIdx, 1);
                Logger.log(usernamesUpdated);
                this.setState({indicators: usernamesUpdated});
            }
        })
    };

    render = () => {
        if (!this.state.show) {
            return null
        }
        let comma = '';
        return <AuthUserContext.Consumer>
            {authUser => (
                <ParagraphXSmall style={{minHeight: '22px', margin: '0px 0px 0px 0px'}}>{this.state.indicators.map(indicator => {

                    const ind = comma + indicator.username;
                    comma = ', ';
                    return ind;
                })} {this.state.indicators.length > 0 ? (this.state.indicators.length > 1 ? ' are typing...' : ' is typing...') : ''}</ParagraphXSmall>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(TypingIndicators);
