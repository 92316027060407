// https://usehooks.com/useWindowSize/

class Screen {
    static width = () => {
        return window.innerWidth;
    };

    static height = () => {
        return window.innerHeight;
    };

    static isDesktop = () =>{
        return [Size.large, Size.xlarge].includes(this.size())
    };

    static size = () => {
        if (window.innerHeight < 575 && window.innerWidth < 768) {
            return Size.xsmall;
        } else if (window.innerWidth < 768) {
            return Size.small;
        } else if (window.innerWidth <= 1024) {
            return Size.medium;
        } else if (window.innerWidth <= 1900) {
            return Size.large;
        } else if (window.innerWidth > 1900) {
            return Size.xlarge
        }
    };
}

export const Size = {
    xsmall: 'XSMALL',
    small: 'SMALL',
    medium: 'MEDIUM',
    large: 'LARGE',
    xlarge: 'XLARGE'
};

export default Screen;
