import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { Textarea } from "baseui/textarea";
import { SIZE as InputSize } from "baseui/input";
import Utils from '../../shared/Utils';
import { ParagraphXSmall} from 'baseui/typography';
import History from './history';
import { Input } from "baseui/input";
import Header from './header';
import Screen from '../../shared/Utils/screen';
import ArrowUp from 'baseui/icon/arrow-up';
import FileMessageButton from '../Upload/file_message_button';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        }
    }
}

const sendButtonProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '50px'
            }),
        }
    }
}

const attachmentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '50px'
            }),
        }
    }
}

class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            room_id: this.props.room_id,
            username: this.props.username,
            protocol: this.props.protocol,
            new_message: null,
            users: [],
            success: null,
            error: null,
            message: '',
            isLoading: false,
            errorMessage: '', // Add errorMessage state
        };

        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        this.adjustTextareaHeight(); // Initial height adjustment

        if (Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL'){
            // Prevent the keyboard on iOS from closing once the send button is clicked.
            this.myRef.current.addEventListener("touchend", (e) => {
                e.preventDefault();
                this.submitMessage();
            });

            document.addEventListener('focusout', function(e) {window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })});
        }
    };

    componentDidUpdate(prevProps, prevState) {
        // Call adjustTextareaHeight when the textarea content changes
        if (prevState.message !== this.state.message) {
            this.adjustTextareaHeight();
        }
    }

    adjustTextareaHeight = () => {
        const textarea = document.querySelector('textarea[name="message_field"]');
        textarea.style.height = '44px'; // Initial height
        textarea.style.maxHeight = '100px';
    
        const scrollHeight = textarea.scrollHeight;
        const clientHeight = textarea.clientHeight;
    
        const newHeight = Math.min(scrollHeight, clientHeight + (scrollHeight - clientHeight));
        textarea.style.height = newHeight + 'px';
    };

    setMessage = (event) => {
        const message = event.target.value;

        if (message.length <= 6000) {
            this.setState({
                message: message,
                errorMessage: '',
            });
        } else {
            this.setState({
                message: message,
                errorMessage: 'Message cannot exceed 6000 characters',
            });
        }
    };

    submitMessage = () => {
        if (!this.state.message) {
            return;
        }

        this.setState({isLoading: true, new_message: { // Only used by history_message
            message: this.state.message,
            created_at: Date.now(),
            username: this.state.username,
            patient: {username: this.state.username},
            sent: true
        }});

        const data = new FormData();
        data.append('body', this.state.message);
        data.append('protocol_id', this.state.protocol.number);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_messages/chat/",
            body: data,
            callbackSuccess: result => this.setState({
                message: '',
                isLoading: false,
                success: {message: "Message sent. We'll text you any responses."},
                ...Utils.clearAllNotificationsExceptSuccess()
            }),
            callbackError: error => this.setState({
                error,
                isLoading: false,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    }

    submitMessageWithEvent = (event) => {
        event.preventDefault();

        this.submitMessage();
    };

    handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();

            if (!Utils.isMobileDevice()) {
                if (this.state.message) {
                    this.submitMessage();
                }
            } else {
                const textarea = document.querySelector('textarea[name="message_field"]');
                const currentPosition = textarea.selectionStart;
                const newValue = `${this.state.message.substring(0, currentPosition)}\n${this.state.message.substring(currentPosition)}`;

                this.setState({
                    message: newValue,
                });
            }
        }
    };


    render = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '92svh', position: 'relative', overflow:'hidden'}}>
                
                    <History
                        protocol={this.state.protocol}
                        socket={this.socket}
                        username={this.state.username}
                        new_message={this.state.new_message}
                        users={this.getUsers}
                        parent_height={this.props.parent_height}
                        style={{ flex: 1 }} // Make the history component fill available space
                    />
                
    
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', padding: '8px 0px 8px 0px', marginBottom: '0px' }}>
                    <div style={{ maxWidth: '50px'}}>
                        <FileMessageButton firebase={this.props.firebase} protocol={this.state.protocol} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flex: 1, marginLeft: '8px', marginRight: '4px' }}>
                        <Textarea
                            name='message_field'
                            value={this.state.message || ''}
                            onChange={this.setMessage}
                            placeholder={"Send message"}
                            autoFocus={false}
                            maxLength={6000}
                            style={{
                                minHeight: '44px', // Set a minimum height
                                resize: 'none',
                                marginBottom: '8px', // Add some spacing
                                width: '100%',
                                flex: 1, // Take up available space
                            }}
                            overrides={{
                                Input: {
                                    props: {
                                        onKeyDown: this.handleKeyDown,
                                    },
                                },
                            }}
                        />
                        {this.state.errorMessage && (
                            <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                        )}
                    { Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                   
                        <div style={{ maxWidth: '50px'}}><Button
                            kind={KIND.primary}
                            size={SIZE.compact}
                            shape={SHAPE.circle}
                            ref={this.myRef}
                            overrides={{
                                BaseButton: {
                                  style: ({ $theme }) => ({
                                    color: $theme.colors.primaryB,
                                    backgroundColor: '#00a1ff',
                                    ":hover": {
                                        backgroundColor: '#00a1ff'
                                    },
                                    ":focus": {
                                        backgroundColor: '#00a1ff'
                                    },
                                    ":active": {
                                        backgroundColor: '#00a1ff'
                                    },
                                    height:"42px",
                                    width:"42px",
                                    marginLeft: '8px'
                                  })
                                }
                              }}
                        >
                        <ArrowUp size={28} />
                        </Button>
                        </div>
                        : null
                    }
                    </div>
                </div>
            </div>
        );
    };       
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Chat);
