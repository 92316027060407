import React, {Component} from 'react';
import * as ROUTES from  '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import Check from 'baseui/icon/check';
import { StyledLink } from "baseui/link";
import {Input} from 'baseui/input';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { Button, KIND, SHAPE, SIZE} from 'baseui/button';
import { LabelLarge, LabelSmall, HeadingLarge, HeadingMedium, HeadingSmall, ParagraphMedium } from 'baseui/typography';
import NotFound from '../../shared/NotFound';
import {Spinner, SIZE as SpinnerSize} from 'baseui/spinner';
import ListCreditCards from '../PaymentMethod/credit_cards';
import CreditCard from '../PaymentMethod/credit_card';
import MpsModal from '../../shared/Utils/modal';
import Logger from '../../shared/Utils/logger';
import Request from '../../shared/Request';
import AddressPicker from '../../shared/Address';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const itemPropsLeft = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
};

const checkedItemProps = {
    maxWidth: '48px',
    minHeight: '40px',
    alignItems: 'center',
    justifyContent: 'flex-end',
};

class Shipping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol_id: this.props.match.params.protocol_id ? this.props.match.params.protocol_id : this.props.protocol_id,
            order_id: this.props.match.params.order_id ? this.props.match.params.order_id : this.props.order_id,
            isOrderLoaded: false,
            order: null,
            last_order: null,
            error: null,
            error_address: null,
            success: null,

            areStateLicensesLoaded: false,

            areAddressesLoaded: false,
            currentPatientAddresses: null,
            selected_address: '',
            show_address_form: false,
            submittingAddress: false,
            associatingAddress: false,

            currentPatientCards: [],
            associatingCard: false,
            selected_card: '',

            name: '',
            shipping_address_1: '',
            shipping_address_2: '',
            city: '',
            state: '',
            physician_license_states: [],
            zip: '',

            // FOMO Pharmacy
            arePharmaciesLoaded: false,
            fomo_pharmacy: {},
            fomo_pharmacy_search_value: '', // value to search by
            fomo_pharmacy_search_results: [],
            fomo_pharmacy_search_results_loaded: false,
            fomo_pharmacy_searching: false,
            submittingFomo: false,
            associatingFomo: false,
            currentPatientFomos: [],
            show_fomo_form: false,
            selected_fomo: '',

        };
    }

    isOrderEditable = () => {
        return this.state.isOrderLoaded && this.state.order && this.state.order.status == 'cart';
    };

    isOnboarding = () => {
        if (this.state.order && this.state.order.protocol_subscription) {
            return ['cart', 'reapply'].includes(this.state.order.protocol_subscription.status);
        }

        return false;
    };

    isFomo = () => {
        Logger.log(this.state.order);
        if (this.state.order && this.state.order.line_items) {
            return this.state.order.line_items.some((line_item) => line_item.type == 'VariantLineItem' && line_item.fulfillment_pharmacy_id == null);
        }

        return false;
    };

    isPartnerPharmacy = () => {
        if (this.state.order && this.state.order.line_items) {
            return this.state.order.line_items.some((line_item) => line_item.type == 'VariantLineItem' && line_item.fulfillment_pharmacy_id != null);
        }

        return false;
    };

    isAddressRequired = () => {
        return this.isOnboarding() || this.isPartnerPharmacy();
    };

    componentDidMount = () => {
        // TODO load these in a promise.all() so the errors can be set correctly
        this.getOrder();
        this.getLicenseStates();
        this.getPatientAddresses();
        this.getPatientFomos();
        window.scrollTo(0, 0);
    };

    getOrder = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_order/" + this.state.order_id,
            callbackSuccess: (result) => this.setState({
                isOrderLoaded: true,
                order: result,
                selected_address: result.address_id ? result.address_id : this.state.selected_address,
                selected_card: result.payment_method_id ? result.payment_method_id : this.state.selected_card,
                selected_fomo: result.fomo_pharmacy_id ? result.fomo_pharmacy_id : this.state.selected_fomo,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getLicenseStates = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/physician_license_states/" + this.state.protocol_id,
            callbackSuccess: (result) => {
                var licensedStates = []
                for (var element of result.state_license) {
                    licensedStates.push(element.state);
                }
                this.setState({
                    physician_license_states: licensedStates,
                    isLoaded: true,
                    ...Utils.clearAllNotifications()
                });
            },
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getPatientFomos = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/pharmacies",
            callbackSuccess: (result) => this.setState({
                arePharmaciesLoaded: true,
                currentPatientFomos: result.reverse(),
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getPatientAddresses = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_address",
            callbackSuccess: (result) => this.setState({
                areAddressesLoaded: true,
                currentPatientAddresses: result.reverse(),
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    // Addresses ////////////////////////////////////////////////////////

    setSelectedAddress = (addr_id, event) => {
        event.preventDefault();
        this.setState({selected_address: addr_id, ...Utils.clearAllNotifications()});
    }

    showCurrentAddresses = () => {
        if (this.state.currentPatientAddresses && this.state.currentPatientAddresses.length > 0) {
            return <FlexGrid
                flexGridColumnCount={1}
                flexGridRowGap={'scale400'}
            >
                {
                    this.state.currentPatientAddresses.map((address, idx) => <FlexGridItem key={idx}>
                        <StyledLink
                            href='#'
                            onClick={(event) => this.setSelectedAddress(address.id, event)}
                            className="a_no_line">
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                                    <FlexGridItem {...itemPropsLeft}>{Utils.renderAddress(address)}</FlexGridItem>
                                    <FlexGridItem {...checkedItemProps}>{address.id == this.state.selected_address
                                        ? <Button kind={KIND.secondary} shape={SHAPE.circle} size={SIZE.compact}><Check size={32} color="#22b573"/></Button>
                                        : ''}</FlexGridItem>
                                </FlexGrid>
                        </StyledLink>
                    </FlexGridItem>)}
            </FlexGrid>
        }
    };

    toggleAddressForm = () => {
        this.setState({show_address_form: true, ...Utils.clearAllNotifications()});
    };

    showNewAddressButton = () => {
        if (this.isOrderEditable()) {
            return <div><Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={this.toggleAddressForm}>Add new address</Button></div>
        }
    };

    closeAddressForm = () => {
        this.setState({
            show_address_form: false,
            submittingAddress: false,
            city: '',
            state: '',
            shipping_address_1: '',
            shipping_address_2: '',
            zip: '',
            ...Utils.clearAllNotifications()
        });
    };

    testNewAddress = (addr) => {
        this.state.shipping_address_1 = addr.street_line;
        this.state.shipping_address_2 = addr.secondary || '';
        this.state.city = addr.city;
        this.state.state = addr.state;
        this.state.zip = addr.zipcode;
    };

    setAddressError = (err) => {
        this.setState({error_address: err});
    };

    showNewAddressForm = () => {
        if (this.state.show_address_form && this.isOrderEditable()) {
            return this.state.show_address_form ? <MpsModal
            open={this.state.show_address_form}
            autoFocus={false}
            callback={this.saveNewAddress}
            callbackCancel={this.closeAddressForm}
            isLoading={this.state.submittingAddress}
            withFooter={true}
            title={Utils.renderTitleCenter("Add new address")}
            body={<>
                {Utils.renderError(this.state.error_address)}
                <AddressPicker callback={this.testNewAddress} callbackError={this.setAddressError}/>
            </>} /> : null;
        }
    };

    // Credit Cards ////////////////////////////////////////////////////////
    setSelectedCard = (id) => {
        if (this.isOrderEditable()) {
            this.setState({selected_card: id, ...Utils.clearAllNotifications()});
        }
    };

    loadCards = (newCards) => {
        var newCardsArray = Array.isArray(newCards) ? newCards : [newCards]
        this.setState(prevState => ({
            currentPatientCards: newCardsArray.concat(prevState.currentPatientCards),
            ...Utils.clearAllNotifications()
        }), () => {Array.isArray(newCards) ? false : this.setSelectedCard(newCards.id)});
    };

    // FOMO ////////////////////////////////////////////////////////
    setSelectedFomo = (id, event) => {
        event.preventDefault();

        this.setState({selected_fomo: id, ...Utils.clearAllNotifications()})
    };

    showCurrentFomos = () => {
        if (this.state.currentPatientFomos && this.state.currentPatientFomos.length > 0) {
            return <FlexGrid
                flexGridColumnCount={1}
                flexGridRowGap={'scale800'}
                border={'1px solid red'}
            >
                {
                    this.state.currentPatientFomos.map((fomo, idx) => <FlexGridItem key={idx}>
                        <StyledLink
                            href='#'
                            onClick={(event) => this.setSelectedFomo(fomo.id, event)}
                            className="a_no_line">
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'} border={'1px solid yellow'}>
                                    <FlexGridItem>{fomo.name} · {Utils.renderAddress(fomo)} · {Utils.renderPhone(fomo.phone)}</FlexGridItem>
                                    <FlexGridItem {...checkedItemProps}>{fomo.id == this.state.selected_fomo
                                    ? <Button kind={KIND.secondary} shape={SHAPE.circle} size={SIZE.compact}><Check size={32} color="#22b573"/></Button> : ''}</FlexGridItem>
                                </FlexGrid>
                        </StyledLink>
                    </FlexGridItem>)}
            </FlexGrid>
        }
    };

    toggleFomoForm = () => {
        this.setState({show_fomo_form: true, ...Utils.clearAllNotifications()});
    };

    showNewFomoButton = () => {
        if (this.isOrderEditable()) {
            return <div><Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={this.toggleFomoForm}>Add new pharmacy</Button></div>
        }
    };

    closeFomoForm = () => {
        this.setState({
            show_fomo_form: false,
            submittingFomo: false,
            fomo_pharmacy_search_results_loaded: false,
            fomo_pharmacy_searching: false,
            fomo_pharmacy_search_value: '',
            fomo_pharmacy_search_results: [],
            ...Utils.clearAllNotifications()
        });
    };

    searchForPharmacies = () => {
        this.setState({fomo_pharmacy_searching: true, ...Utils.clearAllNotifications()})

        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/pharmacies/search/" + this.state.fomo_pharmacy_search_value,
            callbackSuccess: (result) => this.setState({
                fomo_pharmacy_search_results: result,
                isLoaded: true,
                fomo_pharmacy_search_results_loaded: true,
                fomo_pharmacy_searching: false,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                fomo_pharmacy_search_results_loaded: true,
                fomo_pharmacy_searching: false,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    showNewFomoForm = () => {
        if (this.isOrderEditable()) {
            Logger.log('Showing new pharmacy form');
            Logger.log( this.state.fomo_pharmacy_search_results_loaded);
            return this.state.show_fomo_form ? <MpsModal
                open={this.state.show_fomo_form}
                autoFocus={false}
                callback={this.saveFomoToCloud}
                callbackCancel={this.closeFomoForm}
                isLoading={this.state.submittingFomo}
                withFooter={true}
                title={Utils.renderTitleCenter('Pharmacy search')}
                body={<div>
                        {Utils.renderError(this.state.error)}
                        <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale800" flexGridRowGap="scale400">
                            <FlexGridItem {...itemProps}><Input
                                type="number"
                                inputMode="numeric"
                                id="fomo_pharmacy_search_value"
                                name="fomo_pharmacy_search_value"
                                value={this.state.fomo_pharmacy_search_value}
                                onChange={this.handleChange}
                                placeholder="Find by zip code"/>
                            </FlexGridItem>
                            <FlexGridItem {...itemProps}>
                                <Button kind={KIND.secondary} shape={SHAPE.pill} onClick={this.searchForPharmacies}>Search</Button></FlexGridItem>
                        </FlexGrid>
                        {Utils.renderSpace()}
                        {Utils.renderSpace()}
                        {this.state.fomo_pharmacy_searching
                        ? <FlexGrid flexGridColumnCount={1}><FlexGridItem {...itemProps}><Spinner $size={SpinnerSize.large} /></FlexGridItem></FlexGrid>
                        : Array.isArray(this.state.fomo_pharmacy_search_results) && this.state.fomo_pharmacy_search_results.length > 0
                            ? <div>
                                <FlexGrid
                                    flexGridColumnCount={1}
                                    flexGridRowGap={'scale800'}
                                >
                                    {
                                        this.state.fomo_pharmacy_search_results.map((fomo, idx) => <FlexGridItem key={idx}>
                                            <StyledLink
                                                href='#'
                                                onClick={(event) => this.setState({fomo_pharmacy: fomo, ...Utils.clearAllNotifications()})}
                                                className="a_no_line">
                                                    <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                                                        <FlexGridItem>{fomo.name} - {Utils.renderAddress(fomo)} - {Utils.renderPhone(fomo.phone)}</FlexGridItem>
                                                        <FlexGridItem {...checkedItemProps}>{this.state.fomo_pharmacy && fomo.ncpdp_pharmacy_id == this.state.fomo_pharmacy.ncpdp_pharmacy_id
                                                            ? <Button kind={KIND.secondary} shape={SHAPE.circle} size={SIZE.compact}><Check size={32} color="#22b573"/></Button>
                                                            : ''}</FlexGridItem>
                                                    </FlexGrid>
                                            </StyledLink>
                                        </FlexGridItem>)
                                    }
                                </FlexGrid>
                            </div>
                            : <ParagraphMedium style={{textAlign: 'center'}}>{
                                this.state.fomo_pharmacy_search_results_loaded
                                ? 'No results found. Try another city or zip code.'
                                : ''
                            }</ParagraphMedium>
                        }
                    </div>
                }
            /> : null;
        }
    };

    associateAddressToOrder = () => {
        if (this.state.selected_address && this.state.order_id) {
            this.setState({associatingAddress: true});

            const data = new FormData();

            data.append('address_id', this.state.selected_address);
            data.append('order_id', this.state.order_id);

            return new Promise((resolve, reject) => {
                Request.post({
                    firebase: this.props.firebase,
                    route: ROUTES.BASE_URL_API + "/patient_address/associate_address_with_order",
                    body: data,
                    callbackSuccess: (result) => resolve(result),
                    callbackError: (error) => reject(error.message || error)
                })
            });
        } else {
            //no address to save.
            return true;
        }
    };

    associateFomoToOrder = () => {
        var fomo_pharmacy_id = this.state.selected_fomo;
        if (fomo_pharmacy_id) {
            this.setState({associatingFomo: true});
            return new Promise((resolve, reject) => {
                Request.put({
                    firebase: this.props.firebase,
                    route: ROUTES.BASE_URL_API + "/patient_order/" + this.state.order_id,
                    headers: {"Content-type" : 'application/json'},
                    body: JSON.stringify({order: {fomo_pharmacy_id: fomo_pharmacy_id}}),
                    callbackSuccess: (result) => resolve(result),
                    callbackError: (error) => reject(error)
                })
            });
        } else {
            //no fomo to save
            return true;
        }
    };

    saveNewAddress = () => {
        if (this.state.submittingAddress) {
            return;
        }

        if (!this.validateAddress()){
            return;
        }

        this.setState({submittingAddress: true, ...Utils.clearAllNotifications()});

        const data = new FormData();

        data.append('address_1', this.state.shipping_address_1);
        data.append('address_2', this.state.shipping_address_2);
        data.append('city', this.state.city);
        data.append('state', this.state.state);
        data.append('zip', this.state.zip);

        data.append('protocol_id', this.state.protocol_id);
        data.append('order_id', this.state.order_id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_address",
            body: data,
            callbackSuccess: (result) => this.setState(prevState => ({
                isLoaded: true,
                submittingAddress: false,
                success: !this.state.error ? {message: 'Settings saved successfully.'} : null,
                show_address_form: false,
                selected_address: result.address.id,
                currentPatientAddresses: [...prevState.currentPatientAddresses, result.address],
                ...Utils.clearAllNotifications()
            })),
            callbackError: (error) => this.setState({
                submittingAddress: false,
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    validateAddress = () => {
        if (this.state.zip == '' || this.state.state == '' || this.state.shipping_address_1 == '' || this.state.city == '') {
            this.setState({error: {message: 'A complete address is required.'}, ...Utils.clearAllNotificationsExceptError()})
            return false;
        }

        return true;
    };

    associatePaymentMethodToOrder = () => {
        var selected_card_id = this.state.selected_card;
        if (selected_card_id) {
            this.setState({associatingCard: true});
            return new Promise((resolve, reject) => {
                const data = new FormData();

                data.append('payment_method_id', selected_card_id);
                data.append('order_id', this.state.order_id);

                Request.post({
                    firebase: this.props.firebase,
                    route: ROUTES.BASE_URL_API + "/patient_payment/associate_payment_method_with_order",
                    body: data,
                    callbackSuccess: (result) => resolve(result),
                    callbackError: (error) => reject(error)
                })
            });
        } else {
            return true;
        }
    };

    //save the fomo_pharmacy to the cloud
    saveFomoToCloud = () => {

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/pharmacies",
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify({
                fomo_pharmacy: this.state.fomo_pharmacy,
                order_number: this.state.order_id
            }),
            callbackSuccess: (result) => this.setState(prevState => ({
                isLoaded: true,
                submittingFomo: false,
                success: !this.state.error ? {message: 'Settings saved successfully.'} : null,
                show_fomo_form: false,
                selected_fomo: result.fomo_pharmacy.id,
                fomo_pharmacy_search_results: [],
                fomo_pharmacy_search_value: '',
                fomo_pharmacy_search_results_loaded: false,
                fomo_pharmacy_searching: false,

                currentPatientFomos: [result.fomo_pharmacy, ...prevState.currentPatientFomos],
                ...Utils.clearAllNotifications()
            })),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    handleMonthYearCCChange = (event) => {
        this.setState({month_year: event.target.value, ...Utils.clearAllNotifications()});
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications()});
    };

    saveDetails = (event) => {
        if (this.state.error) {
            event.stopPropagation();
        } else {
            var addressPromise = this.associateAddressToOrder();
            var paymentPromise = this.associatePaymentMethodToOrder();
            var fomoPromise = this.associateFomoToOrder();
            Promise.all([addressPromise, paymentPromise, fomoPromise]).then((values) => {
                if (!this.state.error) {
                    if (this.isOnboarding()){
                        this.props.history.push(ROUTES.ORDER_REVIEW + '/' + this.state.order_id);
                    } else {
                        this.props.canContinue(true);
                    }
                }
            }).catch(error =>
                this.setState({associatingAddress: false, associatingFomo: false, associatingCard: false, error: error.error, ...Utils.clearAllNotificationsExceptError()})
            );
        }
    };

    isSelectedStateAllowed = (id) => {
        if(this.state.currentPatientAddresses){
            if (!id) {
                id = this.state.selected_address;
            }
            const selectedAddr = this.state.currentPatientAddresses.filter(addr => addr.id == id && this.isStateAllowed(addr.state));
            return selectedAddr.length > 0;
        }

        return false;
    };

    isStateAllowed = (state) => {
        return this.state.physician_license_states && this.state.physician_license_states.includes(state);
    };

    canContinue = () => {
        return (!this.state.error
            && ((this.isAddressRequired() && this.state.selected_address) || !this.isAddressRequired())
            && this.state.selected_card
            && this.state.selected_fomo //((this.isFomo() && this.state.selected_fomo) || !this.isFomo()) --use this if PartnerPharmacy is reintroduced.
        )
    };

    renderContinueLink = () => {
        return <Button
            disabled={!this.canContinue()}
            onClick={this.saveDetails}
            kind={KIND.primary}
            size={SIZE.default}
            shape={SHAPE.pill}
            isLoading={this.state.associatingAddress || this.state.associatingCard || this.state.associatingFomo}
        >Continue</Button>
    };

    render = () => {

        if (this.state.order && this.state.order.status != 'cart') {
            return <NotFound />
        }

        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
            <div style={{marginTop:'98px'}}>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.success)}
                {Utils.renderTitleCenter('Pharmacy & payment preferences')}
                {
                    !this.isPartnerPharmacy() ? <div>
                        {Utils.renderTitle6('Your pharmacy')}
                        <ParagraphMedium style={{margin: '0px'}}></ParagraphMedium>
                        {Utils.renderSpace()}
                        {this.showCurrentFomos()}
                        {Utils.renderSpace()}
                        {this.showNewFomoButton()}
                        {this.showNewFomoForm()}
                        {Utils.renderSpace()}
                    </div> : ''
                }

                {this.isAddressRequired() ?
                    <div>
                        {Utils.renderSpace()}
                        {Utils.renderTitle6(this.isPartnerPharmacy() ? 'Delivery address' : 'Your address')}
                        {Utils.renderSpace()}
                        {this.isPartnerPharmacy()
                        ? <ParagraphMedium style={{margin: '0px'}}>For meds shipped by Pharmr digital pharmacies</ParagraphMedium>
                        : <ParagraphMedium style={{margin: '0px'}}>Add your address here. It must be in one of the states the doctor is licensed: <strong>{this.state.physician_license_states.join(", ")}</strong></ParagraphMedium>}
                        {Utils.renderSpace()}
                        {this.showCurrentAddresses()}
                        {Utils.renderSpace()}
                        {this.showNewAddressButton()}
                        {this.showNewAddressForm()}
                        {Utils.renderSpace()}
                    </div>
                    : ''
                }

                <div>
                    <ListCreditCards title={'Payment method'} cards={this.state.currentPatientCards} loadCards={this.loadCards} selected_card={this.state.selected_card} newCardCallback={this.setSelectedCard}/>
                    {Utils.renderSpace()}
                    {this.isOrderEditable() ?
                        <CreditCard title='Add payment' callback={this.loadCards}/>
                        : ''
                    }
                    {Utils.renderSpace()}
                </div>

                <div>
                    <div style={{textAlign: 'center', marginBottom: '100px'}}>
                        {Utils.renderSpace()}
                        {this.renderContinueLink()}
                    </div>
                </div>
            </div>
            )
        }
    </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Shipping);
