import React, { Component } from 'react';
import { ParagraphXSmall} from 'baseui/typography';
import History from './history';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { Input } from "baseui/input";
import Header from './header';

class Announcements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            room_id: this.props.room_id,
            username: this.props.username,
            protocol: this.props.protocol,
            users: [],
        }
    }

    componentDidMount = () => {};
    componentWillUnmount = () => {};

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser => (
                <div>
                    <History
                        socket={this.socket}
                        username={this.state.username}
                        new_message={this.state.new_message}
                        users={this.getUsers}
                        parent_height={this.props.parent_height}
                        protocol={this.state.protocol}
                    />
                    <Input
                        disabled={true}
                        value={this.state.message}
                        onChange={this.messageChanged}
                        placeholder={"You do not have permissions to message."}
                        maxLength={0}
                        overrides={{
                            Input: {
                                props: {
                                    onKeyDown: this.handleKeyDown,
                                },
                            },
                            Root: {
                                style: ({ $theme }) => ({
                                    borderTopStyle: 'none',
                                    borderRightStyle: 'none',
                                    borderBottomStyle: 'none',
                                    borderLeftStyle: 'none',
                                    borderTopColor: 'none',
                                    borderRightColor: 'none',
                                    borderBottomColor: 'none',
                                    borderLeftColor: 'none',
                                    
                                })
                            }
                        }}
                    />
                </div> 
            )}
        </AuthUserContext.Consumer>
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Announcements);
