import React, {Component} from 'react';
import { StyledLink } from "baseui/link";
import * as ROUTES from '../../physician/Constants';;
import { AuthUserContext, withAuthorization } from '../Session';
import { ParagraphLarge} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import content from './content';
import SignOut from '../SignOut';

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};

class PhysicianNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: this.getContent()
        }
    }

    getContent = () => {
        return content('en').physician;
    };

    callback = () => {
        this.props.callback();
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <FlexGrid flexGridColumnCount={1} flexGridRowGap={this.state.content.content_spacing} margin={this.state.content.content_margin}>
                            <FlexGridItem {...contentProps}>
                                <StyledLink href={ROUTES.HOME} onClick={this.callback} className="a_no_line">
                                    <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.communities}</ParagraphLarge>
                                </StyledLink>
                            </FlexGridItem>
                            <FlexGridItem {...contentProps} {...topLinners}>
                                <StyledLink href={ROUTES.ACCOUNT} onClick={this.callback} className="a_no_line">
                                <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.account}</ParagraphLarge>
                                </StyledLink>
                            </FlexGridItem>
                            <FlexGridItem {...contentProps} {...topLinners}>
                                <StyledLink href={this.state.content.support_link} target="_blank" onClick={this.callback} className="a_no_line">
                                <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.support}</ParagraphLarge>
                                </StyledLink>
                            </FlexGridItem>
                            <FlexGridItem {...contentProps} {...topLinners}>
                                <SignOut link={true} />
                            </FlexGridItem>
                        </FlexGrid>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
};
const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PhysicianNav);
