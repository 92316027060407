import React, {Component} from 'react';
import Utils from '../../shared/Utils';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {ParagraphMedium, HeadingSmall} from 'baseui/typography';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Request from '../../shared/Request';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class OrderStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            order_id: this.props.match.params.order_id ? this.props.match.params.order_id : null,
            order: null,
        };  
    }

    componentDidMount = () => {
        this.getOrder();
        window.scrollTo(0, 0);
    }; 

    getOrder = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_order/" + this.state.order_id,
            callbackSuccess: (result) => this.setState({
                order: result,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    renderStatus = () => {
        if (this.state.order) {
            return <FlexGrid
            flexGridColumnCount={1}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale600"
        >
                <FlexGridItem><HeadingSmall style={{textAlign: 'center', margin: 0}}>Your order {this.state.order.number} has been submitted</HeadingSmall></FlexGridItem>
                <FlexGridItem><ParagraphMedium style={{textAlign: 'center', margin: 0}}>You will be notified about any status changes to your drug request</ParagraphMedium></FlexGridItem>
                <FlexGridItem {...itemProps}>
                    <Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.large} $as="a" href={ROUTES.PROTOCOL_HOME + '/' + this.state.order.protocol_subscription.protocol.number}>
                        Return to {this.state.order.protocol_subscription.protocol.name}
                    </Button>
                </FlexGridItem>
            </FlexGrid>
        }
    }

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div>
                        {Utils.renderError(this.state.error)}
                        {Utils.renderSpace()}
                        {this.renderStatus()}
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(OrderStatus);
