import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { HeadingXSmall, LabelMedium } from 'baseui/typography';
import Storage from '../../shared/Storage';
import {Checkbox, STYLE_TYPE, LABEL_PLACEMENT} from "baseui/checkbox";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';

const themeProp = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '42px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }),
        },
    },
};

class Theme extends Component {
    constructor(props){
        super(props);

        this.state = {
            dark: ['dark', undefined, null].includes(Storage.get('theme'))
        }
    }

    switchThemeCheck = (dark) => {
        if (dark) {
            Storage.set('theme', 'dark')
        } else {
            Storage.set('theme', 'light')
        }

        window.location.reload();
    };

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser => (<>
                    <HeadingXSmall style={{margin: '0px 0px 15px 0px'}}>Color mode</HeadingXSmall>
                    <FlexGrid flexGridColumnCount={2}>
                        <FlexGridItem {...themeProp}><LabelMedium>Light</LabelMedium></FlexGridItem>
                        <FlexGridItem>
                            <Checkbox
                                checked={this.state.dark}
                                checkmarkType={STYLE_TYPE.toggle_round}
                                onChange={e => this.switchThemeCheck(e.target.checked)}
                                labelPlacement={LABEL_PLACEMENT.right}
                            >
                                Dark
                            </Checkbox>
                        </FlexGridItem>
                    </FlexGrid>
                </>
            )}
        </AuthUserContext.Consumer>
    );
}
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Theme);
