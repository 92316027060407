import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../Constants';
import {SignInLink} from '../SignIn';
import { Input } from "baseui/input";
import Utils from '../Utils';
import { Button, SHAPE, SIZE, KIND } from "baseui/button";
import MFA from '../MFA';
import MFACodeVerification from '../MFA/code_verification';
import Logger from '../Utils/logger';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { FormControl } from "baseui/form-control";
import { StyledLink } from "baseui/link";
import {Checkbox} from 'baseui/checkbox';
import { Select } from "baseui/select";
import Request from '../../shared/Request';
import ReportIssue from '../../shared/ReportIssue';
import { Banner, HIERARCHY, KIND as BannerKind} from "baseui/banner";

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

const SignUpPage = () => <SignUpForm />

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            isLoading: false,
            message: null,
            error: null,

            sign_up_complete: false,
            show_email_code_verification_form: this.props.match.params.code ? true : false,
            name: '',
            email: '',
            password: '',
            concent: false,
            showReportIssue: false,

            accountType: '',
        };

        if (this.props.firebase && this.props.firebase.currentUser() && !this.props.firebase.isEmailVerified()) {
            this.defaultState.sign_up_complete = true;
            this.defaultState.error = {message: 'Email has not been verified yet. Please enter the code from your email.'};
        }

        Logger.log('Sign Up props match params:');
        Logger.log(this.props.match.params);

        this.state = {...this.defaultState};

        this.mfa = new MFA(this.props.firebase);
    }

    componentDidMount = () => {
        if (this.props.firebase && this.props.firebase.currentUser() && this.props.firebase.isEmailVerified() && this.props.firebase.isUserMFAEnrolled()) {
            this.props.history.push(ROUTES.HOME);
        } else if (this.props.firebase && this.props.firebase.currentUser() && this.props.firebase.isEmailVerified() && !this.props.firebase.isUserMFAEnrolled()) {
          Logger.log('not enrolled!');
          this.setState({redirect: ROUTES.MFA_ENROLL, ...Utils.clearAllNotifications()});
        }
        window.scrollTo(0, 0);
    };

    changeHandler = (event) => {
        this.setState({[event.target.name]: event.target.value, ...Utils.clearAllNotifications()});
    };

    changeHandlerEmail = (event) => {
        this.setState({[event.target.name]: event.target.value.toLowerCase(), ...Utils.clearAllNotifications()});
    };

    showReportIssue = () => {
        this.setState({showReportIssue: true});
    };

    closeReportIssue = () => {
        this.setState({showReportIssue: false});
        this.props.closeCallback();
    };

    handleChange = (params) => {
        this.setState({ accountType: params.value });
      };

      handleChange = (params) => {
        if (params.value.length === 0) {
          // Handle the case where the value is cleared (empty array).
          // You can set it to a default value or perform any other desired action.
          // For example, setting it to an empty array as it was before.
          this.setState({ accountType: '' });
        } else {
          this.setState({ accountType: params.value });
        }
      };

    onSubmit = event => {
        this.setState({isLoading: true});
        event.preventDefault();

        const {email, password} = this.state;

        this.props.firebase
            .createUserWithEmailAndPassword(email, password)
            .then(user => {
                if (ROUTES.CREATE_USER_ON_SIGNUP) {
                    this.createAccountOnMainApp();
                }

                this.setState({sign_up_complete: true, isLoading: false, ...Utils.clearAllNotifications()});
            })
            .catch(error => {this.setState({error, isLoading: false, ...Utils.clearAllNotificationsExceptError()});});
    };

    createAccountOnMainApp = () => {
        const data = new FormData();
        data.append('email', this.state.email);
        this.props.firebase.currentUser().getIdToken(false).then((token) => {
            fetch(
                //TODO make this save consents also?
                ROUTES.BASE_URL_API + "/user_management",
                {
                    headers: {
                        "authorization" : `Bearer ${token}`,
                    },
                    method: 'POST',
                    body: data
                }
            ).then(res => res.json()).then(this.saveConsents)
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            })
        });
    }

    saveConsents = () => {
        if (this.props.firebase.isPatient()) {
            this.saveConsent('consent-to-telehealth.md');
            this.saveConsent('terms-of-use.md');
            this.saveConsent('privacy-policy.md');
        } else {
            //TODO build run consent controller and routes...
            this.saveConsent('privacy-policy.md');
            this.saveConsent('terms-of-use.md');

        }
    };

    saveConsent = (agreement_name) => {
        var data = {
            consent_record: {
                agreement_name: agreement_name
            }
        }
        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/consent_records",
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify(data),
            callbackSuccess: (result) => {
            },
            callbackError: (error) => {
                //Fail silently for now.
            }
        })
    };

    shouldSubmitBeDisabled = () => {
            return this.state.password === ''
            || this.state.email === ''
            || this.state.concent == false;
    };

    renderEmailVerificationComplete = () => {
        return <div style={{textAlign: 'center'}}>
            <MFACodeVerification type='email' mfa={this.mfa} phone_number={null}/>
        </div>
    };

    consent = () => {
        if (this.props.firebase.isPatient()) {
            return <span>By checking this box, I agree that I have read, understood, and consent to Clinic’s <StyledLink href={"https://github.com/Pharmr/legal/blob/main/terms-of-use.md"} target="_blank">Terms of Use</StyledLink>, <StyledLink href={"https://github.com/Pharmr/legal/blob/main/privacy-policy.md"} target="_blank">Privacy Policy</StyledLink>, and <StyledLink href={"https://github.com/Pharmr/legal/blob/main/consent-to-telehealth.md"} target="_blank">Consent to Telehealth</StyledLink>.</span>
        } else {
            return <span>By checking this box, I agree that I have read, understood, and consent to Clinic’s <StyledLink href={"https://github.com/Pharmr/legal/blob/main/platform-access-agreement.md"} target="_blank">Platform Access Agreement</StyledLink>, <StyledLink href={"https://github.com/Pharmr/legal/blob/main/business-associate-agreement.md"} target="_blank">Business Associates Agreement</StyledLink>, and <StyledLink href={"https://github.com/Pharmr/legal/blob/main/privacy-policy.md"} target="_blank">Privacy Policy</StyledLink></span>
        }
    };

    signupTitle = () => {
        if (this.props.firebase.isPatient()) {
            return <span>Sign up with Clinic.dev</span>
        } else {
            return <span>Create your Clinic.dev account</span>
        }
    };

    signupSubTitle = () => {
        if (this.props.firebase.isPatient()) {
            return null
        } else {
            return <div><Banner hierarchy={HIERARCHY.low} kind={BannerKind.info} overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    margin: 0,
                    padding: 0
                  })
                }
              }}>
            Creating a clinic is free. All clinics include an eRx, messaging, and labs. If you charge membership, Clinic.dev retains 20% in addition to credit card transaction fees.
          </Banner></div>
        }
    };

    renderSignUpForm = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600" maxWidth="350px">
            {/*<FlexGridItem {...centerProps} style={{textAlign: 'left'}}>
              <ParagraphMedium style={{margin:'0px'}}>{this.signupSubTitle()}</ParagraphMedium>
            </FlexGridItem>
            this.props.firebase.isPatient() ? null : <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginTop: 0,
                                marginBottom: 0
                            })
                        },
                        Label: {
                            style: ({$theme}) => ({
                                marginTop: 0
                            })
                        }
                    }}
                    label={() => "Account type"}>
                    <Select
                        name="accountType"
                        options={[
                        { label: "I am a US-licensed MD", id: "MD" },
                        { label: "I am a US-licensed DO", id: "DO" },
                        { label: "I am a US-licensed APRN", id: "APRN" },
                        { label: "I am clinical staff", id: "Clinical staff" }
                        ]}
                        value={this.state.accountType}
                        required
                        searchable={false}
                        placeholder="Select your account type"
                        onChange={this.handleChange}
                        overrides={{
                            Input: {
                                style: ({ $theme }) => ({
                                    readOnly: 'true',
                                    cursor: 'pointer',
                                })
                              }
                        }
                    }
                    />
                </FormControl>
                </FlexGridItem> */}
            <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginTop: 0,
                                marginBottom: 0
                            })
                        },
                        Label: {
                            style: ({$theme}) => ({
                                marginTop: 0
                            })
                        }
                    }}
                    label={() => "Email address"}>
                    <Input
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.changeHandlerEmail}
                        onBlur={() => {
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        overrides={{
                            Input: {
                                style: {
                                textTransform: 'lowercase'
                                },
                            }
                          }}
                    />
                </FormControl>
            </FlexGridItem>
            <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginTop: 0,
                                marginBottom: 0
                            })
                        },
                        Label: {
                            style: ({$theme}) => ({
                                marginTop: 0
                            })
                        }
                    }}
                    label={() => "Enter password"}>
                    <Input
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.changeHandler}
                        onBlur={() => {
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        //overrides={{
                            //MaskToggleButton: () => null,
                        //}}
                    />
                </FormControl>
            </FlexGridItem>
            <FlexGridItem>
                <Checkbox
                    checked={this.state.concent}
                    onChange={() => this.setState({concent: !this.state.concent, message: null})}
                    > {this.consent()}
                </Checkbox>
            </FlexGridItem>
            <FlexGridItem></FlexGridItem>
            <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.primary}
                    shape={SHAPE.pill}
                    disabled={this.shouldSubmitBeDisabled()}
                    onClick={this.onSubmit}
                    isLoading={this.state.isLoading}
                >Continue</Button>
            </FlexGridItem>
            <ReportIssue show={this.state.showReportIssue} callback={this.closeReportIssue}/>
        </FlexGrid>
    };

    renderRecaptcha = () => {
        return <div id='recaptcha'></div>
    };

    renderSignInLink = () => {
        return <div style={{textAlign: 'center'}}>{Utils.renderSpace()} <SignInLink /></div>
    };


    renderForms = () => {
        if (this.state.sign_up_complete) {
            return this.renderEmailVerificationComplete();
        } else {
            return <div>{Utils.renderTitleCenter(this.signupTitle())} <FlexGrid><FlexGridItem {...centerProps}>{this.renderSignUpForm()}</FlexGridItem></FlexGrid> {this.renderRecaptcha()} {this.renderSignInLink()}<FlexGrid><FlexGridItem {...centerProps}><ParagraphMedium>Trouble signing up? <StyledLink href={"#"} onClick={this.showReportIssue} style={{color:"#00a1ff"}}>Click here</StyledLink></ParagraphMedium></FlexGridItem></FlexGrid></div>
        }
    };

    render() {
        if (this.state.redirect) {
          Logger.log('signup redirecting to ' + this.state.redirect);
          this.props.history.push(this.state.redirect);
          return null;
        }
        return (
            <div>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.message)}
                <div style={{marginTop:'20px'}}>
                    {this.renderForms()}
                </div>
            </div>
        );
    }
}

const SignUpLink = () => <ParagraphMedium style={{margin: 0}}>Don't have an account? <StyledLink href={ROUTES.SIGN_UP} style={{color:"#00a1ff"}}>Sign Up</StyledLink></ParagraphMedium>

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
