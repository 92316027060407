import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {HeadingMedium, HeadingSmall, HeadingXSmall, LabelLarge, LabelMedium, LabelSmall, ParagraphLarge, ParagraphMedium, ParagraphSmall} from 'baseui/typography';
import {Table, DIVIDER, SIZE as TableSize} from 'baseui/table-semantic';
import { ButtonGroup, MODE } from "baseui/button-group";
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import Logger from '../../shared/Utils/logger';
import content from './content';
import Linkify from 'react-linkify';
import Presence from '../../shared/Presence';
import MetaTagsOverride, {MetaTagPages} from '../../shared/Metatags';
import { ThemeProvider, createTheme, darkThemePrimitives} from "baseui";
import Screen, {Size as ScreenSize} from '../../shared/Utils/screen';
import { Drawer, SIZE as DrawerSize, ANCHOR  } from "baseui/drawer";
import { toaster, ToasterContainer } from "baseui/toast";
import { Block } from "baseui/block";
import Storage from '../../shared/Storage';
import { Avatar } from "baseui/avatar";
import { NotificationCircle } from "baseui/badge";
import { Check } from 'baseui/icon';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE as ModalSize
  } from 'baseui/modal';

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const buttonProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '16px',
                marginRight: '16px',
            }),
        },
    },
};

const overrides = {
    Root: {
      style: {
        maxHeight: '300px',
      },
    },
  };

const upperProps = {
    overrides: {
        Block: {
            style: ({$there}) => ({
                minHeight: '76px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
            })
        }
    }
}

const setNoScroll = () => {
    $(document.body).css("overflow", 'hidden');

    return null;
};


// Tabs
const ABOUT = 0;
const FORMULARY = 1;
const SUBSCRIPTION = 2;
const CREATOR = 3;
const REVIEWS = 4;

class ProtocolView extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            protocol: null,
            protocol_subscription: null,
            products: null,
            states: null,
            error: null,
            protocol_id: this.props.match.params.id,
            selectedTab: 0,
            content: this.getContent(),
            online_patients: 0,
            containerHeight: this.calcContainerHeight(), // 10px extra margin
            open_about: false,
            open_creator: false,
            isOpen: false
            //backdrop_clicked: false
        };

        this.close = this.close.bind(this);
    }

    subscribeButtonName = () => {
      if (this.state.protocol && this.state.protocol.billing_price == 0) {
        return 'Join';
      } else {
        return 'Subscribe';
      }
    };


    close = () => {
        this.setState({ isOpen: false });
    };

    getContent = () => {
        return content('en');
    };

    componentDidMount = () => {
        this.getProtocol();
        window.addEventListener("resize", () => this.setState({containerHeight: this.calcContainerHeight()}));
    };

    getProtocol = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/" + this.state.protocol_id,
            callbackSuccess: (result) => this.setState({
                isLoaded: true,
                protocol: result.data.protocol,
                protocol_subscription: result.data.protocol_subscription,
                products: result.data.products,
                states: result.data.states,
                online_patients: result.data.patients_online,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    calcContainerHeight = () => window.innerHeight - 42 - 47;

    renderProtocol = () => {
        if (this.state.protocol) {
            return <HeadingMedium style={{margin: '4px 0px 2px 0px', fontSize: '28px', lineHeight: '36px'}}>{this.state.protocol.name}</HeadingMedium>;
        }
    };

    renderSubscribers = () => {
        if (this.state.protocol){
            return <ParagraphMedium style={{margin: '0px 0px 4px 0px', color: '#999999'}}>{this.state.protocol.subscriber_count} members </ParagraphMedium>; //• {this.state.online_patients} online
        }
    };

    renderSummary = () => {
        if (this.state.protocol) {
            return <LabelMedium style={{
                margin: '0px 0px 14px 0px',
                textAlign: 'center',
                maxWidth: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px'

            }}>{this.state.protocol.summary}</LabelMedium>;
        }
    };

    renderButtonName = () => {
        if (this.state.protocol_subscription) {
            if (this.state.protocol_subscription.status == 'cart') {
                return [this.subscribeButtonName(), ROUTES.PROTOCOL_SUBSCRIPTION + '/' + this.state.protocol_id];
            } else if (['reapply', 'dead'].includes(this.state.protocol_subscription.status)) {
                return ['⚠️ View update', ROUTES.PROTOCOL_SUBSCRIPTION + '/' + this.state.protocol_id];
            } else if (['active', 'inactive', 'active_pending_cancel'].includes(this.state.protocol_subscription.status)) {
                return ['Go to dashboard', ROUTES.PROTOCOL_HOME + '/' + this.state.protocol_id]
            } else {
                return ['Go to status', ROUTES.PROTOCOL_SUBSCRIPTION + '/' + this.state.protocol_id]; // Pending
            }
        } else {
            // Not subscription in cart yet
            return [this.subscribeButtonName(), ROUTES.PROTOCOL_SUBSCRIPTION + '/' + this.state.protocol_id]
        }
    }

    renderProtocolSubscribeLink = () => {
        if (!this.state.isLoaded || !this.state.protocol) {
            return '';
        }

        const [buttonName, buttonLink] = this.renderButtonName();
        if (this.state.protocol.rejecting_new_subscriptions && buttonName === this.subscribeButtonName()) {
            return <Button
                kind={KIND.primary}
                shape={SHAPE.pill}
                size={SIZE.large}
                disabled={true}
                overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            maxWidth: '570px',
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            paddingRight: '0px',
                            paddingLeft: '0px',
                        })
                    }
                }}
                >{buttonName}</Button>
        } else if (buttonName === this.subscribeButtonName()){
            return <FlexGridItem {...itemProps}>
                <Button
                kind={KIND.primary}
                shape={SHAPE.pill}
                size={SIZE.large}
                onClick={() => this.setState({ isOpen: true })}
                overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            maxWidth: '570px',
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            paddingRight: '0px',
                            paddingLeft: '0px',
                        })
                    }
                }}
                >{buttonName}</Button>
                <Modal onClose={this.close} isOpen={this.state.isOpen} overrides={{
                    Dialog: {
                    style: ({ $theme }) => ({
                        width: '343px',
                    })
                    }
                }}>
                <ModalHeader>Before you get started, confirm you're in one of these states:</ModalHeader>
                <ModalBody>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap={"scale500"}style={{marginTop: '24px',
                        marginBottom: '24px'}}>
                        <FlexGridItem>
                            <ParagraphSmall style={{margin: '0px'}}>{this.renderStates()}</ParagraphSmall>
                        </FlexGridItem>
                    </FlexGrid>
                </ModalBody>
                <ModalFooter>
                        <ModalButton kind={KIND.tertiary} onClick={this.close}>
                        Cancel
                        </ModalButton>
                        <ModalButton
                        $as="a"
                        href={buttonLink}
                        >Confirm & continue</ModalButton>
                </ModalFooter>
                </Modal></FlexGridItem>

        } else {

        return <Button
                kind={KIND.primary}
                shape={SHAPE.pill}
                size={SIZE.large}
                $as="a"
                href={buttonLink}
                width={null}
                overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            maxWidth: '570px',
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            paddingRight: '0px',
                            paddingLeft: '0px',
                        })
                    }
                }}
                >{buttonName}</Button>
            }
    };

    renderProducts = () => {
        const COLUMNS = ['Therapeutics', 'Form', 'Restrictions'];
        const DATA = [];
        this.state.products.product_categories[0].products.sort((a, b) => {return (a.display_name.toLowerCase() > b.display_name.toLowerCase()) ? 1 : -1})
        this.state.products.product_categories[0].products.map(product => DATA.push([
            product.display_name,
            (Array.isArray(product.product_properties) && product.product_properties.length > 0 ? product.product_properties[0].value : null),
            (Array.isArray(product.product_properties) && product.product_properties.length > 1 ? product.product_properties[1].value : null)
        ]));
               return  <div /*style={{maxheight: '300px'}}*/><Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/></div>
    };

    renderProductsSM = () => {
        const COLUMNS = ['Available by request'];
        const DATA = [];
        this.state.products.product_categories[0].products.sort((a, b) => {return (a.display_name.toLowerCase() > b.display_name.toLowerCase()) ? 1 : -1})
        this.state.products.product_categories[0].products.map(product => DATA.push([
            product.display_name,
            ]));
               return  <Table overrides={overrides} columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/>
    };

    setSelectedTab = (event, index) => {
        this.setState({selectedTab: index, ...Utils.clearAllNotifications()});
    };

    renderProductList = () => {
        const productList = this.state.products.product_categories[0].products.sort((a, b) => {
          return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1;
        });

        return (
          <div>
            {productList.map((product) => (
              <ParagraphSmall style={{margin: '0px'}} key={product.display_name}>• {product.display_name}</ParagraphSmall>
            ))}
          </div>
        );
      };

    renderTabs = () => {
        return <ButtonGroup
            mode={MODE.radio}
            shape={SHAPE.pill}
            size={SIZE.compact}
            selected={this.state.selectedTab}
            onClick={(event, index) => {
                this.setSelectedTab(event, index);
            }}

            overrides={{
                Root: {
                    style: ({$theme}) => ({
                        maxWidth: 0.95 * window.screen.width + 'px',
                        overflowX: 'auto',
                        boxSizing: 'border-box',
                        padding: '0px',
                        margin: '5px 0px 12px 0px'

                    }),
                },
            }}>

            <Button>About</Button>
            <Button>Formulary</Button>
            <Button>Membership</Button>
            <Button>Creator</Button>
            <Button disabled>Reviews</Button>
        </ButtonGroup>
    };

    renderButtons = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridRowGap={['XSMALL'].includes(Screen.size()) ? 'scale100' : 'scale300'}>
            <FlexGridItem {...buttonProps}>{this.renderProtocolSubscribeLink()}
            </FlexGridItem>
            <FlexGridItem {...buttonProps}>
                <Button
                    shape={SHAPE.pill}
                    size={SIZE.large}
                    kind={KIND.secondary}
                    onClick={this.executeOnOpenAbout}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                maxWidth: '570px',
                                width: '100%',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                            })
                        }
                    }}
                    >+&nbsp;About&nbsp;the&nbsp;clinic</Button>
            </FlexGridItem>
            <FlexGridItem {...buttonProps}>
                <Button
                    shape={SHAPE.pill}
                    size={SIZE.large}
                    kind={KIND.secondary}
                    onClick={this.executeOnOpenCreator}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                maxWidth: '570px',
                                width: '100%',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                            })
                        }
                    }}
                >+&nbsp;About&nbsp;the&nbsp;doctor</Button>
            </FlexGridItem>
            <FlexGridItem {...buttonProps}>
            <ToasterContainer autoHideDuration={2000} closeable={false} overrides={{ ToastBody: { style: () => ({/*width:'fit-content'*/})},}}>
                <Button
                    shape={SHAPE.pill}
                    size={SIZE.large}
                    kind={KIND.secondary}
                    startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711121/link-white_xqpoqu.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711121/link-black_r1lx3s.png"}/>}
                    onClick={() => {
                        Utils.copyToClipboard(['https://www.clinic.dev' + ROUTES.PROTOCOL_VIEW + '/' + this.state.protocol_id]);
                        let toastKey;
                        const msg =
                          "Link copied!";
                        toastKey = toaster.info(
                          <>
                            {msg}
                          </>,
                          {
                            onClose: () => console.log("Toast closed."),
                            overrides: {
                              InnerContainer: {
                                style: { width: "100%", textAlign: "center"}
                              }
                            }
                          }
                        );
                      }}
                      overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                maxWidth: '570px',
                                width: '100%',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                            })
                        },
                        StartEnhancer: {
                            style: ({ $theme }) => ({
                              marginRight: '6px'
                            })
                        }
                    }}
                >Share&nbsp;link</Button></ToasterContainer>
            </FlexGridItem>
        </FlexGrid>
    };

    renderReviews = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800" margin={"0px auto 0px auto"} maxWidth="400px" width={'90%'}>
            <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_rules_intro_title}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.content.protocol_view_rules_intro_body}</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_rules_section_1_title}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.content.protocol_view_rules_section_1_body}</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_rules_section_2_title}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.content.protocol_view_rules_section_2_body}</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_rules_section_3_title}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.content.protocol_view_rules_section_3_body}</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
        </FlexGrid>
    };

    renderCreatorSM = () => {
        Logger.log(this.state.protocol.creator);
        if (this.state.protocol && this.state.protocol.creator) {
            return <Drawer
            isOpen={this.state.open_creator}
            autoFocus
            animate={true}
            closeable={true}
            onClose={() => this.setState({open_creator: false})}
            size= {DrawerSize.full}
            anchor={ANCHOR.bottom}
            showBackdrop={true}
            overrides={{
                DrawerBody: {
                  style: ({ $theme }) => ({
                    maxWidth: ['XSMALL','SMALL'].includes(Screen.size()) ? '' : '600px',
                    marginRight: ['XSMALL','SMALL'].includes(Screen.size()) ? '16px' :  'auto',
                    marginLeft: ['XSMALL','SMALL'].includes(Screen.size()) ? '16px' : 'auto'
                  })
                }
              }}
        >
            <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                <FlexGridItem style={{display:"flex", alignItems:"center"}}>
                    <Avatar name={this.state.protocol.creator.name} size="44px" src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_4.0,h_88/v1685585213/kermen-2_lagwmv.jpg"/>
                    <LabelLarge style={{display: "inline-block", margin: '0px 0px 0px 8px', fontSize:"20px"}}>@{this.state.protocol.creator.username}</LabelLarge>
                    <NotificationCircle
                        content={<Check />}
                        overrides={{
                            Badge: {
                            style: ({ $theme }) => ({
                                //outline: window.matchMedia("(prefers-color-scheme: dark)").matches ? `#000000 5px solid` : `#FFFFFF 5px solid`,
                                backgroundColor: "#00a1ff",
                                marginLeft: '4px'
                            })
                            }
                        }}
                        />
                </FlexGridItem>
                <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.creator.blurb}</ParagraphSmall></Linkify></FlexGridItem>
                <FlexGridItem><Button
                    kind={KIND.secondary}
                    shape={SHAPE.pill}
                    size={SIZE.default}
                    $as="a"
                    href={'https://npiregistry.cms.hhs.gov/provider-view/' + '1306896428' /*this.state.protocol.creator.n_p_i*/}
                    target='_blank'
                    overrides={{
                        BaseButton: {
                        style: ({ $theme }) => ({
                            marginTop: '4px'
                        })
                        }
                    }}
                >National provider registry ↗</Button>
                </FlexGridItem>
            </FlexGrid>
        </Drawer>
        }
    };

    renderCreator = () => {
        Logger.log(this.state.protocol.creator);
        if (this.state.protocol && this.state.protocol.creator) {
            return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"} maxWidth="400px" width={'90%'}>
                <FlexGridItem>
                    <img width="76px" src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_4.0,h_88/v1685585213/kermen-2_lagwmv.jpg" style={{borderRadius: "50%"}}/>
                    <LabelLarge style={{display: "inline-block", margin: '0px 0px 0px 0px'}}><Presence status={this.state.protocol.creator.presence}/> {this.state.protocol.creator.username}</LabelLarge></FlexGridItem>
                <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.creator.blurb}</ParagraphSmall></Linkify></FlexGridItem>
            </FlexGrid>
        }
    };

    /*resetBackdrop = () => {
        this.state.backdrop_clicked = false;
        return null;
    };*/

    executeOnOpenAbout = () => {
        this.setState({open_about: true});

        if (this.props.executeOnOpenAbout) {
            this.props.executeOnOpenAbout();
        }
    };

    executeOnOpenCreator = () => {
        this.setState({open_creator: true});

        if (this.props.executeOnOpenCreator) {
            this.props.executeOnOpenCreator();
        }
    };


renderAboutSM = () => {
        if (this.state.protocol){
            return <Drawer
            isOpen={this.state.open_about}
            autoFocus
            animate={true}
            closeable={true}
            onClose={() => /*this.state.backdrop_clicked ? this.resetBackdrop() :*/ this.setState({open_about: false})}
            size= {DrawerSize.full}
            anchor={ANCHOR.bottom}
            showBackdrop={true}
            overrides={{
                DrawerBody: {
                  style: ({ $theme }) => ({
                    maxWidth: ['XSMALL','SMALL'].includes(Screen.size()) ? '' : '600px',
                    marginRight: ['XSMALL','SMALL'].includes(Screen.size()) ? '16px' :  'auto',
                    marginLeft: ['XSMALL','SMALL'].includes(Screen.size()) ? '16px' : 'auto'
                  })
                }
              }}
            //onBackdropClick={() => this.state.backdrop_clicked = true}
            //mountNode={document.getElementById("padIt")}
            /*overrides={{
                DrawerBody: {
                    style: ({ $theme }) => ({
                      marginRight: "10px",
                      marginLeft: "10px"
                    })
                  },
                /*DrawerContainer: {
                  style: ({ $theme }) => ({
                    width: "75%",
                    borderRadius: "25px",
                    maxHeight: "99%",
                  })
                }
              }}*/
        >
            <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800" margin={"0px auto 0px auto"} >
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: '0px'}}>{this.state.content.protocol_view_description}</HeadingSmall></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.description}</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: 0}}>{this.state.content.subscription_title}</HeadingSmall></FlexGridItem>
                        <FlexGridItem><ParagraphSmall style={{margin: '0px'}}>{this.state.protocol.billing_price == 0 ? this.state.content.subscription_free_clinic : this.state.content.subscription_paragraph_1.replaceAll('{billing_price}', Utils.renderDollarAmount(this.state.protocol.billing_price))}</ParagraphSmall></FlexGridItem>
                        <FlexGridItem><ParagraphSmall style={{margin: '0px'}}>{this.state.content.subscription_paragraph_1_subtext}</ParagraphSmall></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: 0}}>What you get</HeadingSmall></FlexGridItem>
                        <FlexGridItem><ParagraphSmall style={{margin: '0px'}}>• Messaging with the doctor<br/>• Request new scripts & refills<br/>• Order labs through Quest</ParagraphSmall></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: 0}}>Frequently prescribed list</HeadingSmall></FlexGridItem>
                        <FlexGridItem>{this.renderProductList()}</FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: '0px'}}>{this.state.content.protocol_view_eligibility}</HeadingSmall></FlexGridItem>
                        <FlexGridItem><ParagraphSmall style={{margin: '0px'}}>People located in:<br/>{this.renderStates()}</ParagraphSmall></FlexGridItem>
                        </FlexGrid>
                </FlexGridItem>
                {this.state.protocol.patient_eligibility !== "" && ( <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: '0px'}}>Additional requirements</HeadingSmall></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.patient_eligibility }</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>)}
                {this.state.protocol.risks !== "" && (<FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: 0}}>Risks associated with this clinic</HeadingSmall></FlexGridItem>
                        <FlexGridItem>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.risks}</ParagraphSmall></Linkify>
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>)}
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><HeadingSmall style={{margin: 0}}>Insurance disclaimer</HeadingSmall></FlexGridItem>
                        <FlexGridItem>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>Providers on Clinic don’t accept insurance. Payments are by credit card or FSA/FHA card only. You may use insurance at the pharmacy to cover the costs of meds.</ParagraphSmall></Linkify>
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
            </Drawer>
        }
    };

    renderAbout = () => {
        if (this.state.protocol){
            return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800" margin={"0px auto 0px auto"} maxWidth="400px" width={'90%'}>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_description}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.description}</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_eligibility}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.patient_eligibility }</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_conditions}</LabelLarge></FlexGridItem>
                        <FlexGridItem>{this.renderConditions()}</FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_risks}</LabelLarge></FlexGridItem>
                        <FlexGridItem><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphSmall style={{margin: '0px', whiteSpace: 'pre-line'}}>{this.state.protocol.risks }</ParagraphSmall></Linkify></FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
                        <FlexGridItem><LabelLarge style={{margin: '0px'}}>{this.state.content.protocol_view_availability}</LabelLarge></FlexGridItem>
                        <FlexGridItem>{this.renderStates()}</FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        }
    };

    renderSubscriptionTable = () => {
        if (this.state.protocol) {
            const COLUMNS = ['Membership', 'Meds & devices'];
            const DATA = [[this.state.content.subscription_table_membership.replaceAll('{billing_price}', Utils.renderDollarAmount(this.state.protocol.billing_price)), this.state.content.subscription_table_meds]];
            return <Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/>
        }
    };

  //TODO can this be deleted?
    renderFormulary = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800" margin={"0px auto 0px auto"} maxWidth="400px" width={'90%'}>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                    <FlexGridItem><LabelLarge style={{margin: 0}}>{this.state.protocol && this.state.content.formulary_title.replaceAll('{protocol_name}', this.state.protocol.name)}</LabelLarge></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{margin: 0}}>
                        {this.state.protocol && this.state.content.formulary_paragraph_1.replaceAll('{protocol_name}', this.state.protocol.name)}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                    <FlexGridItem>{this.renderProducts()}</FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                    <FlexGridItem><ParagraphSmall style={{margin: 0}}>{this.state.content.formulary_paragraph_2}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    };
    //TODO is this also defunct?
    renderSubscription = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800" margin={"0px auto 0px auto"} maxWidth="400px" width={'90%'}>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                    <FlexGridItem><LabelLarge style={{margin: 0}}>{this.state.content.subscription_title}</LabelLarge></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{margin: '0px'}}>{this.state.protocol && this.state.content.subscription_paragraph_1.replaceAll('{protocol_name}', this.state.protocol.name)}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                    <FlexGridItem>{this.renderSubscriptionTable()}</FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400" margin={"0px auto 0px auto"}>
                    <FlexGridItem><ParagraphSmall style={{margin: '0px'}}>{this.state.content.subscription_paragraph_2}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    };

    renderConditions = () => {
        if (this.state.protocol && this.state.protocol.protocol_metadata_items){
        return <ul style={{margin: '0px', paddingLeft: '0px', listStyleType: 'none'}}>{this.state.protocol.protocol_metadata_items.map((condition, idx) => <li key={idx}><ParagraphSmall style={{margin: '0px'}}>{condition.short_name}</ParagraphSmall></li>)}</ul>
        }
    };

    renderStates = () => {
        this.state.states.sort((a, b) => {return (Utils.renderState(a.state) > Utils.renderState(b.state)) ? 1 : -1})
        return this.state.states.map((state, idx) => <ParagraphSmall style={{margin: '0px'}} key={idx}>• {state.state}</ParagraphSmall>)
    };

    renderFavIcon = () => {
        return <Favicon
            favicon_url={this.state.protocol.favicon_url}
            size={FaviconSize.MEDIUM}
            badge_type={this.state.protocol.badge_type}
            status={this.state.protocol.status}
            rejecting_new_subscriptions={this.state.protocol.rejecting_new_subscriptions}
        />
    };

    renderMetaTags = () => {
        if (this.state.protocol) {
            return <MetaTagsOverride
                page={MetaTagPages.Protocol}
                protocol_name={this.state.protocol.name}
                protocol_summary={this.state.protocol.summary}
                protocol_number={this.state.protocol.number}
                protocol_favicon={this.state.protocol.favicon_url}
            />
        }

        return null;
    };

    viewPage = () => {
            return (<div style={{marginTop:"66px"}}>
                {this.renderMetaTags()}
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.success)}
                {this.state.protocol
                ? <FlexGrid flexGridColumnCount={1} style={{justifyContent:'center'}}>
                     <FlexGridItem
                                    style={
                                            {display: 'flex',
                                            flexWrap: 'wrap',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            position:'fixed',
                                            overflowY:'hidden',
                                            height:'87%'
                                            }}
                                >
                    <FlexGridItem {...upperProps}>{this.renderFavIcon()}</FlexGridItem>
                    <FlexGridItem {...itemProps}>{this.renderProtocol()}</FlexGridItem>
                    <FlexGridItem {...itemProps}>{this.renderSubscribers()}</FlexGridItem>
                    <FlexGridItem {...itemProps}>{this.renderSummary()}</FlexGridItem>
                    <FlexGridItem {...itemProps}>{this.renderButtons()}</FlexGridItem>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>{this.renderAboutSM()}</FlexGridItem>
                    <FlexGridItem {...itemProps}>{this.renderCreatorSM()}</FlexGridItem>
                </FlexGrid>
                : <Spinner />}
                {setNoScroll()}
            </div>)
    }

    render() {
        return (this.viewPage())

    }
}

export default ProtocolView;
