import React, { Component } from 'react';
import { ParagraphXSmall} from 'baseui/typography';
import socketClient, { io }  from "socket.io-client";
import Logger from '../Utils/logger';
import History from './history';
import Message from './message';
import { AuthUserContext, withAuthorization } from '../Session';
import {CHAT_ROOM_URL} from '../Constants';
import TypingIndicators from './typing_indicator';
import Utils from '../Utils';

const SERVER = CHAT_ROOM_URL;

class ChatRoom extends Component {
    constructor(props) {
        super(props)

        this.state = {
            room_id: this.props.room_id,
            username: this.props.username,
            protocol: this.props.protocol,
            new_message: null,
            connected: false,
            num_users: 0,
            failed_messages: [],
            users: [],
            error: null,
            show: this.props.show
        }

        this.socket = null;
        this.refresh_attempts = 0;
        this.pingInterval = null;
        this.lastPing = Date.now();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show}, () => {
                if (this.state.show && !this.socket) {
                    this.run();
                }
            });
        }
    };

    componentDidMount = () => {
        // addEventListener version
        window.ononline = event => {
            Logger.log('User is online.');
            Logger.log(event);

            if (!this.socket){
                this.run(true);
            } else if (!this.socket.connected) {
                this.socket.connect();
                this.setState({connected: true});
            } else {
                this.setState({connected: true});
            }
        };

        window.onoffline = event => {
            Logger.log('User is offline.')
            Logger.log(event)

            this.setState({connected: false});
        }

        if(this.state.show) {
            this.run();
        }

        document.addEventListener('focusout', function(e) {window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })});
    };

    componentWillUnmount = () => {
        if (this.socket) {
            this.socket.emit('leaving', {socket_id: this.socket.id});
            this.socket.disconnect();
        }
    };

    run = (refresh=false) => {
        Logger.log('Refreshing token: ' + refresh);
        if (refresh) {
            // Kill the current socket connection as we will have to setup a whole new conncetion with a new token.
            this.socket.disconnect();
            this.socket = null;
        }

        this.props.firebase.currentUser().getIdToken(refresh)
        .then(idToken => {
            this.setup(idToken);
        }).catch(error => {
            this.setState({error: {message: "Failed to refresh session. Please re-login."}});
            Logger.error(error);
        });
    };

    setup = (token) => {
        this.socket = socketClient(SERVER, {auth: {token: token}});

        this.socket.on('connect_error', err => {

            // This event is fired when:
                // 1. the low-level connection cannot be established
                // 2. the connection is denied by the server in a middleware function
            // In the first case, the Socket will automatically try to reconnect, after a given delay.
            // In the latter case, you need to manually reconnect. You might need to update the credentials:

            this.setState({connected: false});

            Logger.log('Connection error.');
            Logger.log(err);
            Logger.log(err.message); // Returns an object
            Logger.log(err.code);
            Logger.log(err.constructor.name); // Added latest.
            Logger.log(JSON.stringify(err.message));    

            if (err && err.message && err.message.includes('auth/id-token-expired')) {
                Logger.log('Firebase Token has expired. Renewing it...');
                this.socket.disconnect();
                this.run(true);
            }
            // else reconnects automatically.
        });

        this.socket.on('connection', () => {

            Logger.log('Socket connected.');
            this.setState({connected: true});

            this.socket.on('room-joined', data => {
                //Logger.log('Socket connected.');
                //Logger.log("Failed messages: ");
                //Logger.log(this.state.failed_messages)

                this.state.failed_messages.forEach(failed_message => {
                    //Logger.log("Sending message");
                    //Logger.log(failed_message);
                    failed_message['sent'] = true;
                    this.socket.emit('new-message', {
                        socket_id: this.socket.id,
                        message: failed_message // TODO: Should I change the timestamp?
                    })
                    this.setState({new_message: failed_message});
                });

                this.setState({failed_messages: []}); // TODO make this smart to only delete the items that were sent
                                                        // and not the messages that may have been added after failed messages
                                                        // were sent.
            });

            this.socket.on('pong', () => {
                Logger.log('pong received.');
                this.lastPing = Date.now();
            });

            this.socket.emit('join-room', {
                username: this.state.username,
                room_id: this.state.room_id,
                id: this.socket.id
            })

            this.socket.on("disconnect", (reason) => {
                Logger.log('Client Disconnected for reason: ' + reason);
                if (reason === "io server disconnect") {
                    Logger.log('Server disconnected me. Trying to reconnect...');
                  // the disconnection was initiated by the server, you need to reconnect manually
                  this.socket.connect();
                }
                // else the socket will automatically try to reconnect
            });
        })
    };

    newMessage = (message) => {
        if (!this.state.connected) {
            message['sent'] = false;
            this.setState({new_message: message, failed_messages: this.state.failed_messages.concat([message])});
        } else {
            this.setState({new_message: message});
        }
    }

    getUsers = (users) => {
        this.setState({users: users});
    };
 
    newMessageCount = () => {return this.state.messages.length}
    render = () => {
        return <AuthUserContext.Consumer>
            {authUser => (
                this.socket ? <div style={{
                    position: 'sticky'
                }}>
                    {Utils.renderError(this.state.error)}
                    <History
                        socket={this.socket}
                        username={this.state.username}
                        new_message={this.state.new_message}
                        users={this.getUsers}
                        parent_height={this.props.parent_height}
                        show={this.state.show}
                    />
                    <Message
                        socket={this.socket}
                        username={this.state.username}
                        new_message={this.newMessage}
                        room={this.state.room_id}
                        protocol={this.state.protocol}
                        show={this.state.show}
                    />
                    {!this.state.connected
                        ? <ParagraphXSmall style={{minHeight: '22px', margin: '6px 0px 6px 0px', color: '#BC8B2C'}}> Connection failed. Attempting to reconnect...</ParagraphXSmall>
                        : <TypingIndicators socket={this.socket} show={this.state.show}/>
                    }
                </div> : null
            )}
        </AuthUserContext.Consumer>
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ChatRoom);
