import React from 'react';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';
import { Plus, Upload } from 'baseui/icon';
import Logger from '../../shared/Utils/logger';
import * as ROUTES from '../Constants';
import Request from '../../shared/Request';
import Utils from '../../shared/Utils';
import { AuthUserContext, withAuthorization } from '../../shared/Session';

class FileMessageButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: false,
      };
    this.fileInputRef = React.createRef();
  }

  handleFileButtonClick = () => {
    this.fileInputRef.current.click(); // Click the hidden file input to open the picker
    this.setState({ isLoading: true }); // Set isLoading to true when the button is clicked
  };

  uploadFile = (file_upload) => {
    const data = new FormData();
    data.append('file_upload', file_upload);
    data.append('protocol_id', this.props.protocol.number);

    Request.post({
      firebase: this.props.firebase,
      route: ROUTES.BASE_URL_API + "/patient_messages/chat/",
      body: data,
      callbackSuccess: (result) => this.setState({
        isLoading: false,
        success: {message: "File sent. We'll text you any responses."},
        ...Utils.clearAllNotifications()
      }),
      callbackError: (error) => this.setState({
        isLoading: false,
        error,
        ...Utils.clearAllNotificationsExceptError()
      })
    })
  };

  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
      const allowedSize = 10 * 1024 * 1024; // 10MB

      // Check if the file type and size are valid
      if (allowedTypes.includes(selectedFile.type) && selectedFile.size <= allowedSize) {
        // Do something with the selected file here (e.g., upload, read, etc.)
        Logger.log(selectedFile);
      } else {
        // File type or size is not valid, show an alert with the error message
        window.alert('Invalid file. Please select a file of type .pdf, .jpeg, .jpg, or .png, and size 10MB or less.');
      }

      this.uploadFile(event.target.files[0]);
    } else {
      this.setState({ isLoading: false });
    }

  };

  handleFilePickerCancel = () => {
    this.setState({ isLoading: false }); // Set isLoading back to false when the file picker is canceled
  };

  render() {
    return (
      <React.Fragment>
        <input
          type="file"
          name="file_upload"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          onCancel={this.handleFilePickerCancel} // Handle file picker cancel event
          accept=".pdf,.jpeg,.jpg,.png" // Set accepted file types
        />
        <Button
            onClick={this.handleFileButtonClick}
            isLoading={this.state.isLoading}
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.circle}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => ({
                  height:"44px",
                  width:"44px"
                })
              }
            }}
        > <img style={{height:'24px'}} src={"https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1691015017/paperclip-01_yapkpz.png"}/>
        </Button>
      </React.Fragment>
    );
  }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(FileMessageButton);
