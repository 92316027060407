
const data = {
    en: {
        application_fee_tooltip: "This fee is to prevent automated submissions and ensure your request is real. It is non-refundable.",
        network_fee_tooltip: "We assess a fee of {service_fees} per approved prescription request. This supports ePrescibe.",
        total_charge_estimate_tooltip: "This is the total amount we’re authorizing on your card based on your selection, pending approval from the provider. You will only be charged if approved.",
        next_monthly_charge_tooltip: "This is the amount you’ll be invoiced for next month and each month thereafter if approved for this clinic. This does not include purchases like medicines.",
        amount_charged_now_tooltip: "This is the amount we’re charging you now for items that don’t require physician approval (like OTC meds).",

        order_title_onboarding: "Complete application",
        order_byline_onboarding: "You'll hear back within 7 days. If rejected, you have the chance to reapply.",
        order_title_drug_request: 'Medication request',
        order_byline_drug_request: "Your request will be reviewed. We’ll notify you when something happens. ",
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
