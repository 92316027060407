import React from 'react';
import {StyledLink} from "baseui/link";
import * as ROUTES from '../Constants';
import { ParagraphLarge, ParagraphMedium } from 'baseui/typography';
const data = {
    en: {
        // Reject
        reject_title: "You weren’t accepted to {protocol_name}",
        reject_provider_message: "Message from provider",
        reject_pending_charges: "You have not been billed for the subscription.",

        // Reject Timeout
        reject_timeout_title: "Your request to join {protocol_name} expired",
        reject_timeout_option_title: "The doctor didn't get to your membership request in time. Feel free to reapply.",
        reject_timeout_option_1: "",
        reject_timeout_option_2: "",
        reject_timeout_option_3: "",
        reject_timeout_option_4: "",

        // Pending
        pending_title: "Your request to join {protocol_name} is pending",
        pending_description: "The doctor may contact you with follow-up questions. You’ll be notified about your membership within 7 days.",

        // Nothing
        nothing_title: "There is nothing to see here",

        // Inactive
        inactive_title: "Your membership to {protocol_name} is inactive",
        inactive_description: "",

        // Cart
        cart_title: "Your application to {protocol_name} is incomplete",
        cart_description: "",

        // Banned
        banned_title: "You've been kicked from {protocol_name}",
        banned_subtext_1: <ParagraphMedium style={{textAlign: 'center', margin: 0}}></ParagraphMedium>,
        banned_provider_msg: <ParagraphMedium style={{textAlign: 'center', margin: 0, fontFamily:'RoobertSemiBold'}}>Message from provider:</ParagraphMedium>,
        banned_subtext_2: <ParagraphMedium style={{textAlign: 'center', margin: 0}}>You can no longer access this clinic or any of its features. We’ve refunded you this month’s subscription. This decision is final.</ParagraphMedium>,

        // Error
        error_description: <ParagraphLarge>Could not find what you are looking for.</ParagraphLarge>
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
