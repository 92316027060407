import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Spinner} from '../../shared/Spinner';
import * as ROUTES from '../Constants';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphMedium, HeadingSmall} from 'baseui/typography';
import StatusPending from '../Subscriptions/pending';
import Utils from '../../shared/Utils';
import {Button, KIND, SHAPE} from 'baseui/button';
import Request from '../../shared/Request';
import content from './content';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class ScheduleVideoCall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            protocol_id: this.props.protocol_id,
            protocol: null,
            protocol_tasks: null,
            error: null,
            content: content("en")
        };
    }

    componentDidMount = () => {
        this.getProtocolTasks();
        window.scrollTo(0, 0);
    };

    getProtocolTasks = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/protocol_tasks/" + this.state.protocol_id,
            callbackSuccess: (result) => this.setState({
                protocol: result.protocol,
                protocol_tasks: result.protocol_tasks,
                isLoaded: true,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                error,
                isLoaded: true,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getOnboardingVideoCallTask = () => {
        if (this.state.protocol_tasks && this.state.protocol_tasks.length > 0) {
            var videoCallTask = this.state.protocol_tasks.filter(task => task.context_type.toLowerCase() == 'onboarding' && task.task_type.toLowerCase() == 'video_call');
            if (videoCallTask.length > 0) {
                return videoCallTask[0];
            }
        }

        return null;
    };

    renderVideoCallScheduling = () => {
        if (this.state.protocol && this.state.protocol_tasks) {
            var task = this.getOnboardingVideoCallTask();
            if (task){
                return <FlexGrid
                    flexGridColumnCount={1}
                >
                    <FlexGridItem {...itemProps}>
                        <HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '10px'}}>{this.state.content.video_call_title.replaceAll('{protocol_name}', this.state.protocol.name)}</HeadingSmall>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                        <ParagraphMedium style={{textAlign: 'center', marginTop: '0px', marginBottom: '0px', fontFamily:'RoobertSemiBold'}}>{this.state.content.video_call_description}</ParagraphMedium>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                        <ParagraphMedium style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}><i>{task.task_description}</i></ParagraphMedium>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                        <Button kind={KIND.primary} shape={SHAPE.pill} $as="a" href={task.url} target="_blank">Schedule call</Button>
                    </FlexGridItem>
                </FlexGrid>
            } else {
                return !this.state.error && <StatusPending protocol_name={this.state.protocol.name}/>
            }
        } else {
            return <Spinner />
        }
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <div style={{display:'flex', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'85vh'}}>
                            {Utils.renderError(this.state.error)}
                            {this.renderVideoCallScheduling()}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    };
}



const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ScheduleVideoCall);
