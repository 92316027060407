import React from 'react';

const data = {
    en: {
        blurb_description: "You can write something about yourself here & it will display in your profile under the member list (max 256 characters). This will be public to the group.",
        blurb_empty_placeholder: "You haven't written anything.",
        blurb_max_length: 256,
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
