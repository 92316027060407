import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import {Button, SIZE, SHAPE, KIND} from 'baseui/button';
import Utils from '../Utils';
import MpsModal from '../Utils/modal'
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import * as ROUTES from '../Constants';


class PasswordChange extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            message: null,

            showPasswordChangeModal: false,
            password: '',
            password_confirm: ''
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (this.state.redirect && this.state.redirect !== prevState.redirect) {
        this.props.history.push(this.state.redirect);
      }
    };

    submitPasswordChange = () => {
      this.props.firebase.updatePassword(this.state.password)
        .then(() => {
          this.props.firebase.signOut().then(() => {
            this.setState({
              ...Utils.clearAllNotificationsExceptSuccess(),
              redirect: {pathname: ROUTES.SIGN_IN, state: {message: 'Password changed successfully.  Please signin to continue.'}}});
          });
        })
        .catch(error => {
          debugger;
          this.setState({ error, ...Utils.clearAllNotificationsExceptError() });
        });
    };

    openPasswordChangeModal = () => {
        this.setState({
            showPasswordChangeModal: true,
            ...Utils.clearAllNotifications()
        });
    };

    closePasswordChangeModal = () => {
        this.setState({
            showPasswordChangeModal: false,
            password: '',
            password_confirm: '',
            ...Utils.clearAllNotifications()
        });
    };

    shouldSubmitBeDisabled = () => {
        return this.state.password !== this.state.password_confirm || this.state.password === '';
    };

    drawPasswordChangeModal = () => {
        return <MpsModal
            open={this.state.showPasswordChangeModal}
            autoFocus={false}
            callback={this.submitPasswordChange}
            callbackCancel={this.closePasswordChangeModal}
            withFooter={true}
            disabled={this.shouldSubmitBeDisabled()}
            callbackButton={"Apply"}
            callbackCancelButton={'Close'}
            title={Utils.renderTitleCenter('Update password')}
            body={this.passwordChangeForm()}
        />
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications() });
    };

    passwordChangeForm = () => {
        return <div>
            {Utils.renderError(this.state.error)}
            {Utils.renderSuccess(this.state.message)}
            <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale200">
                <FlexGridItem>
                    <FormControl
                    label={() => "Enter new password"}
                    >
                    <Input
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        overrides={{
                            MaskToggleButton: () => null,
                        }}
                    />
                    </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                    <FormControl
                    label={() => "Confirm new password"}
                    >
                    <Input
                        name="password_confirm"
                        type="password"
                        value={this.state.password_confirm}
                        onChange={this.onChange}
                        overrides={{
                            MaskToggleButton: () => null,
                        }}
                    />
                    </FormControl>
                </FlexGridItem>
            </FlexGrid>
        </div>
    };

    showSpace = () => {
        return <p></p>;
    };

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div>
                        {Utils.renderTitle6('Password management')}
                        {this.showSpace()}
                        <Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={this.openPasswordChangeModal}>Change Password</Button>
                        {this.showSpace()}
                        {this.drawPasswordChangeModal()}
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    );
}
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PasswordChange);
