import React, {Component} from 'react';
import { Textarea } from "baseui/textarea";
import { Checkbox } from "baseui/checkbox";
import { RadioGroup, Radio } from "baseui/radio";
import {ParagraphMedium, LabelMedium} from 'baseui/typography';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';

function QuestionInput(props) {
    const value = props.value;
    const questionType = props.questionType;
    const handleChange = props.onChange;
    const index = props.index;
    const options = props.options;
    const question = props.question;

    if (questionType == "multi") {
        return <div>
            <ParagraphMedium marginBottom="scale600">{question}</ParagraphMedium>
            {(options.map((option, idx) => <Checkbox
            key={idx}
            name={option.id}
            checked={(value.filter(val => val.id == option.id)).length > 0}
            onChange={(e) => {
                if (e.target.checked) {
                    // Add this to value
                    value.push({ flag: option.flag, label: e.target.name, id: e.target.name });
                    e.target.value = value;
                    handleChange(value);
                } else {
                    var removed = value.filter(val => val.id != e.target.name);
                    handleChange(removed);
                }
            }}
            overrides={{
                Root: {
                    style: ({$theme}) => ({
                        marginBottom: '15px'
                    }),
                }
            }}
        ><LabelMedium>{option.label}</LabelMedium></Checkbox>))}</div>;
    }

    if (questionType == "single") {
        return (<div>
            <ParagraphMedium marginBottom="scale400">{question}</ParagraphMedium>
            <RadioGroup
                value={value.length > 0 ? value[0].id : ''}
                onChange={(e) => {
                    var val = [{
                        label: e.target.value,
                        id: e.target.value,
                        flag: (typeof e.target.dataset.flag === 'string' ? e.target.dataset.flag === "true" : e.target.dataset.flag)
                    }];

                    handleChange(val);
                }}
            >
                {options.map((option, idx) => <Radio key={idx} overrides={{Input: { props: {'data-flag': option.flag}}}} value={option.id}><LabelMedium>{option.label}</LabelMedium></Radio>)}
            </RadioGroup>
            </div>
        );
    }

    //text is default in case a bad form structure gets saved
    return(
        <div>
            <ParagraphMedium marginBottom="scale300">{question}</ParagraphMedium>
            <Textarea
                value={value}
                key={index}
                onChange={handleChange}
                placeholder=""
                clearOnEscape
                maxLength={500}
                onBlur={() => {
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }}
            />
        </div>
    );

};

//needs index, question, questionType, maybe existing response as props
class FormQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: []
        };
        if (props.questionType == 'text') {
            this.state.value = '';
        }

        if (this.props.question_response && this.props.question_response.responseText) {
            if (this.props.questionType == 'multi' || this.props.questionType == 'single') {
                this.state.value = this.props.question_response.responseText.map((val) => {
                    return {label: val, id: val}
                });
            } else {
                this.state.value = this.props.question_response.responseText[0] || ''
            }
        }
    };

    componentDidUpdate = (prevProps) =>  {
        if (JSON.stringify(prevProps.question_response) !== JSON.stringify(this.props.question_response)) {
            if (this.props.question_response && this.props.question_response.responseText) {
                let new_val = [];
                if (this.props.questionType == 'multi' || this.props.questionType == 'single') {
                    new_val = this.props.question_response.responseText.map((val, idx) => {
                        return {flag: this.props.question_response.responseFlag[idx], label: val, id: val}
                    });

                } else {
                    new_val = this.props.question_response.responseText[0] || ''
                }

                this.setState({value: new_val, ...Utils.clearAllNotifications()});
            }
        }
    };

    handleChange = (options) => {
        let value = [];
        if (this.props.questionType == 'multi' || this.props.questionType == 'single') {
            value = options;
        } else {
            value = options.target.value;
        }
        this.setState({ value: value, ...Utils.clearAllNotifications() }, () => {Logger.log('state:'); Logger.log(this.state)});
        this.props.handleChange(this.props.index, this.formattedValue(value));
    };

    formattedValue = (value) => {
        let formatted_response = {...this.props.question}
        if (this.props.questionType == 'multi' || this.props.questionType == 'single') {
            Logger.log('Formated value:');
            Logger.log(value);
            formatted_response.responseText = value.map((val) => {return val.label});
            formatted_response.responseFlag = value.map((val) => {return val.flag});
        } else {
            formatted_response.responseText = [value];
        }
        return formatted_response;

    };

    getOptions = () => {
        if (this.props.question.responseChoices) {
            return this.props.question.responseChoices.map(function(item, idx){
                return { label: item.text, flag: item.flag, id: item.text };
            });
        }

    };

    render = () => {
        return (
            <div key={this.props.index}>
                <QuestionInput
                    questionType={this.props.questionType}
                    options={this.getOptions()}
                    index={this.props.index}
                    onChange={this.handleChange}
                    value={this.state.value}
                    question={this.props.question.questionText}
                />
                {Utils.renderSpace()}
            </div>);
    }

}

export default FormQuestion;

