import React from 'react';

const data = {
    "en": {
        "patient": {},
        "physician": {}
    }
}

const content = (lang, app) => {
    return data[lang][app];
};

export default content;
