import React, {Component} from 'react'
import { Select } from "baseui/select";
import Logger from './logger';

class InputSelect extends Component {
    constructor(props){
        super(props);

        this.state = {
            disabled: this.props.disabled === true ? true : false,
            name: this.props.name,
            id: this.props.id,
            options: this.props.options,
            value: this.getSelectValue(this.props.value),
            onChange: this.props.onChange,
            placeholder: this.props.placeholder,
            multi: this.props.multi === true ? true : false
        };

        Logger.log('State Select');
        Logger.log(this.state);
    }

    getSelectValue = (value) => {
        if (value) return [{id: value, label: value}]
        else return []
    };

    componentDidUpdate = (prevState) => {
        var options = {};

        if (prevState.value !== this.props.value) {
            options.value = this.getSelectValue(this.props.value);
        }

        if (prevState.disabled !== this.props.disabled) {
            options.disabled = this.props.disabled;
        }

        if (Object.keys(options).length > 0){
            this.setState(options);
        }
    };

    handleChange = (e) => {
        // TODO does not handle multi

        Logger.log(e);
        // Set local values
        this.setState({value: e.value})

        // Reflect values to the caller
        var event = {target: e};
        event.target.id = this.state.id;
        event.target.name = this.state.name;
        event.target.value = e.value[0].id;

        this.state.onChange(event);
    };

    render = () => {
        return <Select
            options={this.state.options}
            value={this.state.value}
            multi={this.state.multi}
            placeholder={this.state.placeholder}
            onChange={this.handleChange}
            disabled={this.state.disabled}
            clearable={false}
      />
    };
}

export default InputSelect;
