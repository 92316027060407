import React, {Component} from 'react';
import { StyledLink } from "baseui/link";
import * as ROUTES from '../../patient/Constants';
import { AuthUserContext, withAuthorization } from '../Session';
import {ParagraphLarge} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import About from '../About';
import content from './content';
import SignOut from '../SignOut';
import SearchProtocol from '../../patient/Browse/search_protocol';
import ReportIssue from '../../shared/ReportIssue';

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};

class PatientNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            callback: this.props.callback,
            showAbout: false,
            showSearchProtocol: false,
            showReportIssue: false,
            content: this.getContent()
        };
    }

    getContent = () => {
        return content('en').patient;
    };

    callback = () => {
        this.state.callback();
    };

    showProtocolSearch = (event) => {
        event.preventDefault();

        this.setState({showSearchProtocol: true});
    };

    hideProtocolSearch = () => {
        this.setState({showSearchProtocol: false});
        this.props.callback();
    };

    showReportIssue = () => {
        this.setState({showReportIssue: true});
    };

    closeReportIssue = () => {
        this.setState({showReportIssue: false});
        this.props.closeCallback();
    };

    showAbout = (event) => {
        event.preventDefault();
        // this.props.callback(); // prevents the About modal to show up.
        this.setState({showAbout: true});
    };

    aboutCallback = () => {
        this.setState({showAbout: false});
        this.props.callback();
    };

    signout = (event) => {
        event.preventDefault();

        this.props.firebase.signout();
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <div>
                            <FlexGrid flexGridColumnCount={1} flexGridRowGap={this.state.content.content_spacing} margin={this.state.content.content_margin}>
                                {this.state.content.communities != '' 
                                ? <FlexGridItem {...contentProps}>
                                    <StyledLink href={ROUTES.DASHBOARD} onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.communities}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.browse != '' 
                                ? <FlexGridItem {...contentProps}>
                                    <StyledLink href={""} onClick={this.showProtocolSearch} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.browse}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.account != ''
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={ROUTES.ACCOUNT} onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.account}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.transaction_history != ''
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={ROUTES.HISTORY} onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.transaction_history}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.support != ''
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={'https://www.findlabtest.com/'} target="_blank" onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.support}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.about != ''
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={"#"} onClick={this.showAbout} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.about}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.physician_credentials
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={ROUTES.PHYSICIAN_CREDS} onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.physician_credentials}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.create_community != ''
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={ROUTES.RUN_BASE_URL} target="_blank" onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.create_community}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                {this.state.content.help != ''
                                ? <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={'https://clinicdev.notion.site/Clinic-dev-user-guide-6709682413d748b6a6a8b09fd088292d?pvs=4'} target="_blank" onClick={this.callback} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.help}</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                : null}
                                <FlexGridItem {...contentProps} {...topLinners}>
                                    <StyledLink href={"#"} onClick={this.showReportIssue} className="a_no_line">
                                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Product support</ParagraphLarge>
                                    </StyledLink>
                                </FlexGridItem>
                                <FlexGridItem {...contentProps} {...topLinners}>
                                    <SignOut link={true} />
                                </FlexGridItem>
                            </FlexGrid>

                            <About show={this.state.showAbout} callback={this.aboutCallback}/>
                            <ReportIssue show={this.state.showReportIssue} callback={this.closeReportIssue}/>
                            {this.state.showSearchProtocol ? <SearchProtocol callbackClose={this.hideProtocolSearch}/> : null}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
};
const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PatientNav);
