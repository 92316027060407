import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { ParagraphLarge, ParagraphSmall, LabelLarge, LabelMedium, LabelSmall} from 'baseui/typography';
import {Input} from 'baseui/input';
import Utils from '../../shared/Utils';
import { FileUploader } from "baseui/file-uploader";
import {Button, KIND, SHAPE, SIZE} from 'baseui/button';
import { Spinner } from "baseui/spinner";
import { FormControl } from "baseui/form-control";
import MpsModal from '../../shared/Utils/modal';
import Logger from '../../shared/Utils/logger';
import Request from '../../shared/Request';

class IDVerification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show ? this.props.show : false,
            isLoaded: false,
            error: null,
            message: null,

            first_name: '',
            last_name: '',

            photo_id_img_is_new: null,
            photo_id_img: null,
            photo_id_img_error: null,
            photo_id_img_uploading: false,

            photo_verification_img_is_new: null,
            photo_verification_img: null,
            photo_verification_error: null,
            photo_verification_uploading: false,

            is_name_editable: true
        };
    }

    componentDidMount = () => {
        this.getPatientVerification();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show, ...Utils.clearAllNotifications()});
        }
    };

    getPatientVerification = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_verification",
            callbackSuccess: (res) => this.setState({
                is_name_editable: res.is_name_editable,

                first_name: res.data.first_name ? res.data.first_name : '',
                last_name: res.data.last_name ? res.data.last_name : '',
                photo_id_img: res.data.photo_identification_url ? res.data.photo_identification_url : null,
                photo_id_img_is_new: false,
                photo_verification_img: res.data.verification_picture_url ? res.data.verification_picture_url : null,
                photo_verification_img_current: false,
                ...Utils.clearAllNotifications()
            }, () => this.callback()),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    changeHandler = (event) => {
        let val = Utils.filterInput(event.target.value);
        this.setState({ [event.target.name]: val, ...Utils.clearAllNotifications() });
    }

    closeModal = () => {
        this.setState({show: false, ...Utils.clearAllNotifications()});

        this.props.callbackCancelHandler();
    };

    savePhotoId = () => {
        const data = new FormData();
        if (this.state.photo_id_img_is_new) {
            // New pic
            data.append('photo_id', this.state.photo_id_img);

            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/patient_verification/new_photo_id",
                body: data,
                callbackSuccess: (result) => this.setState({
                    photo_id_img: result.photo_identification_url,
                    ...Utils.clearAllNotifications()
                }, () => this.loadImages()),
                callbackError: (error) => this.setState({
                    isLoaded: true,
                    error,
                    photo_id_img_uploading: false,
                    ...Utils.clearAllNotificationsExceptError()
                })
            })
        }
    };

    savePhotoVerification = () => {
        const data = new FormData();
        if(this.state.photo_verification_img_is_new) {
            // New pic
            data.append('photo_verification', this.state.photo_verification_img);

            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/patient_verification/new_photo_verification",
                body: data,
                callbackSuccess: (result) => this.setState({
                    photo_verification_img: result.verification_picture_url,
                    ...Utils.clearAllNotifications()
                }, () => this.loadImages()),
                callbackError: (error) => this.setState({
                    isLoaded: true,
                    error,
                    photo_verification_uploading: false,
                    ...Utils.clearAllNotificationsExceptError()
                })
            })
        }
    };

    saveIDVerificationData = () => {
        const trimmed_first_name = this.state.first_name.trim();
        const trimmed_last_name = this.state.last_name.trim();

        if (this.state.first_name.length > 0 && trimmed_first_name == '') {
            this.setState({error: {message: 'Invalid first name.'}});
        } else if (this.state.last_name.length > 0 && trimmed_last_name == '') {
            this.setState({error: {message: 'Invalid last name.'}});
        } else {
            const data = new FormData();
            data.append('first_name', trimmed_first_name);
            data.append('last_name', trimmed_last_name);
            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/patient_verification/id_verification",
                body: data,
                callbackSuccess: (result) => {
                    this.setState({
                        show: false,
                        first_name: trimmed_first_name,
                        last_name: trimmed_last_name,
                        ...Utils.clearAllNotifications()});
                    // This is called for the caller.
                    this.callback();
                },
                callbackError: (error) => {
                    this.callback();

                    this.setState({
                        isLoaded: true,
                        error,
                        ...Utils.clearAllNotificationsExceptError()
                    });
                }
            });
        }
    };

    loadImages = () => {
      Logger.log("loading!");

      if (this.state.photo_id_img && this.state.photo_id_img.slice(0,1) != "b") {
        Request.get({
          notJson: true,
          firebase: this.props.firebase,
          route: this.state.photo_id_img,
          callbackSuccess: (data) => {
            data.blob().then(blob => {
              const url = window.URL || window.webkitURL;
              const src = url.createObjectURL(blob);
              this.setState({
                photo_id_img: src,
                photo_id_img_uploading: false,
              });
            });

          },
          callbackError: (error) => {
            this.setState({
              error
            })
          }
        })
      }
      if (this.state.photo_verification_img && this.state.photo_verification_img.slice(0,1) != "b") {
      Logger.log("loading verf!");
        Request.get({
          notJson: true,
          firebase: this.props.firebase,
          route: this.state.photo_verification_img,
          callbackSuccess: (data) => {
            data.blob().then(blob => {
              const url = window.URL || window.webkitURL;
              const src = url.createObjectURL(blob);
              this.setState({
                photo_verification_img: src,
                photo_verification_uploading: false,
              });
            });
          },
          callbackError: (error) => {
            this.setState({
              error
            })
          }
        })

      }
    };

    callback = () => {
        this.loadImages();
        if (this.isFormNotReady()) {
            this.props.callbackCompleteHandler(false);
        } else {
            this.props.callbackCompleteHandler(true);
        }
    };

    submit = () => {
        this.saveIDVerificationData();
    };

    ////////////////////////////////// Render Methods //////////////////////////////////
    isFormNotReady = () => {
        return this.state.first_name.trim() == ''
            || this.state.last_name.trim() == ''
            || this.state.photo_id_img == null
            || this.state.photo_verification_img == null;
    };

    clearPhotoId = () => {
        this.setState({photo_id_img: null, ...Utils.clearAllNotifications()});
    };

    clearSelfie = () => {
        this.setState({photo_verification_img: null, ...Utils.clearAllNotifications()});
    };

    canUploadMorePhoto = () => {
        return this.state.photo_id_img == null;
    }

    canUploadMoreSelfie = () => {
        return this.state.photo_verification_img == null;
    }

    handlePhotoIdUpload = (acceptedFiles, rejectedFiles) => {
        if (this.canUploadMorePhoto()) {
            this.setState({
                photo_id_img: acceptedFiles[0],
                photo_id_img_is_new: true,
                photo_id_img_uploading: true,
                ...Utils.clearAllNotifications()
            }, () => {Logger.log(this.state); this.savePhotoId()});
        } else {
            this.setState({
                photo_id_img_error: "Only 1 photo ID can be uploaded",
                ...Utils.clearAllNotifications()
            });
        }
    }

    handleSelfieUpload = (acceptedFiles, rejectedFiles) => {
        if (this.canUploadMoreSelfie()) {
            this.setState({
                photo_verification_img: acceptedFiles[0],
                photo_verification_img_is_new: true,
                photo_verification_uploading: true,
                ...Utils.clearAllNotifications()
            }, () => {Logger.log(this.state); this.savePhotoVerification()});
        } else {
            this.setState({photo_id_img_error: "Only 1 selfie can be uploaded", ...Utils.clearAllNotifications()});
        }
    }

    photoIdUploadRetry = () => {
        this.setState({photo_id_img_error: null, ...Utils.clearAllNotifications()});
    };

    selfieUploadRetry = () => {
        this.setState({photo_verification_error: null, ...Utils.clearAllNotifications()});
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <MpsModal
                            open={this.state.show}
                            autoFocus={false}
                            callback={this.submit}
                            callbackCancel={this.closeModal}
                            withFooter={true}
                            title={Utils.renderTitleCenter("Verify identity")}
                            body={
                                <div>
                                    {Utils.renderError(this.state.error)}
                                    {Utils.renderSuccess(this.state.message)}

                                    <div>
                                        <ParagraphLarge style={{marginBottom:"20px", lineHeight:"24px"}}>State laws require the doctor to verify your identity.</ParagraphLarge>
                                        <ParagraphSmall>Upload a clear, legible photo of a non-expired government issued photo ID</ParagraphSmall>
                                        {Utils.renderSpace()}
                                        {this.state.photo_id_img == null
                                        ? <FileUploader
                                            name='photo_id'
                                            accept=".jpeg,.jpg,.png"
                                            maxSize={10000000}
                                            onRetry={this.photoIdUploadRetry}
                                            onDrop={(acceptedFiles, rejectedFiles) => {
                                                // handle file upload...
                                                Logger.log('File Upload In Progress...');
                                                Logger.log(acceptedFiles);
                                                Logger.log(rejectedFiles);
                                                this.handlePhotoIdUpload(acceptedFiles, rejectedFiles);
                                            }}
                                            errorMessage={this.state.photo_id_img_error}
                                        />
                                        : <div>
                                            {this.state.photo_id_img_uploading
                                            ? <Spinner />
                                            : <div>
                                                <div style={{textAlign: 'center'}}><img src={this.state.photo_id_img} alt="Current Photo ID" width="80%"/></div>
                                                    {Utils.renderSpace()}
                                                    <Button onClick={this.clearPhotoId} shape={SHAPE.pill} kind={KIND.secondary} size={SIZE.compact}>Remove</Button>
                                            </div>
                                            }
                                        </div>}
                                    </div>
                                    {Utils.renderSpace()}
                                    {Utils.renderSpace()}
                                    <div>
                                        <ParagraphSmall>Upload a clear, well lit photo of your face</ParagraphSmall>
                                        {Utils.renderSpace()}
                                        {this.state.photo_verification_img == null
                                        ? <FileUploader
                                            name='photo_id'
                                            accept=".jpeg,.jpg,.png"
                                            maxSize={10000000}
                                            onRetry={this.selfieUploadRetry}
                                            onDrop={(acceptedFiles, rejectedFiles) => {
                                                // handle file upload...
                                                Logger.log('File Upload In Progress...');
                                                Logger.log(acceptedFiles);
                                                Logger.log(rejectedFiles);
                                                this.handleSelfieUpload(acceptedFiles, rejectedFiles);
                                            }}
                                            errorMessage={this.state.photo_verification_error}
                                        />
                                        : <div>
                                            {this.state.photo_verification_uploading
                                            ? <Spinner />
                                            : <div>
                                                <div style={{textAlign: 'center'}}><img src={this.state.photo_verification_img} alt="Current Photo Verification"  width="80%"/></div>
                                                {Utils.renderSpace()}
                                                <Button onClick={this.clearSelfie} shape={SHAPE.pill} kind={KIND.secondary} size={SIZE.compact}>Remove</Button>
                                            </div>}
                                        </div>}
                                    </div>
                                    {Utils.renderSpace()}
                                    {Utils.renderSpace()}
                                    <ParagraphSmall style={{marginBottom: '20px'}}>Enter your name exactly as it appears on your ID</ParagraphSmall>

                                    <FormControl
                                        label={() => "First name"}
                                    >
                                        <Input
                                            id="first_name"
                                            name="first_name"
                                            value={this.state.first_name}
                                            onChange={this.changeHandler}
                                            placeholder="First name"
                                            disabled={this.state.is_name_editable == false}
                                        />
                                    </FormControl>
                                    <FormControl
                                        label={() => "Last name"}
                                    >
                                        <Input
                                            id="last_name"
                                            name="last_name"
                                            value={this.state.last_name}
                                            onChange={this.changeHandler}
                                            placeholder="Last name"
                                            disabled={this.state.is_name_editable == false}
                                        />
                                    </FormControl>
                                </div>
                            } />
                    )
                }
            </AuthUserContext.Consumer>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(IDVerification);
