import React, { Component } from "react";
import "./style.css";

class TypingEffect extends Component {
  state = {
    sentences: ["yourpractice", "bionic", "drjohnk", "cavityclub", "oliverscottmd", "airlock", "cgrpmigraine"],
    currentSentence: 0,
    currentSentenceChar: 0,
    text: "",
    intervalValue: null,
  };

  componentDidMount() {
    const intervalValue = setInterval(() => {
      this.TypingEffect();
    }, 100);

    this.setState({ intervalValue });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalValue);
  }

  TypingEffect = () => {
    const { sentences, currentSentence, currentSentenceChar } = this.state;
    const text = sentences[currentSentence].substring(0, currentSentenceChar + 1);
    const intervalValue = 100; // use a fixed interval value

    this.setState({ text });

    if (text === sentences[currentSentence]) {
      clearInterval(this.state.intervalValue);

      setTimeout(() => {
        this.setState({
          intervalValue: setInterval(() => {
            this.DeletingEffect();
          }, intervalValue),
        });
      }, 2800);
    } else {
      this.setState({ currentSentenceChar: currentSentenceChar + 1 });
    }
  };

  DeletingEffect = () => {
    const { sentences, currentSentence, currentSentenceChar } = this.state;
    const text = sentences[currentSentence].substring(0, currentSentenceChar - 1);
    const intervalValue = 100; // use a fixed interval value

    this.setState({ text });

    if (text === "") {
      clearInterval(this.state.intervalValue);

      if (currentSentence === sentences.length - 1) {
        this.setState({ currentSentence: 0 });
      } else {
        this.setState({ currentSentence: currentSentence + 1 });
      }

      this.setState({ currentSentenceChar: 0 });

      setTimeout(() => {
        this.setState({
          intervalValue: setInterval(() => {
            this.TypingEffect();
          }, intervalValue),
        });
      }, 500);
    } else {
      this.setState({ currentSentenceChar: currentSentenceChar - 1 });
    }
  };

  render() {
    const { text } = this.state;

    return (
      <div>
        <div className="text">{text}</div>
        <div className="cursor"></div>
      </div>
    );
  }
}

export default TypingEffect;
