export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PASSWORD_RESET = '/password_reset';
export const PASSWORD_CHANGE = '/password_change';
export const SUBSCRIPTIONS = '/subscriptions';
export const PROTOCOL_VIEW = '/protocol';
export const PROTOCOL_VIEW_SINGLE = '/protocol/:id';
export const PROTOCOL_STATUS = '/protocol/status';
export const PROTOCOL_STATUS_ID = '/protocol/status/:id';
export const PROTOCOL_HOME = '/protocol/home';
export const PROTOCOL_HOME_ID = '/protocol/home/:id';
export const PROTOCOL_SUBSCRIPTION = '/protocol/apply';
export const PROTOCOL_SUBSCRIPTION_REGISTER_ROUTE = '/protocol/apply/:id';
export const PROTOCOL_SUBSCRIPTION_REAPPLY = '/protocol/apply/:action/:id';
export const SHIPPING = '/checkout';
export const SHIPPING_PROTOCOL = '/checkout/:protocol_id';
export const SHIPPING_PROTOCOL_ORDER = '/checkout/:protocol_id/:order_id';
export const ORDER_REVIEW = '/summary';
export const ORDER_REVIEW_SHOW = '/summary/:order_id';
export const ORDER_STATUS = '/order_status';
export const ORDER_STATUS_ID = '/order_status/:order_id';
export const DRUG_STORE = '/drugstore';
export const DRUG_STORE_STANDALONE = '/drugstore/:id';
export const TASK_LIST = '/tasklist';
export const TASK_LIST_DRUG_REQUEST = '/tasklist/:protocol_id/:order_id/:enableBackgroundInformation/:enableDrugRequestForm';
export const MANAGE_SUBSCRIPTION = '/manage_subscriptions';
export const DASHBOARD = '/dashboard';
export const CHAT = '/chat';
export const CHAT_ID = '/chat/:id'; // Protocol ID
export const SUBSCRIPTION_STATUS = '/status';
export const SUBSCRIPTION_STATUS_ID = '/status/:protocol_id'; // Protocol ID
export const HISTORY = '/history';
export const HISTORY_DETAILS = '/history/:order_number/:payment_number';
export const PENDING = '/pending';
export const PENDING_PROTOCOL_ID = '/pending/:protocol_id';
export const REJECT = '/reject';
export const REJECT_ID = '/reject/:protocol_id';
export const MFA_ENROLL = '/mfa/enroll';
export const BROWSE = '/browse';
export const PHYSICIAN_CREDS = '/creds';
export const PROTOCOL_PREVIEW = "/preview";
export const PROTOCOL_PREVIEW_NUMBER = "/preview/:number";

let base_domain = process.env.BASE_DOMAIN;
let dev_domain = "lvh.me";

const DEVELOPMENT_ENV = 'development';
const PRODUCTION_ENV = 'production';
const STAGING_ENV = 'staging';

const DEV_BASE_URL = 'http://' + dev_domain + ':3000';
const PROD_BASE_URL = 'https://' + base_domain;
const PROD_WWW_BASE_URL = `https://www.` + base_domain;
const STAGING_BASE_URL = 'https://staging.' + base_domain;

const DEV_BASE_URL_API = 'http://api.' + dev_domain + ':3000/patient_api/v1';
const PROD_BASE_URL_API = 'https://api.' + base_domain + '/patient_api/v1';
const STAGING_BASE_URL_API = 'https://api.staging.' + base_domain + '/patient_api/v1';

const DEV_RUN_BASE_URL = 'http://run.' + dev_domain + ':3000';
const PROD_RUN_BASE_URL = 'https://run.' + base_domain;
const STAGING_RUN_BASE_URL = 'https://run.staging.' + base_domain;

let base_url = PROD_BASE_URL;
let www_base_url = PROD_WWW_BASE_URL;
let base_url_api = PROD_BASE_URL_API;
let run_base_url = PROD_RUN_BASE_URL;
if (process.env.RAILS_ENV === DEVELOPMENT_ENV) {
    base_url = DEV_BASE_URL;
    base_url_api = DEV_BASE_URL_API;
    run_base_url = DEV_RUN_BASE_URL;
} else if (window.location.hostname == 'staging.' + base_domain) {
    base_url = STAGING_BASE_URL;
    base_url_api = STAGING_BASE_URL_API;
    run_base_url = STAGING_RUN_BASE_URL;
}

export const BASE_URL = base_url;
export const BASE_URL_API = base_url_api;
export const RUN_BASE_URL = run_base_url;
export const WWW_BASE_URL = www_base_url;
