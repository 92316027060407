import React, {Component} from 'react';
import * as ROUTES from  '../Constants';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphMedium, HeadingSmall} from 'baseui/typography';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Button, KIND, SHAPE} from 'baseui/button';
import Utils from '../../shared/Utils';
import content from './content';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class StatusInactive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: content('en')
        }
    }

    render() {
        return <AuthUserContext.Consumer>
            {
                authUser => (
                    <div style={{display:'flex', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'85vh'}}>
                    <FlexGrid
                        flexGridColumnCount={1}
                    >
                        <FlexGridItem {...itemProps}><HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}>{this.state.content.inactive_title.replaceAll('{protocol_name}', this.props.protocol_name)}</HeadingSmall></FlexGridItem>
                        <FlexGridItem {...itemProps}>
                            <Button
                                kind={KIND.primary}
                                shape={SHAPE.pill}
                                $as="a"
                                href={ROUTES.PROTOCOL_HOME + '/' + this.props.protocol_id}
                            >Manage subscription</Button>
                        </FlexGridItem>
                    </FlexGrid>
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(StatusInactive);
