import {CHAT_ROOM_URL} from '../Constants';
import Logger from "../Utils/logger";

class SSE {
    constructor(firebase, source, cb, options={withCredentials: true}) {
        this.sse = null;

        firebase.currentUser().getIdToken(false)
        .then(idToken => {
            this.sse = new EventSource(CHAT_ROOM_URL + source + '?id=' + idToken, options);

            this.sse.onopen = (event) => {
                this.sse.onmessage = (event) => {
                    Logger.log('SSE event received: ' + event.data);
                    cb(event);
                };

                this.sse.onerror = (err) => {
                    Logger.error('SSE Error: ' + err.message);
                }
            }
        }).catch(error => {
            Logger.error("Failed to connect to SSE. Error: " + error.message);
        });
    }

    close = () => {
        if(this.sse) {
            this.sse.close();
        }
    }
}

export default SSE;
