import React, {Component} from 'react';
import { PinCode } from "baseui/pin-code";
import { StyledLink } from "baseui/link";
import * as ROUTES from '../Constants';
import Utils from '../Utils';
import Logger from '../Utils/logger';
import {Spinner} from '../Spinner';
import { ParagraphMedium } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../Request';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { withRouter } from 'react-router-dom';
import Storage from '../Storage';
import * as PATIENT_ROUTES from '../../patient/Constants';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class MFACodeVerification extends Component {
    constructor(props) {
        super(props);

        this.mfa = this.props.mfa;

        this.state = {
            error: null,
            message: null,
            login_verification: this.props.type == 'login',
            enroll_verification: this.props.type == 'enroll',
            email_verification: this.props.type == 'email',
            phone_number: this.props.phone_number || this.mfa.getPhoneNumber(),
            redirect: '',

            codes: Array(6).fill(''),
            checking_code: false,
            resending_code: false,
            resend_counter: 0
        };

        Logger.log('MFA Code Verification type == ' + this.props.type);
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (this.state.redirect && this.state.redirect !== prevState.redirect) {
        if (this.state.redirect == ROUTES.HOME) {
          if (this.mfa.firebase.isPatient()) {
            Request.get({
              firebase: this.mfa.firebase,
              route: PATIENT_ROUTES.BASE_URL_API + "/patient_dashboard/all_subscriptions",
              callbackSuccess: (result) => this.protocolRedirect(result.protocol_subscriptions) &&
              document.addEventListener('focusout', function(e) {window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })}),
              callbackError: (error) => this.props.history.push({pathname: ROUTES.HOME, state: {action: 'refresh'}})
            });
          } else {
            this.props.history.push({pathname: this.state.redirect, state: {action: 'refresh'}});
          }
        } else if (this.state.redirect == ROUTES.SIGN_IN){
          this.props.history.push({pathname: this.state.redirect, state: {message: 'Sign up is complete. Please login to continue.'}});
        } else {
          this.props.history.push(this.state.redirect);
        }
      }
    }

    handleCodes = (codes) => {
        codes = codes.values;
        this.setState({codes: codes, ...Utils.clearAllNotifications()}, () => {
            if (this.areAllCodesSet(codes)) {
                this.setState({checking_code: true, ...Utils.clearAllNotifications()});
                const code = this.state.codes.join('');
                if(this.state.enroll_verification) {
                    this.checkEnrollCode(code);
                } else if(this.state.login_verification){
                    this.checkLoginCode(code);
                } else if (this.state.email_verification) {
                    this.checkEmailCode(code);
                }
            }
        })
    };

    checkEnrollCode = (code) => {
        Logger.log('Enroll code check');
        this.mfa.verifyEnrollCode(code)
        .then(accepted => {
            this.mfa.firebase.signOut().then(user => {
              this.setState({
                redirect: ROUTES.SIGN_IN,
                checking_code: false,
                ...Utils.clearAllNotifications()
              });
            });
        })
        .catch(error => {
            Logger.error(error);
            this.setState({
                codes: Array(6).fill(''),
                error: {message: "Verification code failed."},
                checking_code: false,
                ...Utils.clearAllNotificationsExceptError()
            })
        });
    };

    checkLoginCode = (code) => {
        Logger.log('Login code check')
        this.mfa.verifyLoginCode(code)
        .then(user => {
            if (this.mfa.firebase.isPatient() || this.mfa.firebase.isPhysician()) {
                Request.get({
                    firebase: this.mfa.firebase,
                    route: ROUTES.BASE_URL_API + "/user_management/user_online",
                    callbackSuccess: result => {},
                    callbackError: error => {}
                });
            }

            const ph = this.mfa.firebase.getPhoneNumber();
            if ((this.mfa.firebase.isPhysician() || this.mfa.firebase.isPatient()) && (!ph.includes('*') || !ph.includes('#'))) {
                Request.put({
                    firebase: this.mfa.firebase,
                    route: ROUTES.BASE_URL_API + "/user_management/0",
                    body: JSON.stringify({mobile_number: ph, mobile_phone: ph}),
                    headers: {"Content-Type": "application/json"},
                    callbackSuccess: result => {
                        // Do nothing
                        Logger.log('Phone number updated.');
                        this.setRedirect();
                    },
                    callbackError: error => {
                        // Do something?
                        Logger.error('Could not update phone number.');
                        this.setRedirect();
                    }
                })
            } else {
                this.setRedirect();
            }
        })
        .catch(error => this.setState({
            codes: Array(6).fill(''),
            error: {message: "Verification code failed."},
            checking_code: false,
            ...Utils.clearAllNotificationsExceptError()
        }));
    };

    checkEmailCode = (code) => {
        Logger.log('Email code check')
        this.mfa.verifyEmailCode(code)
        .then(user => this.mfa.firebase.reloadUser().then(user => this.setRedirect()))
        .catch(error => this.setState({
            codes: Array(6).fill(''),
            error: {message: "Verification code failed."},
            checking_code: false,
            ...Utils.clearAllNotificationsExceptError()
        }));
    };

    areAllCodesSet = (codes) => {
        var notSetCodes = codes.filter(code => code.length == 0);
        return notSetCodes.length == 0;
    };

    handleResendCode = (event) => {
        event.preventDefault();

        if (this.state.resend_counter > 1) {
            this.setState({
                error: {message :"You can't request any more new codes, try again later."},
                ...Utils.clearAllNotificationsExceptError()
            });
        } else {
            this.setState({resending_code: true});

            if(this.state.enroll_verification) {
                this.mfa.enroll(this.state.phone_number, this.updateCodeResendCounter, this.codeResendError);
            } else if(this.state.login_verification){
                this.mfa.login(this.updateCodeResendCounter, this.codeResendError);
            } else if (this.state.email_verification) {
                this.mfa.sendEmailVerification()
                .then(() => this.updateCodeResendCounter())
                .catch(error => this.setState({
                    resending_code: false,
                    error: {message: 'Failed to send email verificaton. Please contact support for more help.'},
                    ...Utils.clearAllNotificationsExceptError()
                }));
            }
        }
    };

    codeResendError = (error) => {
        this.setState({resending_code: false});
    };

    updateCodeResendCounter = () => {
        if ([0, 1].includes(this.state.resend_counter)) {
            this.setState({
                resending_code: false,
                message: {message: "New code sent. Please wait 60 seconds before requesting another one."},
                resend_counter: this.state.resend_counter + 1,
                ...Utils.clearAllNotificationsExceptSuccess()
            });
        }
    }

    renderResendCodeLink = () => {
        return (
            <div style={{textAlign: 'center'}}>
                {this.state.resending_code ? <Spinner /> : <StyledLink href="#" onClick={this.handleResendCode}>Didn't get the code? Send it again</StyledLink>}
            </div>
        )
    };

    renderEmailOrPhone = () => {
        if (this.state.email_verification) {
            return <div style={{textAlign: 'center'}}><ParagraphMedium>📩 {this.mfa.firebase.getEmail()}</ParagraphMedium></div>
        } else {
            return <div style={{textAlign: 'center'}}><ParagraphMedium>{this.state.phone_number ? '📲' : ''} {Utils.renderPhone(this.state.phone_number)}</ParagraphMedium></div>
        }
    };

    renderCodeVerificationForm = () => {
        return (
            <div style={{marginRight:'16px', marginLeft:'16px'}}>
                {Utils.renderTitleCenter(this.state.email_verification ? 'Enter your one-time code from your email address' : 'Enter your one-time code from your phone')}
                {this.renderEmailOrPhone()}
                {Utils.renderSpace()}
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600">
                    <FlexGridItem {...centerProps}>
                        <PinCode
                            values={this.state.codes}
                            onChange={this.handleCodes}
                            error={this.state.error != null}
                            autocomplete="one-time-code"
                            autoFocus={true}
                            /*onBlur={() => {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                });
                            }}*//>
                    </FlexGridItem>
                </FlexGrid>
                {Utils.renderSpace()}
                {Utils.renderSpace()}
                {this.renderResendCodeLink()}
            </div>
        )
    };

    renderRecaptchaContainer = () => {
        return <div id='recaptcha'></div>;
    };

    setRedirect = () => {
        var redirectUrl = this.mfa.firebase.getUserSetting('redirect');
        if (redirectUrl){
            this.mfa.firebase.delUserSetting('redirect');
            this.setState({redirect: redirectUrl, checking_code: false});
        } else {
            if (this.state.email_verification){
                this.setState({redirect: ROUTES.MFA_ENROLL, message: {message:'Verification code accepted.'}, checking_code: false});
            } else {
                Logger.log('STOPPED: Redirect to home!');
                this.setState({redirect: ROUTES.HOME, message: {message: 'Verification code accepted.'}, checking_code: false});
            }
        }
    };

    protocolRedirect = (subscriptions) => {
        let active = [];
        let theRest = [];
        subscriptions.forEach(subscription => {
            if (['active', 'active_cancel_pending'].includes(subscription.status)){
                active.push(subscription);
            } else {
                theRest.push(subscription);
            }
        });

        if (active.length > 0) {
            active.sort((a, b) => {return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1});
            this.props.history.push(PATIENT_ROUTES.PROTOCOL_HOME + '/' + active.pop().protocol.number);

            return;
        }

        if (theRest.length > 0) {
            theRest.sort((a, b) => {return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1});
            this.props.history.push(PATIENT_ROUTES.SUBSCRIPTION_STATUS + '/' + theRest.pop().protocol.number);

            return;
        }

        this.props.history.push({pathname: ROUTES.HOME, state: {action: 'refresh'}});
    };

    render = () => {
        if (this.state.redirect) {
            return null;
        }

        return (
            <div className="center_page">
                {!this.state.checking_code ?
                <div>
                    {Utils.renderError(this.state.error)}
                    {Utils.renderSuccess(this.state.message)}
                    {this.renderCodeVerificationForm()}
                </div> : <Spinner />}
                {this.renderRecaptchaContainer()}
            </div>
        );
    };
}

export default withRouter(MFACodeVerification);
