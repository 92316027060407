import React, {Component} from 'react';
import { withFirebase } from '../Firebase';
import content from './content';
import Storage from '../Storage';

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: this.props.active,
            onClick: this.props.onClick,
            content: content('en', this.props.firebase.isPatient() ? "patient" : "physician")
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.active != this.props.active) {
            this.setState({active: this.props.active});
        }
    };

    activePagination = () => {
        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.state.content.pagination_width}
            onClick={this.state.onClick}
            style={{margin: this.state.content.pagination_margin}}
            enableBackground="new 0 0 502 502"
            viewBox="0 0 502 502"
        ><circle cx="251" cy="251" r="250" fill={Storage.get('theme') == 'light' || !this.props.firebase.isPatient() ? "#000000" : "white"}></circle></svg>
    };

    inactivePagination = () => {
        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width={this.state.content.pagination_width}
            onClick={this.state.onClick}
            style={{margin: this.state.content.pagination_margin}}
            enableBackground="new 0 0 502 502"
            viewBox="0 0 502 502"
        ><circle cx="250.6" cy="250.6" r="250" fill="#CBCBCB"></circle></svg>
    };

    renderPaginationItem = () => {
        return this.state.active ? this.activePagination() : this.inactivePagination();
    };

    render = () => this.renderPaginationItem();
}

export default withFirebase(Pagination);