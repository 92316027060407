import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { FileUploader } from "baseui/file-uploader";
import { Input } from "baseui/input";
import Utils from '../../shared/Utils';
import {ListItem, ListItemLabel} from 'baseui/list';
import * as ROUTES from '../Constants';
import SpinnerModal from '../../shared/Spinner';
import {Button, KIND, SHAPE} from 'baseui/button';
import {HeadingSmall, HeadingXSmall, ParagraphMedium, ParagraphSmall} from 'baseui/typography';
import { StyledLink } from "baseui/link";
import Delete from 'baseui/icon/delete'
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import MpsModal from '../../shared/Utils/modal';
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';
import content from './content';
import Linkify from 'react-linkify';

const actionButton = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '50px',
                flexGrow: 0
            }),
        },
    },
};

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isLoading: false,
            protocol_id: this.props.protocol_id,
            order_id: this.props.order_id,
            protocol_task_id: this.props.protocol_task_id,
            task_description: this.props.task_description,
            title: this.props.title,
            response: null,
            file_upload_error: null,
            error: null,
            success: null,
            info: null,
            completeable: false,
            show: this.props.show ? this.props.show : false,
            stored_files: [],
            file_description: '',
            files_uploaded: [],
            files_failed: [],
            content: this.getContent('en')
        };

        this.max_files = 10;
    }

    componentDidMount = () => {
        if (this.state.order_id) {
            Request.get({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/protocol_task_responses/through_order/" + this.state.order_id + '/' + this.state.protocol_task_id,
                callbackSuccess: (result) => this.setState({
                    response: result,
                    stored_files: result.files_url,
                    file_description: result.description,
                    isLoaded: true,
                    ...Utils.clearAllNotifications()
                }, () => this.callback()),
                callbackError: (error) => this.setState({error, isLoaded: true, ...Utils.clearAllNotificationsExceptError()})
            })
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show, ...Utils.clearAllNotifications()});
        }
    };

    getContent = (lang) => {
        return content(lang);
    };

    isNotCompleteable = () => {
        return this.state.file_description == ''
            || (this.getNumberOfStoredFiles() == 0  && this.state.files_uploaded.length == 0);
    };

    closeModal = () => {
        this.setState({show: false, ...Utils.clearAllNotifications()});

        this.props.callbackCancelHandler();
    };

    callback = () => {
        if (this.isNotCompleteable()) {
            this.props.callbackCompleteHandler(false);
        } else {
            this.props.callbackCompleteHandler(true);
        }
    };

    submit = () => {
        this.submitFiles();
    };

    submitFiles = () => {
        this.setState({isLoading: true, ...Utils.clearAllNotifications()});
        const data = new FormData();
        data.append('id', this.state.response ? this.state.response.id : null);
        data.append('protocol_task_id', this.state.protocol_task_id);
        data.append('protocol_id', this.state.protocol_id);
        data.append('order_id', this.state.order_id);
        data.append('description', this.state.file_description);
        Array.isArray(this.state.files_uploaded) && this.state.files_uploaded.map(file => data.append('files[]', file));
        Array.isArray(this.state.stored_files) && this.state.stored_files.map(file => file._destroy ? data.append('delete_files[]', file.id) : '');

        if (this.state.response) {
            // PUT
            Request.put({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/protocol_task_responses" + (this.state.response ? '/'+this.state.response.id : ''),
                body: data,
                callbackSuccess: (result) => {
                    let mergedFiles = this.state.stored_files ? this.state.stored_files.filter(file => !file._destroy) : [];

                    this.setState({
                        response: result,
                        show: false,
                        isLoading: false,
                        stored_files: mergedFiles.concat(this.state.files_uploaded),
                        files_uploaded: [],
                        ...Utils.clearAllNotifications()
                    }, () => this.callback())
                },
                callbackError: (error) => this.setState({
                    error,
                    isLoading: false,
                    ...Utils.clearAllNotificationsExceptError()
                })
            })
        } else {
            // POST
            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/protocol_task_responses" + (this.state.response ? '/'+this.state.response.id : ''),
                body: data,
                callbackSuccess: (result) => {
                    let mergedFiles = this.state.stored_files.filter(file => !file._destroy);

                    this.setState({
                        response: result,
                        show: false,
                        isLoading: false,
                        stored_files: mergedFiles.concat(this.state.files_uploaded),
                        files_uploaded: [],
                        ...Utils.clearAllNotifications()
                    }, () => this.callback())
                },
                callbackError: (error) => this.setState({
                    error,
                    isLoading: false,
                    ...Utils.clearAllNotificationsExceptError()
                })
            })
        }
    };

    getPatientFileDescription = () => {
        if (this.state.response) {
            return this.state.response.description
        }

        return '';
    };

    getPatientUplaodedFiles = () => {
        if (this.state.response && this.state.response.files_url) {
            return this.state.response.files_url;
        }

        return [];
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, ...Utils.clearAllNotifications()});
    }

    getNumberOfStoredFiles = () => {
        if (Array.isArray(this.state.stored_files)){
            const result = this.state.stored_files.filter(file => !file._destroy);
            return result.length;
        }
        return 0;
    };

    canUploadMore = (howManyNewFiles) => {
        if (howManyNewFiles + this.state.files_uploaded.length + this.getNumberOfStoredFiles() > this.max_files) {
            this.setState({
                file_upload_error: "The maximum number of allowed files to be uploaded is " + this.max_files + "."
            });
            return false;
        }

        return true;
    }

    handleFileUpload = (acceptedFiles, rejectedFiles) => {
        if (this.canUploadMore(acceptedFiles.length)) {
            this.setState(state => {
                const accepted = state.files_uploaded.concat(acceptedFiles);
                const rejected = state.files_failed.concat(rejectedFiles);
                return {
                    files_uploaded: accepted,
                    files_failed: rejected
                }
            });
        }
    }

    fileUploadRetry = () => {
        this.setState({file_upload_error: null});
    };

    formatFileSize = (size) => {
        return size;
    };

    deleteSuccessfulUploads = (index) => {
        let files_uploaded = [...this.state.files_uploaded]
        files_uploaded.splice(index, 1);
        this.setState({files_uploaded: files_uploaded});
    };

    renderFilesUploaded = () => {
        if (this.state.files_uploaded.length > 0) {
            return <div>
                {Utils.renderSpace()}
                <ParagraphMedium>{this.state.files_uploaded.length} files ready to be uploaded</ParagraphMedium>
                <FlexGrid
                    flexGridColumnCount={1}
                    flexGridColumnGap="scale200"
                    flexGridRowGap="scale400"
                >
                    {this.state.files_uploaded.map((file, idx) => <FlexGridItem key={idx}><FlexGrid flexGridColumnCount={2}><FlexGridItem><Input
                    value={file.name}
                    disabled
                />
                </FlexGridItem>
                <FlexGridItem {...actionButton}>
                <Button id={idx} onClick={() => this.deleteSuccessfulUploads(idx)} kind={KIND.tertiary} shape={SHAPE.square}>
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
                </FlexGrid></FlexGridItem>)}

                </FlexGrid>
            </div>
        }
    };

    deleteFailedUploads = (index) => {
        let files_failed = [...this.state.files_failed]
        files_failed.splice(index, 1);
        this.setState({files_failed: files_failed});
    };

    renderFilesNotUploaded = () => {
        if (this.state.files_failed.length > 0) {
            return <div>
                {Utils.renderSpace()}
                <div style={{backgroundColor: '#EFD6D8', color: '#632228'}}><strong>These files cannot be uploaded. Accepted files: .png, jpeg, .pdf under 10 MB in size.</strong></div>
                <ul>
                    {this.state.files_failed.map((file, idx) => <ListItem
                        endEnhancer={() => <Button id={idx} onClick={() => this.deleteFailedUploads(idx)} kind={KIND.tertiary} shape={SHAPE.square}>
                            <Delete size={24} />
                        </Button>}
                        key={idx}>
                        {file.name}
                    </ListItem>)}
                </ul>
            </div>
        }
    };

    getFileName = (uri) => {
        return uri.split('/').pop();
    };

    deleteStoredUpload = (index) => {
        let stored_files = [...this.state.stored_files];
        stored_files[index]._destroy = true;
        this.setState({stored_files: stored_files});
    };

    restoreStoredUpload = (index) => {
        if (this.canUploadMore(1)) {
            let stored_files = [...this.state.stored_files];
            delete stored_files[index]._destroy;
            this.setState({stored_files: stored_files});
        }
    };

    renderSingleStoredFile = (id, file) => {
        if (file._destroy) {
            return <FlexGridItem key={id}>
                <FlexGrid flexGridColumnCount={2}>
                    <FlexGridItem><Input
                    value={this.getFileName(decodeURI(file.url || file.name))}
                    disabled
                /></FlexGridItem>
                    <FlexGridItem {...actionButton}><Button onClick={() => this.restoreStoredUpload(id)} kind={KIND.tertiary} shape={SHAPE.square}>Undo</Button></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        } else {
            return <FlexGridItem key={id}>
                <FlexGrid flexGridColumnCount={2}>
                    <FlexGridItem><Input
                    value={this.getFileName(decodeURI(file.url || file.name))}
                    disabled
                /></FlexGridItem>
                    <FlexGridItem {...actionButton}><Button id={id} onClick={() => this.deleteStoredUpload(id)} kind={KIND.tertiary} shape={SHAPE.square}><Delete size={24} /></Button></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        }
    };

    renderStoredFiles = () => {
        if (this.state.stored_files && this.state.stored_files.length > 0) {
            return <div>
                {Utils.renderSpace()}
                <FlexGrid
                    flexGridColumnCount={1}
                    flexGridColumnGap="scale200"
                    flexGridRowGap="scale400">
                    {this.state.stored_files.map((file, idx) => this.renderSingleStoredFile(idx, file))}
                </FlexGrid>
            </div>
        }
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <MpsModal
                            open={this.state.show}
                            autoFocus={false}
                            callback={this.submit}
                            callbackCancel={this.closeModal}
                            withFooter={true}
                            title={Utils.renderTitleCenter(this.state.title)}
                            isLoading={this.state.isLoading}
                            body={
                                <div>
                                    {Utils.renderError(this.state.error)}
                                    {Utils.renderSuccess(this.state.success)}
                                    {Utils.renderInfo(this.state.info)}
                                    {this.state.isLoaded ?
                                        <div>
                                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><ParagraphMedium style={{margin: '0px', textAlign: 'center', whiteSpace: 'pre-line'}}><i>{this.state.task_description}</i></ParagraphMedium></Linkify>
                                            {this.renderStoredFiles()}
                                            {this.renderFilesUploaded()}
                                            {this.renderFilesNotUploaded()}
                                            {Utils.renderSpace()}
                                            <FileUploader
                                                name='files_uploaded'
                                                accept=".pdf,.jpeg,.jpg,.png"
                                                maxSize={10000000}
                                                onRetry={this.fileUploadRetry}
                                                onDrop={(acceptedFiles, rejectedFiles) => {
                                                    // handle file upload...
                                                    Logger.log('File Upload In Progress...');
                                                    Logger.log(acceptedFiles);
                                                    Logger.log(rejectedFiles);
                                                    this.handleFileUpload(acceptedFiles, rejectedFiles);
                                                }}
                                                multiple
                                                errorMessage={this.state.file_upload_error}
                                            />
                                            {Utils.renderSpace()}
                                            <Input
                                                name='file_description'
                                                value={this.state.file_description}
                                                onChange={this.handleChange}
                                                placeholder="Give your file attachments a name"
                                            />

                                            {/*this.state.content.file_attachment_note*/}
                                        </div>
                                    : <SpinnerModal title={this.state.title}/>}
                                </div>
                            }
                        />
                    )
                }
            </AuthUserContext.Consumer>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Upload);
