import React from 'react';
import Screen from './screen';
import Storage from '../Storage';
import Logger from './logger';

const getLandingLogo = () => {
    Logger.log('UI Theme is: ' + Storage.get('theme'));
    if (Storage.get('theme') == 'light') {
        return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={Screen.size() == 'SMALL' ? '72px' : '76px'} x="0" y="0" viewBox="0 0 392 392" xmlSpace="preserve">
        <path fill={"#1F1F1F"} d="M52.6.7c46.5 0 103 27.7 169.5 83.1 30.2 0 49.7.9 58.4 2.5 23.7 5.1 35.6 17.3 35.6 36.7 0 11.6-4.5 24.9-13.4 39.9 26.9 31.7 48 62.4 63.5 92.2 16.7 31.9 25 59.9 25 83.8 0 16.5-4.6 29.3-13.8 38.5-9.2 9.2-22 13.8-38.5 13.8-31 0-68-13.6-111.1-40.7-40.2-25.2-79.1-57.7-116.9-97.6l-25.6 51.5H20L69.8 205C23.8 145.2.7 94.4.7 52.6c0-16.4 4.5-29.2 13.6-38.3S36.2.7 52.6.7zM25.8 61.4c0 34.4 17.8 76.3 53.5 125.7l52.1-103.2h87.1C161 37.4 112.5 14.2 72.9 14.2c-15 0-26.6 4.1-34.8 12.3-8.2 8.2-12.3 19.9-12.3 34.9zM120 236c34.1 37.2 69.9 67.7 107.3 91.4 39.9 25.6 74.1 38.4 102.6 38.4 15 0 26.6-4.1 34.8-12.3 8.2-8.2 12.3-19.8 12.3-34.8 0-41.9-25.3-93-75.9-153.5-16.9 27.6-42.8 45.5-77.7 53.7-19.6 4.6-52.5 6.9-98.8 6.9L120 236zm74.7-105h-22.1l-23.8 47.2H184c13.6 0 25.8-2.2 36.7-6.5 15-5.8 22.5-14.5 22.5-26.1 0-7.5-6.8-12.1-20.3-13.8-3.4-.5-12.8-.8-28.2-.8z"></path></svg>
    } else {
        return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={Screen.size() == 'SMALL' ? '72px' : '76px'} x="0" y="0" viewBox="0 0 392 392" xmlSpace="preserve">
        <path fill={"white"} d="M52.6.7c46.5 0 103 27.7 169.5 83.1 30.2 0 49.7.9 58.4 2.5 23.7 5.1 35.6 17.3 35.6 36.7 0 11.6-4.5 24.9-13.4 39.9 26.9 31.7 48 62.4 63.5 92.2 16.7 31.9 25 59.9 25 83.8 0 16.5-4.6 29.3-13.8 38.5-9.2 9.2-22 13.8-38.5 13.8-31 0-68-13.6-111.1-40.7-40.2-25.2-79.1-57.7-116.9-97.6l-25.6 51.5H20L69.8 205C23.8 145.2.7 94.4.7 52.6c0-16.4 4.5-29.2 13.6-38.3S36.2.7 52.6.7zM25.8 61.4c0 34.4 17.8 76.3 53.5 125.7l52.1-103.2h87.1C161 37.4 112.5 14.2 72.9 14.2c-15 0-26.6 4.1-34.8 12.3-8.2 8.2-12.3 19.9-12.3 34.9zM120 236c34.1 37.2 69.9 67.7 107.3 91.4 39.9 25.6 74.1 38.4 102.6 38.4 15 0 26.6-4.1 34.8-12.3 8.2-8.2 12.3-19.8 12.3-34.8 0-41.9-25.3-93-75.9-153.5-16.9 27.6-42.8 45.5-77.7 53.7-19.6 4.6-52.5 6.9-98.8 6.9L120 236zm74.7-105h-22.1l-23.8 47.2H184c13.6 0 25.8-2.2 36.7-6.5 15-5.8 22.5-14.5 22.5-26.1 0-7.5-6.8-12.1-20.3-13.8-3.4-.5-12.8-.8-28.2-.8z"></path></svg>
    }
};

const getNavLogo = () => {
    
        return <>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={'22px'} x="0" y="0" viewBox="0 0 637 141" xmlSpace="preserve" style={{paddingTop:"3px"}}>
                <path fill= "#ffffff" d="M.3.4h45.5c29.4 0 50.1 21.2 50.1 48.5 0 27.1-20.7 48.5-50.2 48.5H20.9v41.3H.3V.4zm20.6 18.9v59h23.3c19 0 30.9-12.9 30.9-29.4 0-16.7-11.9-29.6-30.9-29.6H20.9zM113.1.4h20.1V56c6.4-8.3 16.9-13.8 29.2-13.8 23.9 0 38.5 15.5 38.5 41.3v55.4h-20.7V85.3c0-15.7-7.6-25.6-22.9-25.6-13.5 0-23.9 10.2-23.9 26.9v52.1h-20.3V.4zm103.7 91c0-30.3 22.6-49.3 47.4-49.3 12.5 0 24.5 5.5 31.5 14.2V44H316v94.8h-20.3v-13.1c-7.2 9.3-19.1 15-31.7 15-24.1-.1-47.2-19.2-47.2-49.3zm80-.2c0-16.9-12.7-30.7-30-30.7-16.9 0-30 13.5-30 30.7s13.1 30.9 30 30.9c17.3 0 30-13.9 30-30.9zM336.4 44h20.3v15c5.1-9.7 14.4-15 27.7-15h9.1v19.3h-12.3c-17.3 0-24.3 8.7-24.3 28.4v47h-20.5V44zm73.6 0h20.1v11.4c6.1-8.3 15.7-13.3 27.9-13.3 15.2 0 25.8 6.4 31.3 17.1 6.4-10.4 18-17.1 32-17.1 25.8 0 39.2 15.5 39.2 39.8v56.9h-20.7V83.2c0-14.2-7.8-23.5-22-23.5-12.7 0-22.6 8.7-22.6 25.6v53.5h-20.5V82.3c0-13.8-7.8-22.6-21.4-22.6-13.3 0-23.1 8.9-23.1 26.4v52.7H410V44zm169.7 0H600v15c5.1-9.7 14.4-15 27.7-15h9.1v19.3h-12.3c-17.3 0-24.3 8.7-24.3 28.4v47h-20.5V44z">
                </path>
            </svg>
        </>
};

const data = {
    "en": {
        "patient": {
            
            logo: getNavLogo()
            ,
    
            landingLogo: getLandingLogo()
        },
        "physician": {
            // No need to add anything here at this point.
        }
    }
}

const content = (lang, app) => {
    return data[lang][app];
};

export default content;
