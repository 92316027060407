import * as ROUTES from '../Constants';
import Logger from '../Utils/logger';
import Request from '../Request';

class MFA {
    constructor(firebase, callbackError) {
        this.firebase = firebase;
        this.callbackError = callbackError;
        this.resolver = null;
        this.verification_id = null;
        this.recaptcha = null;
        this.phoneNumber = null;
    }

    getFirebase = () => {
        return this.firebase;
    };

    getPhoneNumber = () => {
      return this.phoneNumber || this.firebase.getPhoneNumber();
    }

    setResolver = (resolver) => {
        this.resolver = resolver;
    };

    isEmailVerified = () => {
        return this.firebase.isEmailVerified();
    };

    isUserEnrolled = () => {
        return this.firebase.isUserMFAEnrolled();
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    sendEmailVerification = () => {
        return new Promise((resolve, reject) => {
            const body = {
                tenantId: this.firebase.getTenant(),
                userEmail: this.firebase.getEmail()
            };

            var status_ok = false;
            this.firebase.currentUser().getIdToken(false).then((token) => {
                fetch(ROUTES.EMAIL_NEW_CODE_URL,
                    {
                        headers: {
                            "authorization" : `Bearer ${token}`,
                            "Content-Type": 'application/json'
                        },
                        method: 'POST',
                        mode: 'cors',
                        body: JSON.stringify(body)
                    }
                ).then(res => {
                    status_ok = res.ok;
                    return res.json();
                }).then(res => {
                    if (status_ok) {
                        resolve();
                    } else {
                        reject();
                    }
                }).catch((error) => {
                    reject();
                })
            });
        });
    };

    // Used in Sign Up process
    verifyEnrollCode = (code) => {
        if (code.length != 6) {
            return false;
        }

        return new Promise((resolve, reject) => {
            Request.post({
                firebase: this.firebase,
                route: ROUTES.PHONE_CODE_VERIFICATION_URL,
                headers: {"Content-Type": 'application/json'},
                body: JSON.stringify({
                    tenantId: this.firebase.getTenant(),
                    userEmail: this.firebase.getEmail(),
                    verificationCode: code
                  }),
                callbackSuccess: result => resolve(result),
                callbackError: error => reject(error)
            })
        });
    };

    recaptchaCallback = (response) => {
        // https://stackoverflow.com/questions/41132750/uncaught-error-recaptcha-placeholder-element-must-be-an-element-or-id
        // TODO do we need to do anything?
        // TODO clean up: Uncaught (in promise) Timeout (i)
        //      https://github.com/google/recaptcha/issues/269
        Logger.log('------ RECAPTCHA callback called... ------');
        Logger.log(response);
    };

    // Used by Sign Up
    enroll = (phone_number, callback, errorCallback) => {
        Request.post({
            firebase: this.firebase,
            route: ROUTES.ENROLL_NEW_PHONE_CODE_URL,
            body: JSON.stringify({
                tenantId: this.firebase.getTenant(),
                userEmail: this.firebase.getEmail(),
                phoneNumber: phone_number
            }),
            headers: {"Content-Type": "application/json"},
            callbackSuccess: result => {
                if (callback) {
                    callback();
                }
            },
            callbackError: error => {
                this.callbackError(error);
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        })
    };

    // Used in Sign In process
    login = (callback, errorCallback) => {
        var factorId = this.resolver.hints[0].factorId;

        if (factorId === 'phone') {
            if (!this.recaptcha) {
                this.recaptcha = this.firebase.getRecaptchaVerifier('recaptcha', this.recaptchaCallback);
                if (!this.recaptcha) {
                    this.callbackError({error: {message: "Could not initiate recaptcha..."}});
                }
            }

            var phoneInfoOptions = {
                multiFactorHint: this.resolver.hints[0],
                session: this.resolver.session
            };
            this.phoneNumber = this.resolver.hints[0].phoneNumber;

            var phoneAuthProvider = this.firebase.getPhoneAuthProvider();

            return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptcha)
            .then((verificationId) => {
                this.verification_id = verificationId;
                if(callback) {
                    callback();
                }
            })
            .catch((error) => {
                this.callbackError(error);
                if(errorCallback) {
                    errorCallback(error);
                }
            });
        }
    };

    verifyLoginCode = (code) => {
        var cred = this.firebase.getPhoneAuthProviderCredential(this.verification_id, code);
        var multiFactorAssertion = this.firebase.getMultiFactorAssertion(cred);

        // Complete sign-in.
        return this.resolver.resolveSignIn(multiFactorAssertion);
    };

    verifyEmailCode = (code) => {
        return new Promise((resolve, reject) => {
            const body = {
                tenantId: this.firebase.getTenant(),
                userEmail: this.firebase.getEmail(),
                verificationCode: code
            };

            var status_ok = false;
            this.firebase.currentUser().getIdToken(false).then((token) => {
                fetch(ROUTES.EMAIL_CODE_VERIFICATION_URL,
                    {
                        headers: {
                            "authorization" : `Bearer ${token}`,
                            "Content-Type": 'application/json'
                        },
                        method: 'POST',
                        mode: 'cors',
                        body: JSON.stringify(body)
                    }
                ).then(res => {
                    this.firebase.currentUser().reload()
                    status_ok = res.ok;
                    return res.json();
                }).then(res => {
                    if (status_ok) {
                        resolve();
                    } else {
                        reject();
                    }
                }).catch((error) => {
                    reject();
                })
            });
        })
    };
}

export default MFA;
