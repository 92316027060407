import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import * as ROUTES from '../Constants';
import {Input, MaskedInput} from 'baseui/input';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { Button, KIND, SHAPE, SIZE} from 'baseui/button';
import { FormControl } from "baseui/form-control";
import MpsModal from '../../shared/Utils/modal';
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';

class CreditCard extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            message: null,

            showModal: this.props.showModal ? this.props.showModal : false,
            title: this.props.title,
            card_holder_name: '',
            month_year: '',
            billing_zip: '',
            hide_spinner: false
        };
    }

    componentDidMount = () => {
        Logger.log("Payment component mounted...");

        Spreedly.on("ready", () => {
            this.setState({hide_spinner: true, ...Utils.clearAllNotifications()});
            Spreedly.setStyle("number", this.spreedlyStyle());
            Spreedly.setStyle("cvv", this.spreedlyStyle());
            Spreedly.setFieldType("number", "text");
            Spreedly.setNumberFormat("prettyFormat");
            Spreedly.setPlaceholder("number", "Card Number");
            Spreedly.setPlaceholder("cvv", "CVV");
        });

        Spreedly.on("fieldEvent", (name, type, activeEl, inputProperties) => {
            if(name == "number") {
                if(type == "focus") {
                    Spreedly.setStyle("number", this.spreedlyFocusStyle());
                } else if (type == "blur") {
                    Spreedly.setStyle("number", this.spreedlyStyle());
                }
            } else if(name == "cvv") {
                if(type == "focus") {
                    Spreedly.setStyle("cvv", this.spreedlyFocusStyle());
                } else if (type == "blur") {
                    Spreedly.setStyle("cvv", this.spreedlyStyle());
                }
            }
        });

        Spreedly.on('errors', (errors) => {
            Logger.log('spreedly errors');
            var error_message = ""
            for (var i=0; i < errors.length; i++) {
                var error = errors[i];
                Logger.log(error);
                error_message = error_message + errors[i]["message"] + '. ';
            };
            if (error_message.length > 0) {
                this.setState({submittingCard: false, error: {message: error_message}, hide_spinner: true, ...Utils.clearAllNotificationsExceptError()});
            }
        });

        Spreedly.on('validation', (inputProperties) => {
            Logger.log('spreedly_validation');
            Logger.log(inputProperties);
            var error_message = ""
            //TODO: set error status on these fields
            if (!inputProperties["validNumber"]) {
                error_message = error_message + "Please enter a valid credit card number. ";
            }
            if (!inputProperties["validCvv"]) {
                error_message = error_message + "Please enter a valid CVV card number. ";
            }
            if (inputProperties['validCvv'] && inputProperties['validNumber']) {
                //tokenize the card.
                var requiredFields = {};

                // Get required, non-sensitive, values from host page
                requiredFields["full_name"] = this.state.card_holder_name;
                requiredFields["month"] = this.state.month_year.split('/')[0];
                requiredFields["year"] = this.state.month_year.split('/')[1];
                requiredFields["zip"] = this.state.billing_zip;
                Logger.log("CC Required Fields to be validated:");
                Logger.log(requiredFields);
                //check processing_card and card_token/payment_method then set processing card.
                Spreedly.tokenizeCreditCard(requiredFields);
            } else {
                this.setState({submittingCard: false, error: {message: error_message}, hide_spinner: true, ...Utils.clearAllNotificationsExceptError()});
            }
        });

        Spreedly.on('paymentMethod', (token, pmData) => {
            var payment_method_data = {
                token: token,
                pmData: pmData
            };
            Logger.log('on payment method');

            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/patient_payment/billing_info",
                body: JSON.stringify(payment_method_data),
                headers: {"Content-type" : 'application/json'},
                callbackSuccess: (result) => this.setState({
                    message: {message: 'New credit card added'},
                    submittingCard: false,
                    ...Utils.clearAllNotifications()
                }, () => {
                    this.callback(result.payment_method);
                    this.closeCardForm();
                }),
                callbackError: (error) => this.setState({
                    submittingCard: false,
                    error,
                    hide_spinner: true,
                    ...Utils.clearAllNotificationsExceptError()
                })
            })
        });

    };

    componentWillUnmount = () => {
        Spreedly.removeHandlers();
    };

    callback = (new_card) => {
        this.props.callback && this.props.callback(new_card);
    };

    openModal = () => {
        this.setState(
            {
                showModal: true,
                card_holder_name: '',
                month_year: '',
                billing_zip: '',
                hide_spinner: false,
                ...Utils.clearAllNotifications()
            },
            () => this.initSpreedly()
        );
    };

    spreedlyStyle = () => {
        // Light mode
        let backgroundColor = 'rgb(238, 238, 238);';
        let caretColor = 'rgb(0, 0, 0);';
        let color = 'rgb(0, 0, 0);';
        let outlineColor = 'rgb(0, 0, 0);';

        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            backgroundColor = 'rgb(41, 41, 41);';
            caretColor = 'rgb(226, 226, 226);';
            color = 'rgb(226, 226, 226);';
            outlineColor = 'rgb(255, 255, 255);';
        }

        return 'background-color: ' + backgroundColor +
        'border-style: none;' +
        'border-width: 0px;' +
        'border-radius: 8px;' +
        'box-sizing: border-box;' +
        'caret-color: ' + caretColor +
        'color: ' + color +
        'cursor: text;' +
        'font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;' +
        'font-size: 16px;' +
        'font-weight: 400;' +
        'line-height: 24px;' +
        'margin-bottom: 0px;' +
        'margin-left: 0px;' +
        'margin-right: 0px;' +
        'margin-top: 0px;' +
        'max-width: 100%;' +
        'outline-color: ' + outlineColor +
        'outline-style: none;' +
        'outline-width: 0px;' +
        'overflow: visible;' +
        'overflow-x: visible;' +
        'overflow-y: visible;' +
        'padding-bottom: 12px;' +
        'padding-left: 14px;' +
        'padding-right: 14px;' +
        'padding-top: 12px;' +
        'pointer-events: all;' + 
        'width: 100%;' +
        'height: 48px;'
    };

    spreedlyFocusStyle = () => {
        // Light mode
        let backgroundColor = 'rgb(246, 246, 246);';
        let borderColor = 'rgb(0, 0, 0);';
        let caretColor = 'rgb(0, 0, 0);';
        let color = 'rgb(0, 0, 0);';
        let outlineColor = 'rgb(0, 0, 0);';

        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            backgroundColor = 'rgb(41, 41, 41);';
            borderColor = 'rgb(255, 255, 255);';
            caretColor = 'rgb(226, 226, 226);';
            color = 'rgb(226, 226, 226);';
            outlineColor = 'rgb(255, 255, 255);';
        }

        return 'background-color: ' + backgroundColor +
        'border-style: solid;' +
        'border-width: 2px;' +
        'border-radius: 8px;' +
        'border-color: ' + borderColor +
        'box-sizing: border-box;' +
        'caret-color: ' + caretColor +
        'color: ' + color +
        'cursor: text;' +
        'font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;' +
        'font-size: 16px;' +
        'font-weight: 400;' +
        'line-height: 24px;' +
        'margin-bottom: 0px;' +
        'margin-left: 0px;' +
        'margin-right: 0px;' +
        'margin-top: 0px;' +
        'max-width: 100%;' +
        'outline-color: ' + outlineColor +
        'outline-style: none;' +
        'outline-width: 0px;' +
        'overflow: visible;' +
        'overflow-x: visible;' +
        'overflow-y: visible;' +
        'padding-bottom: 10px;' +
        'padding-left: 14px;' +
        'padding-right: 14px;' +
        'padding-top: 10px;' +
        'pointer-events: all;'
    };

    initSpreedly = () => {
        Logger.log('Spreedly initiating...');
        Request.get({
            firebase: this.props.firebase,
            headers: {"Content-type" : 'application/json'},
            route: ROUTES.BASE_URL_API + "/patient_payment/spreedly_token",
            callbackSuccess: (result) => Spreedly.init(result.spreedly_token, {
                "numberEl": "spreedly-number",
                "cvvEl": "spreedly-cvv"
            }),
            callbackError: (error) => this.setState({
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    checkPayment = () => {
        Logger.log('Chevking payment');
        this.setState({submittingCard: true, hide_spinner: false, ...Utils.clearAllNotifications()});
        if (Number.isNaN(this.state.billing_zip) || this.state.billing_zip.length != 5) {
            Logger.log('Initial zip check failure');
            this.setState({error: {message: 'Invalid zip code.'}, hide_spinner: true, submittingCard: false, ...Utils.clearAllNotificationsExceptError()});
        } else {
            Logger.log('Validating zip.....');
            this.validateZipCode(this.addCardToSpreedly, () => {
                this.setState({error: {message: 'Zip code is invalid'}, hide_spinner: true, submittingCard: false, ...Utils.clearAllNotificationsExceptError()});
            });
        }
    };

    addCardToSpreedly = () => {
        Logger.log('Zip validated and now sending to spreedly...');
        Spreedly.validate();
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, ...Utils.clearAllNotifications()});
    };

    handleMonthYearCCChange = (event) => {
        this.setState({month_year: event.target.value, ...Utils.clearAllNotifications()});
    };

    validateZipCode = (success, failed) => {
        const zipRegex = new RegExp('[0-9]{5}', 'g');

        if (zipRegex.test(this.state.billing_zip)) {
            Logger.log("Zip Success!");
            success();
        } else {
            Logger.error("Zip Error!");
            failed();
        }
    };

    showSpace = () => {
        return <p></p>;
    };

    closeCardForm = () => {
        this.setState({
            showModal: false,
            submittingCard: false,
            card_holder_name: '',
            month_year: '',
            billing_zip: '',
            error: null,
            hide_spinner: true
        });
    };

    presentPaymentForm = () => {
        return this.state.showModal ? <MpsModal
        open={this.state.showModal}
        autoFocus={false}
        callback={this.checkPayment}
        callbackCancel={this.closeCardForm}
        isLoading={this.state.submittingCard}
        withFooter={true}
        title={Utils.renderTitleCenter(this.props.title)}
        body={<div>
            {Utils.renderError(this.state.error)}
            <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale800" flexGridRowGap="scale200">
                <FlexGridItem>
                    <FormControl
                        overrides={{
                            ControlContainer:{
                                style: ({ $theme }) => ({
                                    marginBottom: '0px'
                                })
                            }
                        }}
                        label={() => "Card holder's name"}
                    ><Input type="text" id="card_holder_name" name="card_holder_name" value={this.state.card_holder_name} onChange={this.handleChange} placeholder="Cardholder's Name"/></FormControl>
                </FlexGridItem>
                <FlexGridItem>
                <FormControl 
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginBottom: '0px'
                            })
                        }
                    }}
                    label={() => "Debit or credit card number"}
                ><div id="spreedly-number" style={{width: '100%', height: '48px', margin: '0px'}}></div></FormControl>
                </FlexGridItem>
                <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginBottom: '0px'
                            })
                        }
                    }}
                    label={() => "Expiration date (MM/YYYY)"}
                    ><MaskedInput type="text" inputMode="numeric" id="month_year" name="month_year" value={this.state.month_year} onChange={this.handleMonthYearCCChange} placeholder="MM/YYYY" mask="99/9999" /></FormControl>
                </FlexGridItem>
                <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginBottom: '0px'
                            })
                        }
                    }}
                    label={() => "Security code"}
                    ><div id="spreedly-cvv" style={{width: '100%', height: '48px', margin: '0px'}}></div></FormControl></FlexGridItem>
                <FlexGridItem>
                    <FormControl
                        overrides={{
                            ControlContainer:{
                                style: ({ $theme }) => ({
                                    marginBottom: '0px'
                                })
                            }
                        }}
                        label={() => "Billing zip code"}
                    ><Input maxLength={5} id="billing_zip" name="billing_zip" value={this.state.billing_zip} onChange={this.handleChange} placeholder="Billing Zip" type="number" inputMode="numeric"/></FormControl></FlexGridItem>
            </FlexGrid>
        </div>} /> : '';
    };

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div>
                    <Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={this.openModal}>Add new card</Button>
                    {Utils.renderSpace()}
                        {this.presentPaymentForm()}
                    </div>
                )
            }
            </AuthUserContext.Consumer>
        );
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(CreditCard);
