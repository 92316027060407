import React, {Component} from 'react';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE as ModalSize,
    ROLE
} from "baseui/modal";
import Utils from '../../shared/Utils';
import { LabelLarge, ParagraphMedium, ParagraphSmall, HeadingSmall, LabelSmall, LabelMedium } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../../shared/Request';
import * as ROUTES from '../Constants';

const addRemoveProps = {
    maxWidth: '92px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
};

class DrugRequest extends Component {
    constructor(props){
        super(props);

        this.state = {
            defaultProducts: {},
            selectedProducts: [],
            showFulfiller: false,
        };

        this.fomo_pharmacy_id = 0;
        this.fomo_fulfiller_id = 0;
        this.fomo_price = '0.0';
        this.addProductToState();
        this.preselectDrugs(this.props.order_on_cart);
    }

    preselectDrugs = (order_on_cart) => {
        if (!order_on_cart.is_reorder && Object.keys(order_on_cart).length > 0) {
            // Update the request drugstore

            order_on_cart.line_items.map(line_item => {
                if (line_item.variant) {
                    const variant_name = line_item.variant.change_drug_id + '_' + line_item.variant.id;
                    this.addProduct(variant_name);
                    this.addLineItem(variant_name, line_item, order_on_cart.number);
                    if (line_item.fulfillment_pharmacy_id) {
                        this.setFulfiller(
                            variant_name,
                            this.getFulfillerBasedOnPharmacy(line_item.fulfillment_pharmacy_id, line_item.variant.fulfillment_prices),
                            line_item.fulfillment_pharmacy_id,
                            line_item.price);
                    } else {
                        this.setFulfiller(variant_name, this.fomo_fulfiller_id, this.fomo_pharmacy_id, this.fomo_price);
                    }
                }
            });
        }
    };

    getFomoAddress = () => {
        //TODO use fomo_pharmacy object instead of single field probably need to assemble and format the address from this.props.order_on_cart.fomo_pharmacy
        if (Object.keys(this.props.order_on_cart).length > 0 && this.props.order_on_cart.fomo_pharmacy_address) {
            return this.props.order_on_cart.fomo_pharmacy_address;
        }

        return '';
    };

    getFulfillerBasedOnPharmacy(pharmacy_id, fulfillment_prices) {
        var fulfiller_id = 0;

        fulfillment_prices.map(fulfillment_price => {
            if (fulfillment_price.pharmacy_id == pharmacy_id) {
                fulfiller_id = fulfillment_price.id;
            }
        });

        return fulfiller_id;
    }

    setFulfiller = (name, fulfiller_id, pharmacy_id, price) => {
        this.state.defaultProducts[name].fulfiller_id = fulfiller_id;
        this.state.defaultProducts[name].price = price;
        this.state.defaultProducts[name].pharmacy_id = pharmacy_id;

        if (this.props.results[name]) {
            this.props.results[name].fulfiller_id = fulfiller_id;
            this.props.results[name].price = price;
            this.props.results[name].pharmacy_id = pharmacy_id;
        }

        this.props.notify();
    };

    addProductToState = () => {
        for (var i = 0; i < this.props.products.length; i++) {
            for (var j = 0; j < this.props.products[i].variants.length; j++) {
                this.addVariantToState(this.props.products[i], this.props.products[i].variants[j]);
            }
        }
    };

    addVariantToState = (product, variant) => {
        variant.fulfillment_prices.push({
            id: this.fomo_fulfiller_id,
            price: this.fomo_price,
            pharmacy_id: this.fomo_pharmacy_id,
            pharmacy: {
                id: 0,
                address: this.getFomoAddress(),
                name: 'FOMO',
                phone: ''
            }
        });

        var defaultValue = {
            product_name: product.display_name,
            fulfillers: variant.fulfillment_prices,
            variant_id: variant.id,
            quantity: 1,
            price: null,
            fulfiller_id: null,
            pharmacy_id: null
        };

        this.state.defaultProducts[variant.change_drug_id + '_' + variant.id] = defaultValue;
    };

    addProduct = (name) => {
        this.state.selectedProducts.push(name);
        this.props.results[name] = this.state.defaultProducts[name];
        this.props.notify();
    };

    addLineItem = (name, line_item, order_number) => {
        this.props.results[name]['line_item_id'] = line_item.id;
        this.props.results[name]['order_number'] = order_number;
    };

    isDrugFomoOnly = (name) => {
        return this.state.defaultProducts[name].fulfillers.length == 1 && this.state.defaultProducts[name].fulfillers[0].id == this.fomo_fulfiller_id;
    };

    addSelectedProduct = (event) => {
        this.addProduct(event.target.name);
        if (!this.isDrugFomoOnly(event.target.name)) {
            this.openFulfiller();
        } else {
            this.setFulfillerForDrug(event.target.name, this.fomo_fulfiller_id);
        }
    };

    removeSelectedProduct = (event) => {
        this.removeSelectedProductByName(event.target.name);
    };

    removeSelectedProductByName = (name) => {
        this.state.selectedProducts = this.state.selectedProducts.filter((value) => value != name);
        this.state.defaultProducts[name].fulfiller_id = null;
        if (this.props.results[name].line_item_id && this.props.results[name].order_number) {
            this.removeLineItemFromOrder(this.props.results[name].line_item_id, this.props.results[name].order_number);
        }

        delete this.props.results[name];
        this.props.notify();
    };

    removeLineItemFromOrder = (line_item_id, order_number) => {
        const data = new FormData();
        data.append('line_item_id', line_item_id);
        data.append('order_number', order_number);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_order/delete_from_order",
            body: data,
            callbackSuccess: (result) => {},
            callbackError: (error) => {}
        })
    }

    getLatestSelectedDrug = () => {
        return this.state.selectedProducts[this.state.selectedProducts.length - 1];
    };

    removeLatestSelectedDrug = () => {
        this.removeSelectedProductByName(this.getLatestSelectedDrug());
    };

    renderPharmacyInfo = (fulfiller) => {
        var pharmacy = fulfiller.pharmacy.name;

        if (fulfiller.pharmacy.address) {
            pharmacy += ' - ' + fulfiller.pharmacy.address
        }

        if (fulfiller.pharmacy.phone) {
            pharmacy += ' ' + fulfiller.pharmacy.phone;
        }

        return pharmacy;
    };

    drawFulfillerOptions = () => {
        // Get the last item in the selected drugs
        var selectedDrug = this.state.defaultProducts[this.getLatestSelectedDrug()];
        return selectedDrug.fulfillers.map((fulfiller, idx) => <Radio key={idx}
            value={fulfiller.id}
            description={fulfiller.price == 0 ? 'Route the prescription to a pharmacy of my choosing. You will do this on the next page.' : this.renderPharmacyInfo(fulfiller)}>
                {fulfiller.price == 0 ? 'Prescription only' : Utils.renderDollarAmount(fulfiller.price)}
        </Radio>);
    };

    drawVariant = (product_name, product_id, variant, idx) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem>
                <FlexGrid
                    flexGridColumnCount={1}
                    flexGridColumnGap={'scale200'}
                    flexGridRowGap={'scale200'}
                >
                    <FlexGridItem><LabelMedium>{product_name}</LabelMedium></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{margin: '0px', color: 'grey'}}>{"Quantity: " + variant.quantity}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem {...addRemoveProps}>
                <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        style={this.state.selectedProducts.includes(variant.change_drug_id + '_' + variant.id)? {display: 'none'} : {}}
                        name={variant.change_drug_id + '_' + variant.id}
                        disabled={this.props.disable}
                        onClick={this.addSelectedProduct}>Add</Button>

                    <Button
                        kind={KIND.primary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        style={this.state.selectedProducts.includes(variant.change_drug_id + '_' + variant.id)? {} : {display: 'none'}}
                        name={variant.change_drug_id + '_' + variant.id}
                        onClick={this.removeSelectedProduct}>Remove</Button>
            </FlexGridItem>
        </FlexGrid>
    };

    pharmacyChangeHandler = (event) => {
        this.setFulfillerForDrug(event.target.name, event.target.value);
    };

    setFulfillerForDrug = (drug_name, fulfiller_id) => {
        this.state.defaultProducts[drug_name].fulfiller_id = parseInt(fulfiller_id, 10);

        if (this.props.results[drug_name]) {
            this.props.results[drug_name].fulfiller_id = parseInt(fulfiller_id, 10);
        }

        this.props.notify();
    };

    renderDrugOptions = () => {
        this.props.products.sort((a, b) => {return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1})

        return <FlexGrid
            flexGridColumnCount={1}
            flexGridColumnGap={'scale600'}
            flexGridRowGap={'scale800'}
        >
            {
                this.props.products.map(product => product.variants.map((variant, idx) => <FlexGridItem>{this.drawVariant(product.display_name, product.id, variant, idx)}</FlexGridItem>))
            }
        </FlexGrid>
    };

    closeFulfiller = () => {
        this.setState({showFulfiller: false, ...Utils.clearAllNotifications()});
    };

    openFulfiller = () => {
        this.setState({showFulfiller: true, ...Utils.clearAllNotifications()});
    };

    isFulfillerSelected = () => {
        return this.state.defaultProducts[this.getLatestSelectedDrug()].fulfiller_id != null;
    };

    // TODO Update to MpsModal
    renderFulfillerOptions = () => {
        return <Modal
            onClose={this.closeFulfiller}
            isOpen={this.state.showFulfiller}
            animate
            closeable={false}
            autoFocus
            size={ModalSize.full}
            role={ROLE.dialog}
            unstable_ModalBackdropScroll
            >
            <ModalHeader>Fill with Clinic</ModalHeader>
            <ModalBody>

                <ParagraphMedium>Use a digital pharmacy vetted by Clinic. Pay now and have it shipped directly to you.</ParagraphMedium>
                <RadioGroup
                    value={this.state.defaultProducts[this.getLatestSelectedDrug()].fulfiller_id}
                    onChange={this.pharmacyChangeHandler}
                    name={this.getLatestSelectedDrug()}
                    align={ALIGN.vertical}
                >
                    {this.drawFulfillerOptions()}
                </RadioGroup>
            </ModalBody>
            <ModalFooter>
                <ModalButton kind={KIND.tertiary} onClick={() => {this.removeLatestSelectedDrug(); this.closeFulfiller();}}>Cancel</ModalButton>
                <ModalButton disabled={!this.isFulfillerSelected()} onClick={this.closeFulfiller}>Save</ModalButton>
            </ModalFooter>
        </Modal>
    };

    renderRequesTitle = () => {
        if (this.props.showTitle) {
            return <span>
                {Utils.renderSpace()}
                <LabelLarge>Request</LabelLarge>
                {Utils.renderSpace()}</span>
        } else {
            return Utils.renderSpace();
        }
    };

    render() {
        return(
            <AuthUserContext.Consumer>
                {authUser => (
                    <div>
                        {this.renderRequesTitle()}
                        {this.renderDrugOptions()}
                        {this.state.selectedProducts.length > 0 ? this.renderFulfillerOptions() : ''}
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(DrugRequest);
