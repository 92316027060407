import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { HeadingLarge, HeadingMedium, HeadingSmall, HeadingXSmall, ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import Request from '../Request'
import * as ROUTES from '../Constants'
import Logger from '../Utils/logger';
import Utils from '../Utils';
import { toaster, ToasterContainer, PLACEMENT } from "baseui/toast";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';




class PersistentNotifications extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            updatable: true,
            notifications: []
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
      //TODO seems to work for now but we might also need to reload notifications here.
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + '/persistent_notifications',
            callbackSuccess: result => {
                Logger.log("Notifications found:");
                Logger.log(result);
                let notifications = this.dismissCurrentPageNotifications(result);
                notifications = this.prepNotifications(notifications);

                notifications.forEach(notification => {
                  console.log("testing");
                  console.log(notification);
                  const data = JSON.parse(notification.data) || {};
                  let msg;

                  if(notification.notification_type == 'error' && Object.keys(notification.message).includes('error')){
                    msg = notification.message.error.message;
                  } else {
                    msg = notification.message;
                  }
                  console.log(msg);

                  if (notification.notification_type == 'success') {
                    toaster.positive(<React.Fragment>{msg}</React.Fragment>, {key: notification.id});
                  } else if (notification.notification_type == 'error') {
                    toaster.negative(<React.Fragment>{msg}</React.Fragment>,
                      {
                        key: notification.id,
                        onClose: notification.callback,
                      }
                    );
                  } else if (notification.notification_type == 'info') {
                    toaster.info(<React.Fragment>{msg}</React.Fragment>,
                      {
                        key: notification.id,
                        overrides: {
                          Body: {
                            style: ({$theme}) => ({
                              backgroundColor: $theme.colors.primaryA,
                              color: $theme.colors.primaryB,
                            })
                          },
                          InnerContainer: {
                            style: ({$theme}) => ({
                              backgroundColor: $theme.colors.primaryA,
                              color: $theme.colors.primaryB,
                              width: '100%'
                            })
                          },
                          CloseIcon: {
                            style: ({$theme}) => ({
                              display: 'none',
                            })
                          }
                        }
                      }
                    );
                  } else if (notification.notification_type == 'verify_me') {
                    toaster.info(
                      <React.Fragment><FlexGrid flexGridColumnCount={2} gridColumnGap={"scale200"}>
                      <FlexGridItem {...Utils.centerProps}><ParagraphMedium style={{margin: 0}}>{msg}</ParagraphMedium></FlexGridItem>
                      <FlexGridItem {...Utils.rightProps}><Button size={SIZE.compact} $as="a" href={data.url} target="_blank" shape={SHAPE.pill}>Verify Me</Button></FlexGridItem>
                      </FlexGrid>
                      </React.Fragment>,
                      {
                        key: notification.key,
                        onClose: notification.callback,
                        overrides: {
                          Body: {
                            style: ({$theme}) => ({
                              backgroundColor: $theme.colors.primaryB,
                              color: $theme.colors.primaryA,
                              minWidth: '343px'
                            })
                          },
                          InnerContainer: {
                            style: ({$theme}) => ({
                              backgroundColor: $theme.colors.primaryB,
                              color: $theme.colors.primaryA,
                              width: '100%'
                            })
                          },
                        }
                      });
                  } else {
                    toaster.info(
                      <React.Fragment><FlexGrid flexGridColumnCount={2} gridColumnGap={"scale200"}>
                      <FlexGridItem {...Utils.centerProps}><ParagraphMedium style={{margin: 0}}>{msg}</ParagraphMedium></FlexGridItem>
                      <FlexGridItem {...Utils.rightProps}><Button size={SIZE.compact} shape={SHAPE.pill} onClick={notification.callback}>View</Button></FlexGridItem>
                      </FlexGrid>
                      </React.Fragment>,

                      {
                        key: notification.key,
                        overrides: {
                          Body: {
                            style: ({$theme}) => ({
                              backgroundColor: $theme.colors.primaryB,
                              color: $theme.colors.primaryA,
                              minWidth: '343px'
                            })
                          },
                          InnerContainer: {
                            style: ({$theme}) => ({
                              backgroundColor: $theme.colors.primaryB,
                              color: $theme.colors.primaryA,
                              width: '100%'
                            })
                          },
                          CloseIcon: {
                            style: ({$theme}) => ({
                            })
                          }
                        }
                      });
                  }
                });
                this.setState({
                    isLoaded: true,
                    updatable: false
                });
            },
            callbackError: error => this.setState({error})
        })
    };

    // Avoid having the same notification show multiple times
    shouldComponentUpdate = () => {return this.state.updatable};

    dismissCurrentPageNotifications = (notifications) => {
        Logger.log('checking notifications for dimissal...');
        let filteredNotifications = [];
        let currentLocation = encodeURI(window.location.pathname);
        notifications.forEach(notification => {
            const data = JSON.parse(notification.data);
            if (data && data.url == currentLocation) {
                this.notificationCompleted(notification);
            } else {
                filteredNotifications.push(notification);
            }
        });

        return filteredNotifications;
    };

    notificationCompleted = (notification) => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + '/persistent_notifications/completed/' + encodeURI(notification.id),
            callbackSuccess: res => this.redirect(notification),
            callbackError: error => this.redirect(notification)
        })
    };

    prepNotifications = (notifications) => {
        return notifications.map(notification => {
            notification.callback = (event) => {
              if (event) {
                event.preventDefault();
              }
              this.notificationCompleted(notification);
            };
            return notification;
        })
    };

    protocol = (data) => {
        window.location.href = data.url;
    };

    redirect = (notification) => {
        const data = JSON.parse(notification.data);

        switch(data.component) {
            case 'protocol':
                this.protocol(data);
                break;
            default:
                Logger.error('Unknown persistent notification component');
        }
    };

    render = () => {
      return (
        <AuthUserContext.Consumer>
          {authUser => <ToasterContainer placement={PLACEMENT.bottom}></ToasterContainer>}
        </AuthUserContext.Consumer>
      );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PersistentNotifications);
