import React, {Component} from 'react';
import Utils from '../Utils';
import { PhoneInput, COUNTRIES } from "baseui/phone-input";
import { Button, SHAPE } from "baseui/button";
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import MFA from './index';
import MFACodeVerification from './code_verification';
import {Spinner} from '../../shared/Spinner';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { StyledLink } from "baseui/link";
import * as ROUTES from  '../Constants';
import Request from '../../shared/Request';
import Logger from '../Utils/logger';

const customCountries = [COUNTRIES.CA, COUNTRIES.US];

class MFAEnroll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firebase_reloaded: false,
            error: null,
            message: null,

            country: COUNTRIES.US,
            phone_number: '',

            consent: false,

            verify_code: false
        };

        this.callbackError = this.callbackError.bind(this);
        this.mfa = new MFA(this.props.firebase, this.callbackError);
    }

    componentDidMount = () => {
        Logger.log('Refresheing user token from Enroll');
        this.props.firebase.reloadUser()
        .then(success => {
            this.props.firebase.currentUser();
            Logger.log('Firebase reloaded...');
            this.mfa = new MFA(this.props.firebase, this.callbackError);
            this.setState({firebase_reloaded: true, ...Utils.clearAllNotifications()});
        })
        .catch(error => this.setState({
            error: {message: 'Failed to reaload the user'},
            firebase_reloaded: true,
            ...Utils.clearAllNotificationsExceptError()
        }));
        window.scrollTo(0, 0);
    };

    callbackError = (error) => {
        this.setState({error, ...Utils.clearAllNotificationsExceptError()});
    };

    handleCountryChange = (country) => {
        this.setState({country: country.option, ...Utils.clearAllNotifications()});
    };

    handlePhoneChange = (event) => {
        this.setState({phone_number: event.currentTarget.value, ...Utils.clearAllNotifications()});
    };

    handleSignUpContinue = (event) => {
        if (this.mfa.isEmailVerified()){
            if (!this.mfa.isUserEnrolled()) {
                this.mfa.enroll(this.state.country.dialCode + this.state.phone_number);
                this.setState({verify_code: true, ...Utils.clearAllNotifications()});
            } else {
                this.setState({
                    message: {message: 'You are already enrolled into 2 Factor Authentication. Please go to sign in page.'},
                    ...Utils.clearAllNotificationsExceptSuccess()
                });
            }
        } else {
            this.mfa.sendEmailVerification()
            .then(sent => this.setState({
                error: {message: 'Email has not been verified yet. A new email verification has been sent.'},
                ...Utils.clearAllNotificationsExceptError()
            }))
            .catch(error => this.setState({
                error: {message: 'Email has not been verified yet. However, failed to send another email verification. Please contact support for more help.'},
                ...Utils.clearAllNotificationsExceptError()
            }));
        }
    };

    renderPhoneNumberForm = () => {
        return <div>
            <PhoneInput
                country={this.state.country}
                onCountryChange={this.handleCountryChange}
                text={this.state.phone_number}
                onTextChange={this.handlePhoneChange}
                onBlur={() => {
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }}
                overrides={{
                    Root: {
                        style: ({ $theme }) => ({
                            maxWidth: "343px",
                            margin: 'auto'
                        })
                    },
                    CountrySelect: {
                        props: {
                          // Set 'options' to our customCountries array
                          options: customCountries,
                        },
                      },
                }}
            />
        </div>
    };

    renderRecaptchaContainer = () => {
        return <div id='recaptcha'></div>;
    };

    renderContinueButton = () => {
        return  <div style={{textAlign: 'center'}}>
            <Button
                shape={SHAPE.pill}
                disabled={this.state.consent == false && this.props.firebase.isPatient()}
                onClick={this.handleSignUpContinue}
            >Continue
            </Button>
        </div>;
    };

    setConsent = (event) => {
        this.setState({consent: event.target.checked, ...Utils.clearAllNotifications()});
         var data = {
            consent_record: {
                agreement_name: 'communications-policy.md'
            }
        }
        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/consent_records",
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify(data),
            callbackSuccess: (result) => {
            },
            callbackError: (error) => {
                //Fail silently for now.
            }
        })
    };

    renderConsent = () => {
        if (this.props.firebase.isPatient()) {
            return <Checkbox
                checked={this.state.consent}
                onChange={this.setConsent}
                labelPlacement={LABEL_PLACEMENT.right}
                overrides={{
                    Root: {
                        style: ({ $theme }) => ({
                            maxWidth: "343px",
                            margin: 'auto'
                        })
                    },
                }}
                > I agree and consent to Clinic’s <StyledLink href={"https://github.com/Pharmr/legal/blob/main/communications-policy.md"} target="_blank">Communications Policy</StyledLink> to receive health related communications via text message and phone.</Checkbox>
        } else {
            return null;
        }
    };

    renderForms = () => {
        if (this.state.verify_code) {
            return <MFACodeVerification type='enroll' mfa={this.mfa} phone_number={this.state.country.dialCode + this.state.phone_number}/>
        } else {
            return (
                <div style={{margin: 'auto'}}>
                    {Utils.renderSpace()}
                    {Utils.renderSpace()}
                    {Utils.renderTitleCenter('Verify your mobile phone number')}
                    <ParagraphMedium style={{textAlign: 'center'}}>{
                        this.props.firebase.isPatient()
                        ? "You’ll use this for account access and to receive notifications."
                        : "You’ll use this for account access and to receive notifications."}
                    </ParagraphMedium>
                    {this.renderPhoneNumberForm()}
                    {Utils.renderSpace()}
                    {this.renderConsent()}
                    {Utils.renderSpace()}
                    {this.renderContinueButton()}
                </div>
            )
        }
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        this.state.firebase_reloaded ?
                            <div>
                                {Utils.renderError(this.state.error)}
                                {Utils.renderSuccess(this.state.message)}

                                <div className="center_page">
                                    {this.renderForms()}
                                </div>

                                {this.renderRecaptchaContainer()}
                            </div>
                        : <Spinner />
                    )
                }
            </AuthUserContext.Consumer>
        )
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(MFAEnroll);
