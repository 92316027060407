import React, {Component} from 'react';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import content from './content';
import MpsModal from '../../shared/Utils/modal';
import { withFirebase } from '../../shared/Firebase';

class Overview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            content: this.getContent('en'),
            error: null
        };
    }

    componentDidMount = () => {
        Logger.log("Overview did mount...");
    };

    componentDidUpdate = (prevProps) => {
        Logger.log("Overview did update...");
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    };

    getContent = (lang) => {
        return content(lang);
    };

    render () {
        Logger.log(this.state);
        return (
            <MpsModal
                open={this.state.show}
                callbackCancel={this.props.callback}
                title={Utils.renderTitleCenter(this.state.content.title)}
                body={<div style={{textAlign: 'center', marginBottom: '35px'}}>{this.state.content.body}</div>}
                withFooter={false}
                autoFocus={false}
                overrideModalWidth={'343px'}
            />
        );
    }
}

export default withFirebase(Overview);
