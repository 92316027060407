import React from 'react';
import { ReproUltra } from '../../shared/Fonts';
import '../Styles/style.scss';

const circleProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                fontFeatureSettings:'"calt" 1, "liga" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss16" 1',
            }),
        },
    },
};

const data = {
    en: {
        patient: {
            home:"Home",
            communities: "",
            browse: "Search",
            transaction_history: "Transactions",
            account: "Account",
            about: "",
            support: "",
            physician_credentials: "",
            create_community: "",
            help: "Documentation ↗",
            
            // Nav bar logo
            nav_bar_logo: <ReproUltra  style={{fontSize: "41px", lineHeight: "41px", marginTop: "0px", marginBottom: "0px", fontFeatureSettings:'"calt" 1, "liga" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss16" 1'}}>P</ReproUltra>,

            // Modal
            content_margin: '25px 0px 0px 10px',
            content_spacing: 'scale0',

            // favicon
            favicon_margin_right: '8px',
            favicon_margin_top: '3px',

            navigation_color: "transparent",
            //navigation_box_shadow: "0px 1px 2px #7f7f7f47",
            //Utils.isLightMode() ? "#ffffff" : "#141414"

        },
        physician: {
            communities: "Home",
            account: "Profile",
            support: "Support ↗",
            support_link: "https://discord.gg/RkK77ajn24",

            // Modal
            content_margin: '25px 0px 0px 10px',
            content_spacing: 'scale0',

            navigation_color: "transparent",
            navigation_box_shadow: "0px 1px 2px #7f7f7f47"
        }
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
