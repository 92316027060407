import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import PhoneChange from './phone_change';

class PersonalInfo extends Component {
    constructor(props){
        super(props);
    }

    showSpace = () => {
        return <p></p>;
    };

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser => 
                (
                    <div>
                        {this.showSpace()}
                        {Utils.renderTitle6('Personal details')}
                        {this.showSpace()}
                        <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600">
                            <FlexGridItem><LabelMedium style={{margin: 0}}>Name</LabelMedium><ParagraphMedium style={{margin: 0, marginTop: '5px'}}>{this.props.name}</ParagraphMedium></FlexGridItem>
                            <FlexGridItem><LabelMedium style={{margin: 0}}>Birthdate</LabelMedium><ParagraphMedium style={{margin: 0, marginTop: '5px'}}>{this.props.birthdate}</ParagraphMedium></FlexGridItem>
                            <FlexGridItem><LabelMedium style={{margin: 0}}>Email</LabelMedium><ParagraphMedium style={{margin: 0, marginTop: '5px'}}>{this.props.email}</ParagraphMedium></FlexGridItem>
                            <FlexGridItem><PhoneChange /></FlexGridItem>
                            <FlexGridItem><LabelMedium style={{margin: 0}}>UUID</LabelMedium><ParagraphMedium style={{margin: 0, marginTop: '5px'}}>{this.props.niceuuid}</ParagraphMedium></FlexGridItem>
                        </FlexGrid>
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    );
}
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PersonalInfo);
