import React, {Component} from 'react';
import DrugRequest from './request';
import Reorder from './reorder';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Button, KIND, SHAPE, SIZE} from 'baseui/button';
import MpsModal from '../../shared/Utils/modal';
import Utils from '../../shared/Utils';
import { ParagraphMedium } from 'baseui/typography';
import {Spinner, SIZE as SpinnerSize} from 'baseui/spinner';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../../shared/Request';
import content from './content';
import Logger from '../../shared/Utils/logger';

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class Drugstore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isLoading: false,
            protocol: null,
            order_id: null,
            protocol_subscription_status: null,
            reorder_results: {},
            results: {},
            error: null,
            success: null,
            count: 0,
            protocol_id: this.props.match.params.id ? this.props.match.params.id : this.props.protocol_id,
            isModal: this.props.showModal ? this.props.showModal : false,
            showModal: this.props.showModal ? this.props.showModal : false,
            content: this.getContent()
        };

        this.standAlone = !this.props.protocol_id;
        this.request_type = "REQUEST";
        this.reorder_type = "REORDER";
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isLoaded: false,
                protocol: null,
                protocol_id: this.props.match.params.id,
                ...Utils.clearAllNotifications()
            });

            this.getProductsForProtocol(this.props.match.params.id);
        }
    };

    getContent = () => {
        return content('en');
    };

    isDrugRequest = () => {
        return ['active', 'active_pending_cancel'].includes(this.state.protocol_subscription_status)
    };

    callbackError = (error) => {
        if (this.props.callbackError) {
            this.props.callbackError(error);
        }
    };

    callbackSuccess = (message) => {
        if (this.props.callbackSuccess) {
            this.props.callbackSuccess(message);
        }
    };

    componentDidMount = () => {
        this.getProductsForProtocol(this.state.protocol_id);
    };

    notifyChange = () => {
        this.setState({
            count: this.state.count + 1,
            ...Utils.clearAllNotifications()
        });
    };

    prepareRxRequestData = (data) => {
        var variant_ids = [];
        var quantities = [];
        var fulfiller_ids = [];

        for (const id in data) {
            variant_ids.push(data[id].variant_id);
            quantities.push(data[id].quantity);
            fulfiller_ids.push(data[id].fulfiller_id);
        }

        return {
            variant_ids: variant_ids,
            quantities: quantities,
            fulfiller_ids: fulfiller_ids
        };
    };

    getRequestOrReorderData = () => {
        if (Object.keys(this.state.results).length > 0) {
            return this.state.results;
        } else {
            return this.state.reorder_results;
        }
    };

    createNewOrder = () => {
        this.setState({isLoading: true, ...Utils.clearAllNotifications()});

        var orderData = this.getRequestOrReorderData();

        const data = new FormData();
        const userSelectedData = this.prepareRxRequestData(orderData);

        data.append('is_reorder', Object.keys(this.state.reorder_results).length > 0);
        data.append('protocol_id', this.state.protocol_id);
        data.append('variant_ids', userSelectedData.variant_ids);
        data.append('quantities', userSelectedData.quantities);
        data.append('fulfiller_ids', userSelectedData.fulfiller_ids);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_drugstore",
            body: data,
            callbackSuccess: (result) => this.setState({order_id: result.order_id, ...Utils.clearAllNotifications()}, () => this.redirect()),
            callbackError: (error) => {
                this.callbackError({message: error.message});
                this.setState({
                    isLoading: false,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    getProductsForProtocol = (protocol_id) => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_drugstore/" + protocol_id,
            callbackSuccess: (result) => {
                Logger.log(result);
                this.setState({
                isLoaded: true,
                protocol: result,
                protocol_subscription_status: result.data.protocol_subscription_status,
                order_id: Object.keys(result.data.order_on_cart).length > 0 ? result.data.order_on_cart.number : null,
                ...Utils.clearAllNotifications()
            })},
            callbackError: (error) => {
                this.callbackError({message: error.message});
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    canBeReordered = (prescription) => {
        return prescription.variant // Only show drugs prescribed from our formulary
            && prescription.refills_remaining > 0
            && prescription.pharmacy_id != null;
    };

    getProducts = () => {
        if (!this.state.isLoaded) {
            return <div>Loading...</div>;
        } else if (this.state.protocol && this.state.protocol.data) {
            if (this.state.protocol.data.products.product_categories.length == 0 || this.state.protocol.data.products.product_categories[0].products.length == 0) {
                return <strong>No drugs found for this clinic.</strong>
            } else {
                return <div id="requests">
                    <DrugRequest
                        showTitle={
                            this.state.protocol.data.active_prescriptions.length > 0
                            && this.state.protocol.data.active_prescriptions.some(prescription => this.canBeReordered(prescription))
                        }
                        order_on_cart={this.state.protocol.data.order_on_cart}
                        products={this.state.protocol.data.products.product_categories[0].products}
                        results={this.state.results}
                        notify={this.notifyChange}
                        disable={Object.keys(this.state.reorder_results).length > 0}/>
                </div>;

            }
        }
    };

    getReorders = () => {
        if (this.state.isLoaded && this.state.protocol && this.state.protocol.data.active_prescriptions.length > 0) {
            return <div id="reorders">
                <Reorder
                    order_on_cart={this.state.protocol.data.order_on_cart}
                    prescriptions={this.state.protocol.data.active_prescriptions}
                    results={this.state.reorder_results}
                    notify={this.notifyChange}
                    disable={Object.keys(this.state.results).length > 0}/>
                {Utils.renderSpace()}
            </div>
        }
    };

    checkout = (event) => {
        if (event) event.persist();

        this.createNewOrder();

        if (event) event.preventDefault();
    };

    redirect = () => {
        if (this.state.order_id && !this.state.error) {
            if (Object.keys(this.state.results).length > 0) {
                // Request order. Need request form. Request Background Info and Drug Request Form
                if(this.isDrugRequest()) {
                    this.props.canContinue('task_list', {order_id: this.state.order_id});
                } else {
                    this.setState({redirect: ROUTES.TASK_LIST + '/' + this.state.protocol_id + '/' + this.state.order_id + '/1/1'});
                }
            }

            if (Object.keys(this.state.reorder_results).length > 0){
                // Reorder so def not onboarding.
                this.props.canContinue('shipping', {order_id: this.state.order_id});
                // this.setState({redirect: ROUTES.SHIPPING + '/' + this.state.protocol_id + '/' + this.state.order_id});
            }
        }
        return '';
    };

    showCheckout = () => {
        return (<div style={{textAlign: 'center'}}><Button
            size={SIZE.default}
            kind={KIND.primary}
            shape={SHAPE.pill}
            disabled={this.isFormNotReady()}
            isLoading={this.state.isLoading}
            onClick={this.checkout}>Continue</Button>
        </div>);
    };

    closeModal = () => {
        this.setState({showModal: false, ...Utils.clearAllNotifications()});
        this.props.callbackCancelHandler();
    };

    callback = () => {
        if (this.isFormNotReady()) {
            this.props.callbackCompleteHandler(false);
        } else {
            this.props.callbackCompleteHandler(true);
        }
    };

    submitModal = () => {
        this.checkout();

        this.setState({showModal: false, ...Utils.clearAllNotifications()}); // Close modal but do not call the callback

        // This is called for the caller.
        this.callback();
    };

    isFormNotReady = () => {
        return (Object.keys(this.state.results).length == 0 && Object.keys(this.state.reorder_results).length == 0)
            || (Object.keys(this.state.results).length > 0 && Object.keys(this.state.reorder_results).length > 0);
    };

    showDrugstore = () => {
        return <div>
            {this.getReorders()}
            {this.getProducts()}
        </div>
    };

    render() {
        if (this.state.redirect) {
            this.props.history.push(this.state.redirect);
        }

        return(
            <AuthUserContext.Consumer>
                {authUser => 
                    (
                        <div>
                        {this.state.isModal
                            ?
                                <MpsModal
                                    open={this.state.showModal}
                                    autoFocus={false}
                                    callback={this.submitModal}
                                    callbackCancel={this.closeModal}
                                    withFooter={true}
                                    title={Utils.renderTitleCenter(this.state.content.create_rx_request)}
                                    body={
                                        this.state.isLoaded ?
                                        <div>
                                            {Utils.renderError(this.state.error)}
                                            {Utils.renderSuccess(this.state.success)}
                                            {this.state.content.drug_therapy_description}
                                            {/*Utils.renderSpace()*/}
                                            {this.showDrugstore()}
                                        </div>
                                         : <div>
                                         {Utils.renderSpace()}
                                         {Utils.renderSpace()}
                                         <FlexGrid flexGridColumnCount={1}>
                                             <FlexGridItem {...itemProps}><Spinner $size={SpinnerSize.large} /></FlexGridItem>
                                         </FlexGrid>
                                     </div>
                                    }
                                />
                            :
                            this.state.isLoaded ?
                            <div>
                                {Utils.renderError(this.state.error)}
                                {Utils.renderSuccess(this.state.success)}
                                {this.showDrugstore()}
                                {Utils.renderSpace()}
                                {Utils.renderSpace()}
                                {this.showCheckout()}
                            </div>: <div>
                                         {Utils.renderSpace()}
                                         {Utils.renderSpace()}
                                         <FlexGrid flexGridColumnCount={1}>
                                             <FlexGridItem {...itemProps}><Spinner $size={SpinnerSize.large} /></FlexGridItem>
                                         </FlexGrid>
                                     </div>
                            
                        }
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Drugstore);
