import React, {Component} from 'react';
import { toaster, ToasterContainer, PLACEMENT } from "baseui/toast";
import {HeadingLarge, HeadingMedium, HeadingSmall, HeadingXSmall, ParagraphMedium, ParagraphXSmall} from 'baseui/typography';
import Logger from './logger';
import Screen from './screen';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import content from './content';
import Storage from '../Storage';
import {StatefulTooltip} from 'baseui/tooltip';


TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');
const rightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '70px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }),
        },
    },
};

class Toast extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            canUpdate: true
        };
        this.toastKey = null;
        this.msg = '';
    }

    componentDidMount = () => {
        this.setState({
            isMounted: true,
            canUpdate: false
        })

        if(this.props.type == 'error' && Object.keys(this.props.message).includes('error')){
            this.msg = this.props.message.error.message;
        } else {
            this.msg = this.props.message.message;
        }

        if (this.props.type == 'success') {
            const toastMethod = toaster.positive;
            this.toastKey = toastMethod.call(
                toaster,
                <React.Fragment>{this.msg}</React.Fragment>,
                {key: <span key={Math.floor(Math.random() * 10000)} display="none"></span>});
        } else if (this.props.type == 'error') {
            const toastMethod = toaster.negative;
            this.toastKey = toastMethod.call(
                toaster,
                <React.Fragment>{this.msg}</React.Fragment>,
                {
                    key: <span key={Math.floor(Math.random() * 10000)} display="none"></span>,
                    overrides: {
                        CloseIcon :{
                            style: ({$theme}) => ({
                                display: 'none',
                            })
                        }
                    }
                }
            );
        } else if (this.props.type == 'info') {
            const toastMethod = toaster.info;
            this.toastKey = toastMethod.call(
                toaster,
                <React.Fragment>{this.msg}</React.Fragment>,
                {
                    key: <span key={Math.floor(Math.random() * 10000)} display="none"></span>,
                    overrides: {
                        Body: {
                            style: ({$theme}) => ({
                                backgroundColor: $theme.colors.primaryA,
                                color: $theme.colors.primaryB,
                            })
                        },
                        InnerContainer: {
                            style: ({$theme}) => ({
                                backgroundColor: $theme.colors.primaryA,
                                color: $theme.colors.primaryB,
                                width: '100%'
                            })
                        },
                        CloseIcon :{
                            style: ({$theme}) => ({
                                display: 'none',
                            })
                        }
                    }
                }
            );
        } else {
            const toastMethod = toaster.info;
            this.toastKey = toastMethod.call(toaster,
            <React.Fragment><FlexGrid flexGridColumnCount={2} gridColumnGap={"scale200"}>
                <FlexGridItem {...centerProps}><ParagraphMedium style={{margin: 0}}>{this.msg}</ParagraphMedium></FlexGridItem>
                <FlexGridItem {...rightProps}><Button size={SIZE.compact} shape={SHAPE.pill} onClick={this.props.callback}>View</Button></FlexGridItem>
                </FlexGrid>
            </React.Fragment>,
            {
                key: <span key={Math.floor(Math.random() * 10000)} display="none"></span>,
                overrides: {
                    Body: {
                        style: ({$theme}) => ({
                            backgroundColor: $theme.colors.primaryB,
                            color: $theme.colors.primaryA,
                            minWidth: '343px'
                        })
                    },
                    InnerContainer: {
                        style: ({$theme}) => ({
                            backgroundColor: $theme.colors.primaryB,
                            color: $theme.colors.primaryA,
                            width: '100%'
                        })
                    },
                    CloseIcon :{
                        style: ({$theme}) => ({
                            display: 'none',
                        })
                    }
                }
            });
        }
    };

    render = () => {
        return this.state.isMounted ? this.toastKey : null;
    };
};

const STATES = {
    'AL': 'Alabama',
    'MT': 'Montana',
    'AK': 'Alaska',
    'NE': 'Nebraska',
    'AZ': 'Arizona',
    'NV': 'Nevada',
    'AR': 'Arkansas',
    'NH': 'New Hampshire',
    'CA': 'California',
    'NJ': 'New Jersey',
    'CO': 'Colorado',
    'NM': 'New Mexico',
    'CT': 'Connecticut',
    'NY': 'New York',
    'DE': 'Delaware',
    'NC': 'North Carolina',
    'DC': 'District of Columbia',
    'ND': 'North Dakota',
    'FL': 'Florida',
    'OH': 'Ohio',
    'GA': 'Georgia',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'HI': 'Hawaii',
    'PA': 'Pennsylvania',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'RI': 'Rhode Island',
    'IN': 'Indiana',
    'SC': 'South Carolina',
    'IA': 'Iowa',
    'SD': 'South Dakota',
    'KS': 'Kansas',
    'TN': 'Tennessee',
    'KY': 'Kentucky',
    'TX': 'Texas',
    'LA': 'Louisiana',
    'UT': 'Utah',
    'ME': 'Maine',
    'VT': 'Vermont',
    'MD': 'Maryland',
    'VA': 'Virginia',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'WA': 'Washington',
    'MN': 'Minnesota',
    'WV': 'West Virginia',
    'MS': 'Mississippi',
    'WI': 'Wisconsin',
    'MO': 'Missouri',
    'WY': 'Wyoming'
}

class Utils {

    static upperCaseFirstLetter = (word) => {
        if (!word) {
            return word;
        }

        return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    };

    static clearAllNotifications = () => {
        // TODO remove key: message from these notification clearing functions.
        return {error: null, message: null, success: null, info: null, warning: null};
    };

    static clearAllNotificationsExceptError = () => {
        return {message: null, success: null, info: null, warning: null};
    };

    static clearAllNotificationsExceptSuccess = () => {
        return {error: null, info: null, warning: null};
    };

    static clearAllNotificationsExceptInfo = () => {
        return {error: null, message: null, success: null, warning: null};
    };

    static renderInfo = (message, timout=3000) => {
        return message && <><ToasterContainer
        overrides={{
            Root: {
              style: ({ $theme }) => ({
                //position: 'fixed',
                //zIndex: '6000'
              })
            }
          }}
          autoHideDuration={3000}/>
          <Toast message={message} type="info" /></>
    };

    static renderError = (error, timeout=3000) => {
        if (error) {
            return <><ToasterContainer
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    //position: 'fixed',
                    //zIndex: '6000'
                  })
                }
              }}
            autoHideDuration={timeout}/><Toast message={error} type="error" /></>
        }
        return null;
    };

    static renderErrorMulti = (errors, timeout=3000) => {
        return Array.isArray(errors) && errors.map((error, idx) => <><ToasterContainer
        overrides={{
            Root: {
              style: ({ $theme }) => ({
                //position: 'fixed',
                //zIndex: '6000'
              })
            }
          }}
        autoHideDuration={timeout}/><Toast message={error} type="error" /></>)
    };

    static renderSuccess = (message, timeout=3000) => {
        if (message) {
            return <><ToasterContainer
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    //position: 'fixed',
                    //zIndex: '6000'
                  })
                }
              }}
            autoHideDuration={timeout}/><Toast message={message} type={'info'}/></>
        }
        return null;
    };

    static renderSuccessMulti = (messages, timeout=3000) => {
        return messages.length > 0 && messages.map((message, idx) => <><ToasterContainer
        overrides={{
            Root: {
              style: ({ $theme }) => ({
                //position: 'fixed',
                //zIndex: '6000'
              })
            }
          }}
        autoHideDuration={timeout}/><Toast message={message} type="info"/></>)
    };

    static renderSpace = () => {
        return <br/>;
    };

    static renderTitle = (title) => {
        return <HeadingLarge>{title}</HeadingLarge>
    };

    static renderTitle4 = (title) => {
        return <HeadingMedium>{title}</HeadingMedium>
    };

    static renderTitle6 = (title) => {
        return <HeadingXSmall style={{margin: '0px'}}>{title}</HeadingXSmall>
    };

    static renderTitleCenter = (title) => {
        return <HeadingSmall style={{textAlign: 'center', marginTop: '40px', marginBottom: '10px'}}>{title}</HeadingSmall>
    };

    static uuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    static renderTimeAgo = (date_input) => {
        if (!date_input) {
            return '';
        }

        let date = date_input;
        if (['string', 'number'].includes(typeof date)) {
            date = new Date(date);
        }

        let ret = timeAgo.format(date);

        return ret;
    };

    static renderLocaleString = (date_input) => {
        let ret = '';
        let date = date_input;

        if (typeof date == 'string') {
            date = new Date(date);
        }

        ret = timeAgo.format(date);

        if (ret.includes('week')
            || ret.includes('weeks')
            || ret.includes('month')
            || ret.includes('months')
            || ret.includes('year')
            || ret.includes('years')
        ) {
            return date.toLocaleString();
        } else {
            return ret;
        }
    };

    static render_mm_dd_yyyy_date = (date) => {
        if (!date) {
            return date;
        }
        if (date.length > 10) {
            return new Date(date).toLocaleDateString();
        } else {
            //for bare dates without zone it uses utc.
            return new Date(date).toLocaleDateString('en-us', { timeZone: 'utc' });
        }

    };

    static renderDateOnly = (date_time) => {
        return date_time.split('T')[0];
    };

    static renderAddress = (address) => {
        if (address && address.address_1) {
            return address.address_1
                + (address.address_2 && address.address_2.length > 0 ? ', ' + address.address_2 +'\n' : '\n')
                + ' ' + address.city + ', ' + address.state + ' ' + (address.postal_code || address.zip)
                + (address.country && address.country.length > 0 ? address.country : '');
        }

        return "No address found";
    };

    static addressPickerToString = (address) => {
        return address.street_line + ' ' + (address.secondary ? address.secondary + ', ' : '') + address.city + ', ' + address.state + ' ' + address.zipcode;
    };

    static renderPhone = (phone) => {
        if (phone) {
            if (phone.startsWith('+')){
                // +1 555 555 5555
                return phone.substring(0, 2) + '-' + phone.substring(2, 5) + '-' + phone.substring(5, 8) + '-' + phone.substring(8);
            } else {
                // 555-555-5555
                return phone.substring(0, 3) + '-' + phone.substring(3, 6) + '-' + phone.substring(6);
            }
        }

        return phone;
    };

    static renderDollarAmount = (amount) => {
        return '$' + parseFloat(amount).toFixed(2);
    };

    static renderCompanyAddress = () => {
        return Utils.renderAddress({address_1: '276 Fifth Ave', address_2: 'STE 704', city: 'New York', state: 'NY', postal_code: '10001'});
    };

    static renderLegalStatement = () => {
        return "Copyright © 2023";
    };

    static renderState = (abbreviation) => {
        return STATES[abbreviation];
    };

    static getFileName = (path) => {
        if (!path) {
            return path;
        }

        if (typeof path == 'string') {
            return decodeURI(path.split('/').pop());
        } else {
            return decodeURI(path.path);
        }
    };

    static logo() {
        let data = content('en', "patient");
        return data.logo;
    };

    static landingLogo() {
        let data = content('en', "patient");
        return data.landingLogo;
    };

    static getResponsiveMargins = () => {
        if (['SMALL', 'MEDIUM'].includes(Screen.size())) {
            return '0px 16px';
        } else {
            return '0px 40px';
        }
    };

    static getContentMaxWidthCss = () => {
        let css = {};

        if (Screen.size() == 'XLARGE') {
            css.margin = 'auto';
            css.maxWidth = '1800px';
        } else {
            css.margin = Utils.getResponsiveMargins();
        }

        return css;
    };

    static copyToClipboard = (copy_value) => {
        if(navigator.clipboard) {
            navigator.clipboard.writeText(copy_value).then(() => {
                Logger.log('Navigator Clipboard: Content copied to clipboard');
            }, (err) => {
                Logger.error('Navigator Clipboard: Content failed to copy. Error: ', err);
            });
        } else {
            var input = document.createElement('textarea');
            document.body.appendChild(input);
            input.value = copy_value;
            input.focus();
            input.select();
            if(document.queryCommandEnabled("copy")){
                document.execCommand('Copy');
                Logger.log("Copy Ok");
                //alert("Copy Ok");
            }else{
                Logger.log("Content failed to copy. queryCommandEnabled is disabled");
                //alert("Copy Failed");
            }
            input.remove();
        }
    };

    static patientMessageMaxLen = () => {return 6000;}

    static filterInput(value) {
        const regEx = /[^a-z A-Z 0-9!"#$%&'()\*\+,-.\/:;<=>?@\[\]^_`{|}~]*/gm;

        return value.replace(regEx, '');
    }

    static isLightMode = () => {
        return ['light'].includes(Storage.get('theme'));
    };

    static isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    static crownIcon = (width) => {
        return <StatefulTooltip
            accessibilityType={'tooltip'}
            content={"Lead physician"}
            showArrow
            returnFocus
            autoFocus
            placement={PLACEMENT.bottom}
            >
            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 18 15"
                    x="0px"
                    y="0px"
                    className="ScIconSVG-sc-1bgeryd-1 hcjcOH"
                    width={width ? width : '15px'}
                ><g>
                    <path
                        fill={Utils.isLightMode() ? '#000000' : '#ffffff'}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.798 10.456L10 6.657l-3.798 3.799L4 8.805V13h12V8.805l-2.202 1.65zM18 5v8a2 2 0 0 1-2 2H4a2.002 2.002 0 0 1-2-2V5l4 3 4-4 4 4 4-3z"/>
                </g>
            </svg>
        </StatefulTooltip>
    }

    static botTag = (width="20px") => {
        return <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 205 105"
            enableBackground="new 0 0 205 105"
            xmlSpace="preserve"
            width={width}
        >
            <path
                d="M204.3 84.4c0 10.9-8.9 19.8-19.8 19.8h-164C9.6 104.2.7 95.3.7 84.4V20.6C.7 9.7 9.6.8 20.5.8h164c10.9 0 19.8 8.9 19.8 19.8v63.8z"
                fill="#666666"
            />
            <path
                fill="#ffffff"
                d="M41 28.4h17.8c9 0 14.9 4.5 14.9 12.4 0 4.5-3.1 8.1-6.2 9.4 4.8 1.7 8.4 5.6 8.4 12.2 0 8.7-6.2 14.1-16.2 14.1H41V28.4zm17.5 19.5c5.4 0 8.3-2.3 8.3-6.7s-2.9-6.7-8.3-6.7H48.1V48h10.4zm.2 22.4c6.7 0 10-2.8 10-8.1s-3.3-8.1-10-8.1H48.1v16.1h10.6zM81.2 52.5c0-14.2 10.2-24.6 23.9-24.6S129 38.4 129 52.5s-10.2 24.6-23.9 24.6-23.9-10.4-23.9-24.6zm40.5 0c0-10.5-7-18.3-16.6-18.3S88.5 42 88.5 52.5s7.1 18.3 16.7 18.3 16.5-7.8 16.5-18.3zM144.4 34.8h-15.1v-6.3h37.3v6.3h-15.1v41.8h-7.2V34.8z"
            />
        </svg>
    };
}

export default Utils;
export {centerProps,
  rightProps};
