import React, {Component} from 'react';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Screen from '../Utils/screen';
import Utils from '../Utils';
import { withFirebase } from '../Firebase';
import MpsModal from '../Utils/modal';
import * as PATIENT_ROUTES from '../../patient/Constants';
import * as PHYSICIAN_ROUTES from '../../physician/Constants';
import { StyledLink } from "baseui/link";
import {FocusOnce} from 'baseui/modal';
import Storage from '../Storage';
import content from './content';
import UnAuthNavPatientOptions from './unauth_patient_nav_options';
import {HeadingMedium, ParagraphLarge} from 'baseui/typography';
import PhysicianExplainer from '../../patient/PhysicianExplainer';
import Logger from '../Utils/logger';
import { LogoFontBold, LogoFontSemibold, OutlineScreen, OutlineMonoMed, LogoFontMaxi } from '../../shared/Fonts';
import '../Styles/style.scss';
import { Search, Overflow } from "baseui/icon";
import SearchProtocol from '../../patient/Browse/search_protocol';

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};

const signInOutProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'max-content',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight:'8px'
            }),
        },
    },
};

const subscriptionProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                overflowX: 'auto'
            }),
        },
    },
};

const logoProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'max-content',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft:'8px'
            }),
        },
    },
};


const flexGridProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                alignItems: 'center',
                justifyContent: 'space-between'
            }),
        },
    },
};

const asteriskStyle = {
  overrides: {
      Block: {
          style: ({$theme}) => ({
              fontFeatureSettings: "'rlig' 1, 'kern' 1, 'rclt' 1, 'ss01' 1, 'case' 1",
              fontVariationSettings: "'wght' 700, 'MONO' 1",
          }),
      },
  },
};

const navBarStyling = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                backgroundColor:$theme.colors.backgroundTertiary,
                borderRadius: '100px',
                height: '50px',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px',
                width: '100%',
                marginRight: '16px',
                marginLeft: '16px',
                position: 'relative',
                top: ['XSMALL'].includes(Screen.size()) ? '4px' :'16px',
            }),
        },
    },
};


var left = '\u3010';
var right = '\u3011';
var asterisk = '\u002a';
var info = '\u2139'

function Icon() {
    return Utils.logo();
};

class UnAuthNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showSignInModal: false,
            showSearchProtocol: false,
            content: this.getContent()
        };
    }

    getContent = () => {
        return this.props.firebase.isPatient() ? content('en').patient : content('en').physician;
    };

    getResponsiveSpacing = () => {
        if (['SMALL', 'MEDIUM'].includes(Screen.size())) {
            return '3px 16px';
        } else {
            return '3px 24px';
        }
    };

    getCss = () => {
        let css = {};
        //css.padding = this.getResponsiveSpacing();
        //css["backgroundColor"] = this.state.content.navigation_color;
        //css["boxShadow"] = this.state.content.navigation_box_shadow;
        css["position"] = 'fixed';
        //css["zIndex"] = 4000;
        css["top"] = '0';
        css["left"] = '0';
        css["right"] = '0';
        css["display"] = 'flex';
        css["justifyContent"] = 'center';

        return css;
    };
    
    showPhysicianExplainer = () => {
        Logger.log('Setting to show PhysicianExplainer');
        this.setState({showPhysicianExplainer: true});
    };

    closePhysicianExplainer = () => {
        this.setState({showPhysicianExplainer: false});
    };

    showProtocolSearch = (event) => {
        event.preventDefault();

        this.setState({showSearchProtocol: true});
    };

    hideProtocolSearch = () => {
        this.setState({showSearchProtocol: false});
        this.props.callback();
    };

    renderNavBar = () => {
        return <div style={this.getCss()}>
            <FlexGrid {...navBarStyling} flexGridColumnCount={2}>
                <FlexGridItem {...logoProps}>
                {this.props.firebase.isPatient() ? 
                            <Button
                            kind={KIND.secondary}
                            shape={SHAPE.circle}
                            size={SIZE.compact} 
                            onClick={this.showProtocolSearch}>
                                <Search style={{display: 'flex', alignItems: 'center', paddingTop:'2px'}} size={24}/>
                            </Button>
                            :
                            <StyledLink href={PHYSICIAN_ROUTES.HOME} style={{paddingTop: '0px', margin: 0}} className="a_no_line">
                                <ReproUltra  style={{fontSize: "41px", lineHeight: "41px", marginTop: "0px", marginBottom: "0px", fontFeatureSettings:'"calt" 1, "liga" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss16" 1'}}>C</ReproUltra>
                            </StyledLink>}
                </FlexGridItem>
                <FlexGridItem {...signInOutProps}>
                        <Button
                            kind={KIND.secondary}
                            shape={SHAPE.circle}
                            size={SIZE.compact}
                            onClick={() => this.setState({showModal: true})}
                        >
                            <Overflow style={{transform: "rotate(90deg)"}} size={24} />
                        </Button>
                    </FlexGridItem>
                
               {/*<FlexGridItem {...signInOutProps}>
                    <FocusOnce>
                        <Button
                            kind={KIND.secondary}
                            shape={SHAPE.circle}
                            size={SIZE.compact}
                            onClick={() => this.setState({showModal: true})}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        marginRight: '8px',
                                        paddingTop: "6px",
                                        paddingRight:"6px",
                                        paddingBottom:"6px",
                                        paddingLeft:"6px",
                                    })
                                }
                            }}
                        >
                            <Overflow style={{transform: "rotate(90deg)"}} size={24} />
                        </Button>
                    </FocusOnce>
                    <Button 
                        kind={KIND.primary} 
                        shape={SHAPE.pill} 
                        size={SIZE.compact} 
                        style={{width: '100%'}} 
                        onClick={() => this.setState({showSignInModal: true})}
                        >Sign&nbsp;in
                    </Button>
                </FlexGridItem> */}
                
            </FlexGrid>
        </div>
    };

    closeModal = () => {this.setState({showModal: false})};

    renderNavBarOptions = () => {
        return <MpsModal
            open={this.state.showModal}
            title={null}
            callbackCancel={this.closeModal}
            overrideModalWidth={'343px'}
            body={<UnAuthNavPatientOptions closeCallback={this.closeModal}/>}
        />;
    };

    closeSignInModal = () => this.setState({showSignInModal: false});

    renderSignInOptions = () => {
        return <MpsModal
            open={this.state.showSignInModal}
            title={null}
            callbackCancel={this.closeSignInModal}
            overrideModalWidth={'343px'}
            body={<FlexGrid flexGridColumnCount={1} flexGridRowGap={this.state.content.content_spacing} margin={this.state.content.content_margin}>
            <FlexGridItem {...contentProps}>
                <StyledLink href={PATIENT_ROUTES.SIGN_IN} className="a_no_line">
                    <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Patient sign in</ParagraphLarge>
                </StyledLink>
            </FlexGridItem>
            <FlexGridItem {...contentProps} {...topLinners}>
                <StyledLink href={PHYSICIAN_ROUTES.BASE_URL + PHYSICIAN_ROUTES.SIGN_IN} className="a_no_line">
                    <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Provider sign in</ParagraphLarge>
                </StyledLink>
            </FlexGridItem>
        </FlexGrid>}
        />;
    };

    render(){
        return <div style={{marginBottom:['XSMALL'].includes(Screen.size()) ? '54px' :'70px'}}>
            {this.renderNavBar()}
            {this.renderNavBarOptions()}
            {this.renderSignInOptions()}
            <PhysicianExplainer show={this.state.showPhysicianExplainer} callback={this.closePhysicianExplainer}/>
            {this.state.showSearchProtocol ? <SearchProtocol callbackClose={this.hideProtocolSearch}/> : null}
        </div>
    }
};

export default withFirebase(UnAuthNav);
