import React from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants';
import AuthUserContext from './context';
import Logger from '../Utils/logger';
import Storage from '../Storage';

const withAuthorization = (condition) => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.firebaseAuthObserver = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if (!condition(authUser)) {
                        // Move the login redirect to the code_verification.js
                        if (!Storage.get('signing_out')){
                            Storage.set('login_redirect', encodeURI(window.location.pathname))

                            this.props.history.push(ROUTES.SIGN_UP);
                        } else {
                            Storage.remove('signing_out'); //remove?
                            Storage.clear();

                            // TODO add the current URL to memory
                            this.props.history.push(ROUTES.HOME);
                        }
                    } else {
                        let redirectUrl = Storage.get('login_redirect');
                        if (
                            this.isEmailVerified(authUser) // Do not interrupt the sign up process
                            && this.isMfaEnrolled(authUser) // Do not interrupt the sign up process
                            && redirectUrl)
                        {
                          Storage.remove('login_redirect'); // Remove?
                          Storage.clear();
                          this.props.history.push(redirectUrl);
                        } else if (!this.isEmailVerified(authUser) && this.props.location.pathname !== '/')
                        {
                          Logger.log('not verified going to signup');
                          this.props.history.push(ROUTES.SIGN_UP);
                        }
                         else if (!this.isMfaEnrolled(authUser) && this.props.location.pathname !== '/')
                        {
                          Logger.log('not verified going to enroll');
                          this.props.history.push(ROUTES.MFA_ENROLL);
                        }
                    }
                },
            );
        }

        isEmailVerified = (authUser) => {
            return authUser && authUser.emailVerified;
        };

        isMfaEnrolled = (authUser) => {
            return authUser && authUser.multiFactor.enrolledFactors.length > 0;
        };

        componentWillUnmount() {
            this.firebaseAuthObserver();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser => condition(authUser) /* && this.isEmailVerified(authUser) && this.isMfaEnrolled(authUser) */ ? <Component {...this.props} /> : null}
                </AuthUserContext.Consumer>
            );
        }
    };

    return withRouter(withFirebase(WithAuthorization));
};

export default withAuthorization;
