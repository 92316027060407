import React, {Component} from 'react';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import content from './content';
import { withFirebase } from '../Firebase';
import about_img from '../../../../assets/images/about-landing.jpeg';
import MpsModal from '../Utils/modal';
import Carousel, {consts} from 'react-elastic-carousel';

import { Button, SHAPE, KIND } from 'baseui/button';
import ChevronLeft from 'baseui/icon/chevron-left';
import ChevronRight from 'baseui/icon/chevron-right';

import Pagination from './pagination';
import Screen, {Size} from '../Utils/screen';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            content: this.getContent('en', this.props.firebase.isPatient() ? "patient" : "physician")
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    };

    closeModal = () => {
        if (this.props.callback) {
            this.props.callback();
        }
    };

    getContent = (lang, app) => {
        return content(lang, app);
    };
    
    renderContent = (item, idx) => {
        return <FlexGrid flexGridRowGap={this.state.content.row_gap} key={idx}>
            {!this.props.firebase.isPatient() ? <FlexGridItem {...centerProps}><img style={{margin: this.state.content.image_margins}} src={item.image.href} alt={item.image.alt} height={item.image.height} width={item.image.width}/></FlexGridItem> : null}
            <FlexGridItem {...centerProps}>{item.title}</FlexGridItem>
            <FlexGridItem {...centerProps}>{item.description}</FlexGridItem>
            { this.props.firebase.isPatient() ? <FlexGridItem {...centerProps}><img style={{margin: this.state.content.image_margins}} src={item.image.href} alt={item.image.alt} height={item.image.height} width={item.image.width}/></FlexGridItem> : null}
        </FlexGrid>
    };

    arrows = ({type, onClick, isEdge}) => type === consts.PREV
        ? <Button
            onClick={onClick}
            shape={SHAPE.circle}
            kind={KIND.tertiary}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        maxHeight: '40px',
                        backgroundColor: 'transparent'
                    })
                }
            }}
        ><ChevronLeft size={40}/></Button>
        : <Button
            onClick={onClick}
            shape={SHAPE.circle}
            kind={KIND.tertiary}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        maxHeight: '40px',
                        backgroundColor: 'transparent'
                    })
                }
            }}
        ><ChevronRight size={40}/></Button>

    pagination = ({ pages, activePage, onClick }) => <div>{pages.map((page) => <Pagination key={page} active={activePage === page} onClick={() => onClick(page)} />)}</div>

    render = () => {
        return <MpsModal
            open={this.state.show}
            title={null}
            callbackCancel={this.closeModal}
            body={
                <Carousel
                    renderArrow={this.arrows}
                    renderPagination={this.pagination}
                    showArrows={Screen.size() != Size.small}
                >
                    {this.state.content.how_it_works.map((item, idx) => this.renderContent(item, idx))}
                </Carousel>
            }
        />
    };
}

export default withFirebase(About);
