import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants';
import Utils from '../Utils';
import Request from '../Request';
import PasswordResetVerify from './verify';
import {Spinner} from '../Spinner';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { FormControl } from "baseui/form-control";
import { Button, KIND, SHAPE } from "baseui/button";
import { Input } from "baseui/input";
import { ParagraphMedium } from 'baseui/typography';

const PasswordResetPage = () => <PasswordResetForm />
const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class PasswordResetFormBase extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            email: '',
            verifyEmail: false,
            error: null,
            checking: false
        };
        this.state = { ...this.defaultState };
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({checking: true});

        Request.post({
            firebase: null,
            route: ROUTES.RESET_PASSWORD_SEND_EMAIL_CODE_URL,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                tenantId: this.props.firebase.getTenant(),
                userEmail: this.state.email
            }),
            callbackSuccess: result => this.setState({verifyEmail: true, checking: false}),
            callbackError: error => this.setState({ error, checking: false, ...Utils.clearAllNotificationsExceptError() })
        })
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value.toLowerCase(), ...Utils.clearAllNotifications() });
    };

    shouldSubmitBeDisabled = () => {
        return this.state.email === '';
    };

    renderEmailVerificationComplete = () => {
        return <div style={{textAlign: 'center'}}>
            <PasswordResetVerify type='email' email={this.state.email}/>
        </div>
    };

    render() {
        return (
            <div className="center_page" style={{maxWidth: '343px'}}>
                {Utils.renderError(this.state.error)}
                {this.state.checking
                ? <Spinner />
                : this.state.verifyEmail
                    ? this.renderEmailVerificationComplete()
                    : <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600">
                        <FlexGridItem>{Utils.renderTitleCenter('Reset your password')}</FlexGridItem>
                        <FlexGridItem><ParagraphMedium style={{textAlign: 'center', margin: 0}}>Enter the email address associated with your Clinic user account.</ParagraphMedium></FlexGridItem>
                    <FlexGridItem {...centerProps}>
                            <Input
                                overrides={{
                                    Root: {
                                        style: ({ $theme }) => ({
                                            maxWidth: "343px",
                                            textTransform: 'lowercase'
                                        })
                                    }
                                }}
                                name="email"
                                value={this.state.email}
                                onChange={this.onChange}
                                placeholder="Email address"
                            />
                    </FlexGridItem>
                    <FlexGridItem {...centerProps}>
                        <Button kind={KIND.secondary} shape={SHAPE.pill} disabled={this.shouldSubmitBeDisabled()} onClick={this.onSubmit}>Continue</Button>
                    </FlexGridItem>
                </FlexGrid>
                }
            </div>
        );
    }
}

const PasswordResetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_RESET}>Forgot Password?</Link>
  </p>
);

export default PasswordResetPage;

const PasswordResetForm = withFirebase(PasswordResetFormBase);

export { PasswordResetForm, PasswordResetLink };