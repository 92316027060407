import { ParagraphMedium, LabelLarge} from 'baseui/typography';
import {StyledLink} from 'baseui/link';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import React from 'react';

const itemProps = {
  
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const itemProps2 = {
  
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const data = {
    en: {
        title: "Security",
        body: <>
        <ParagraphMedium style={{marginTop: "0px", marginBottom: "22px", textAlign:"left"}}>Clinic.dev is built and hosted on Google Cloud. All data is encrypted using AES-256k in transit and rest. Payments are tokenized and fully PCI compliant. We are Surescripts Certified and verify all providers on the platform using ID.me.</ParagraphMedium>
        {/*<LabelLarge style={{lineHeight:"24px", marginBottom: "30px"}}>Clinic.dev<br/>276 Fifth Ave. Suite 704<br/>New York, NY 10001</LabelLarge>*/}
        <FlexGrid
            flexGridRowCount={2}
            flexGridRowGap="scale800"
        >
            <FlexGridItem {...itemProps}>
              <img src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,w_180/v1693330213/Google_Cloud_logo_l5vesn.png" alt="Google Cloud" width="180" border="0" className= "center"></img>    
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
              <img src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,q_100,w_180/v1693347532/surescripts-removebg-preview_1_qypxtj.png" alt="Certified by Surescripts" width="180" border="0" className= "center"></img>    
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
              <img src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,w_180/v1693330213/idme_wn1jpr.png" alt="Verified by Id.me" width="180" border="0" className= "center"></img>    
            </FlexGridItem>
        </FlexGrid>
    </>
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
