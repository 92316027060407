import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Request from '../../shared/Request';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { LabelLarge, LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import MpsModal from '../../shared/Utils/modal';
import {Spinner} from '../../shared/Spinner';

const medProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                border: '1px solid ' + $theme.colors.mono400,
                borderRadius: '10px',
                padding: '20px'
            }),
        },
    },
};

class Meds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: null,
            isLoaded: false,
            isRemoving: false,
            meds: null,
            subscription: this.props.subscription,
            remove_id: null,
            show_chicken_message: false
        }
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_prescriptions/" + this.state.subscription.id,
            callbackSuccess: (result) => this.setState({
                isLoaded: true,
                meds: result
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error
            })
        })
    };

    hideMedication = (prescription_id) => {
        Logger.log('Removing active prescription: ' + prescription_id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_prescriptions/hide/" + prescription_id,
            body: new FormData(),
            callbackSuccess: result => this.setState(prevState => ({
                meds: prevState.meds.filter(med => med.id != prescription_id),
                success: {message: 'Medication removed successfully.'},
                show_chicken_message: false,
                isRemoving: false,
                remove_id: null,
                ...Utils.clearAllNotificationsExceptSuccess()
            })),
            callbackError: error => this.setState({
                error,
                isRemoving: false,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    filterByDate = (meds) => {
        return meds.filter(refill => { return  !refill.patient_hide && new Date(refill.end_date) > new Date()})
    }

    renderMed = (med, index) => {
        return <FlexGridItem key={index}>
            <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale300" {...medProps}>
                <FlexGridItem><ParagraphSmall style={{margin: 0, color: '#999999'}}>{Utils.renderLocaleString(med.created_at)}</ParagraphSmall></FlexGridItem>
                <FlexGridItem><LabelMedium style={{margin: 0}}>{med.drug_name}</LabelMedium></FlexGridItem>
                <FlexGridItem><ParagraphSmall style={{color: 'grey', margin: 0}}>Quantity {med.quantity_dispensed} • Day supply {med.days_supply} • Refills {med.refills}</ParagraphSmall></FlexGridItem>
                <FlexGridItem><ParagraphSmall style={{margin: 0}}>{med.pharmacy_detail}</ParagraphSmall></FlexGridItem>
                <FlexGridItem></FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
    };


    renderChickenMessage = () => {
        if (this.state.show_chicken_message) {
            return <MpsModal
                open={this.state.show_chicken_message}
                isLoading={this.state.isRemoving}
                title='Remove script?'
                withFooter={true}
                body={<ParagraphMedium>Removing this script will hide it from your Rx list. It still may exist at the pharmacy though.</ParagraphMedium>}
                callbackButton="Yes Remove"
                callback={() => {
                    this.setState({isRemoving: true});
                    this.hideMedication(this.state.remove_id)
                }}
                callbackCancelButton="Cancel"
                callbackCancel={(event) => this.setState({
                    show_chicken_message: false,
                    remove_id: null,
                    ...Utils.clearAllNotifications()
                })}
            />
        }
    };

    renderMeds = () => {
        if(this.state.meds) {
            return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600">{this.filterByDate(this.state.meds).map( (med, index) => this.renderMed(med, index))}</FlexGrid>
        }
    };

    render = () => {
        return <AuthUserContext.Consumer>
        {authUser => <div>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.success)}
                {this.state.isLoaded ? this.renderMeds() : <Spinner />}
                {this.renderChickenMessage()}
            </div>}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Meds);
