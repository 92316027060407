import React, {Component} from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import HistoryMessage from './history_message';
import {Spinner, SpinnerSize} from '../../shared/Spinner';
import {ParagraphSmall} from 'baseui/typography';
import Logger from '../../shared/Utils/logger';

class History extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.color_index = 0;
        this.bot_color = '#00ff7f';
        this.colors = [
            '#8a2be2',
            '#1e90ff',
            '#ff7f50',
            '#daa520',
            '#008000',
            '#2e8b57',
            '#9acd32',
            '#ff69b4',
            '#0000ff',
            '#5f9ea0',
            '#ff0000',
            '#ff4500',
            '#e31e1e',
            '#b22222',
            '#db15de',
            '#ffcbfd',
            '#5d5da2',
            '#da5fac',
            '#fff882',
            '#bf94ff',
            '#ffc35e',
            '#00ff12',
            '#d2691e',
            '#ede4be',
            '#ccffff',
            '#00fcaf',
            '#e2f200',
            '#ff82f6',
            '#d91775',
            '#0f7d53',
            '#9fff00',
            '#ff8d00'
        ];

        this.history_attributes = {};
        this.last_username = '';

        this.state = {
            username: this.props.username,
            new_message: this.props.new_message,
            parent_height: this.props.parent_height,
            chat_height: this.calcHeight(this.props.parent_height),
            protocol: this.props.protocol,
            chat_history: [{
                room: "",
                time_sent: 1647617924702,
                username: this.props.protocol.creator.username,
                message: this.props.protocol.server_rules ? this.props.protocol.server_rules : "",
                sent: true
            }
        ],
            usernames: []
        }
    }

    componentDidMount = () => {}

    // Header: 73px
    // Typing indicator: 22px
    // Input Message: 44px
    // 12 bottom margin
    // Additional space: 24px
    calcHeight = (parent_height) => parent_height - (61 + 48 + 24 - 6);

    componentDidUpdate = (prevProps) => {}

    getColor = () => {
        const color = this.colors[this.color_index];

        if (this.color_index >= (this.colors.length - 1)) {
            this.color_index = 0;
        } else {
            this.color_index += 1;
        }

        return color;
    };

    executeScroll = () => {
        if (!this.messages_loaded || this.myRef.current.scrollTop + this.myRef.current.offsetHeight >= this.myRef.current.scrollHeight - 100 ){
            this.myRef.current.scroll({
                top: this.myRef.current.scrollHeight,
                left: 0,
                behavior: 'auto'
            });
        }
    }

    renderMessage = (message) => {
        if (!this.history_attributes[message.username]) {
            this.history_attributes[message.username] = {color: this.bot_color};
        }

        return <HistoryMessage
            data={message}
            color={this.history_attributes[message.username].color}
            username={this.state.username}
            show_user={true}
            usernames={this.state.usernames}
        />
    };

    render = () => {
        this.last_username = '';

        return <AuthUserContext.Consumer>
            {authUser => (
                <div style={{overflowY: 'auto', height: this.state.chat_height + 'px'}} ref={this.myRef}>
                    <FlexGrid flexGridRowGap={'scale0'}>
                        
                        {this.state.chat_history && this.state.chat_history.map((message, idx) => <FlexGridItem key={idx}>{this.renderMessage(message)}</FlexGridItem>)}
                        <FlexGridItem><ParagraphSmall></ParagraphSmall></FlexGridItem>

                        {this.state.chat_history.length == 0 ? <FlexGridItem style={{paddingTop: '15px'}}><Spinner size={SpinnerSize.SMALL}/></FlexGridItem> : null}
                    </FlexGrid>
                </div>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(History);
