import { HeadingSmall, HeadingXSmall, HeadingMedium, ParagraphLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import {Block} from 'baseui/block';
import Storage from '../../shared/Storage';
import { StyledLink } from "baseui/link";
import Screen from "../../shared/Utils/screen";
import React from 'react';

const blockProps = {
  overrides: {
      Block: {
          style: ({$theme}) => ({
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              borderRadius: '',
              width:"100%",
              marginTop:'40px',
              marginRight: "auto",
              marginBottom:'10px',
              marginLeft: "auto"
          }),
      },
  },
};

const itemProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  align: 'center',
};

const bottomProps = {
  maxWidth: '360px',
  marginLeft:'auto',
  marginRight:'auto'
};

const greyTextProps = {
  overrides: {
      Block: {
        style: ({$theme}) => ({
          color: $theme.colors.primary400,
        }),
      },
  },
};


const data = {
    en: {
        body: <>
            <Block {...blockProps}>
              <div >
                <HeadingSmall style={{textAlign:"left", margin: 0}}>
                  About Clinic.dev
                </HeadingSmall>
                <ParagraphMedium style={{textAlign:"left", lineHeight:"22px"}}>
                  Clinic.dev is a lightweight, flexible, free practice builder and hosting service for doctors and nurse practitioners.
                </ParagraphMedium>
                <ParagraphMedium style={{textAlign:"left", lineHeight:"22px"}}>
                  Build your own clinic in minutes and share your link online with patients. Set a subscription price and earn recurring income. Every clinic includes an eRx pad with ECPS, messaging and payments. Our goal is to give every doctor in America their own personal clinic. 
                </ParagraphMedium>
                <ParagraphMedium style={{textAlign:"left", lineHeight:"22px"}}>
                  Want to know more? Read our <StyledLink href={"https://clinicdev.notion.site/Clinic-dev-user-guide-6709682413d748b6a6a8b09fd088292d?pvs=4"} target="_blank" className='a_no_line' style={{color:"#00a1ff"}}>documentation ↗</StyledLink>
                </ParagraphMedium>
              </div>
            </Block>
        </>
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
