import firebase from 'firebase/app';
import 'firebase/auth';
import {FIREBASE_TENANT_ID, FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN} from '../Constants';
import Logger from '../Utils/logger';

class Firebase {
    constructor() {
        firebase.initializeApp({
            apiKey: FIREBASE_API_KEY,
            authDomain: FIREBASE_AUTH_DOMAIN
        });

        this.auth = firebase.auth();
        this.setTenant(FIREBASE_TENANT_ID);
        this.setPersistence();

        this.user_settings = {};
    }

    setTenant = (tenantId) => {
        this.auth.tenantId = tenantId;
    }

    getTenant = () => {
        return this.auth.tenantId;
    };

    isPatient = () => {
        return this.auth.tenantId.startsWith('patient');
    };

    isPhysician = () => {
        return this.auth.tenantId.startsWith('physician');
    };

    isAdmin = () => {
        return this.auth.tenantId.startsWith('admin');
    };

    setPersistence = () => {
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    };

    createUserWithEmailAndPassword = (email, password) => {
        return this.auth.createUserWithEmailAndPassword(email, password);
    };
    signInWithPassword = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    };
    signOut = () => {
        Logger.log(this.auth.currentUser);
        return this.auth.signOut();
    };
    reloadUser = () => {
        return this.auth.currentUser.reload()
    };
    resetPassword = (email) => { return this.auth.sendPasswordResetEmail(email); };
    updatePassword = (password) => { return this.auth.currentUser.updatePassword(password); };
    updatePhoneNumberWithCredential = (phoneCredential) => { return this.auth.currentUser.updatePhoneNumber(phoneCredential)};
    currentUser = () => { return this.auth.currentUser; }
    isUserOnline = () => { return this.auth.currentUser ? true : false };

    setUserSettingIfOffline = (key, val) => {
        if (!this.isUserOnline()) {
            Logger.log("Firebase Current User is offline!");
            this.setUserSetting(key, val);
        }
    };

    setUserSetting = (key, val) => {
        this.user_settings[key] = val;
    };

    getUserSetting = (key) => {
        if (key in this.user_settings) {
            return this.user_settings[key];
        }

        return null;
    };

    delUserSetting = (key) => {
        delete this.user_settings[key];
    };

    isEmailVerified = () => { return this.currentUser() && this.currentUser().emailVerified; };
    getEmail = () => {if (this.currentUser()) {return this.currentUser().email } else {return null}};
    getPhoneNumber = () => {
        Logger.log('Firebase Getting the phone number...');
        Logger.log(this.auth.currentUser);
        if (this.currentUser() && this.isUserMFAEnrolled()) {
            Logger.log(this.currentUser().multiFactor.enrolledFactors);
            return this.currentUser().multiFactor.enrolledFactors[0].phoneNumber;
        } else {
            return null
        }
    };
    getRecaptchaVerifier = (dom_id, callback) => { return new firebase.auth.RecaptchaVerifier(dom_id, {'size': 'invisible', 'callback': (response) => {Logger.log(response); callback(response)}}); };
    getPhoneAuthProvider = () => { return new firebase.auth.PhoneAuthProvider(); };
    getPhoneAuthProviderCredential = (verificationId, verificationCode) => { return firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode) };
    getMultiFactorAssertion = (credential) => { return firebase.auth.PhoneMultiFactorGenerator.assertion(credential) };
    getMultiFactorSession = () => { return this.auth.currentUser.multiFactor.getSession(); };
    enrollUserToMFA = (mfaAssertion, mfaType) => {return this.auth.currentUser.multiFactor.enroll(mfaAssertion, mfaType)};
    getUserMFAEnrolledFactors = () => { return this.auth.currentUser ? this.auth.currentUser.multiFactor.enrolledFactors : []; };
    isUserMFAEnrolled = () => { return this.auth.currentUser && this.getUserMFAEnrolledFactors().length > 0; };
}

export default Firebase;
