import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './favicon.css';
import Storage from '../Storage';
import { withFirebase } from '../Firebase';
import {StatefulTooltip} from 'baseui/tooltip';

class Favicon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            favicon_url: this.props.favicon_url,
            size: this.props.size,
            badge_type: this.props.badge_type,
            status: this.props.status,
            rejecting_new_subscriptions: this.props.rejecting_new_subscriptions,
            style: this.props.style,
            showBadge: this.props.showBadge === false ? false : true,
            showNewActivity: this.props.showNewActivity,
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.favicon_url != this.props.favicon_url
            || prevProps.size != this.props.size
            || prevProps.badge_type != this.props.badge_type
            || prevProps.status != this.props.status
            || prevProps.rejecting_new_subscriptions != this.props.rejecting_new_subscriptions
            || prevProps.style != this.props.style
            || prevProps.showBadge != this.props.showBadge
            || prevProps.showNewActivity != this.props.showNewActivity
        ) {
            this.setState({
                favicon_url: this.props.favicon_url,
                size: this.props.size,
                badge_type: this.props.badge_type,
                status: this.props.status,
                rejecting_new_subscriptions: this.props.rejecting_new_subscriptions,
                style: this.props.style,
                showBadge: this.props.showBadge,
                showNewActivity: this.props.showNewActivity
            })
        }
    };

    getCssClasses = () => {
        if (this.state.size == FaviconSize.MEDIUM) {
            return { container: 'favicon_container_medium', favicon: 'favicon_medium', badge: 'badge_icon_medium', new_activity: 'new_activity_medium'};
        } else if (this.state.size == FaviconSize.LARGE) {
            return { container: 'favicon_container_large', favicon: 'favicon_large', badge: 'badge_icon_large', new_activity: 'new_activity_large'};
        } else if (this.state.size == FaviconSize.XLARGE) {
            return { container: 'favicon_container_xlarge', favicon: 'favicon_xlarge', badge: 'badge_icon_xlarge', new_activity: 'new_activity_xlarge'};
        } else {
            return { container: 'favicon_container', favicon: 'favicon', badge: 'badge_icon', new_activity: 'new_activity'};
        }
    }

    pendingIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Under review"}
        showArrow
        returnFocus
        autoFocus
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                x="0"
                y="0"
                enableBackground="new 0 0 210 210"
                viewBox="0 0 210 210"
            >
                <path
                fill={window.matchMedia("(prefers-color-scheme: light)").matches ? "#FFFFFF" : "#000000"}
                d="M108.4 209.1C48.3 211-1.1 161.7.9 101.6 2.7 47.1 46.9 2.8 101.5 1.1c60.1-2 109.4 47.4 107.4 107.4-1.8 54.5-46 98.8-100.5 100.6z"
                ></path>
                <path
                fill="#b3b3b3"
                d="M104.9 37.8c-37.1 0-67.2 30.1-67.2 67.2s30.1 67.2 67.2 67.2 67.2-30.1 67.2-67.2-30.1-67.2-67.2-67.2zm-9.1 21.6c12.3-2.4 24.9.3 35.1 7.3l9.2-6.3 2.9 37.3-33.7-16.1 7.9-5.4c-5.8-2.5-12.2-3.2-18.5-2-12 2.3-21.7 11.6-24.6 23.5L59.5 94C63.8 76.5 78 62.9 95.8 59.4zm18.2 91.4c-3 .6-5.9.9-8.9.9-9.3 0-18.4-2.8-26.2-8.1l-9.2 6.3-2.9-37.3 33.7 16.1-7.9 5.4c5.8 2.5 12.2 3.2 18.5 2 12-2.3 21.7-11.6 24.6-23.5l14.6 3.6c-4.3 17.5-18.5 31.1-36.3 34.6z"
                ></path>
            </svg>
      </StatefulTooltip>
    };

    activeIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Online"}
        showArrow
        returnFocus
        autoFocus
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                x="0"
                y="0"
                enableBackground="new 0 0 210 210"
                viewBox="0 0 210 210"
            >
                <path
                fill={window.matchMedia("(prefers-color-scheme: light)").matches ? "#FFFFFF" : "#000000"}
                d="M108.8 208.9C48.7 210.9-.6 161.5 1.3 101.4 3.2 46.9 47.4 2.6 101.9.9c60.1-2 109.4 47.4 107.4 107.4-1.7 54.5-45.9 98.8-100.5 100.6z"
                ></path>
                <circle cx="105.4" cy="104.9" r="67.2" fill="#00FF00"></circle>
            </svg>
        </StatefulTooltip>
    };

    inactiveIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Offline"}
        showArrow
        returnFocus
        autoFocus
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                x="0"
                y="0"
                enableBackground="new 0 0 210 210"
                viewBox="0 0 210 210"
            >
                <circle cx="105.4" cy="104.9" r="104.1" fill={window.matchMedia("(prefers-color-scheme: light)").matches ? "#FFFFFF" : "#000000"}></circle>
                <path
                fill="#b3b3b3"
                d="M105.4 37.6c-37.1 0-67.2 30.1-67.2 67.2S68.3 172 105.4 172s67.2-30.1 67.2-67.2-30.1-67.2-67.2-67.2zm0 102.8c-19.6 0-35.5-15.9-35.5-35.5s15.9-35.5 35.5-35.5 35.5 15.9 35.5 35.5-15.9 35.5-35.5 35.5z"
                ></path>
            </svg>
        </StatefulTooltip>
    };

    fullIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"This clinic isn't accepting new members"}
        showArrow
        returnFocus
        autoFocus
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                x="0"
                y="0"
                enableBackground="new 0 0 210 210"
                viewBox="0 0 210 210"
            >
                <path
                fill={window.matchMedia("(prefers-color-scheme: light)").matches ? "#FFFFFF" : "#000000"}
                d="M108.8 208.9C48.7 210.9-.6 161.5 1.3 101.4 3.2 46.9 47.4 2.6 101.9.9c60.1-2 109.4 47.4 107.4 107.4-1.7 54.5-45.9 98.8-100.5 100.6z"
                ></path>
                <circle cx="105.4" cy="104.9" r="67.2" fill="#fa8a1a"></circle>
            </svg>
        </StatefulTooltip>
    };
 
    shutdownIcon = (width) => {
        return <StatefulTooltip
            accessibilityType={'tooltip'}
            content={"This service has ended"}
            showArrow
            returnFocus
            autoFocus
        >
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 210 210"
                enableBackground="new 0 0 210 210"
                xmlSpace="preserve"
                width={width}
            >
                <circle fill="#ffffff" cx="105" cy="105" r="104.1"/>
                <circle cx="105" cy="105" r="67.2" fill="#ed1c24"/>
                <circle fill="#ffffff" cx="105" cy="105" r="35.5"/>
            </svg>
        </StatefulTooltip>
    };

    getBadge = (cssClass) => {
        if (this.state.rejecting_new_subscriptions) {
            if (this.state.status == 'inactive'){
                return <div className={cssClass}>{this.inactiveIcon(this.state.size.badge)}</div>
            } else if (this.state.status == 'is_shutdown'){
                return <div className={cssClass}>{this.shutdownIcon(this.state.size.badge)}</div>
            } else {
                return <div className={cssClass}>{this.fullIcon(this.state.size.badge)}</div>
            }
        } else if (this.state.status == 'pending'){
            return <div className={cssClass}>{this.pendingIcon(this.state.size.badge)}</div>
        } else if (this.state.status == 'active'){
            return <div className={cssClass}>{this.activeIcon(this.state.size.badge)}</div>
        } else if (this.state.status == 'inactive'){
            return <div className={cssClass}>{this.inactiveIcon(this.state.size.badge)}</div>
        } else if (this.state.status == 'is_shutdown'){
            return <div className={cssClass}>{this.shutdownIcon(this.state.size.badge)}</div>
        } else {
            return null;
        }
    };

    newActivity = (width) => {
        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            enableBackground="new 0 0 600 600"
            viewBox="0 0 600 600"
        >
            <circle cx="300" cy="300" r="299" fill="#ff4500"></circle>
        </svg>
    };

    getNewActivityBadge = (cssClass) => {
        return <div className={cssClass}>{this.newActivity(this.state.size.new_activity)}</div>
    };

    render = () => {
        let css = this.getCssClasses();
        return <div className={css.container} style={this.state.style}>
            <div className={css.favicon}>
                {this.state.favicon_url ? <img width={this.state.size.favicon} src={this.state.favicon_url} style={{borderRadius: '50%'}}/>
                 : 
                 <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={this.state.size.favicon} x="0" y="0" viewBox="0 0 117 117" xmlSpace="preserve"><style></style>
                 <defs>
                    <linearGradient id="gradient" gradientTransform="rotate(45)"> 
                    <stop offset="5%" stopColor="#40e6ff" />
                    <stop offset="95%" stopColor="#d576f8" />
                    </linearGradient>
                </defs>
                <path d="M58.5 116.3C26.6 116.3.7 90.4.7 58.5S26.6.7 58.5.7s57.8 25.9 57.8 57.8-25.9 57.8-57.8 57.8z" fill="url(#gradient)"/><path d="M58.5 1.2c31.6 0 57.3 25.7 57.3 57.3s-25.7 57.3-57.3 57.3S1.2 90.1 1.2 58.5 26.9 1.2 58.5 1.2m0-1C26.3.2.2 26.3.2 58.5s26.1 58.3 58.3 58.3 58.3-26.1 58.3-58.3S90.7.2 58.5.2z" fill="url(#gradient)"/>
                </svg>}
            </div>
            {this.state.showBadge ? this.getBadge(css.badge) : null}
            {this.state.showNewActivity ? this.getNewActivityBadge(css.new_activity) : null}
        </div>
    };
}

export const FaviconSize = {
    SMALL: {favicon: '36px', badge: '20px', new_activity: '14px'},
    MEDIUM: {favicon: '76px', badge: '33px', new_activity: '24px'},
    LARGE: {favicon: '88px', badge: '38px', new_activity: '28px'},
    XLARGE: {favicon: '128px', badge: '28px', new_activity: '36px'}
};

export default withFirebase(Favicon);
