import React, {Component} from 'react';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {HeadingXXLarge, HeadingLarge, HeadingXSmall} from 'baseui/typography';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class NotFound extends Component {
    constructor(props){
        super(props);
    }

    render = () => {
        return <FlexGrid
            maxWidth={'450px'}
            className={"center_page"}
            flexGridColumnCount={1}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale600"
        >
            <FlexGridItem {...itemProps}><HeadingXXLarge style={{margin: 0}}>Oops!</HeadingXXLarge></FlexGridItem>
            <FlexGridItem {...itemProps}><HeadingLarge style={{margin: 0}}>404 - Page not found</HeadingLarge></FlexGridItem>
            <FlexGridItem {...itemProps} style={{margin: 0, textAlign: 'center'}}>
                <HeadingXSmall style={{margin: 0}}>The page you are looking for might have been removed, had its name changed or is temporarly unavailable.</HeadingXSmall>
            </FlexGridItem>
        </FlexGrid>
    };
}

export default NotFound;