import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import MpsModal from '../../shared/Utils/modal';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { LabelLarge} from 'baseui/typography';
import { StyledLink } from "baseui/link";
import {Button, KIND, SHAPE, SIZE} from 'baseui/button';
import Request from '../../shared/Request';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import content, {displaySearchContent} from './content';
import Truncate from 'react-truncate';
import Screen, {Size} from '../../shared/Utils/screen';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '80px',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
};

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};

const topLinnersDoNothing = {
    overrides: {
      Block: {
        style: ({$theme}) => ({}),
      },
    },
};

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const favProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                marginTop: '5px',
                maxWidth: '36px',
            }),
        },
    },
};

class Dashboard extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            response: null,
            redirect: '',
            show: true,
            content: content('en')
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_dashboard",
            callbackSuccess: (result) => this.setState({
                isLoaded: true,
                response: result,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getProtocols = () => {
        if (this.state.isLoaded && this.state.response){
            if(this.state.response.protocols.length == 0) {
                return <div style={{textAlign: 'center'}}>
                    {Utils.renderTitleCenter("No active subscriptions found")}
                    <Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.default} $as="a" href={ROUTES.HOME}>Go home</Button>
                </div>;
            } else if (this.state.response.protocols.length == 1){
                if (this.state.response.protocol_subscriptions[0].status == 'pending') {
                    this.props.history.push(ROUTES.SUBSCRIPTION_STATUS + '/' + this.state.response.protocols[0].number);
                } else {
                    this.setState({redirect: ROUTES.PROTOCOL_HOME + '/' + this.state.response.protocols[0].number});
                }
            } else {
                return this.renderActiveProtocols();
            }
        }
    };

    getSubscriptionStatus = (protocol) => {
        return this.state.response.protocol_subscriptions.filter(subscription => subscription.protocol_id == protocol.id)[0];
    };

    renderProtocols = () => {
        return this.state.response.protocols.map((protocol, idx) => {

            let linners = {...topLinnersDoNothing}
           if (idx > 0) {
                linners = {...topLinners}
            }

            return <FlexGridItem
                {...contentProps}
                {...linners}
                key={idx}
                padding={this.state.content.search_result_row_padding}
            >
                <FlexGrid flexGridColumnCount={2} width={'100%'}>
                    <FlexGridItem {...favProps}>
                    <StyledLink key={idx} href={ROUTES.SUBSCRIPTION_STATUS + '/' + protocol.number} className="a_no_line">
                        <Favicon
                            favicon_url={protocol.favicon_url}
                            size={FaviconSize.SMALL}
                            badge_type={protocol.badge_type}
                            status={protocol.status}
                            rejecting_new_subscriptions={protocol.rejecting_new_subscriptions}
                        />
                        </StyledLink>
                    </FlexGridItem>
                    <FlexGridItem>
                        <StyledLink key={idx} href={ROUTES.SUBSCRIPTION_STATUS + '/' + protocol.number} className="a_no_line">
                            {displaySearchContent(<Truncate lines={1} width={Screen.size() == Size.small ? 220 : 330}>{protocol.name + ' • ' + protocol.summary}</Truncate>)}
                        </StyledLink>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        })
    };

    renderActiveProtocols = () => {
        return <MpsModal
            open={this.state.show}
            autoFocus={false}
            title={null}
            body={
                <FlexGrid
                    flexGridColumnCount={1}
                    flexGridRowGap={this.state.content.search_result_content_spacing}
                    margin={this.state.content.search_result_content_margin}
                >
                    {this.renderProtocols()}
                </FlexGrid>
            }
        />
    };

    render(){
        if (this.state.redirect !== '') {
            // just simply redirecting does not work because then Persistent Notificaitons will not know the
            // current href location because history.push does not appear to change that value. Through this
            // redirect then window.location.pathname gives us the current page value instead of the page
            // of the link the user clicked.
            window.location.href = this.state.redirect;
            return null;
        }
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div>
                        {Utils.renderError(this.state.error)}
                        {Utils.renderSuccess(this.state.message)}
                        {Utils.renderSpace()}
                        {this.getProtocols()}
                    </div>
                )}
            </AuthUserContext.Consumer>
        )
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Dashboard);
