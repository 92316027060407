import React from 'react';
import { ParagraphMedium, ParagraphLarge } from 'baseui/typography';
import { Redirect } from 'react-router-dom';

const data = {
    en: {
        
        //desktop
        banner_background_image: "url()",

        banner_welcome_margin_top: "5px",
        banner_margins: "0px 0px 0px 0px",
        banner_padding: "40px",
        
        banner_title: "Find clincs by condition, drug, or name",
        banner_display_custom_margin: '0px 0px 20px 0px',
        banner_display_custom_padding: '0px 0px 0px 0px',
        banner_display_custom_font_size: '28px',
        banner_display_custom_line_height: '32px',
        
        banner_subtext: <span>Search by condition, drug, creator or tag</span>,
        banner_subtext_margin: '0px 0px 20px 0px',
        banner_subtext_font_size: '18px',
        banner_subtext_line_height: '24px',
        banner_subtext_max_width: '950px',
        
        banner_search_placeholder: "Find a clinic",
        banner_search_max_width: "570px",
        banner_search_search_radius: "25px",
        banner_search_padding_left: "20px",
        
        banner_call_action: <span><span style={{color:'#24bc73'}}>●</span> 6 active servers • 12 supported conditions</span>,
        banner_call_action_font_size: "14px",
        banner_call_action_line_height: "20px",
        banner_call_action_margin: "16px 0px 0px 0px",
        
        
        //mobile
        banner_mobile_margins: "0px 0px 15px 0px",
        banner_mobile_title: "Discover digital health on Clinic",
        banner_mobile_display_custom_font_size: '28px',
        banner_mobile_display_custom_line_height: '32px',
        banner_mobile_display_custom_margin: '0px 0px 10px 0px',
        banner_mobile_display_custom_padding: '0px 0px 0px 0px',
        
        banner_mobile_subtext: <span>Search by condition, drug, creator or tag</span>,
        banner_mobile_subtext_font_size: '16px',
        banner_mobile_subtext_line_height: '22px',
        banner_mobile_subtext_margin: '0px 0px 20px 0px',
        
        //buttons
        create_protocol_on_run: "+ Create new server",
        conditions: "Condition index",
        drugs: "Therapeutic index",
        sort: "↑↓",

        //tables
        condition_title: 'Find servers by diagnosis/condition',
        condition_blurb: <ParagraphMedium></ParagraphMedium>,
        condition_col1: "Condition",
        condition_col2: "Server name",

        therapeutic_title: 'Find servers by drug/therapeutic',
        therapeutic_blurb: <ParagraphMedium></ParagraphMedium>,
        therapeutic_col1: 'Therapeutic',
        therapeutic_col2: 'Server name',

        search_button_content_padding_right_large: '190px',
        search_button_content_padding_right_small: '44px',
        search_result_content_margin: '25px 0px 0px 0px',
        search_result_content_spacing: 'scale0',
        search_result_row_padding: "5px 0px",
        search_result_text_font_size: '18px',
        search_result_text_margin: '10px 0px 10px 18px',

        search_image_url: "",
        search_image_height: '86px',
        search_image_margin: '0px 0px 10px 0px'
    }
}

const content = (lang) => {
    return data[lang];
};

const searchResultProps = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
            color: $theme.colors.primary
        }),
      },
    },
};

export const displaySearchContent = (text) => {
    // Displays the data of the search for a protocol
    return <ParagraphLarge {...searchResultProps} style={{margin: data.en.search_result_text_margin, fontSize: data.en.search_result_text_font_size}}>{text}</ParagraphLarge>
}
export default content;
