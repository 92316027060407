import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import Request from '../Request';
import * as PATIENT_ROUTES from '../../patient/Constants';
import Logger from '../Utils/logger';
import Favicon, {FaviconSize} from '../Favicon';
import { StyledLink } from 'baseui/link';
import content from './content';

class PatientProtocolNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol_subscriptions: [],
            content: content('en').patient
        };
    }
 
    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: PATIENT_ROUTES.BASE_URL_API + "/patient_dashboard/all_subscriptions",
            callbackSuccess: (result) => this.setState({
                protocol_subscriptions: result.protocol_subscriptions
            }),
            callbackError: (error) => this.setState({
                error
            })
        });
    };

    getProtocolSubscriptionLink = (protocol_subscription) => {
        if (!['active'].includes(protocol_subscription.protocol.status)) {
            return PATIENT_ROUTES.BASE_URL + '/protocol/status/' + protocol_subscription.protocol.number;
        } else if (['active', 'active_pending_cancel', 'inactive'].includes(protocol_subscription.status)) {
            return PATIENT_ROUTES.BASE_URL + '/protocol/home/' + protocol_subscription.protocol.number;
        } else if (['cart'].includes(protocol_subscription.status)) {
            return PATIENT_ROUTES.BASE_URL + '/protocol/apply/' + protocol_subscription.protocol.number;
        } else {
            return PATIENT_ROUTES.BASE_URL + '/status/' + protocol_subscription.protocol.number;
        }
    };

    renderProtocols = () => {
        this.state.protocol_subscriptions.sort((a, b) => {return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1});

        return this.state.protocol_subscriptions && this.state.protocol_subscriptions.map(protocol_subscription => {
            let new_activity = protocol_subscription.new_activity_for_patient;
            if (location.pathname.endsWith('/protocol/home/' + protocol_subscription.protocol.number)
                || location.pathname.endsWith('/status/' + protocol_subscription.protocol.number)) {
                new_activity = false;
            }

            return <StyledLink
                    key={protocol_subscription.protocol.id}
                    href={this.getProtocolSubscriptionLink(protocol_subscription)}
                >
                    <Favicon
                        favicon_url={protocol_subscription.protocol.favicon_url}
                        size={FaviconSize.SMALL}
                        status={protocol_subscription.protocol.status}
                        rejecting_new_subscriptions={protocol_subscription.protocol.rejecting_new_subscriptions}
                        style={{marginRight: this.state.content.favicon_margin_right, marginTop: this.state.content.favicon_margin_top}}
                        showBadge={false}
                        showNewActivity={new_activity}
                    />
                </StyledLink>
        })
    }

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    this.renderProtocols()
                )
            }
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PatientProtocolNavigation);