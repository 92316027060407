import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphMedium, HeadingSmall} from 'baseui/typography';
import {Button, KIND, SHAPE} from 'baseui/button';
import Utils from '../../shared/Utils';
import content from './content';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class StatusRejectTimeout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: this.getContent()
        }
    }

    getContent = () => {
        return content('en');
    };

    render () {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <div style={{display:'flex', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'85vh'}}>
                            <FlexGrid
                                flexGridColumnCount={1}
                            >
                                <FlexGridItem {...itemProps}>
                                    <HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '10px'}}>{this.state.content.reject_timeout_title.replaceAll("{protocol_name}", this.props.protocol_name)}</HeadingSmall></FlexGridItem>
                                <FlexGridItem {...itemProps}>
                                    <ParagraphMedium style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}>
                                        {this.state.content.reject_timeout_option_title}
                                    </ParagraphMedium>
                                </FlexGridItem>
                                <FlexGridItem {...itemProps}>
                                    <Button
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        $as="a"
                                        href={ROUTES.PROTOCOL_SUBSCRIPTION + '/reapply/' + this.props.protocol_id}
                                    >Reapply</Button>
                                </FlexGridItem>
                            </FlexGrid>
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(StatusRejectTimeout);
