import React, {Component} from 'react';
import { Avatar as BaseAvatar } from "baseui/avatar";

class Avatar extends Component {
    constructor(props){
        super(props);

        this.state = {
            size: this.props.size,
            name: this.props.name
        }
    }

    render = () => {
        return <BaseAvatar
            name={this.state.name}
            size={this.state.size}
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.primaryA
                    })
                },
                Initials: {
                    style: ({ $theme }) => ({
                        color: $theme.colors.primaryB
                    })
                }
            }}
        />
    }
}

export const AvatarSize = {
    SMALL: '40px',
    MEDIUM: '50px',
    LARGE: '60px',
    XLARGE: '70px'
};

export default Avatar;