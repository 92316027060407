import React, {Component} from 'react';
import './style.css';
import content from './content';
import { withFirebase } from '../Firebase';
import Logger from '../Utils/logger';

class SlideShow extends Component {
    constructor(props) {
        super(props);

        this.width = this.props.width;
        this.height = this.props.height;
        this.images = this.props.images;
        this.timeout = this.props.timeout;
        this.current = 0;
        this.next = 1;

        this.state = {
            isLoaded: false,
        }
    }

    componentDidMount = () => {
        this.setState({isLoaded: true}, () => setInterval(this.swapImages, this.timeout));
    };

    swapImages = () => {
        var $active = $('#' + this.current.toString());
        var $next = $('#' + this.next.toString());

        $active.removeClass('active');
        $active.addClass('inactive');

        $next.removeClass('inactive');
        $next.addClass('active');

        this.current = this.next;
        this.next = this.next >= this.images.length - 1 ? 0 : this.next + 1;
    }

    getContent = (lang, app) => {
        return content(lang, app);
    };

    renderImages = () => {
        return <div id="myGallery" style={{height: this.height, width: this.width}}>
            {this.images.map((img, idx) => <img id={idx} key={idx} src={img} className={idx == 0 ? "active" : "inactive"}/>)}
        </div>
    };

    render = () => {
        return this.state.isLoaded ? this.renderImages() : null;
    };
}

export default withFirebase(SlideShow);
