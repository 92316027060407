import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import MpsModal from '../../shared/Utils/modal';
import Utils from '../../shared/Utils';
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import Logger from '../../shared/Utils/logger';
import * as ROUTES from '../Constants';
import Request from '../../shared/Request';
import ReactMarkdown from 'react-markdown'

const AGREED = 'agreed';
const DECLINED = 'declined';

//

class OnboardingConsent extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            protocol_id: this.props.protocol_id,
            show: this.props.show ? this.props.show : false,
            data: null,
            agreed: '',
            consent: ''
        }
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/" + this.state.protocol_id,
            callbackSuccess: (result) => {
                Logger.log('Protocol data:');
                Logger.log(result);
                this.setState({
                    isLoaded: true,
                    data: result.data,
                    metadata: { protocol_business_name: result.data.protocol.creator.business_name,
                        protocol_name: result.data.protocol.name,
                        protocol_description: result.data.protocol.description,
                        protocol_supported_conditons: result.data.protocol.protocol_metadata_items.map(condition => condition.short_name).join(', '),
                        protocol_eligibility: result.data.protocol.patient_eligibility,
                        protocol_formulary: result.data.products.product_categories[0].products.map(product => product.display_name).join(', '),
                        protocol_risks_and_alternatives: result.data.protocol.risks,
                        protocol_availability: result.data.states.map(state => state.state).join(', '),
                        protocol_fee: Utils.renderDollarAmount(result.data.protocol.billing_price)
                    },
                    ...Utils.clearAllNotifications()
                });
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })

        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/legal/medical-service-agreement.md",
            callbackSuccess: (result) => {
                Logger.log('Consent data:');
                Logger.log(result);
                this.setState({
                    consent: result.content,
                    version: result.version
                });
            },
            callbackError: (error) => {
                this.setState({
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show, ...Utils.clearAllNotifications()});
        }
    };

    closeModal = () => {
        this.setState({show: false, ...Utils.clearAllNotifications()});

        this.props.callbackCancelHandler();
    };


    callback = () => {
        this.props.callbackCompleteHandler(this.isFormReady());
    };

    isFormReady = () => { return this.state.error == null && this.state.agreed == AGREED};

    submit = () => {
        this.setState({isLoading: true, ...Utils.clearAllNotifications()});
        var data = {
            consent_record: {
                metadata: JSON.stringify(this.state.metadata),
                version: this.state.version,
                protocol_id: this.state.data.protocol.id,
                agreement_name: 'medical-service-agreement.md'
            }
        }
        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/consent_records",
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify(data),
            callbackSuccess: (result) => {
                Logger.log('Consent saved!');
                this.callback();
            },
            callbackError: (error) => {
                Logger.log(error);
                this.setState({
                    error,
                    agreed: '',
                    consent: error.consent,
                    version: error.version,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    handleChange = (event) => {
        let options = { [event.target.name]: event.target.value, ...Utils.clearAllNotifications() };
        if (event.target.value == DECLINED) {
            options.error = {message: 'By declining you cannot continue with the application.'};
        }

        this.setState(options);
    };

    setupConsent = () => {
        let consent = '';
        if (this.state.metadata && this.state.consent) {
            consent = this.state.consent;
            consent = consent.replaceAll('{protocol_business_name}', this.state.metadata.protocol_business_name);
            consent = consent.replaceAll('{protocol_name}', this.state.metadata.protocol_name);
            consent = consent.replaceAll('{protocol_description}', this.state.metadata.protocol_description);
            consent = consent.replaceAll('{protocol_supported_conditons}', this.state.metadata.protocol_supported_conditons);
            consent = consent.replaceAll('{protocol_eligibility}', this.state.metadata.protocol_eligibility);
            consent = consent.replaceAll('{protocol_formulary}', this.state.metadata.protocol_formulary);
            consent = consent.replaceAll('{protocol_risks_and_alternatives}', this.state.metadata.protocol_risks_and_alternatives);
            consent = consent.replaceAll('{protocol_availability}', this.state.metadata.protocol_availability);
            consent = consent.replaceAll('{protocol_fee}', this.state.metadata.protocol_fee);
        }

        return consent;
    };

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div>
                        {Utils.renderError(this.state.error)}

                        <MpsModal
                            open={this.state.show}
                            autoFocus={false}
                            callback={this.submit}
                            callbackCancel={this.closeModal}
                            withFooter={true}
                            title={null}
                            disabled={this.state.agreed == ''}
                            body={<div>
                                <div style={{height: '400px', overflowY: 'auto'}}>
                                    <ReactMarkdown children={this.setupConsent()} />
                                </div>
                                {Utils.renderSpace()}
                                <div>
                                    <RadioGroup
                                        value={this.state.agreed}
                                        onChange={this.handleChange}
                                        name={AGREED}
                                        align={ALIGN.vertical}
                                    >
                                        <Radio value="agreed">I agree</Radio>
                                        <Radio
                                            value={DECLINED}
                                            description="By declining, you can't proceed."
                                        >I decline</Radio>
                                    </RadioGroup>
                                </div>
                            </div>}
                        />
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(OnboardingConsent);
