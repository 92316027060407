import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { HeadingXSmall, LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import MpsModal from '../../shared/Utils/modal';
import { Input } from "baseui/input";
import Request from '../../shared/Request';
import Check from 'baseui/icon/check';
import Delete from 'baseui/icon/delete';
import * as ROUTES from '../Constants';
import { Spinner, SpinnerSize } from '../../shared/Spinner';
import content from './content';
import { Textarea } from "baseui/textarea";

const buttonWidthCol = {
    maxWidth: '60px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
};

const uniqueWidthCol = {
    maxWidth: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const rightProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
};

class Blurb extends Component {
    constructor(props){
        super(props);

        this.state = {
            show_blurb_form: false,
            original_blurb: this.props.blurb ? this.props.blurb : '',
            blurb: this.props.blurb ? this.props.blurb : '',

            error: null,
            success: null,
            content: content('en'),
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.blurb !== prevProps.blurb) {
            this.setState({blurb: this.props.blurb, original_blurb: this.props.blurb});
        }
    };

    updateBlurb = () => {
        const data = new FormData();
        data.append('blurb', this.state.blurb);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/user_management/update_blurb",
            body: data,
            callbackSuccess: (result) => {
                this.setState({
                    success: result,
                    show_blurb_form: false,
                    original_blurb: this.state.blurb,
                    ...Utils.clearAllNotificationsExceptSuccess()
                });
            },
            callbackError: (error) => {
                this.setState({
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    showBlurbForm = () => {
        this.setState({
            show_blurb_form: true,
            ...Utils.clearAllNotifications()
        })
    };

    doChangeBlurb = () => {
        this.updateBlurb();
    };

    dontChangeBlurb = () => {
        this.setState({
            show_blurb_form: false,
            blurb: this.state.original_blurb,
            ...Utils.clearAllNotifications()
        });
    };

    handleChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                ...Utils.clearAllNotifications()
            }
        );
    };

    renderBlurbChangeForm = () => {
        return <MpsModal
            open={this.state.show_blurb_form}
            autoFocus={false}
            callback={this.doChangeBlurb}
            callbackCancel={this.dontChangeBlurb}
            withFooter={true}
            callbackButton={"Save"}
            callbackCancelButton={'Close'}
            title={Utils.renderTitleCenter('About yourself')}
            body={<>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.success)}

                <FlexGrid>
                    <FlexGridItem>
                        <ParagraphSmall style={{textAlign: 'center'}}>{this.state.content.blurb_description}</ParagraphSmall>
                    </FlexGridItem>
                    <FlexGridItem>
                        <FlexGrid>
                            <FlexGridItem>
                            <Textarea
                                id="blurb"
                                name="blurb"
                                value={this.state.blurb}
                                onChange={this.handleChange}
                                maxLength={this.state.content.blurb_max_length}
                                overrides={{
                                    Input: {
                                        style: {
                                            maxHeight: '300px',
                                            minHeight: '100px',
                                            minWidth: '300px',
                                            width: '100vw', // fill all available space up to parent max-width
                                            resize: 'both',
                                        },
                                    },
                                    InputContainer: {
                                        style: {
                                            maxWidth: '100%',
                                            width: 'min-content',
                                        },
                                    },
                                }}
                            />
                            </FlexGridItem>
                            <FlexGridItem>
                                <ParagraphSmall {...rightProps}>
                                    {this.state.blurb.length}/{this.state.content.blurb_max_length}
                                </ParagraphSmall>
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>
                </FlexGrid>
                </>
            }
        />
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => 
                    (
                        <>
                            <FlexGrid>
                                <FlexGridItem>
                                    <HeadingXSmall style={{margin: 0}}>About yourself</HeadingXSmall>
                                </FlexGridItem>
                                <FlexGridItem>
                                    <ParagraphSmall style={{margin: 0}}>
                                        {this.state.content.blurb_description}
                                    </ParagraphSmall>
                                </FlexGridItem>
                                <FlexGridItem>
                                    <FlexGrid flexGridColumnCount={2}>
                                        <FlexGridItem>
                                            <ParagraphMedium style={{margin: '5px 0px 0px 0px', color: (!this.state.original_blurb ? 'gray' : 'gray')}}>
                                                {this.state.original_blurb ? this.state.original_blurb : this.state.content.blurb_empty_placeholder}
                                            </ParagraphMedium>
                                        </FlexGridItem>
                                        <FlexGridItem {...buttonWidthCol}>
                                            <Button
                                                kind={KIND.secondary}
                                                shape={SHAPE.pill}
                                                size={SIZE.compact}
                                                onClick={this.showBlurbForm}
                                            >Edit</Button>
                                        </FlexGridItem>
                                    </FlexGrid>
                                </FlexGridItem>
                            </FlexGrid>

                            {this.renderBlurbChangeForm()}
                        </>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Blurb);
