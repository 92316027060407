import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Drugstore} from '../Drugstore';
import BackgroundInformation from '../Background';
import {IntakeForm, DrugRequestForm } from '../Form';
import IDVerification from '../IDVerification';
import { Link } from 'react-router-dom';
import * as ROUTES from '../Constants';
import OnboardingUpload from '../Upload/onboarding';
import DrugRequestUpload from '../Upload/drug_request';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { Button, SHAPE, SIZE, KIND } from 'baseui/button';
import Check from 'baseui/icon/check'
import Utils from '../../shared/Utils';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import Request from '../../shared/Request';
import {OnboardingConsent} from '../Consent';
import { PrescriptionRouting } from '../Pharmacy';
import Screen, {Size as ScreenSize} from '../../shared/Utils/screen';
import Favicon, {FaviconSize} from '../../shared/Favicon';

const centerProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const upperProps = {
    overrides: {
        Block: {
            style: ({$there}) => ({
                minHeight: '76px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
            })
        }
    }
}

class Tasklist extends Component{
    constructor(props){
        super(props);

        this.state = {
            mainButtonName: this.props.mainButtonName ? this.props.mainButtonName : "Continue",
            isOnboarding: this.props.isOnboarding === true, // || !this.props.match.params.protocol_id,
            isLoaded: false,
            protocol: this.props.protocol,
            protocol_id: this.props.match.params.protocol_id ? this.props.match.params.protocol_id : this.props.protocol_id,
            order_id: this.props.match.params.order_id ? this.props.match.params.order_id : this.props.order_id,
            title: this.props.match.params.protocol_id || !this.props.isOnboarding ? 'Please check in with the doctor' : this.props.title,
            description: this.props.match.params.protocol_id || !this.props.isOnboarding ? 'All uploads are encrypted.' : 'Please complete this quick application to join. All uploads are encrypted.',

            enableBackgroundInformation: this.props.match.params.enableBackgroundInformation ? this.props.match.params.enableBackgroundInformation : this.props.enableBackgroundInformation,
            showBackgroundInformation: false,
            completedBackgroundInformation: false,

            enableIntakeForm: this.props.match.params.enableIntakeForm ? this.props.match.params.enableIntakeForm : this.props.enableIntakeForm,
            showIntakeForm: false,
            completedIntakeForm: false,

            enableIDVerification: this.props.match.params.enableIDVerification ? this.props.match.params.enableIDVerification : this.props.enableIDVerification,
            showIDVerification: false,
            completedIDVerification: false,

            enableDrugstore: this.props.match.params.enableDrugstore ? this.props.match.params.enableDrugstore : this.props.enableDrugstore,
            showDrugstore: false,
            completedDrugstore: false,

            enableDrugRequestForm: this.props.match.params.enableDrugRequestForm ? this.props.match.params.enableDrugRequestForm : this.props.enableDrugRequestForm,
            showDrugRequestForm: false,
            completedDrugRequestForm: false,

            enableOnboardingFileAttach:  this.props.match.params.enableOnboardingFileAttach ? this.props.match.params.enableOnboardingFileAttach : this.props.enableOnboardingFileAttach,
            showOnboardingFileAttach: false,
            completedOnboardingFileAttach: false,

            enableDrugRequestFileAttach:  this.props.match.params.enableDrugRequestFileAttach ? this.props.match.params.enableDrugRequestFileAttach : this.props.enableDrugRequestFileAttach,
            showDrugRequestFileAttach: false,
            completedDrugRequestFileAttach: false,

            enablePrescriptionRouting:  this.props.match.params.enablePrescriptionRouting ? this.props.match.params.enablePrescriptionRouting : this.props.enablePrescriptionRouting,
            showPrescriptionRouting: false,
            completedPrescriptionRouting: false,

            enableConsent: this.props.isOnboarding === true, // || !this.props.match.params.protocol_id, // Is the patient onboarding?
            showConsent: false,
            completedConsent: false,

            protocol_tasks: this.props.protocol_tasks,
            isStandAlone: this.props.match.params.protocol_id && this.props.match.params.order_id,
            isLoading: false
        };
    }

    componentDidMount = () => {
        if (!this.state.protocol_tasks) {
            this.getProtocolTasks();
            window.scrollTo(0, 0);
        } else {
            this.setState({isLoaded: true});
        }
    };

    componentDidUpdate = () => {
            document.addEventListener('focusout', function(e) {window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })});
    };

    setDrugRequestFormIfStandAlone = () => {
        if (this.state.isStandAlone && this.state.protocol_tasks && this.state.protocol) {
            if (['active', 'active_pending_cancel'].includes(this.state.protocol.status)){
                // Drug request
                const enableDrugRequestFileAttach = this.state.protocol_tasks.find(task => task.context_type == 'drug_request' && task.task_type == 'file_attach');
                if (enableDrugRequestFileAttach) {
                    this.setState({enableDrugRequestFileAttach: true, ...Utils.clearAllNotifications()});
                }
            }
        }
    };

    getProtocolTasks = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/protocol_tasks/" + this.state.protocol_id,
            callbackSuccess: (result) => this.setState({
                protocol_tasks: result.protocol_tasks,
                protocol: result.protocol,
                isLoaded: true,
                ...Utils.clearAllNotifications()
            }, () => this.setDrugRequestFormIfStandAlone()),
            callbackError: (error) => this.setState({error, isLoaded: true, ...Utils.clearAllNotificationsExceptError()})
        })
    };

    ///////////////////////////////////////////////////////////////////////
    // Background Info
    ///////////////////////////////////////////////////////////////////////
    presentBackgroundInformation = () => {
        if (this.state.enableBackgroundInformation) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isBackgroundInformationCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isBackgroundInformationCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showBackgroundInformation}>Patient profile</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteBackgroundInfo = (completed) => {
        this.setState({completedBackgroundInformation: completed, showBackgroundInformation: false});
        window.scrollTo(0, 0);
    };
    callbackHandlerForCancelBackgroundInfo = () => {
        this.setState({showBackgroundInformation: false});
        window.scrollTo(0, 0);
    };

    showBackgroundInformation = () => { this.setState({showBackgroundInformation: true}); };
    isBackgroundInformationCompleted = () => {return this.state.completedBackgroundInformation};

    ///////////////////////////////////////////////////////////////////////
    // Onboarding Form
    ///////////////////////////////////////////////////////////////////////
    presentIntakeForm = () => {
        if (this.state.enableIntakeForm) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isFormCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isFormCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showIntakeForm}>Medical questionnaire</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteIntakeForm = (completed) => {
        this.setState({completedIntakeForm: completed, showIntakeForm: false});
        window.scrollTo(0, 0);
    };
    callbackHandlerForCancelIntakeForm = () => {
        this.setState({showIntakeForm: false});
        window.scrollTo(0, 0);
    };

    showIntakeForm = () => { this.setState({showIntakeForm: true}); };
    isFormCompleted = () => {return this.state.completedIntakeForm};

    /////////////////////////////////////////////////////////////////////////////////
    // ID Verification
    /////////////////////////////////////////////////////////////////////////////////
    presentIDVerification = () => {
        if (this.state.enableIDVerification) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isIDVerificationCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isIDVerificationCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showIDVerification}>Verify identity</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteIDVerification = (completed) => {
        this.setState({completedIDVerification: completed, showIDVerification: false});
        window.scrollTo(0, 0);
    };
    callbackHandlerForCancelIDVerification = () => {
        this.setState({showIDVerification: false});
        window.scrollTo(0, 0);
    };

    showIDVerification = () => { this.setState({showIDVerification: true}); };
    isIDVerificationCompleted = () => {return this.state.completedIDVerification};

    /////////////////////////////////////////////////////////////////////////////////
    // Drug Store
    /////////////////////////////////////////////////////////////////////////////////
    presentDrugstore = () => {
        if (this.state.enableDrugstore) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isDrugstoreCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isDrugstoreCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showDrugstore}>Prescription request</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteDrugstore = (completed) => {
        this.setState({completedDrugstore: completed, showDrugstore: false});
        window.scrollTo(0, 1000);
    };
    callbackHandlerForCancelDrugstore = () => {
        this.setState({showDrugstore: false});
        window.scrollTo(0, 1000);
    };

    showDrugstore = () => { this.setState({showDrugstore: true}); };
    isDrugstoreCompleted = () => {return this.state.completedDrugstore};

    /////////////////////////////////////////////////////////////////////////////////
    // Drug Request Form
    /////////////////////////////////////////////////////////////////////////////////
    presentDrugRequestForm = () => {
        if (this.state.enableDrugRequestForm) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isDrugRequestFormCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isDrugRequestFormCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showDrugRequestForm}>Check-in questionnaire</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteDrugRequestForm = (completed) => {
        this.setState({completedDrugRequestForm: completed, showDrugRequestForm: false});
        window.scrollTo(0, 0);
    };
    callbackHandlerForCancelDrugRequestForm = () => {
        this.setState({showDrugRequestForm: false});
        window.scrollTo(0, 0);
    };

    showDrugRequestForm = () => { this.setState({showDrugRequestForm: true}); };
    isDrugRequestFormCompleted = () => {return this.state.completedDrugRequestForm};

    /////////////////////////////////////////////////////////////////////////////////
    // Onboarding File Attachment
    /////////////////////////////////////////////////////////////////////////////////
    presentOnboardingFileAttach = () => {
        if (this.state.enableOnboardingFileAttach) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isOnboardingFileAttachCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isOnboardingFileAttachCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showOnboardingFileAttach}>Uploads</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteOnboardingFileAttach = (completed) => {
        this.setState({completedOnboardingFileAttach: completed, showOnboardingFileAttach: false})
    };
    callbackHandlerForCancelOnboardingFileAttach = () => {
        this.setState({showOnboardingFileAttach: false})
    };

    showOnboardingFileAttach = () => {this.setState({showOnboardingFileAttach: true}); };
    isOnboardingFileAttachCompleted = () => {return this.state.completedOnboardingFileAttach};
    getOnboardingFileAttachData = () => {
        if (this.state.protocol_tasks && this.state.protocol_tasks.length > 0) {
            const result = this.state.protocol_tasks.filter(task => task.context_type === 'onboarding' && task.task_type === 'file_attach');
            if (result.length > 0) {
                return result[0];
            }
        }

        return {
            task_description: ''
        }
    };
    /////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////
    // Drug Request File Attachment
    /////////////////////////////////////////////////////////////////////////////////
    presentDrugRequestFileAttach = () => {
        if (this.state.enableDrugRequestFileAttach) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isDrugRequestFileAttachCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isDrugRequestFileAttachCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showDrugRequestFileAttach}>Uploads</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteDrugRequestFileAttach = (completed) => {
        this.setState({completedDrugRequestFileAttach: completed, showDrugRequestFileAttach: false})
    };
    callbackHandlerForCancelDrugRequestFileAttach = () => {
        this.setState({showDrugRequestFileAttach: false})
    };

    showDrugRequestFileAttach = () => {this.setState({showDrugRequestFileAttach: true}); };
    isDrugRequestFileAttachCompleted = () => {return this.state.completedDrugRequestFileAttach};
    getDrugRequestFileAttachData = () => {
        if(this.state.protocol_tasks && this.state.protocol_tasks.length > 0) {
            const result = this.state.protocol_tasks.filter(task => task.context_type === 'drug_request' && task.task_type === 'file_attach');
            if (result.length > 0) {
                return result[0];
            }
        }

        return {
            task_description: ''
        }
    };
    /////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////
    // Prescription Routing
    /////////////////////////////////////////////////////////////////////////////////
    presentPrescriptionRouting = () => {
        if (this.state.enablePrescriptionRouting) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isPrescriptionRoutingCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isPrescriptionRoutingCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showPrescriptionRouting}>Pharmacy preference</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompletePrescriptionRouting = (completed) => {
        this.setState({completedPrescriptionRouting: completed, showPrescriptionRouting: false})
    };
    callbackHandlerForCancelPrescriptionRouting = () => {
        this.setState({showPrescriptionRouting: false})
    };

    showPrescriptionRouting = () => {this.setState({showPrescriptionRouting: true}); };
    isPrescriptionRoutingCompleted = () => {return this.state.completedPrescriptionRouting};
    /////////////////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////
    // Consent
    /////////////////////////////////////////////////////////////////////////////////
    presentConsent = () => {
        if (this.state.enableConsent) {
            return <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.large}
                    shape={SHAPE.pill}
                    overrides={this.isConsentCompleted() ? {
                        Root: {
                            style: ({$theme}) => ({
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#1F1F1F' :'#CBCBCB'
                            }),
                        },
                    } : {
                        Root: {
                            style: {
                                width: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            },
                        },
                    }}
                    endEnhancer={() => this.isConsentCompleted() ? <Check size={24} color="#22b573" /> : null}
                    onClick={this.showConsent}>Services agreement</Button>
            </FlexGridItem>
        } else {
            return '';
        }
    };

    callbackHandlerForCompleteConsent = (completed) => {
        this.setState({completedConsent: completed, showConsent: false})
    };
    callbackHandlerForCancelConsent = () => {
        this.setState({showConsent: false})
    };

    showConsent = () => {this.setState({showConsent: true}); };
    isConsentCompleted = () => {return this.state.completedConsent};
    /////////////////////////////////////////////////////////////////////////////////

    canContinue = () => {
        // Only check the ones that enabled.
        return ((this.state.enableBackgroundInformation && this.state.completedBackgroundInformation) || (!this.state.enableBackgroundInformation))
            && ((this.state.enableIntakeForm && this.state.completedIntakeForm) || (!this.state.enableIntakeForm))
            && ((this.state.enableIDVerification && this.state.completedIDVerification) || (!this.state.enableIntakeForm))
            && ((this.state.enableDrugstore && this.state.completedDrugstore) || (!this.state.enableDrugstore))
            && ((this.state.enableDrugRequestForm && this.state.completedDrugRequestForm) || (!this.state.enableDrugRequestForm))
            && ((this.state.enableOnboardingFileAttach && this.state.completedOnboardingFileAttach) || (!this.state.enableOnboardingFileAttach))
            && ((this.state.enableDrugRequestFileAttach && this.state.completedDrugRequestFileAttach) || (!this.state.enableDrugRequestFileAttach))
            && ((this.state.enablePrescriptionRouting && this.state.completedPrescriptionRouting) || (!this.state.enablePrescriptionRouting))
            && ((this.state.enableConsent && this.state.completedConsent) || (!this.state.enableConsent));
    };

    renderFavIcon = () => {
        if (this.state.protocol){
        return <Favicon
            favicon_url={this.state.protocol.favicon_url}
            size={FaviconSize.MEDIUM}
            //badge_type={this.state.protocol.badge_type}
            //status={this.state.protocol.status}
            //rejecting_new_subscriptions={this.state.protocol.rejecting_new_subscriptions}
        />}
    };

    showTaskList = () => {
        return <div>
            <FlexGrid>
            {this.props.isOnboarding ? <FlexGridItem {...upperProps}>{this.renderFavIcon()}</FlexGridItem> : ''}
            <FlexGridItem {...centerProps}><HeadingSmall style={{margin:'4px 0px 6px 0px', textAlign: 'center'}}>{this.state.title}</HeadingSmall></FlexGridItem>
            <FlexGridItem {...centerProps}><ParagraphMedium style={{margin: '0px 0px 16px 0px', textAlign: 'center'}}>{this.state.description}</ParagraphMedium></FlexGridItem>
            </FlexGrid>
            <FlexGrid flexGridColumnCount={1} flexGridRowGap={'scale300'} justifyContent="center">
                {this.presentBackgroundInformation()}
                {this.presentIntakeForm()}
                {this.presentDrugRequestForm()}
                {this.presentOnboardingFileAttach()}
                {this.presentDrugRequestFileAttach()}
                {this.presentIDVerification()}
                {this.presentDrugstore()}
                {this.presentConsent()}
                {this.presentPrescriptionRouting()}
                <FlexGridItem {...centerProps}>
                    {this.canContinue()
                    ?
                        (this.state.isOnboarding ?
                            <Button
                                onClick={() => this.setState({isLoading: true})}
                                isLoading={this.state.isLoading}
                                kind={KIND.primary}
                                size={SIZE.default}
                                shape={SHAPE.pill}
                                $as="a"
                                href={ROUTES.SHIPPING + '/' + this.state.protocol_id + '/' + this.state.order_id}
                            >{this.state.mainButtonName}</Button> : <Button
                                onClick={() => this.props.canContinue()}
                                isLoading={this.state.isLoading}
                                kind={KIND.primary}
                                size={SIZE.default}
                                shape={SHAPE.pill}
                            >{this.state.mainButtonName}</Button>
                        )
                    :
                        <Button
                            disabled={true}
                            kind={KIND.secondary}
                            size={SIZE.default}
                            shape={SHAPE.pill}
                        >{this.state.mainButtonName}</Button>
                    }
                </FlexGridItem>
            </FlexGrid>
        </div>
    };

    render = () => {
        var onboardingFileAttachData = this.getOnboardingFileAttachData();
        var drugRequestFileAttachData = this.getDrugRequestFileAttachData();
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                    <div style={{marginTop: '98px', marginBottom:'40px'}}>
                        {Utils.renderError(this.state.error)}
                        {Utils.renderSuccess(this.state.message)}
                        {this.state.linkToSubscriptions && <div><Link to={ROUTES.MANAGE_SUBSCRIPTION}>Subscriptions</Link></div>}
                        {this.state.linkToHome && <div><Link to={ROUTES.HOME}>Subscriptions</Link></div>}
                        {this.showTaskList()}

                        {this.state.enableBackgroundInformation ? <BackgroundInformation callbackCompleteHandler={this.callbackHandlerForCompleteBackgroundInfo} callbackCancelHandler={this.callbackHandlerForCancelBackgroundInfo} show={this.state.showBackgroundInformation} /> : ''}
                        {this.state.enableIntakeForm ? <IntakeForm order_id={this.state.order_id} protocol_id={this.state.protocol_id} callbackCompleteHandler={this.callbackHandlerForCompleteIntakeForm} callbackCancelHandler={this.callbackHandlerForCancelIntakeForm} show={this.state.showIntakeForm}/> : ''}
                        {this.state.enableIDVerification ? <IDVerification callbackCompleteHandler={this.callbackHandlerForCompleteIDVerification} callbackCancelHandler={this.callbackHandlerForCancelIDVerification} show={this.state.showIDVerification}/> : ''}
                        {this.state.showDrugstore ? <Drugstore protocol_id={this.state.protocol_id} showModal={this.state.showDrugstore}  callbackCompleteHandler={this.callbackHandlerForCompleteDrugstore} callbackCancelHandler={this.callbackHandlerForCancelDrugstore}/> : ''}
                        {this.state.enableDrugRequestForm ? <DrugRequestForm order_id={this.state.order_id} show={this.state.showDrugRequestForm} protocol_id={this.state.protocol_id} callbackCompleteHandler={this.callbackHandlerForCompleteDrugRequestForm} callbackCancelHandler={this.callbackHandlerForCancelDrugRequestForm}/> : ''}
                        {this.state.enableOnboardingFileAttach ? <OnboardingUpload order_id={this.state.order_id} protocol_id={this.state.protocol_id} task={onboardingFileAttachData} callbackCompleteHandler={this.callbackHandlerForCompleteOnboardingFileAttach} callbackCancelHandler={this.callbackHandlerForCancelOnboardingFileAttach} show={this.state.showOnboardingFileAttach}/> : ''}
                        {this.state.enableDrugRequestFileAttach ? <DrugRequestUpload order_id={this.state.order_id} protocol_id={this.state.protocol_id} task={drugRequestFileAttachData} callbackCompleteHandler={this.callbackHandlerForCompleteDrugRequestFileAttach} callbackCancelHandler={this.callbackHandlerForCancelDrugRequestFileAttach} show={this.state.showDrugRequestFileAttach}/> : ''}
                        {this.state.enablePrescriptionRouting ? <PrescriptionRouting order_id={this.state.order_id} protocol_id={this.state.protocol_id} callbackCompleteHandler={this.callbackHandlerForCompletePrescriptionRouting} callbackCancelHandler={this.callbackHandlerForCancelPrescriptionRouting} show={this.state.showPrescriptionRouting}/> : ''}
                        {this.state.enableConsent ? <OnboardingConsent protocol_id={this.state.protocol_id} callbackCompleteHandler={this.callbackHandlerForCompleteConsent} callbackCancelHandler={this.callbackHandlerForCancelConsent} show={this.state.showConsent}/> : ''}
                    </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Tasklist);
