import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import TaskList from '../Tasklist';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { HeadingMedium} from 'baseui/typography';
import { Button, SHAPE, SIZE, KIND } from 'baseui/button';
import Cookie from '../../shared/Utils/cookie';
import { Input } from "baseui/input";

const centerProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class ProtocolSubscribe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inviteCodeAccepted: false,
            inviteCode: "",
            isLoaded: false,
            error: null,
            order_id: null,
            protocol: null,
            info: null,
            linkToSubscriptions: false,
            linkToHome: false,
            protocol_id: this.props.match.params.id,
            action: this.props.match.params.action
        };
    }

    subscribeMe = () => {
        const data = new FormData();

        data.append('number', this.state.protocol_id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_onboarding/subscribe",
            body: data,
            callbackSuccess: (res) => {
                if (res.status == "pending") {
                    this.props.history.push(ROUTES.SUBSCRIPTION_STATUS + '/' + this.state.protocol_id);
                    return;
                } else if (res.status == 'reapply' && this.state.action != 'reapply') {
                    this.props.history.push(ROUTES.SUBSCRIPTION_STATUS + '/' + this.state.protocol_id);
                    return;
                } else if (res.status == 'active' || res.status == 'active_pending_cancel') {
                    this.props.history.push(ROUTES.PROTOCOL_HOME + '/' + this.state.protocol_id );
                    return;
                } else if (res.status == 'inactive') {
                    // TODO: Redirect to protocol status page.
                    // this.props.history.push(ROUTES.SUBSCRIPTIONS);
                    this.setState({
                        isLoaded: true,
                        linkToSubscriptions: true,
                        info: {message: 'You were subscribed to this clinic already but deactivated it. Please reactivate the clinic in the Subscriptions page.'},
                        ...Utils.clearAllNotificationsExceptInfo()
                    })
                } else if (res.status == 'dead') {
                    this.props.history.push(ROUTES.SUBSCRIPTION_STATUS + '/' + this.state.protocol_id);
                } else {
                    Logger.log('Setting the order ID...');
                    Logger.log(res);
                    this.setState({order_id: res.order_id, ...Utils.clearAllNotifications()});
                    this.getProtocol();
                }
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        });
    };

    getProtocol = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/" + this.state.protocol_id,
            callbackSuccess: (result) => this.setState(
                {isLoaded: true, protocol: result.data.protocol, inviteCodeAccepted: !result.data.protocol.invite_code_required, ...Utils.clearAllNotifications()}
            ),
            callbackError: (error) => this.setState(
                {isLoaded: true, error, ...Utils.clearAllNotificationsExceptError()}
            )
        })
    };

    invitationCodeHandler = (event) => {
        this.setState({inviteCode: event.target.value.toLowerCase(), ...Utils.clearAllNotifications()});
    };

    disableInvitationButton = () => {
        return this.state.inviteCode.length < 1;
    };

    renderInviteTitle = () => {
        return <HeadingMedium style={{margin: 0}}>{this.state.protocol.name} is a private clinic</HeadingMedium>
    };

    submitInvitationCode = () => {
      const data = new FormData();
      data.append('invitation_code', this.state.inviteCode);
      data.append('protocol_number', this.state.protocol_id);
      Request.post({
        firebase: this.props.firebase,
        route: ROUTES.BASE_URL_API + "/invitation",
        body: data,
        callbackSuccess: (result) => {
          Cookie.setCookie("invite_code_for_"+this.state.protocol_id, this.state.inviteCode, 1)
          this.setState({inviteCodeAccepted: true, message: result, ...Utils.clearAllNotificationsExceptSuccess()})
        },
        callbackError: (error) => this.setState(
          {inviteCodeAccepted: false, error, ...Utils.clearAllNotificationsExceptError()}
        )});
    }

    renderInvitationForm = () => {
      return <div className="center_page">
        <FlexGrid flexGridColumnCount={1} margin={'auto'} flexGridRowGap="scale600">
        <FlexGridItem {...centerProps} style={{textAlign: 'center', marginLeft:'16px', marginRight:'16px'}}>{this.renderInviteTitle()}</FlexGridItem>
        <FlexGridItem {...centerProps}>
        {Utils.renderError(this.state.error)}
        <Input
          value={this.state.inviteCode}
          onChange={this.invitationCodeHandler}
          placeholder="Enter code to join"
          clearOnEscape
          autoFocus
          onBlur={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }}
          overrides={{
            Root:{
              style: ({ $theme }) => ({
                maxWidth: '350px',
                marginLeft:'16px',
                marginRight:'16px'
              })
            },
            Input: {
                style: {
                textTransform: 'lowercase'
                },
            }
          }}
        />
        </FlexGridItem>
        <FlexGridItem {...centerProps}><Button kind={KIND.primary} shape={SHAPE.pill} disabled={this.disableInvitationButton()} onClick={this.submitInvitationCode}>Enter</Button></FlexGridItem>
        </FlexGrid>
        </div>
    };


    // NOTE Only the last request response in the chain of requests should set isLoaded to true.
    componentDidMount = () => {
        this.subscribeMe();
        window.scrollTo(0, 0);
    };

    title = () => {
        if (this.state.protocol) {
            return "Join " + this.state.protocol.name;
        }
    };

    isOnboardingFileAttachEnabled = () => {
        const fileAttach = (element) => element.task_type === 'file_attach' && element.context_type === 'onboarding';
        return this.state.protocol && this.state.protocol.protocol_tasks.some(fileAttach);
    }

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (   this.state.isLoaded ? (
                          this.state.inviteCodeAccepted ?
                            <div>
                                {Utils.renderError(this.state.error)}
                                {Utils.renderInfo(this.state.info)}
                                {this.state.linkToSubscriptions && <div><Link to={ROUTES.MANAGE_SUBSCRIPTION}>Subscriptions</Link></div>}
                                {this.state.linkToHome && <div><Link to={ROUTES.HOME}>Subscriptions</Link></div>}

                                {this.state.isLoaded && !this.state.error
                                ?
                                <TaskList
                                    isOnboarding={true}
                                    order_id={this.state.order_id}
                                    title={this.title()}
                                    protocol_id={this.state.protocol_id}
                                    protocol={this.state.protocol}
                                    protocol_tasks={this.state.protocol ? this.state.protocol.protocol_tasks : null}
                                    enableBackgroundInformation={true}
                                    enableIntakeForm={true}
                                    enableIDVerification={true}
                                    enableDrugstore={this.state.protocol ? this.state.protocol.is_refill_service : false
                                    }
                                    enableOnboardingFileAttach={this.isOnboardingFileAttachEnabled()}
                                    enableDrugRequestFileAttach={false}
                                />
                                : this.state.error ? '' : <Spinner style={{position:'absolute', top: '50%', left: '50%'}}/>}
                            </div>
                          : <div>
                              {this.renderInvitationForm()}
                            </div> )
                          : <Spinner />
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}


const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ProtocolSubscribe);
