import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants';
import {SignUpLink} from '../SignUp';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { Input } from "baseui/input";
import Utils from '../Utils';
import MFA from '../MFA';
import MFACodeVerification from '../MFA/code_verification';
import { ParagraphMedium } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { FormControl } from "baseui/form-control";
import { StyledLink } from "baseui/link";
import Logger from '../Utils/logger';
import ReportIssue from '../../shared/ReportIssue';
import { Avatar } from "baseui/avatar";

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

const SignInPage = () => (
    <div>
        <SignInForm />
    </div>
);

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        let defaultState = {
            message: null,
            error: null,

            email: '',
            password: '',

            showReportIssue: false,
        
            verify_login_code: false,
            verify_email_code: false,
            resolver: null,
            redirect: '',
            isLoading: false
        };

        this.state = { ...defaultState};

        this.callbackError = this.callbackError.bind(this);
        this.mfa = new MFA(this.props.firebase, this.callbackError);
    }

    componentDidMount = () => {
        if (this.props.location.state && this.props.location.state.message) {
            this.state.message = {message: this.props.location.state.message};
            window.history.replaceState({}, '');
        }
        window.scrollTo(0, 0);
        if (this.props.firebase && this.props.firebase.currentUser() && !this.props.firebase.isEmailVerified()) {
          this.setState({redirect: ROUTES.SIGN_UP, ...Utils.clearAllNotifications()});
        } else if (this.props.firebase && this.props.firebase.currentUser() && !this.props.firebase.isUserMFAEnrolled()) {
          this.setState({redirect: ROUTES.MFA_ENROLL, ...Utils.clearAllNotifications()});
        } else if (this.props.firebase && this.props.firebase.currentUser() && this.props.firebase.isEmailVerified() && this.props.firebase.isUserMFAEnrolled()) {
          this.setState({redirect: ROUTES.HOME, ...Utils.clearAllNotifications()});
        }
    };

    callbackError = (error) => {
        this.setState({error, ...Utils.clearAllNotificationsExceptError()});
    };

    onSubmit = (event) => {
        event.preventDefault();
        /*document.addEventListener('focusout', function(e) {window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })});*/
        this.setState({isLoading: true});

        this.mfa.getFirebase()
            .signInWithPassword(this.state.email, this.state.password)
            .then((userCreds) => {
                if (this.mfa.isEmailVerified()){
                    if (!this.mfa.isUserEnrolled()) {
                        this.setState({redirect: ROUTES.MFA_ENROLL, ...Utils.clearAllNotifications()});
                    }
                } else {
                    this.mfa.sendEmailVerification()
                    .then(sent => this.setState({
                        error: {message: 'Email has not been verified yet. A new email verification has been sent.'},
                        verify_email_code: true,
                        isLoading: false,
                        ...Utils.clearAllNotificationsExceptError()
                    }))
                    .catch(error => this.setState({
                        isLoading: false,
                        error: {message: 'Email has not been verified yet. However, failed to send an email verification code. Please contact support for more help.'},
                        ...Utils.clearAllNotificationsExceptError()
                    }))
                }
            })
            .catch((error) => {
                if (error.code == 'auth/multi-factor-auth-required'){
                    this.mfa.setResolver(error.resolver);
                    this.mfa.login(this.loginSuccessCallback);
                } else {
                    this.setState({ error, isLoading: false, ...Utils.clearAllNotificationsExceptError() });
                }
            });
    };

    loginSuccessCallback = () => {
        this.setState({verify_login_code: true, isLoading: false, ...Utils.clearAllNotifications()});
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications() });
    };

    onChangeEmail = (event) => {
        this.setState({ [event.target.name]: event.target.value.toLowerCase(), ...Utils.clearAllNotifications() });
    };

    shouldSubmitBeDisabled = () => {
        return this.state.email === '' || this.state.password === '';
    };

    signinTitle = () => {
        if (this.props.firebase.isPatient()) {
            return <span><Avatar
            name="Patient sign-in"
            size="scale650"
            src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1693950876/patient-dot_i1nk7d.png"
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    margin: '0 6px 0 0'
                  })
                }
              }}
            />Sign in to Clinic.dev</span>
        } else {
            return <span><Avatar
            name="Patient sign-in"
            size="scale650"
            src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1693950876/provider-dot_cmvjcv.png"
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    margin: '0 6px 0 0'
                  })
                }
              }}
            />Provider sign in to Clinic.dev</span>
        }
    };

    renderSignInForm = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600" maxWidth="350px">
            <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginTop: 0,
                                marginBottom: 0
                            })
                        },
                        Label: {
                            style: ({$theme}) => ({
                                marginTop: 0
                            })
                        }
                    }}
                    label={() => "Email address"}>
                    <Input
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        onBlur={() => {
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        overrides={{
                            Input: {
                                style: {
                                textTransform: 'lowercase'
                                },
                            }
                          }}
                    />
                </FormControl>
            </FlexGridItem>
            <FlexGridItem>
                <FormControl
                    overrides={{
                        ControlContainer:{
                            style: ({ $theme }) => ({
                                marginTop: 0,
                                marginBottom: 0
                            })
                        },
                        Label: {
                            style: ({$theme}) => ({
                                marginTop: 0
                            })
                        }
                    }}
                    label={() => "Password"}
                    caption={() => this.renderForgotPassword()}>
                    <Input
                        name="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        onBlur={() => {
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        type="password"
                        //overrides={{
                           // MaskToggleButton: () => null,
                        //}}
                    />
                </FormControl>
            </FlexGridItem>
            <FlexGridItem {...centerProps}>
                <Button kind={KIND.primary} shape={SHAPE.pill} isLoading={this.state.isLoading} disabled={this.shouldSubmitBeDisabled()} onClick={this.onSubmit}>Sign In</Button>
            </FlexGridItem>
            
            <ReportIssue show={this.state.showReportIssue} callback={this.closeReportIssue}/>
        </FlexGrid>
    };

    renderForms = () => {
        if (this.state.verify_login_code) {
            return <MFACodeVerification type='login' mfa={this.mfa} phone_number={null}/>
        } else if (this.state.verify_email_code){
            return <MFACodeVerification type='email' mfa={this.mfa} phone_number={null}/>
        } else {
            return <div>{Utils.renderTitleCenter(this.signinTitle())}<FlexGrid><FlexGridItem {...centerProps}>{this.renderSignInForm()}</FlexGridItem></FlexGrid> {Utils.renderSpace()} <div style={{textAlign: 'center'}}><SignUpLink /><FlexGrid><FlexGridItem {...centerProps}><ParagraphMedium>Trouble signing in? <StyledLink href={"#"} onClick={this.showReportIssue} style={{color:"#00a1ff"}}>Click here</StyledLink></ParagraphMedium></FlexGridItem></FlexGrid></div></div>
        }
    };

    renderRecaptchaContainer = () => {
        return <div id='recaptcha'></div>;
    };

    renderForgotPassword = () => {
        return <StyledLink href={ROUTES.PASSWORD_RESET}>Forgot password?</StyledLink>
    };

    componentDidUpdate = (prevProps, prevState) => {
      if (this.state.redirect && this.state.redirect !== prevState.redirect) {
        this.props.history.push(this.state.redirect);
      }
    };

    showReportIssue = () => {
        this.setState({showReportIssue: true});
    };

    closeReportIssue = () => {
        this.setState({showReportIssue: false});
        this.props.closeCallback();
    };

    render() {
        if (this.state.redirect) {
            return null;
        }

        return (
            <div>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.message)}
                <div className="center_page">
                    {this.renderForms()}
                </div>
                {this.renderRecaptchaContainer()}
            </div>
        );
    }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

const SignInLink = () => <ParagraphMedium style={{margin: 0}}>Already have an account? <StyledLink href={ROUTES.SIGN_IN} style={{color:"#00a1ff"}}>Sign In</StyledLink></ParagraphMedium>                      

const SignInButton = () => <Button $as={"a"} href={ROUTES.SIGN_IN} kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} style={{width: '100%'}}>Sign in</Button>

export default SignInPage;

export { SignInForm, SignInLink, SignInButton };
