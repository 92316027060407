import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import MpsModal from '../../shared/Utils/modal';
import * as ROUTES from  '../Constants';
import Utils from '../../shared/Utils';
import {Spinner, SIZE as SpinnerSize} from 'baseui/spinner';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Button, SIZE, SHAPE, KIND } from 'baseui/button';
import Request from '../../shared/Request';
import { StyledLink } from 'baseui/link';
import { Check } from 'baseui/icon';
import { ParagraphMedium } from 'baseui/typography';
import {Input} from 'baseui/input';
import Logger from '../../shared/Utils/logger';

const checkedItemProps = {
    maxWidth: '48px',
    minHeight: '40px',
    alignItems: 'center',
    justifyContent: 'flex-end',
};

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class PrescriptionRouting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            order_id: this.props.match.params.order_id ? this.props.match.params.order_id : this.props.order_id,
            order: null,

            message: null,
            error: null,
            currentPatientPharmaciesLoaded: false,
            currentPatientPharmacies: null,
            associatingPharmacy: false,
            selected_pharmacy_id: null,
            pharmacy: null,
            pharmacy_search_value: '',
            show_pharmacy_form: false,
            pharmacy_searching: false,
            pharmacy_search_results: [],
            pharmacy_search_results_loaded: true,
            
        }
    }

    componentDidMount = () => {
        this.getOrder();
        this.getPatientPharmacies();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    };

    getOrder = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_order/" + this.state.order_id,
            callbackSuccess: (result) => this.setState({
                order: result,
                selected_pharmacy_id: result.fomo_pharmacy_id ? result.fomo_pharmacy_id : this.state.selected_fomo,
                ...Utils.clearAllNotifications()
            }, () => this.completeTaskList()),
            callbackError: (error) => this.setState({
                order: {},
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getPatientPharmacies = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/pharmacies",
            callbackSuccess: (result) => this.setState({
                currentPatientPharmaciesLoaded: true,
                currentPatientPharmacies: result.reverse()
            }),
            callbackError: (error) => this.setState({
                currentPatientPharmaciesLoaded: true,
                currentPatientPharmacies: [],
                error
            })
        })
    };

    completeTaskList = () => {
        if (this.props.callbackCompleteHandler && this.state.selected_pharmacy_id && this.state.selected_pharmacy_id > 0) {
            this.props.callbackCompleteHandler(true);
        }
    };

    cancelTaskList = () => {
        if (this.props.callbackCancelHandler) {
            this.props.callbackCancelHandler();
        }
    };

    setSelectedPharmacy = (id, event) => {
        event.preventDefault();

        if (id != this.state.selected_pharmacy_id) {
            this.setState({selected_pharmacy_id: id, ...Utils.clearAllNotifications()}, () => this.associatePharmacyToOrder());
        }
    };

    listPatientPharmacies = () => {
        if(this.state.currentPatientPharmacies === null) {
            return <Spinner />
        }

        return <FlexGrid
            flexGridColumnCount={1}
            flexGridRowGap={'scale800'}
            border={'1px solid red'}
        >
            {
                this.state.currentPatientPharmacies.map((pharmacy, idx) => <FlexGridItem key={idx}>
                    <StyledLink
                        href='#'
                        onClick={(event) => this.setSelectedPharmacy(pharmacy.id, event)}
                        className="a_no_line">
                            <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'} border={'1px solid yellow'}>
                                <FlexGridItem>{pharmacy.name} · {Utils.renderAddress(pharmacy)} · {Utils.renderPhone(pharmacy.phone)}</FlexGridItem>
                                <FlexGridItem {...checkedItemProps}>{pharmacy.id == this.state.selected_pharmacy_id
                                ? <Button kind={KIND.tertiary} shape={SHAPE.circle} size={SIZE.compact}><Check size={32} color="#22b573"/></Button> : ''}</FlexGridItem>
                            </FlexGrid>
                    </StyledLink>
                </FlexGridItem>
                )
            }
        </FlexGrid>
    };

    searchForPharmacies = () => {
        this.setState({pharmacy_searching: true, ...Utils.clearAllNotifications()})

        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/pharmacies/search/" + this.state.pharmacy_search_value,
            callbackSuccess: (result) => this.setState({
                pharmacy_search_results: result,
                pharmacy_search_results_loaded: true,
                pharmacy_searching: false,
                ...Utils.clearAllNotifications()
            }),
            callbackError: (error) => this.setState({
                error,
                pharmacy_search_results_loaded: true,
                pharmacy_searching: false,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    togglePharmacyForm = () => {
        this.setState({show_pharmacy_form: true, ...Utils.clearAllNotifications()});
    };

    showNewPharmacyButton = () => {
        return <div><Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={this.togglePharmacyForm}>Add new pharmacy</Button></div>
    };

    savePharmacyToCloud = () => {
        if (this.state.pharmacy) {
            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/pharmacies",
                headers: {"Content-type" : 'application/json'},
                body: JSON.stringify({
                    fomo_pharmacy: this.state.pharmacy,
                    order_number: this.state.order_id
                }),
                callbackSuccess: (result) => 
                    this.setState(prevState => ({
                        isLoaded: true,
                        submittingPharmacy: false,
                        success: !this.state.error ? {message: 'Settings saved successfully.'} : null,
                        show_pharmacy_form: false,
                        selected_pharmacy_id: result.fomo_pharmacy.id,
                        pharmacy_search_results: [],
                        pharmacy_search_value: '',
                        pharmacy_search_results_loaded: false,
                        pharmacy_searching: false,
        
                        currentPatientPharmacies: [result.fomo_pharmacy, ...prevState.currentPatientPharmacies]
                    })),
                callbackError: (error) => this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                })
            })
        }
    };

    closePharmacyForm = () => {
        this.setState({
            show_pharmacy_form: false,
            submittingPharmacy: false,
            pharmacy_search_results_loaded: false,
            pharmacy_searching: false,
            pharmacy_search_value: '',
            pharmacy_search_results: [],
            ...Utils.clearAllNotifications()
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications()});
    };

    showNewPharmacyForm = () => {
        return this.state.show_pharmacy_form ? <MpsModal
            open={this.state.show_pharmacy_form}
            autoFocus={false}
            callback={this.savePharmacyToCloud}
            callbackCancel={this.closePharmacyForm}
            isLoading={this.state.submittingPharmacy}
            withFooter={true}
            title={Utils.renderTitleCenter('Pharmacy search')}
            body={<div>
                    {Utils.renderError(this.state.error)}
                    <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale800" flexGridRowGap="scale400">
                        <FlexGridItem {...itemProps}><Input
                            type="number"
                            inputMode="numeric"
                            id="pharmacy_search_value"
                            name="pharmacy_search_value"
                            value={this.state.pharmacy_search_value}
                            onChange={this.handleChange}
                            placeholder="Find by zip code"/>
                        </FlexGridItem>
                        <FlexGridItem {...itemProps}>
                            <Button kind={KIND.secondary} shape={SHAPE.pill} onClick={this.searchForPharmacies}>Search</Button></FlexGridItem>
                    </FlexGrid>
                    {Utils.renderSpace()}
                    {Utils.renderSpace()}
                    {this.state.pharmacy_searching
                    ? <FlexGrid flexGridColumnCount={1}><FlexGridItem {...itemProps}><Spinner $size={SpinnerSize.large} /></FlexGridItem></FlexGrid>
                    : Array.isArray(this.state.pharmacy_search_results) && this.state.pharmacy_search_results.length > 0
                        ? <div>
                            <FlexGrid
                                flexGridColumnCount={1}
                                flexGridRowGap={'scale800'}
                            >
                                {
                                    this.state.pharmacy_search_results.map((pharmacy, idx) => <FlexGridItem key={idx}>
                                        <StyledLink
                                            href='#'
                                            onClick={(event) => this.setState({pharmacy: pharmacy, ...Utils.clearAllNotifications()})}
                                            className="a_no_line">
                                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                                                    <FlexGridItem>{pharmacy.name} - {Utils.renderAddress(pharmacy)} - {Utils.renderPhone(pharmacy.phone)}</FlexGridItem>
                                                    <FlexGridItem {...checkedItemProps}>{this.state.pharmacy && pharmacy.ncpdp_pharmacy_id == this.state.pharmacy.ncpdp_pharmacy_id
                                                        ? <Button kind={KIND.tertiary} shape={SHAPE.circle} size={SIZE.compact}><Check size={32} color="#22b573"/></Button>
                                                        : ''}</FlexGridItem>
                                                </FlexGrid>
                                        </StyledLink>
                                    </FlexGridItem>)
                                }
                            </FlexGrid>
                        </div>
                        : <ParagraphMedium style={{textAlign: 'center'}}>{
                            this.state.pharmacy_search_results_loaded
                            ? 'No results found. Try another city or zip code.'
                            : ''
                        }</ParagraphMedium>
                    }
                </div>
            }
        /> : null;
    };

    associatePharmacyToOrder = () => {
        if (this.state.selected_pharmacy_id) {
            this.setState({associatingPharmacy: true});
            Request.put({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/patient_order/" + this.state.order_id,
                headers: {"Content-type" : 'application/json'},
                body: JSON.stringify({order: {fomo_pharmacy_id: this.state.selected_pharmacy_id}}),
                callbackSuccess: (result) => this.setState({associatingPharmacy: false}),
                callbackError: (error) => this.setState({error: error, associatingPharmacy: false})
            });
        } else {
            //no pharmacy to save
            return true;
        }
    };

    done = () => {
        this.completeTaskList();
    };

    close = () => {
        this.cancelTaskList();
    };

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    <MpsModal
                        open={this.state.show}
                        autoFocus={false}
                        callback={this.done}
                        callbackCancel={this.close}
                        withFooter={true}
                        callbackButton={"Done"}
                        callbackCancelButton={'Close'}
                        title={Utils.renderTitleCenter('Pharmacy preference')}
                        body={this.state.order === null || this.state.currentPatientPharmacies === null ? <Spinner /> : <>
                            {Utils.renderError(this.state.error)}
                            {Utils.renderSuccess(this.state.success)}
                            {this.listPatientPharmacies()}
                            {Utils.renderSpace()}
                            {this.showNewPharmacyButton()}
                            {this.showNewPharmacyForm()}
                        </>}
                    />
                )
            }
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PrescriptionRouting);
