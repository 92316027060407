import React, {Component} from 'react';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Storage from '../Storage';
import Logger from '../Utils/logger';
import Request from '../Request';
import { StyledLink } from "baseui/link";
import { ParagraphLarge} from 'baseui/typography';

class SignOutButton extends Component {
    constructor(props) {
        super(props);
    }

    signOut = () => {
        if (ROUTES.FIREBASE_TENANT_ID.startsWith('admin')) {
            var csrftok = $('meta[name="csrf-token"]')[0].attributes.content.value;
            Logger.log('Signing admin user out')
            fetch(
                ROUTES.BASE_URL + "/users/logout.html",
                {
                    headers: { 'X-CSRF-Token': csrftok },
                    method: 'GET',
                    credentials: 'include'
                }
            ).catch(error => Logger.error('Error while logging out admin user'));
        }

        if (this.props.firebase.isPatient() || this.props.firebase.isPhysician()) {
            Request.get({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_offline",
                callbackSuccess: result => {},
                callbackError: error => {
                    Logger.log('. . . . . . . . Could not mark user offline. . . . . . . . .')
                }
            });
        }

        // Only set it for withAuthorization paths
        if (!['/'].includes(window.location.pathname)) {
            Storage.clear();
            Storage.set('signing_out', true);
            Logger.log("Current Path: " + window.location.pathname);
        }

        this.props.firebase.signOut();
    };

    render(){
        var style = {};
        if (this.props.width) {
            style = {width: this.props.width};
        }

        if (this.props.link === true) {
            return <StyledLink href='#' onClick={this.signOut} className="a_no_line">
                <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Sign Out</ParagraphLarge>
            </StyledLink>
        }

        return (
            <Button
                kind={this.props.kind ? this.props.kind : KIND.primary}
                shape={this.props.shape ? this.props.shape : SHAPE.pill}
                size={this.props.size ? this.props.size : SIZE.compact}
                style={{width: '100%'}}
                onClick={this.signOut}>
                Sign out
            </Button>
        );
    }
}

export default withFirebase(SignOutButton);
