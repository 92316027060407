import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import Utils from '../Utils';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { HeadingXSmall, LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import MpsModal from '../Utils/modal';
import { Input } from "baseui/input";
import Request from '../Request';
import Check from 'baseui/icon/check';
import Delete from 'baseui/icon/delete';
import * as ROUTES from '../Constants';
import { Spinner, SpinnerSize } from '../Spinner';

const buttonWidthCol = {
    maxWidth: '60px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
};

const uniqueWidthCol = {
    maxWidth: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class Username extends Component {
    constructor(props){
        super(props);

        this.state = {
            show_username_form: false,
            error: null,
            success: null,
            original_username: this.props.username,
            username: this.props.username,
            description: this.props.description,
            is_username_valid: true,
            checking_username: false,
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.username !== prevProps.username) {
            this.setState({username: this.props.username, original_username: this.props.username});
        }
    };

    isUsernameValid = () => {
        this.setState({checking_username: true});

        // `.` does not get handled well by rails and returns HTML page causing errors on the front end. Therefore, convert `.`.
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/user_management/is_username_valid/" + encodeURI(this.state.username.replaceAll('.', '%2E')),
            callbackSuccess: (result) => {
                this.setState({
                    checking_username: false,
                    is_username_valid: true,
                    ...Utils.clearAllNotificationsExceptSuccess()
                });
            },
            callbackError: (error) => {
                this.setState({
                    checking_username: false,
                    is_username_valid: false,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    updateUsername = () => {
        const data = new FormData();
        data.append('username', this.state.username);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/user_management/update_username",
            body: data,
            callbackSuccess: (result) => {
                this.setState({
                    success: result,
                    show_username_form: false,
                    original_username: this.state.username,
                    ...Utils.clearAllNotificationsExceptSuccess()
                });
            },
            callbackError: (error) => {
                this.setState({
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    showChangeUsernameForm = () => {
        this.setState({show_username_form: true, ...Utils.clearAllNotifications()})
    };

    doChangeUsername = () => {
        this.updateUsername();
    };

    dontChangeUsername = () => {
        this.setState({
            show_username_form: false,
            username: this.state.original_username,
            ...Utils.clearAllNotifications()
        });
    };

    handleChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                ...Utils.clearAllNotifications()
            },
            () => this.isUsernameValid()
        );
    };

    renderUsernameChangeForm = () => {
        return <MpsModal
            open={this.state.show_username_form}
            autoFocus={false}
            callback={this.doChangeUsername}
            callbackCancel={this.dontChangeUsername}
            withFooter={true}
            callbackButton={"Change"}
            callbackCancelButton={'Close'}
            title={Utils.renderTitleCenter('Update username')}
            disabled={!this.state.is_username_valid || this.state.checking_username}
            body={
                <FlexGrid>
                    <FlexGridItem><ParagraphSmall>It must be between 4 and 15 characters in length, and contain only alphanumeric characters (letters A-Z, numbers 0-9) and underscores, without spaces.</ParagraphSmall></FlexGridItem>
                    <FlexGridItem>
                        <FlexGrid flexGridColumnCount={2}>
                            <FlexGridItem>
                                <Input
                                    name='username'
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    onBlur={() => {
                                        window.scroll({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth'
                                        });
                                    }}
                                />
                            </FlexGridItem>
                            <FlexGridItem {...uniqueWidthCol}>
                                {
                                    this.state.checking_username
                                    ? <Spinner size={SpinnerSize.SMALL}/>
                                    : (this.state.is_username_valid ? <Check size={32}/> : <Delete size={32}/>)
                                }
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>
                </FlexGrid>
            }
        />
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => 
                    (
                        <>
                            {Utils.renderError(this.state.error)}
                            {Utils.renderSuccess(this.state.success)}

                            <FlexGrid>
                                <FlexGridItem>
                                    <HeadingXSmall style={{margin: 0}}>Username</HeadingXSmall>
                                </FlexGridItem>
                                {this.state.description
                                ? <FlexGridItem>
                                    <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>{this.state.description}</ParagraphSmall>
                                </FlexGridItem>
                                : null
                                }
                                <FlexGridItem>
                                    <FlexGrid flexGridColumnCount={2}>
                                        <FlexGridItem>
                                            <ParagraphMedium style={{margin: 0, marginTop: '5px'}}>{this.state.username}</ParagraphMedium>
                                        </FlexGridItem>
                                        <FlexGridItem {...buttonWidthCol}>
                                            <Button
                                                kind={KIND.secondary}
                                                shape={SHAPE.pill}
                                                size={SIZE.compact}
                                                onClick={this.showChangeUsernameForm}
                                            >Edit</Button></FlexGridItem>
                                    </FlexGrid>
                                </FlexGridItem>
                            </FlexGrid>

                            {this.renderUsernameChangeForm()}
                        </>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Username);
