import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE as ModalSize, FocusOnce} from 'baseui/modal';
import {KIND} from 'baseui/button';
import Screen, {Size as ScreenSize} from './screen';
import Logger from './logger';
import '../Styles/style.scss';

const autoOverride = {
   /*Root: {
        style: ({ $theme }) => ({
            zIndex: 5000
        })
      },*/
    Dialog: {
        style: ({$theme}) => ({
            width: (window.innerWidth - 32) + 'px',
            backgroundColor: $theme.colors.backgroundPrimary,
        }),
    }
};

class MpsModal extends Component{
    constructor(props) {
        super(props);

        this.state = {
            count: 0,
            open: this.props.open ? this.props.open : false,
            autoFocus: this.props.autoFocus ? this.props.autoFocus : true,
            title: this.props.title,
            closeable: this.props.closeable == false ? false : true,
            body: this.props.body,
            callback: this.props.callback,
            callbackButton: this.props.callbackButton ? this.props.callbackButton : 'Save',
            callbackButtonShow: this.props.callbackButtonShow === false ? false : true,
            callbackCancel: this.props.callbackCancel,
            callbackCancelButton: this.props.callbackCancelButton ? this.props.callbackCancelButton : 'Cancel',
            callbackCancelShow: this.props.callbackCancelShow === false ? false : true,
            disabled: this.props.disabled ? this.props.disabled : false,
            isLoading: this.props.isLoading ? this.props.isLoading : false,
            withFooter: this.props.withFooter ? this.props.withFooter : false,
            fullscreen: (this.props.fullscreen ? this.props.fullscreen : false),
            scrollableBody: (this.props.scrollableBody ? this.props.scrollableBody : false),
            overrideSize: this.props.overrideSize,
            overrides: this.props.overrides ? this.props.overrides : {
                /*Root: {
                    style: ({ $theme }) => ({
                        zIndex: 5000
                    })
                  },*/
                Dialog: {
                    style: ({$theme}) => ({
                        marginTop: this.props.fullscreen == true ? '0px' : '16px',
                        marginRight: '0px',
                        marginBottom: this.props.fullscreen == true ? '0px' : '16px',
                        marginLeft: '0px',
                        maxWidth: (['MEDIUM','LARGE','XLARGE'].includes(Screen.size()) ? (this.props.overrideModalWidth ? this.props.overrideModalWidth : '475px') : '100%'),
                        minWidth: (['MEDIUM','LARGE','XLARGE'].includes(Screen.size()) ? (this.props.overrideModalWidth ? this.props.overrideModalWidth : '475px') : '100%'),
                        backgroundColor: $theme.colors.backgroundPrimary,
                    }),
                }
            },
        };
    }

    componentDidUpdate = (prevProps) => {
        if (
            prevProps.open !== this.props.open
            || prevProps.disabled !== this.props.disabled
            || prevProps.isLoading !== this.props.isLoading
            || prevProps.title !== this.props.title
            || prevProps.withFooter !== this.props.withFooter
            || prevProps.fullscreen !== this.props.fullscreen
            || prevProps.closeable !== this.props.closeable
            || prevProps.overrideSize !== this.props.overrideSize
        ) {
            this.setState({
                open : this.props.open,
                disabled: this.props.disabled,
                isLoading: this.props.isLoading,
                title: this.props.title,
                withFooter: this.props.withFooter,
                fullscreen: this.props.fullscreen,
                closeable: this.props.closeable,
                overrideSize: this.props.overrideSize
            });
        }
    };

    callbackCancel = () => {
        if (this.props.callbackCancel) {
            this.props.callbackCancel();
        }
    };

    closeModal = () => {
        this.setState({open: false});
        this.callbackCancel();
    };

    submit = () => {
        if (this.props.callback) {
            this.props.callback();
        }
    };

    getModalSize = () => {
        if (this.state.overrideSize) {
            return this.state.overrideSize;
        }

        if (['XSMALL','SMALL'].includes(Screen.size()) || this.state.fullscreen) {
            return ModalSize.auto;
        }

        return ModalSize.default;
    };

    render = () => {
        return <Modal
                onClose={this.closeModal}
                isOpen={this.state.open}
                animate
                closeable={this.state.closeable}
                autoFocus
                overrides={['XSMALL','SMALL'].includes(Screen.size()) || this.state.fullscreen ? autoOverride : this.state.overrides}
                size={this.getModalSize()}
                unstable_ModalBackdropScroll
            >
                <FocusOnce><ModalHeader>{this.state.title}</ModalHeader></FocusOnce>
            <ModalBody>
                {this.state.scrollableBody ? <div style={{overflowY: 'auto'}} className='no_scrollbar'>{this.props.body}</div> : this.props.body}
            </ModalBody>
            {this.state.withFooter
                ? <ModalFooter>
                <ModalButton
                    kind={KIND.tertiary}
                    onClick={this.closeModal}>{this.state.callbackCancelButton}</ModalButton>
                {this.state.callbackButtonShow ? <ModalButton
                    disabled={this.state.disabled}
                    onClick={this.submit}
                    isLoading={this.state.isLoading}>{this.state.callbackButton}</ModalButton> : null}
            </ModalFooter>
            : ''
            }
          </Modal>
    };
}

export const Size = {
    default: ModalSize.default,
    auto: ModalSize.auto,
    full: ModalSize.full
};

export default MpsModal;
