import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import Utils from '../Utils';
import {Spinner} from '../Spinner';
import {LabelLarge, ParagraphLarge, ParagraphMedium} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { PinCode } from "baseui/pin-code";
import Logger from '../Utils/logger';
import { withFirebase } from '../Firebase';
import Request from '../Request';
import { Input } from "baseui/input";
import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { withRouter } from 'react-router-dom';
import { StyledLink } from "baseui/link";

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class PasswordResetVerify extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            message: null,
            //email_verification: this.props.type == 'email',
            //phone_verification: this.props.type == 'phone',
            email: this.props.email,
            phone_number: this.props.phone_number,
            email_verified: false,
            phone_verified: false,
            redirect: '',

            password: '',
            confirm_password: '',
            password_reset_code: '',

            codes: Array(6).fill(''),
            checking_code: false,
            resetting: false,
            resend_counter: 0,
            resending_code: false
        }
    }

    handleCodes = (codes) => {
        codes = codes.values;
        this.setState({codes: codes, ...Utils.clearAllNotifications()}, () => {
            if (this.areAllCodesSet(codes)) {
                this.setState({checking_code: true, ...Utils.clearAllNotifications()});
                const code = this.state.codes.join('');
                if (!this.state.email_verified) {
                    this.checkEmailCode(code);
                } else if(!this.state.phone_verified) {
                    this.checkPhoneCode(code);
                } 
            }
        })
    };

    areAllCodesSet = (codes) => {
        var notSetCodes = codes.filter(code => code.length == 0);
        return notSetCodes.length == 0;
    };

    renderCodeVerificationForm = () => {
        return (
            <div style={{marginRight:'16px', marginLeft:'16px'}}>
                {Utils.renderTitleCenter(!this.state.email_verified ? 'Enter the one-time code from your 📩 email address' : 'Now enter the one-time code from your 📲 phone')}
                {this.renderEmailOrPhone()}
                <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600">
                    <FlexGridItem {...centerProps}>
                        <PinCode
                            values={this.state.codes}
                            onChange={this.handleCodes}
                            error={this.state.error != null}
                            autocomplete="one-time-code"
                            autoFocus={true}/>
                    </FlexGridItem>
                    <FlexGridItem>
                        {this.renderResendCodeLink()}
                    </FlexGridItem>
                </FlexGrid>
            </div>
        )
    };

    renderEmailOrPhone = () => {
        if (!this.state.email_verified) {
            return <div style={{textAlign: 'center'}}><ParagraphMedium>{this.state.email}</ParagraphMedium></div>
        } else {
            return <div style={{textAlign: 'center'}}><ParagraphMedium></ParagraphMedium></div>
        }
    };

    checkEmailCode = (code) => {
        Logger.log('Email code check')
        const data = JSON.stringify({
            tenantId: this.props.firebase.getTenant(),
            userEmail: this.state.email,
            verificationCode: code,
        });
        Logger.log(data);
        Request.post({
            firebase: null,
            route: ROUTES.RESET_PASSWORD_EMAIL_CODE_VERIFICATION_URL,
            headers: {'Content-Type': 'application/json'},
            body: data,
            callbackSuccess: result => this.setState({
                message: {message: 'Email code verification succeeded.'},
                codes: Array(6).fill(''),
                checking_code: false,
                email_verified: true,
                resend_counter: 0,
                ...Utils.clearAllNotificationsExceptSuccess()}),
            callbackError: error => this.setState({ error, checking_code: false, ...Utils.clearAllNotificationsExceptError() })
        })
    };

    checkPhoneCode = (code) => {
        Logger.log('Phone code check')
        const data = JSON.stringify({
            tenantId: this.props.firebase.getTenant(),
            userEmail: this.state.email,
            verificationCode: code,
        });
        Logger.log(data);
        Request.post({
            firebase: null,
            route: ROUTES.RESET_PASSWORD_TEXT_CODE_VERIFICATION_URL,
            headers: {'Content-Type': 'application/json'},
            body: data,
            callbackSuccess: result => this.setState({
                message: {message: 'Phone code verification succeeded.'},
                codes: Array(6).fill(''),
                checking_code: false,
                email_verified: true,
                phone_verified: true,
                password_reset_code: result.passwordResetCode,
                ...Utils.clearAllNotificationsExceptSuccess()}),
            callbackError: error => this.setState({ error, checking_code: false, ...Utils.clearAllNotificationsExceptError() })
        })
    };

    handleResendCode = (event) => {
        event.preventDefault();

        if (this.state.resend_counter > 1) {
            this.setState({
                error: {message :"You can't request any more new codes, try again later."},
                ...Utils.clearAllNotificationsExceptError()
            });
        } else {
            this.setState({resending_code: true});

            if(!this.state.email_verified) {
                Request.post({
                    firebase: null,
                    route: ROUTES.RESET_PASSWORD_SEND_EMAIL_CODE_URL,
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        tenantId: this.props.firebase.getTenant(),
                        userEmail: this.state.email
                    }),
                    callbackSuccess: result => this.setState({
                        resending_code: false,
                        message: {message: 'New code sent to your email address. Please wait 60 seconds before requesting another one.'},
                        resend_counter: this.state.resend_counter + 1,
                        ...Utils.clearAllNotificationsExceptSuccess()
                    }),
                    callbackError: error => this.setState({ error, resending_code: false, ...Utils.clearAllNotificationsExceptError() })
                })
            } else if(!this.state.phone_verified){
                Request.post({
                    firebase: null,
                    route: ROUTES.RESET_PASSWORD_SEND_TEXT_CODE_URL,
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        tenantId: this.props.firebase.getTenant(),
                        userEmail: this.state.email
                    }),
                    callbackSuccess: result => this.setState({
                        resending_code: false,
                        message: {message: 'New code sent to your phone. Please wait 60 seconds before requesting another one.'},
                        resend_counter: this.state.resend_counter + 1,
                        ...Utils.clearAllNotificationsExceptSuccess()
                    }),
                    callbackError: error => this.setState({ error, resending_code: false, ...Utils.clearAllNotificationsExceptError() })
                })
            }
        }
    };

    renderResendCodeLink = () => {
        return (
            <div style={{textAlign: 'center'}}>
                {this.state.resending_code ? <Spinner /> : <StyledLink href="#" onClick={this.handleResendCode}>Didn't get the code? Send it again</StyledLink>}
            </div>
        )
    };

    submitReset = (event) => {
        event.preventDefault();

        this.setState({
            resetting: true
        })

        const data = JSON.stringify({
            tenantId: this.props.firebase.getTenant(),
            userEmail: this.state.email,
            verificationCode: this.state.password_reset_code,
            password: this.state.password
        });

        Request.post({
            firebase: null,
            route: ROUTES.RESET_PASSWORD_URL,
            headers: {'Content-Type': 'application/json'},
            body: data,
            callbackSuccess: result => this.setState({
                message: {message: 'Password has been reset. Please login.'},
                codes: Array(6).fill(''),
                checking_code: false,
                resetting: false,
                email_verified: true,
                phone_verified: true,
                password_reset_code: result.passwordResetCode,
                ...Utils.clearAllNotificationsExceptSuccess()},
                () => this.props.history.push({pathname: ROUTES.SIGN_IN, state: {message: 'Your password has been reset. Login with your new password to access your account.'}})),
            callbackError: error => this.setState({ error, checking_code: false, ...Utils.clearAllNotificationsExceptError() })
        })
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications() });
    };

    renderPasswordForm = () => {
        return <FlexGrid gridRowGap={"scale600"}>
            <FlexGridItem {...centerProps}>
                <ParagraphLarge>You have been authenticated. Please set your new password now.</ParagraphLarge>
            </FlexGridItem>
            <FlexGridItem {...centerProps}>
                <Input
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="New password"
                    overrides={{
                        Root: {
                            style: ({ $theme }) => ({
                                maxWidth: "343px"
                            })
                        },
                        //MaskToggleButton: () => null,
                    }}
                />
            </FlexGridItem>
            <FlexGridItem {...centerProps}>
                <Input
                    name="confirm_password"
                    value={this.state.confirm_password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm new password"
                    overrides={{
                        Root: {
                            style: ({ $theme }) => ({
                                maxWidth: "343px"
                            })
                        },
                        //MaskToggleButton: () => null,
                    }}
                />
            </FlexGridItem>
            <FlexGridItem {...centerProps}>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.default}
                    shape={SHAPE.pill}
                    disabled={this.shouldSubmitBeDisabled()}
                    onClick={this.submitReset}
                >Submit</Button>
            </FlexGridItem>
        </FlexGrid>
    };

    shouldSubmitBeDisabled = () => {
        return this.state.password !== this.state.confirm_password;
    };

    render = () => {
        return (
            <div>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.message)}

                {this.state.email_verified && this.state.phone_verified
                ? (this.state.resetting ? <Spinner /> : this.renderPasswordForm())
                : (this.state.checking_code ? <Spinner /> : this.renderCodeVerificationForm())}
            </div>
        );
    };
}

export default withRouter(withFirebase(PasswordResetVerify));