import React, {Component} from 'react';
import {StatefulTooltip} from 'baseui/tooltip';

class Presence extends Component {
    constructor(props){
        super(props);

        this.state = {
            status: this.props.status
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.status !== prevProps.status) {
            this.setState({status: this.props.status});
        }
    };

    idleIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Idle"}
        showArrow
        returnFocus
        autoFocus
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            enableBackground="new 0 0 502 502"
            viewBox="0 0 502 502"
        ><circle cx="251" cy="251" r="250" fill="#fa8a1a"></circle></svg>
        </StatefulTooltip>
    };

    onlineIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Online"}
        showArrow
        returnFocus
        autoFocus
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            enableBackground="new 0 0 502 502"
            viewBox="0 0 502 502"
        ><circle cx="251" cy="251" r="250" fill="#00FF00"></circle></svg>
        </StatefulTooltip> 
    };

    offlineIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Offline"}
        showArrow
        returnFocus
        autoFocus
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            enableBackground="new 0 0 502 502"
            viewBox="0 0 502 502"
        ><circle cx="250.6" cy="250.6" r="250" fill="#b3b3b3"></circle></svg>
        </StatefulTooltip>
    };

    renderOnline = () => {
        return this.onlineIcon('10px');  
    };

    renderOffline = () => {
        return this.offlineIcon('10px');
    };

    renderIdle = () => {
        return this.idleIcon('10px');
    };

    render() {
        if(this.state.status == 'online') {
            return this.renderOnline();
        } else if (this.state.status == 'offline') {
            return this.renderOffline();
        } else if (this.state.status == 'idle') {
            return this.renderIdle();
        } else {
            return null;
        }
    }
}

export default Presence;
