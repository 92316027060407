import React, {Component} from 'react';
import Upload from './index';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import content from './content';

class DrugRequestUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol_id: this.props.protocol_id,
            order_id: this.props.order_id,
            protocol_task: this.props.task,
            show: this.props.show ? this.props.show : false,
            content: this.getContent('en')
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    };

    getContent = (lang) => {
        return content(lang);
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (<Upload
                        show={this.state.show}
                        protocol_id={this.state.protocol_id}
                        order_id={this.state.order_id}
                        title={this.state.content.drug_request_file_attachment_title}
                        protocol_task_id={this.state.protocol_task.id}
                        task_description={this.state.protocol_task.task_description}
                        callbackCompleteHandler={this.props.callbackCompleteHandler}
                        callbackCancelHandler={this.props.callbackCancelHandler}
                    />
                    )
                }
            </AuthUserContext.Consumer>
        )
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(DrugRequestUpload);
