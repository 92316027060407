import React from 'react';
import { ParagraphLarge } from "baseui/typography";
import { DisplayCustom2 } from '../../shared/Fonts';

const data = {
    en: {
        patient: {
            row_gap: "",
            image_margins: "0px 0px 16px 0px", // Top, Right, Bottom, Left
            pagination_width: "10px",
            pagination_margin: "3px",
            how_it_works: [
            ],
        },
        physician: {
            row_gap: "",
            image_margins: "25px 0px 15px 0px",
            pagination_width: "8px",
            pagination_margin: "5px",
            how_it_works: [
            ],
        }
    }
}

const content = (lang, app) => {
    return data[lang][app];
};

export default content;
