import React, {Component} from 'react';
import * as ROUTES from  '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {
    LabelLarge,
    ParagraphMedium,
    ParagraphSmall, HeadingLarge, HeadingXSmall, ParagraphXSmall
} from 'baseui/typography';
import {StatefulTooltip, TRIGGER_TYPE} from 'baseui/tooltip';
import { Alert } from "baseui/icon";
import {
    Checkbox,
    LABEL_PLACEMENT
} from "baseui/checkbox";
import { Button, KIND, SIZE, SHAPE } from "baseui/button";
import Utils from '../../shared/Utils';
import NotFound from '../../shared/NotFound';
import {Block} from 'baseui/block';
import {Spinner} from '../../shared/Spinner';
import Request from '../../shared/Request';
import { StyledLink } from "baseui/link";
import content from './content';
import Logger from '../../shared/Utils/logger';
import Cookie from '../../shared/Utils/cookie';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '80px',
                display: 'flex',
                flexGrow: 0,
                justifyContent: 'flex-end',
                alignItems: 'start',
            }),
        },
    },
};

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class OrderReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            isCheckoutLoading: false,
            order_id: this.props.match.params.order_id ? this.props.match.params.order_id : this.props.order_id,
            order: null,
            subtotal: 0.0,
            monthly_charge: 0.0,
            application_fee: 0.0,
            service_fees: 0.0,
            shipping_fee: 0.0,
            tax_fee: 0.0,
            agreed: false, // Only needed for onboarding.
            subscription: null,
            redirect: '',
            content: this.getContent()
        };
    }

    getContent = () => {
        return content('en');
    };

    componentDidMount = () => {
        this.getOrder();
        window.scrollTo(0, 0);
    };

    getOrder = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_order/" + this.state.order_id,
            callbackSuccess: (result) => this.setState({
                isLoaded: true,
                order: result,
                subtotal: 0.0,
                ...Utils.clearAllNotifications()
            }, () => this.calculateTotals()),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    isOnboarding = () => {
        return this.state.order
            && this.state.order.protocol_subscription
            && ['cart', 'reapply'].includes(this.state.order.protocol_subscription.status);
    };

    isPartnerPharmacy = () => {
        if (this.state.order && this.state.order.line_items) {
            return this.state.order.line_items.some((line_item) => line_item.fulfillment_pharmacy_id != null);
        }

        return false;
    };

    isFomo = () => {
        if (this.state.order && this.state.order.line_items) {
            return this.state.order.line_items.some((line_item) => line_item.fulfillment_pharmacy_id == null);
        }

        return false;
    };

    isReorderPartnerOnly = () => {
        return this.state.order.is_reorder && this.isPartnerPharmacy() && !this.isFomo();
    };

    submitCheckout = () => {
        this.setState({isCheckoutLoading: true, ...Utils.clearAllNotifications()})

        var status_ok = true;

        const data = new FormData();
        data.append('order_id', this.state.order_id);
        data.append('invite_code', Cookie.getCookie("invite_code_for_"+this.state.order.protocol_subscription.protocol.number));

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_onboarding/checkout",
            body: data,
            callbackSuccess: (result) => {
                if (this.isOnboarding()) {
                    Logger.log('Redirecting to protocol status...');
                    this.setState({redirect: ROUTES.SUBSCRIPTION_STATUS + '/' + this.state.order.protocol_subscription.protocol.number});
                } else {
                    this.props.canContinue();
                    //Logger.log('Redirecting to order status...');
                    //this.setState({redirect: ROUTES.ORDER_STATUS + '/' + this.state.order.number});
                }
            },
            callbackError: (error) => {
                this.setState({
                  error: error,
                  isCheckoutLoading: false,
                  ...Utils.clearAllNotificationsExceptError()},
                  () => {
                    setTimeout(() => {
                      if (error.error.redirect == 'protocol/apply') {
                        this.setState({redirect: ROUTES.PROTOCOL_SUBSCRIPTION + '/' + this.state.order.protocol_subscription.protocol.number});
                      }
                    }, 1500)
                  }
                );
            }
        })
    };

    calculateTotals = () => {
        var subtotal = 0;
        var application_fee = 0;
        var service_fee = 0;
        var service_fees = 0;
        var monthly_charge = 0;
        var subscription = null;
        if (this.state.order && this.state.order.line_items) {
            this.state.order.line_items.map(line_item => {
                var price = parseFloat(line_item.price);
                var quantity = parseFloat(line_item.quantity);

                if (line_item.type == 'SubscriptionFeeLineItem') {
                    subscription = line_item;
                    monthly_charge += price * quantity;
                    subtotal += price * quantity;
                    return
                }

                if (line_item.type == 'ApplicationFeeLineItem') {
                    application_fee += price * quantity;
                    return;
                }

                if (line_item.type == 'ServiceFeeLineItem') {
                    service_fee = price;
                    service_fees += price * quantity;
                    return;
                }

                subtotal += price * quantity;
            });
        }

        this.setState({
            subtotal: subtotal,
            application_fee: application_fee,
            service_fee: service_fee,
            service_fees: service_fees,
            monthly_charge: monthly_charge,
            subscription: subscription,
            ...Utils.clearAllNotifications()
        });
    };

    isString = (value) => {
        return typeof value === 'string';
    };

    showSubscriptionFee = () => {
        if (this.state.subscription) {
            var price = parseFloat(this.state.subscription.price);
            return <FlexGrid
            flexGridColumnCount={2}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale400"
            marginBottom={'30px'}
        >
                <FlexGridItem><ParagraphMedium style={{margin: '0px'}}>{this.state.subscription.name}</ParagraphMedium></FlexGridItem>
                <FlexGridItem {...narrowItemProps}><ParagraphMedium style={{margin: '0px'}}>{Utils.renderDollarAmount(price)}</ParagraphMedium></FlexGridItem>
                <FlexGridItem><ParagraphMedium style={{color:"grey", margin: '0px'}}>Monthly subscription</ParagraphMedium></FlexGridItem>
                <FlexGridItem {...narrowItemProps}></FlexGridItem>
            </FlexGrid>
        }
    };

    showProducts = () => {
        if (this.state.isLoaded && this.state.order && this.state.order.line_items) {
            return this.state.order.line_items.map((line_item, idx) => {
                if (line_item.type != 'VariantLineItem') {
                    return;
                }

                var price = parseFloat(line_item.price);

                var name = line_item.name
                var fulfiller = '';
                var quantity = '';

                if (line_item.variant && line_item.variant.product) {
                    name = line_item.variant.product.display_name;

                    if (line_item.fulfillment_pharmacy_id){
                        quantity = "📦 Quantity " + line_item.variant.quantity;
                        fulfiller = line_item.fulfillment_pharmacy.name + ", " + line_item.fulfillment_pharmacy.address + " " + Utils.renderPhone(line_item.fulfillment_pharmacy.phone);
                    } else {
                        fulfiller = this.state.order.fomo_pharmacy.name + ", " + Utils.renderAddress(this.state.order.fomo_pharmacy) + " " + Utils.renderPhone(this.state.order.fomo_pharmacy.phone);
                        quantity = "Quantity " + line_item.variant.quantity;
                    }
                }

                let margin = '30px';
                if (idx == this.state.order.line_items.length-1) {
                    margin = '15px';
                }

                return <FlexGrid
                    flexGridColumnCount={2}
                    flexGridColumnGap="scale200"
                    flexGridRowGap="scale200"
                    key={line_item.id}
                    marginBottom={margin}
                >
                    <FlexGridItem><ParagraphMedium style={{margin: 0}}>{name}</ParagraphMedium></FlexGridItem>
                    <FlexGridItem {...narrowItemProps}><ParagraphMedium style={{margin: 0}}>{price === 0.0 ? null : Utils.renderDollarAmount(price)}</ParagraphMedium></FlexGridItem>
                    <FlexGridItem><ParagraphMedium style={{color:"grey", margin: 0}}>{quantity}</ParagraphMedium></FlexGridItem>
                    <FlexGridItem {...narrowItemProps}></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{margin: 0}}>{fulfiller}</ParagraphSmall></FlexGridItem>
                    <FlexGridItem {...narrowItemProps}></FlexGridItem>
                </FlexGrid>
            });
        }

        return null;
    };

    showSubtotal = () => {
        return <FlexGrid
            flexGridColumnCount={2}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale200"
        >
            <FlexGridItem><ParagraphMedium>Subtotal</ParagraphMedium></FlexGridItem>
            <FlexGridItem {...narrowItemProps}><ParagraphMedium>{Utils.renderDollarAmount(this.calculateSubtotal())}</ParagraphMedium></FlexGridItem>\
        </FlexGrid>
    };

    calculateSubtotal = () => {
        return parseFloat(this.state.subtotal);
    };

    getDeliveryAddress = () => {
        return this.state.order.address.address_1 +
        ' ' + this.state.order.address.address_2 +
        ' ' + this.state.order.address.city +
        ', ' + this.state.order.address.state +
        ' ' + this.state.order.address.postal_code;
    };

    showDeliveryAddress = () => {
        if (this.state.order && this.state.order.address && this.isPartnerPharmacy()){
            return <div>
                <FlexGrid
            flexGridColumnCount={2}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale400"
        >
                <FlexGridItem><HeadingXSmall style={{margin: 0}}>Delivery address</HeadingXSmall></FlexGridItem>
                <FlexGridItem {...narrowItemProps}>
                    <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        $as="a"
                        // http://lvh.me:3000/checkout/529580486/T135124840
                        href={ROUTES.SHIPPING + '/' + this.state.order.protocol_subscription.protocol.number + '/' + this.state.order.number}
                    >Edit</Button>
                </FlexGridItem>
                <FlexGridItem><ParagraphMedium style={{margin: 0}}>{this.getDeliveryAddress()}</ParagraphMedium></FlexGridItem>
                <FlexGridItem {...narrowItemProps}></FlexGridItem>
            </FlexGrid>

            {Utils.renderSpace()}
            {Utils.renderSpace()}
            </div>
        }
    };

    showPaymentMethod = () => {
        if (this.state.order && this.state.order.payment_method){
            return <div><FlexGrid
            flexGridColumnCount={2}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale400"
        >
                <FlexGridItem><HeadingXSmall style={{margin: 0}}>Payment method</HeadingXSmall></FlexGridItem>
                <FlexGridItem {...narrowItemProps}>
                    <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        $as="a"
                        // http://lvh.me:3000/checkout/529580486/T135124840
                        href={ROUTES.SHIPPING + '/' + this.state.order.protocol_subscription.protocol.number + '/' + this.state.order.number}
                    >Edit</Button>
                </FlexGridItem>
                <FlexGridItem><ParagraphMedium style={{margin: 0}}>{this.state.order.payment_method.full_name} - {this.state.order.payment_method.card_type.toUpperCase() + ' ' + this.state.order.payment_method.last_four_digits}</ParagraphMedium></FlexGridItem>
                <FlexGridItem {...narrowItemProps}></FlexGridItem>
            </FlexGrid>

            {Utils.renderSpace()}
            {Utils.renderSpace()}
            </div>
        }
    };

    showApplicationFee = () => {
        if (this.state.application_fee > 0.0) {
            return <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale400"
            >
                <FlexGridItem><ParagraphMedium>Application fee <StatefulTooltip
                    accessibilityType={'tooltip'}
                    content={() => (
                        <Block padding={"10px"} maxWidth={"200px"}>
                            {this.state.content.application_fee_tooltip}
                        </Block>
                    )}
                    showArrow
                ><Alert style={{transform: "rotate(180deg)"}}/></StatefulTooltip></ParagraphMedium></FlexGridItem>
                <FlexGridItem {...narrowItemProps}><ParagraphMedium>{Utils.renderDollarAmount(this.state.application_fee)}</ParagraphMedium></FlexGridItem>
            </FlexGrid>
        }
    };

    showShippingCost = () => {
        return <FlexGrid
            flexGridColumnCount={2}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale400"
        >
            <FlexGridItem>
            <ParagraphMedium>Shipping</ParagraphMedium></FlexGridItem>
            <FlexGridItem {...narrowItemProps}><ParagraphMedium>{Utils.renderDollarAmount(this.state.shipping_fee)}</ParagraphMedium></FlexGridItem>
        </FlexGrid>
    };

    showTaxesCost = () => {
        return <FlexGrid
            flexGridColumnCount={2}
            flexGridColumnGap="scale200"
            flexGridRowGap="scale400"
        >
            <FlexGridItem>
            <ParagraphMedium>Taxes</ParagraphMedium></FlexGridItem>
            <FlexGridItem {...narrowItemProps}><ParagraphMedium>{Utils.renderDollarAmount(this.state.tax_fee)}</ParagraphMedium></FlexGridItem>
        </FlexGrid>
    };

    calcTotalChargeEstimate = () => {
        //TODO fix service_fee into a real line item
        return (parseFloat(this.state.service_fees) + parseFloat(this.state.subtotal) + parseFloat(this.state.application_fee));
    };

    showTotalChargeEstimate = () => {
        // Do not show when a reorder of partner pharmacy only...
        if (this.state.isLoaded && this.state.order && !this.isReorderPartnerOnly()){
            return <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale400"
                marginBottom="30px"
            >
                <FlexGridItem>
                    <LabelLarge>Total charge estimate {/*<StatefulTooltip
                        accessibilityType={'tooltip'}
                        content={() => (
                            <Block padding={"10px"} maxWidth={"200px"}>
                                {this.state.content.total_charge_estimate_tooltip}
                            </Block>
                        )}
                        showArrow
                        ><Alert/></StatefulTooltip>*/}</LabelLarge>
                </FlexGridItem>
                <FlexGridItem {...narrowItemProps}><LabelLarge style={{margin: '0px'}}>{Utils.renderDollarAmount(this.calcTotalChargeEstimate())}</LabelLarge></FlexGridItem>
            </FlexGrid>
        }

        return null;
    };

    showMonthlyRecurringCosts = () => {
        if (this.state.isLoaded && this.state.monthly_charge > 0.0) {
            return <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale400"
                marginBottom="30px"
            >
                <FlexGridItem>
                    <LabelLarge>Next monthly charge <StatefulTooltip
                        accessibilityType={'tooltip'}
                        content={() => (
                            <Block padding={"10px"} maxWidth={"200px"}>
                                {this.state.content.next_monthly_charge_tooltip}
                            </Block>
                        )}
                        showArrow
                    ><Alert style={{transform: "rotate(180deg)"}}/></StatefulTooltip></LabelLarge>
                </FlexGridItem>
                <FlexGridItem {...narrowItemProps}><LabelLarge style={{margin: '0px'}}>{Utils.renderDollarAmount(this.state.monthly_charge)}</LabelLarge></FlexGridItem>
            </FlexGrid>
        }

        return null;
    };

    showTotalChargesNow = () => {
        if (this.state.isLoaded && this.state.order) {
            return <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale400"
                marginBottom="30px"
            >
                <FlexGridItem>
                    <LabelLarge>Amount charged now <StatefulTooltip
                        showArrow
                        accessibilityType={'tooltip'}
                        content={() => (
                            <Block padding={"10px"} maxWidth={"200px"}>
                                {this.state.content.amount_charged_now_tooltip}
                            </Block>
                        )}
                    ><Alert style={{transform: "rotate(180deg)"}}/></StatefulTooltip></LabelLarge>
                </FlexGridItem>
                <FlexGridItem {...narrowItemProps}><LabelLarge style={{margin: '0px'}}>{Utils.renderDollarAmount(0.00)}</LabelLarge></FlexGridItem>
            </FlexGrid>
        }

        return null;
    };

    showAgreement = () => {
        if (this.state.monthly_charge > 0) {
            return <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale200" flexGridRowGap="scale400">
                <FlexGridItem>
                <Checkbox
                    checked={this.state.agreed}
                    onChange={this.setContinue}
                    labelPlacement={LABEL_PLACEMENT.right}
                    >
                        I acknowledge that my payment method will be charged each 30 day period for the term of my subscription until canceled. Subscriptions can be canceled at any time by visiting your dashboard.
                    </Checkbox>
                </FlexGridItem>
            </FlexGrid>
        }
    };

    showServiceFee = () => {
      if (this.state.service_fee && this.state.service_fees > 0) {
        return <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200" flexGridRowGap="scale400">
            <FlexGridItem>
                <ParagraphMedium>Drugstore fee <StatefulTooltip
                    accessibilityType={'tooltip'}
                    content={() => (
                        <Block padding={"10px"} maxWidth={"200px"}>
                            {this.state.content.network_fee_tooltip.replaceAll("{service_fees}", Utils.renderDollarAmount(this.state.service_fee))}
                        </Block>
                    )}
                    showArrow
                    ><Alert style={{transform: "rotate(180deg)"}}/></StatefulTooltip>
                </ParagraphMedium>
            </FlexGridItem>
            <FlexGridItem {...narrowItemProps}>
                <ParagraphMedium>{Utils.renderDollarAmount(this.state.service_fees)}</ParagraphMedium>
            </FlexGridItem>
        </FlexGrid>
      }
    };

    setContinue = (event) => {
        this.setState({agreed: event.target.checked, ...Utils.clearAllNotifications()});
         var data = {
            consent_record: {
                metadata: JSON.stringify({text: event.target.parentElement.innerText || event.target.parentElement.textContent}),
                protocol_id: this.state.order.protocol_subscription.protocol.id,
                agreement_name: 'credit-card-order-review'
            }
        }
        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/consent_records",
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify(data),
            callbackSuccess: (result) => {
            },
            callbackError: (error) => {
                //Fail silently for now.
            }
        })
    };

    showContinue = () => {
        Logger.log("Monthly Charge: " + this.state.monthly_charge);
        return <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale200" flexGridRowGap="scale400" marginTop="30px">
            <FlexGridItem {...itemProps} style={{marginBottom:'40px'}}>
                <Button
                    disabled={this.state.monthly_charge > 0.0 && !this.state.agreed}
                    onClick={this.submitCheckout}
                    kind={KIND.primary}
                    size={SIZE.default}
                    shape={SHAPE.pill}
                    isLoading={this.state.isCheckoutLoading}
                >Submit</Button>
            </FlexGridItem>
        </FlexGrid>
    }

    showError = () => {
        return this.state.error && <div>{this.state.error.message}</div>
    };

    showPageTitle = () => {
        return  Utils.renderTitleCenter(
            this.isOnboarding() ? this.state.content.order_title_onboarding : this.state.content.order_title_drug_request
        );
    };

    renderByline = () => {

        let content = this.state.content.order_byline_onboarding;
        if(!this.isOnboarding()) {
            content = this.state.content.order_byline_drug_request;
        }
        return  <div style={{textAlign: 'center'}}><ParagraphMedium>{content}</ParagraphMedium></div>;
    };

    render() {
        if (this.state.order && this.state.order.status != 'cart') {
            return <NotFound />
        }

        if (this.state.redirect) {
            this.props.history.push(this.state.redirect);
        }

        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        this.state.isLoaded ?
                        <div style={{marginRight: '10px', marginTop:'98px', marginBottom:'80px'}}>
                            {Utils.renderError(this.state.error)}
                            {Utils.renderErrorMulti(this.state.error)}

                            {this.showPageTitle()}
                            {this.renderByline()}
                            {this.showDeliveryAddress()}
                            {this.showPaymentMethod()}
                            <HeadingXSmall style={{margin: 0}}>Membership</HeadingXSmall>
                            {Utils.renderSpace()}
                            {this.showSubscriptionFee()}
                            {this.showProducts()}

                            {/* this.showSubtotal() */}
                            {this.showApplicationFee()}
                            {this.showServiceFee()}
                            {/* this.showShippingCost() */}
                            {/* this.showTaxesCost() */}

                            {Utils.renderSpace()}
                            <hr style={{height: '1px', borderWidth: 0, color: 'grey', backgroundColor: 'grey'}}/>
                            {Utils.renderSpace()}
                            {this.showTotalChargeEstimate()}
                            {/* this.showMonthlyRecurringCosts()*/}
                            {/*this.showTotalChargesNow()*/}

                            {this.showAgreement()}
                            {this.showContinue()}
                        </div> : <div>
                            {Utils.renderSpace()}
                            {Utils.renderSpace()}
                            <Spinner />
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(OrderReview);
