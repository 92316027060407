import { LabelMedium, ParagraphMedium, ParagraphSmall, ParagraphXSmall, HeadingMedium } from 'baseui/typography';
import React, {Component} from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import Logger from '../Utils/logger';
import { AuthUserContext, withAuthorization } from '../Session';
import Utils from '../Utils';
import { StyledLink } from 'baseui/link';
import Alert from 'baseui/icon/alert'

class HistoryMessage extends Component {
    constructor(props) {
        super(props);

        this.data = this.props.data;
        this.color = this.props.color;
        this.username = this.props.username;
        this.show_user = this.props.show_user;
        this.state = {
            usernames: this.props.usernames
        }

        // RegEx must be wrapped in parenthesis otherwise you can get undefined results (str.plit(regex) is one example of weirdness)
        this.mentionRegex = /(@[^\s]+)/g;
        this.urlRegex = /([https|http]+:\/\/[^\s]+)/g;
    }

    shouldComponentUpdate = () => {
        return this.show_user; // ONly should be True if timeAgo is present in the message
    };

    urlify = (text) => {
        const foundUrls = text.match(this.urlRegex);
        if (foundUrls) {
            return text.split(this.urlRegex).map((subtext, idx) => this.urlRegex.test(subtext)
                ? <StyledLink key={idx} target='_blank' href={subtext}>{subtext}</StyledLink>
                : subtext)
        } else {
            return text;
        }
    };

    isUser = (username) => {
        // username does not have `@` in the front.
        return this.state.usernames.findIndex(user => user.toLowerCase() == username.toLowerCase()) >= 0
    };

    renderMessageText = (data, isMentioned) => {
        const foundUrls = data.message.match(this.mentionRegex);
        if (foundUrls) {
            return data.message.split(this.mentionRegex).map((subtext, idx) => this.mentionRegex.test(subtext) && this.isUser(subtext.substring(1))
                ? <ParagraphSmall key={idx} margin={'0px 0px 0px 0px'} display={'inline-block'} style={{fontWeight: 'bold', whiteSpace: 'pre-wrap', color: this.renderMessageStatus(data.sent)}}>{subtext} {data.sent === false ? " (failed)" : null }</ParagraphSmall>
                : <ParagraphSmall key={idx} margin={'0px 0px 0px 0px'} display={'inline-block'} style={{whiteSpace: 'pre-wrap', color: this.renderMessageStatus(data.sent)}}>{this.urlify(subtext)} {data.sent === false ? " (failed)" : null }</ParagraphSmall>)
        } else {
            return <ParagraphSmall margin={'0px 0px 0px 0px'} style={{color: this.renderMessageStatus(data.sent)}}>{this.urlify(data.message)} {data.sent === false ? " (failed)" : null }</ParagraphSmall>
        }
    };

    renderMessageStatus = (sent) => {
        if (sent === false) {
            return '#AB1300';
        } else {
            return '';
        }
    }

    renderMessage = () => {
        const isMentioned = this.data.message.toLowerCase().includes('@' + this.username.toLowerCase());
        return <FlexGrid
                flexGridRowGap={'scale0'}
                margin={0}
            >
            {this.show_user ? <FlexGridItem>
                <HeadingMedium
                    margin={'12px 0px 0px 0px'}
                    display={'inline-block'}
                    color={this.color}
                    style={{lineHeight: '20px', fontSize:'14px'}}
                >{this.data.username}</HeadingMedium> <ParagraphXSmall margin={0} display={'inline-block'} color={'gray'}> • {Utils.renderTimeAgo(this.data.time_sent)}</ParagraphXSmall>
                </FlexGridItem> : null}
            <FlexGridItem backgroundColor={isMentioned? '#79797930' : ''}>{this.renderMessageText(this.data, isMentioned)}</FlexGridItem>
        </FlexGrid>
    };

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser => (
                <FlexGridItem>{this.renderMessage()}</FlexGridItem>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(HistoryMessage);
