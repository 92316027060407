import React from 'react';
import { ParagraphMedium, HeadingXSmall } from 'baseui/typography';

const data = {
    en: {
        // video_call
        video_call_title: "The final step is a quick video call",
        video_call_description: "Call instructions:",
        video_call_subtext_1: ""
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
