import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphMedium, HeadingSmall} from 'baseui/typography';
import {Button, KIND, SHAPE} from 'baseui/button';
import Utils from '../../shared/Utils';
import { StyledLink } from "baseui/link";
import content from './content';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class StatusBanned extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            content: content('en')
        };

        this.standAlone = this.props.match.params.protocol_id > 0;
    }

    render () {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <div style={{display:'flex', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'85vh'}}>
                        <FlexGrid
                            flexGridColumnCount={1}
                            flexGridRowGap="scale600"
                        >
                            <FlexGridItem {...itemProps}>{Utils.renderError(this.state.error)}</FlexGridItem>
                            <FlexGridItem {...itemProps}>
                                <HeadingSmall style={{textAlign: 'center', margin: 0}}>{this.state.content.banned_title.replaceAll("{protocol_name}", this.props.protocol_name)}</HeadingSmall></FlexGridItem>
                            <FlexGridItem>
                                <ParagraphMedium style={{textAlign: 'center', margin: 0}}>
                                    {this.state.content.banned_provider_msg}
                                </ParagraphMedium>
                                {Utils.renderSpace()}
                                <ParagraphMedium style={{textAlign: 'center', margin: 0}}>
                                    <i>{this.props.reject_reason}</i>
                                </ParagraphMedium>
                            </FlexGridItem>
                            <FlexGridItem {...itemProps}>
                                {this.state.content.banned_subtext_2}
                            </FlexGridItem>
                            <FlexGridItem {...itemProps}>
                                <Button
                                    kind={KIND.secondary}
                                    shape={SHAPE.pill}
                                    $as="a"
                                    href={ROUTES.HOME}
                                >Return to home</Button>
                            </FlexGridItem>
                        </FlexGrid>
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(StatusBanned);
