import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import * as ROUTES from '../Constants';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import Drugstore from '../Drugstore/drugstore';
import Chat from '../Chat';
import {HeadingLarge, HeadingMedium, HeadingSmall, HeadingXSmall, LabelMedium, ParagraphLarge, ParagraphMedium, ParagraphSmall, ParagraphXSmall} from 'baseui/typography';
import { ButtonGroup, MODE } from "baseui/button-group";
import Subscription from '../Subscriptions';
import {Drawer, ANCHOR, SIZE as DrawerSize} from 'baseui/drawer';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { StyledLink } from 'baseui/link';
import {Spinner, SIZE as SpinnerSize} from 'baseui/spinner';
import Logger from '../../shared/Utils/logger';
import Request from '../../shared/Request';
import Meds from '../Medications';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import content from './content';
import Linkify from 'react-linkify';
import Presence from '../../shared/Presence';
import MetaTagsOverride, {MetaTagPages} from '../../shared/Metatags';
import MpsModal from '../../shared/Utils/modal';
import Avatar, { AvatarSize } from "../../shared/Avatar";
import ChatRoom from '../../shared/ChatRoom';
import Announcements from '../Announcements';
import Screen, {Size as ScreenSize} from '../../shared/Utils/screen';
import Storage from '../../shared/Storage';
import Labs from '../Labs';
import Tasklist from '../Tasklist';
import {StatefulTooltip, PLACEMENT} from 'baseui/tooltip';
import { ThemeProvider, createTheme, darkThemePrimitives, createThemedWithStyle} from "baseui";
import SSE from '../../shared/SSE';
import { Alert } from "baseui/icon";
import Delete from 'baseui/icon/delete';
import { NotificationCircle, COLOR } from "baseui/badge";
import { toaster, ToasterContainer } from "baseui/toast";
import Ticker from 'react-ticker';
import {Layer} from 'baseui/layer';
import { Input } from "baseui/input";

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '16px',
                marginRight: '16px',
            }),
        },
    },
};

const upperProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const buttonProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '16px',
                marginRight: '16px',
            }),
        },
    },
};

const rightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
            }),
        },
    },
};

const messengerButtonsProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            }),
        },
    },
};

const chatRightButtonsProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '100px',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const chatHeaderProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#7f7f7f47',
                margin: '0px 0px 0px 0px',
                padding: '0px 0px 24px 0px'
            }),
        },
    },
};

let rightLineProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                borderRightWidth: '1px',
                borderRightStyle: 'solid',
                borderRightColor: '#7f7f7f47',
                padding: '0px 28px 0px 0px'
            }),
        },
    },
};

const borderBottomProp = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#7f7f7f47',
            }),
        },
    },
};

const greyTextProps = {
    overrides: {
        Block: {
          style: ({$theme}) => ({
            color: $theme.colors.contentTertiary,
          }),
        },
    },
  };

const setNoScroll = () => {
    $(document.body).css("overflow", 'hidden');
    //$(document.body).css("position", 'fixed');
    //$(document.body).css("left", '46%');

    return null;
};

const WIZARD_DRUGSTORE_IDX = 0;
const WIZARD_TASKLIST_IDX = 1;

class ProtocolHome extends Component {
    constructor(props) {
        super(props);

        this.sse = null;
        this.protocol_id = this.props.match.params.id ? this.props.match.params.id : this.props.protocol_id;
        this.membersInterval = null;
        this.audio = new Audio('https://res.cloudinary.com/mpsh87/video/upload/v1646255037/pharmr-notification_kdapbu.mp3');

        this.state = {
            isLoaded: false,
            protocol_id: this.props.match.params.id,
            subscription: null,
            creator: null,
            members: [],
            error: null,
            success: null,
            show_meds: true,
            show_meds_window: false,
            show_drugstore: false,
            show_drugstore_window: false,
            show_labs: false,
            show_labs_window: false,
            show_share: false,
            show_share_window: false,
            show_subscription: false,
            show_subscription_window: false,
            show_community: false,
            show_community_window: false,
            enable_community_features: false,

            content: this.getContent(),

            logged_in_user: '',
            dm_show: false,
            dm_user: null,

            selectedTab: 0,
            chat_tab: 0,

            containerHeight: this.calcContainerHeight(), // 10px extra margin

            support_messages_unseen: 0,
            new_meds_unseen: 0,

            // Wizard settings
            wizard_index: WIZARD_DRUGSTORE_IDX,
            wizard_order_id: null,

            event_status: null,

            protocol: this.props.match.params.id ? null : {
                enable_community_features: false,
            },
        };
    };

    sseCallback = (event) => {
        Logger.log('Server Updates CB executing....')
        this.setState({event_status: JSON.parse(event.data)});
    };

    calcContainerHeight = () => window.innerHeight - 42 - 47;

    getContent = () => {
        return content('en');
    };

    componentDidMount = () => {
        this.getProtocol(this.protocol_id);

        window.addEventListener("resize", () => this.setState({containerHeight: this.calcContainerHeight()}));

        window.scrollTo(0, 0);

        // TODO do a fetch with the token and then make the SSE call
        Logger.log('Calling SSE...');
        this.sse = new SSE(this.props.firebase, '/events', this.sseCallback);
    };

    componentWillUnmount = () => {
        clearInterval(this.membersInterval);

        if (this.sse) {
            this.sse.close();
        }
    };

    redirectStatusChange = () => {
        if (this.state.event_status) {
            if (this.state.event_status.protocol == this.protocol_id && ['inactive', 'banned'].includes(this.state.event_status.subscription)) {
                this.props.history.push(ROUTES.SUBSCRIPTION_STATUS + '/' + this.protocol_id);
            } else if (this.state.event_status.protocol == this.protocol_id && ['shutdown'].includes(this.state.event_status.status)) {
                this.props.history.push(ROUTES.PROTOCOL_STATUS + '/' + this.protocol_id);
            }
        }
    };

    getProtocolCallback = (result) => {
        Logger.log("0000000000000 Protocol Home 0000000000000");
        Logger.log(result);
        this.setState({
            isLoaded: true,
            members: result.members,
            logged_in_user: result.logged_in_user,
            subscription: result.subscription,
            protocol: result.subscription.protocol,
            creator: result.subscription.protocol.creator,
            enable_community_features: result.subscription.protocol.enable_community_features,
            support_messages_unseen: result.provider_messages_unseen + result.bot_notifications_unseen,
            new_meds_unseen: result.new_meds_unseen,
            ...Utils.clearAllNotifications()
        }, () => {
            if (this.state.support_messages_unseen > 0 /*&& this.state.chat_tab !== 1*/) {
                this.audio.play();
            }

            /*this.prescriptionsSeenByPatient(true);*/
        });

        if (result.subscription.new_activity_for_patient) {
            Request.get({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/subscription_management/clear_new_activity_flag/" + this.protocol_id,
                callbackSuccess: (result) => {},
                callbackError: (error) => {}
            })
        }

        
          this.membersInterval = setInterval(() => {
              if (this.props.firebase.isUserOnline()) {
                  const unseen_msgs = this.state.support_messages_unseen;
                  Request.get({
                      firebase: this.props.firebase,
                      route: ROUTES.BASE_URL_API + "/patient_home/community/" + this.protocol_id,
                      callbackSuccess: (result) => this.setState({
                          members: result.members,
                          creator: result.protocol.creator,
                          support_messages_unseen: result.provider_messages_unseen + result.bot_notifications_unseen,
                          new_meds_unseen: result.new_meds_unseen,
                      }, () => {
                          if (this.state.support_messages_unseen > unseen_msgs /*&& this.state.chat_tab !== 1*/){
                              this.audio.play();
                          }
                      }),
                      callbackError: (error) => Logger.error("Could not refresh information")
                  })
              } else {
                  clearInterval(this.membersInterval);
                  this.membersInterval = null;
              }
          }, 5000);
        
    };

    getProtocolCallbackError = (error) => {
        Logger.log('getProtocolCallbackError:');
        Logger.log(error);
        this.setState({
            isLoaded: true,
            error,
            ...Utils.clearAllNotificationsExceptError()
        });
    }

    getProtocol = (protocol_id) => {
        Logger.log("!!!Getting that protocol!!!");
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/home/" + protocol_id,
            callbackSuccess: this.getProtocolCallback,
            callbackError: this.getProtocolCallbackError
        })
    };

    callbackError = (error) => {
        this.setState({error: error, ...Utils.clearAllNotificationsExceptError()});
    };

    callbackSuccess = (success) => {
        this.setState({success : success, ...Utils.clearAllNotificationsExceptSuccess()});
    };

    memberIcon = (width) => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Member count"}
        showArrow
        returnFocus
        autoFocus
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            type="color-text-accessible-red"
            version="1.1"

            viewBox="0 0 19 17"
            x="0px"
            y="0px"
            className="ScSvg-sc-1j5mt50-1 hRBkFL"
            width={width || '17px'}
        >
            <g>
                <path
                    fill={window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#ffffff'}
                    fillRule="evenodd"
                    d="M5 7a5 5 0 116.192 4.857A2 2 0 0013 13h1a3 3 0 013 3v2h-2v-2a1 1 0 00-1-1h-1a3.99 3.99 0 01-3-1.354A3.99 3.99 0 017 15H6a1 1 0 00-1 1v2H3v-2a3 3 0 013-3h1a2 2 0 001.808-1.143A5.002 5.002 0 015 7zm5 3a3 3 0 110-6 3 3 0 010 6z"
                    clipRule="evenodd"/>

            </g>
        </svg>
        </StatefulTooltip>
    };

    presentChat = () => {
        return this.state.show_chat ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM'  ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ?  '#000000' : '#FFFFFF',
                padding: '3px 3px 3px 3px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF')
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_chat: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            <Chat
                protocol_id={this.protocol_id}
                room_id={this.protocol_id}
                username={this.state.logged_in_user}
                protocol={this.state.subscription.protocol}
                parent_height={window.innerHeight + 25}
                show={this.state.show_chat}
            />
        </div></Layer> : null;
    };

    presentMeds = () => {
        return this.state.show_meds_window ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF',
                padding: '10px 10px 10px 10px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF'),
                overflowY:'auto',
                overflowX:'hidden'
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_meds_window: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });

                            this.markMedsSeen(true);
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            {this.renderMedsWindow()}
        </div></Layer> : null;
    };

    presentDrugstore = () => {
        return this.state.show_drugstore_window ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF',
                padding: '10px 10px 10px 10px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF'),
                overflowY:'auto',
                overflowX:'hidden'
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_drugstore_window: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            {this.renderDrugstoreWindow()}
        </div></Layer> : null;
    };

    presentLabs = () => {
        return this.state.show_labs_window ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF',
                padding: '10px 10px 10px 10px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF')
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_labs_window: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            {this.renderLabsWindow()}
        </div></Layer> : null;
    };

    presentShare = () => {
        return this.state.show_share_window ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF',
                padding: '10px 10px 10px 10px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF')
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_share_window: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            {this.renderShareWindow()}
        </div></Layer> : null;
    };

    presentSubscription = () => {
        return this.state.show_subscription_window ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF',
                padding: '10px 10px 10px 10px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF')
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_subscription_window: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            {this.renderSubscriptionWindow()}
        </div></Layer> : null;
    };

    presentCommunity = () => {
        return this.state.show_community_window ? <Layer><div
            style={{
                position: 'fixed',
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? null : '50%',
                bottom: 0,
                left: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                right: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL' || Screen.size() == 'MEDIUM' ? 0 : '25%',
                top: 0,
                background: window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF',
                padding: '10px 10px 10px 10px',
                border: '2px solid ' + (window.matchMedia("(prefers-color-scheme: dark)").matches ? '#000000' : '#FFFFFF')
            }}
        >
            <FlexGrid>
                <FlexGridItem {...rightProps}>
                    <Button
                        onClick={() => {
                            this.setState({show_community_window: false});

                            // Scroll page up for mobile as on Safari the page gets scrolled down when when keyboard is openned on chat.
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        kind={KIND.tertiary}
                        size={SIZE.compact}
                        shape={SHAPE.round}
                    >
                        <Delete size={24} />
                    </Button>
                </FlexGridItem>
            </FlexGrid>
            {this.renderChatRoomWindow()}
        </div></Layer> : null;
    };

    renderProtocolAttributes = () => {

        if (this.state.subscription && this.state.subscription.protocol) {
            return (<FlexGrid flexGridColumnCount={1} display="flex" justifyContent="center" maxWidth="570px" width="100%" marginLeft={'auto'} marginRight={'auto'}>
                {['inactive'].includes(this.state.subscription.status) ? '': <FlexGridItem {...upperProps}><Favicon
                    favicon_url={this.state.subscription.protocol.favicon_url}
                    size={FaviconSize.MEDIUM}
                    badge_type={this.state.subscription.protocol.badge_type}
                    status={this.state.subscription.protocol.status}
                    rejecting_new_subscriptions={this.state.subscription.protocol.rejecting_new_subscriptions}
                    />
                </FlexGridItem>}
                {['inactive'].includes(this.state.subscription.status) ? '': <FlexGridItem {...itemProps}>
                    <HeadingMedium style={{margin: "4px 0px 2px 0px", fontSize: '28px', lineHeight: '36px', display: 'inline-block'}} >{this.state.subscription.protocol.name}</HeadingMedium>
                </FlexGridItem>}
                {['inactive'].includes(this.state.subscription.status) ? <FlexGridItem {...itemProps}><HeadingSmall style={{margin:"0px 0px 18px 0px", textAlign:'center'}}>Your subscription to {this.state.subscription.protocol.name} is inactive.</HeadingSmall></FlexGridItem> : ''}
                {['active', 'active_pending_cancel'].includes(this.state.subscription.status) ?
                    <FlexGridItem><Ticker speed={5} offset={'15%'}>
                    {({ index }) => (
                      <>
                        <ParagraphMedium style={{ margin:'0', paddingRight: ".5em", whiteSpace:'nowrap' }}>
                        {this.renderDrInfo()}<span style={{paddingLeft: ".5em"}}>•</span>
                        </ParagraphMedium>
                      </>
                    )}
                  </Ticker></FlexGridItem>

                    /*<div style={{overflow: 'hidden', maxWidth:'570px', width:'100%',justifyContent:'center'}}>
                        <div className="text-ticker" style={{display: 'flex'}}>
                            <ParagraphMedium style={{marginRight: '1rem', marginTop:'0', marginBottom:'0', alignSelf: 'self-start', flex: 'none'}}>{this.renderDrInfo()}</ParagraphMedium>
                        </div>
                    </div>*/
                        /*<FlexGridItem style={{display:'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '6px'}}><ParagraphMedium style={{margin:"0px", textAlign:"center", maxWidth: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px'}}>{this.renderDrInfo()}</ParagraphMedium></FlexGridItem>*/
                        : null}
            </FlexGrid>);
      } else {
         if (this.state.subscription && this.state.subscription.protocol) {
            return (<FlexGrid flexGridColumnCount={1} justifyContent="center">
                <FlexGridItem><Favicon
                    favicon_url={this.state.subscription.protocol.favicon_url}
                    size={FaviconSize.MEDIUM}
                    badge_type={this.state.subscription.protocol.badge_type}
                    status={this.state.subscription.protocol.status}
                    rejecting_new_subscriptions={this.state.subscription.protocol.rejecting_new_subscriptions}
                /></FlexGridItem>
                <FlexGridItem>
                    <HeadingMedium style={{margin: "8px 0px 6px 0px", fontSize: '28px', lineHeight: '36px', display: 'inline-block'}} >{this.state.subscription.protocol.name}</HeadingMedium>
                    <ParagraphSmall style={{ margin: "8px 0px 6px 7px", display: 'inline-block'}}>{this.memberIcon()}</ParagraphSmall>
                    <ParagraphLarge style={{margin: "8px 0px 6px 2px", display: 'inline-block', lineHeight: '18px'}}>{this.state.members.length}</ParagraphLarge>

                </FlexGridItem>
                {['inactive'].includes(this.state.subscription.status) ? <FlexGridItem {...itemProps} {...itemProps}><ParagraphMedium margin="0px 0px 10px 0px 0px 10px 0px">Your subscription is not active</ParagraphMedium></FlexGridItem> : ''}
                {['active', 'active_pending_cancel'].includes(this.state.subscription.status) ? <FlexGridItem><ParagraphMedium margin="0px">{this.renderDrInfo()}</ParagraphMedium></FlexGridItem> : null}
            </FlexGrid>);
        }
      }
    };

    showMeds = () => {
        this.setState({
            show_meds: true,
            show_drugstore: false,
            show_labs: false,
            show_subscription: false,
            ...Utils.clearAllNotifications()
        });

        this.markMedsSeen(true);
    };

    showDrugstore = () => {
        this.setState({
            show_drugstore: true,
            show_meds: false,
            show_labs: false,
            show_subscription: false,
            ...Utils.clearAllNotifications()});
    };

    showSubscription = () => {
        this.setState({
            show_drugstore: false,
            show_meds: false,
            show_labs: false,
            show_subscription: true,
            ...Utils.clearAllNotifications()});
    };

    showLabs = () => {
        this.setState({
            show_drugstore: false,
            show_meds: false,
            show_labs: true,
            show_subscription: false,
            ...Utils.clearAllNotifications()});
    };

    setSelectedTab = (event, index) => {
        this.setState({selectedTab: index, ...Utils.clearAllNotifications()});
    };

    renderProtocolOptions = () => {
        if (this.state.subscription && this.state.subscription.protocol /*&& ['XSMALL', 'SMALL', 'MEDIUM'].includes(Screen.size())*/) {
            return (
                <FlexGrid flexGridColumnCount={1} flexGridRowGap={['XSMALL'].includes(Screen.size()) ? 'scale100' : 'scale300'} style={{marginTop:"10px"}}>
                    <FlexGridItem {...itemProps}>
                            <NotificationCircle
                                content={this.state.support_messages_unseen}
                                hidden={this.state.support_messages_unseen > 0 ? false:true}
                                overrides={{
                                    Root: {
                                      style: ({ $theme }) => ({
                                        maxWidth: '570px',
                                        width: '100%',
                                      })
                                    },
                                    Badge: {
                                        style: ({ $theme }) => ({
                                          outline: window.matchMedia("(prefers-color-scheme: dark)").matches ? `#000000 5px solid` : `#FFFFFF 5px solid`,
                                          backgroundColor: "#FF5722"
                                        })
                                    }
                                  }}
                                horizontalOffset="-3px"
                                verticalOffset="-3px"
                                >
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={() => this.setState({show_chat: true})}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648195/messenger-white_uacbar.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/messenger-black_uguqjd.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                        paddingBottom: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >Messenger
                                    </Button>
                            </NotificationCircle>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                        <NotificationCircle
                                content={this.state.new_meds_unseen}
                                hidden={this.state.new_meds_unseen > 0 ? false:true}
                                horizontalOffset="-3px"
                                verticalOffset="-3px"
                                overrides={{
                                    Root: {
                                      style: ({ $theme }) => ({
                                        maxWidth: '570px',
                                        width: '100%',
                                      })
                                    },
                                    Badge: {
                                        style: ({ $theme }) => ({
                                            outline: window.matchMedia("(prefers-color-scheme: dark)").matches ? `#000000 5px solid` : `#FFFFFF 5px solid`,
                                          backgroundColor: "#FF5722"
                                        })
                                    }
                                  }}
                                >
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={() => this.setState({show_meds_window: true})}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648195/myscripts-white_xxfvnq.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/myscripts-black-01_lnjyt9.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                        paddingBottom: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >{this.state.content.protocol_home_meds}
                                    </Button>
                            </NotificationCircle>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={() => this.setState({show_drugstore_window: true})}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/drugstore-white_fl9zxg.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/drugstore-black_n1wdde.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                        paddingBottom: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >Drugstore</Button>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={() => this.setState({show_labs_window: true})}
                                            startEnhancer={() => <img style={{height:'22px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/labs-white_hkgbji.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/labs-black-01_prkde2.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                        paddingBottom: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '4px'
                                                    })
                                                }
                                            }}
                                        >{this.state.content.protocol_home_labs}</Button>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={() => this.setState({show_share_window: true})}
                                            //startEnhancer={() => <img style={{height:'22px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/labs-white_hkgbji.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680648194/labs-black-01_prkde2.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                        paddingBottom: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                    })
                                                },
                                                //StartEnhancer: {
                                                   //style: ({ $theme }) => ({
                                                      //marginRight: '4px'
                                                    //})
                                                //}
                                            }}
                                        >+&nbsp;Invite&nbsp;members</Button>
                    </FlexGridItem>
                    {this.state.enable_community_features ? <FlexGridItem {...itemProps}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={() => this.setState({show_community_window: true})}
                                            startEnhancer={() => <img style={{height:'20px'}} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681172446/community-white_saf8e9.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681172446/community-black_te92a9.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                        paddingBottom: ['XSMALL'].includes(Screen.size()) ? '16px':'20px',
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >Community (&nbsp;{this.state.members.length}&nbsp;)</Button>
                    </FlexGridItem> : null }
                    <FlexGridItem {...itemProps}>
                                    <StyledLink
                                            href="#"
                                            onClick={() => this.setState({show_subscription_window: true})}
                                            style={{ color: '#CBCBCB' }}
                                        >{this.state.content.protocol_home_subscription}</StyledLink>
                    </FlexGridItem>
                </FlexGrid>);
        } else {
            return (
                <ButtonGroup
                mode={MODE.radio}
                shape={SHAPE.pill}
                size={SIZE.compact}
                selected={this.state.selectedTab}
                onClick={(event, index) => {this.setSelectedTab(event, index);}}
                overrides={{
                    Root: {
                        style: ({$theme}) => ({
                            maxWidth: 0.95 * window.screen.width + 'px',
                            /* overflowX: 'auto', */
                            boxSizing: 'border-box',
                            padding: '0px',
                            marginTop: '18px',
                            marginRight: '0px',
                            marginBottom: '18px',
                            marginLeft: '0px',
                        }),
                    },
                }}>
                    {
                        this.state.new_meds_unseen > 0
                        ? <Button
                        style={{marginLeft: '0px', marginRight: '4px'}}
                        onClick={this.showMeds}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    display: 'inline',
                                })
                            }
                        }}
                        endEnhancer={() => <span style={{position: 'relative', display: 'inline'}}>
                        <span style={{
                            height: '10px',
                            border: "3px solid #ff4500",
                            display: 'inline',
                            position: 'absolute',
                            top: '-31px',
                            left: '50px',
                            padding: "0px 5px 0px 5px",
                            color: 'white',
                            backgroundColor: '#ff4500',
                            borderRadius: '10px'}}></span>
                    </span>}>{this.state.content.protocol_home_meds}</Button>
                        : <Button style={{marginLeft: '0px'}} onClick={this.showMeds}>{this.state.content.protocol_home_meds}</Button>
                    }

                <Button style={{marginLeft: '0px', marginRight: '4px'}} onClick={this.showDrugstore}>{this.state.content.protocol_home_drugstore}</Button>
                <Button style={{marginLeft: '0px', marginRight: '4px'}} onClick={this.showLabs}>{this.state.content.protocol_home_labs}</Button>
                <Button style={{marginLeft: '0px', marginRight: '4px'}} onClick={this.showSubscription}>{this.state.content.protocol_home_subscription}</Button>
            </ButtonGroup>);
        }
    };

    /*prescriptionsSeenByPatient = (new_meds) => {
        if (new_meds) {
            setTimeout(() => {
                Logger.log('Setting new prescription to seen');
                this.markMedsSeen();
            }, 2000);
        }
    };*/

    markMedsSeen = () => {
        const data = new FormData();
        data.append('protocol_subscription_id', this.state.subscription.id)

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_prescriptions/seen/",
            body: data,
            callbackSuccess: result => {
                this.setState({new_meds_unseen: 0});
            },
            callbackError: error => {}
        })
    };

    renderMeds = () => {
        if (this.state.show_meds && this.state.subscription) {
            return <div>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_meds_title}</HeadingSmall>
                <ParagraphSmall style={{marginTop: '0px', marginBottom: '0px'}}>{this.state.content.protocol_home_meds_paragraph_1}</ParagraphSmall>
                {Utils.renderSpace()}
                <Meds subscription={this.state.subscription}/>
            </div>
        }
    };

    renderMedsWindow = () => {
        if (this.state.show_meds_window && this.state.subscription) {
            return <div>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_meds_title}</HeadingSmall>
                <ParagraphSmall style={{marginTop: '0px', marginBottom: '0px'}}>{this.state.content.protocol_home_meds_paragraph_1}</ParagraphSmall>
                {Utils.renderSpace()}
                <Meds subscription={this.state.subscription}/>
            </div>
        }
    };

    submitOrder = () => {
        const data = new FormData();
        data.append('order_id', this.state.wizard_order_id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_onboarding/checkout",
            body: data,
            callbackSuccess: (result) => this.setState({wizard_index: WIZARD_DRUGSTORE_IDX}),
            callbackError: (error) => this.setState({
                error: error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    setDrugStoreCompleteCallback = (nextDestination, data) => {
        // No longer going to a shipping page in drug requests. Everything is in task list.
        this.setState({wizard_index: WIZARD_TASKLIST_IDX, wizard_order_id: data.order_id})
    };

    setTaskListComplete = () => {
        // Submit order
        this.submitOrder();
        this.setState({show_drugstore_window: false});
        alert('Request submitted!')
    };

    isDrugRequestFileAttachEnabled = () => {
        const fileAttach = (element) => element.task_type === 'file_attach' && element.context_type === 'drug_request';
        return this.state.protocol && this.state.protocol.protocol_tasks.some(fileAttach);
    };

    renderDrugRequestWizard = () => {
        if (this.state.show_drugstore||this.state.show_drugstore_window) {
            switch (this.state.wizard_index) {
                case WIZARD_DRUGSTORE_IDX:
                    return <Drugstore protocol_id={this.protocol_id} canContinue={this.setDrugStoreCompleteCallback}/>
                case WIZARD_TASKLIST_IDX:
                    return <ToasterContainer autoHideDuration={2000} closeable={false} overrides={{ ToastBody: { style: () => ({/*width:'fit-content'*/})},}}>
                    <Tasklist
                        mainButtonName="Submit"
                        isOnboarding={false}
                        protocol_id={this.protocol_id}
                        order_id={this.state.wizard_order_id}
                        protocol_tasks={this.state.protocol ? this.state.protocol.protocol_tasks : null}
                        enableBackgroundInformation={true}
                        enableDrugRequestForm={true}
                        enablePrescriptionRouting={true}
                        enableDrugRequestFileAttach={this.state.protocol ? this.isDrugRequestFileAttachEnabled() : null}
                        enableOnboardingFileAttach={false}
                        canContinue={this.setTaskListComplete}
                        /*={() => {
                            let toastKey;
                            const msg =
                              "Request submitted!";
                            toastKey = toaster.info(
                              <>
                                {msg}
                              </>,
                              {
                                onClose: () => console.log("Toast closed."),
                                overrides: {
                                  InnerContainer: {
                                    style: { width: "100%", textAlign: "center"}
                                  }
                                }
                              }
                            );
                          }}*/
                        /></ToasterContainer>
                default:
                    return <Drugstore protocol_id={this.protocol_id} canContinue={this.setDrugStoreCompleteCallback}/>
            }
        }
    };

    renderDrugstore = () => {
        if (this.state.show_drugstore) {
            return <div>
                {this.state.wizard_index === WIZARD_DRUGSTORE_IDX
                ? <>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.subscription.protocol.name} {this.state.content.protocol_home_drugstore_title}</HeadingSmall>
                <ParagraphSmall style={{marginTop: '0px', marginBottom: '0px'}}>{this.state.content.protocol_home_drugstore_paragraph_1}</ParagraphSmall>
                </>
                : null}


                {this.renderDrugRequestWizard()}
                <FlexGrid margin={'10px'}><FlexGridItem {...itemProps}>{this.state.wizard_index != WIZARD_DRUGSTORE_IDX ? <Button kind={KIND.tertiary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => this.setState({wizard_index: this.state.wizard_index-1})}>Back</Button> : null}</FlexGridItem></FlexGrid>
            </div>
        }
    };

    renderDrugstoreWindow = () => {
        if (this.state.show_drugstore_window) {
            return <div>
                {this.state.wizard_index === WIZARD_DRUGSTORE_IDX
                ? <>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.subscription.protocol.name} {this.state.content.protocol_home_drugstore_title}</HeadingSmall>
                <ParagraphSmall style={{marginTop: '0px', marginBottom: '0px'}}>{this.state.content.protocol_home_drugstore_paragraph_1}</ParagraphSmall>
                </>
                : null}


                {this.renderDrugRequestWizard()}
                <FlexGrid margin={'10px'}><FlexGridItem {...itemProps}>{this.state.wizard_index != WIZARD_DRUGSTORE_IDX ? <Button kind={KIND.tertiary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => this.setState({wizard_index: this.state.wizard_index-1})}>Back</Button> : null}</FlexGridItem></FlexGrid>
            </div>
        }
    };

    renderLabs = () => {
        if (this.state.show_labs) {
            return <div>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_labs_title}</HeadingSmall>
                <ParagraphSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_labs_paragraph_1}</ParagraphSmall>
                <Labs />
            </div>
        }
    };

    renderLabsWindow = () => {
        if (this.state.show_labs_window) {
            return <div>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_labs_title}</HeadingSmall>
                <ParagraphSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_labs_paragraph_1}</ParagraphSmall>
                <Labs />
            </div>
        }
    };

    copyToClipboard = () => {
        if (!this.inputRef.current) return;
    
        // Select the URL text inside the input element
        this.inputRef.current.select();
        this.inputRef.current.setSelectionRange(0, 99999); // For mobile devices
    
        // Copy the selected text to the clipboard
        document.execCommand('copy');
    
        // Deselect the text
        this.inputRef.current.blur();
    
        // Show a toast message to indicate successful copy
        toaster.positive('URL copied to clipboard');
      };

    renderShareWindow = () => {
        if (this.state.show_share_window) {
            return <div>
                <HeadingSmall style={{marginTop: '0px', marginBottom: '10px'}}>{this.state.content.protocol_home_share_title}</HeadingSmall>
                <img src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_76,r_76/v1692647482/200w_fvg6bu.gif" style={{ height:"76px", borderRadius:"76px"}}/>
                <ParagraphSmall style={{marginTop: '6px', marginBottom: '14px'}}>If you like this clinic, please share it with others. {this.state.subscription.protocol.name} is entirely member supported. Without subscibers like you, this clinic wouldn’t exist.</ParagraphSmall>
                <ToasterContainer autoHideDuration={2000} closeable={false} overrides={{ ToastBody: { style: () => ({/*width:'fit-content'*/})},}}>
                    <Input
                    value={['https://www.clinic.dev' + ROUTES.PROTOCOL_VIEW + '/' + this.state.protocol_id]}
                    readOnly
                    overrides={{
                        Input: {
                            style: ({ $theme }) => ({
                              paddingLeft:'0px'
                            })
                          },
                            InputContainer: {
                              style: ({ $theme }) => ({
                                alignItems:'center',
                                paddingLeft:'8px',
                                paddingRight:'8px'
                              })
                            },
                        After: () => (
                        <Button
                        shape={SHAPE.pill} 
                        size={SIZE.compact} 
                        kind={KIND.primary}
                        startEnhancer={() => <img style={{height:'18px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_18/v1681409754/copy-white-01_dv3pr0.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_18/v1681409754/copy-black-01_j0lgrq.png"}/>}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    height:"36px",
                                })
                            },
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                    marginRight:'4px'
                                })
                            }
                          }}
                        onClick={() => {
                            Utils.copyToClipboard(['https://www.clinic.dev' + ROUTES.PROTOCOL_VIEW + '/' + this.state.protocol_id]);
                            let toastKey;
                            const msg =
                            "Link copied!";
                            toastKey = toaster.info(
                            <>
                                {msg}
                            </>,
                            {
                                onClose: () => console.log("Toast closed."),
                                overrides: {
                                InnerContainer: {
                                    style: { width: "100%", textAlign: "center"}
                                }
                                }
                            }
                            );
                        }}
                        >Copy</Button>
                        ),
                    }}
                    />
            </ToasterContainer>
            </div>
        }
    };

    //renderChat = () => {
    //    if (this.state.show_chat) {
    //        return <div>
    //        <HeadingSmall style={{marginTop: '0px', marginBottom: '15px'}}>{this.state.content.protocol_home_chat_title}</HeadingSmall>
    //        <ParagraphSmall style={{marginTop: '0', marginBottom: '15px'}}><Presence status={this.state.creator.presence}/> {this.state.creator.username}</ParagraphSmall>
    //        <ParagraphSmall style={{marginTop: '0px', marginBottom: '0px'}}>{this.state.content.protocol_home_chat_paragraph_1}</ParagraphSmall>
    //        <LabelSmall style={{marginTop: '15px', marginBottom: '15px'}}>{Utils.renderPhone(this.props.firebase.getPhoneNumber())}</LabelSmall>
    //        <Chat protocol_id={this.protocol_id} />
    //    </div>
    //    }
    //};

    renderSubscription = () => {
        if (this.state.show_subscription || (this.state.subscription && this.state.subscription.status == 'inactive')) {
            return <Subscription
                protocol_id={this.protocol_id}
                callbackError={this.callbackError}
                callbackSuccess={this.callbackSuccess}
                content={this.state.content}
            />
        }
    };

    renderSubscriptionWindow = () => {
        if (this.state.show_subscription_window || (this.state.subscription && this.state.subscription.status == 'inactive')) {
            return <Subscription
                protocol_id={this.protocol_id}
                callbackError={this.callbackError}
                callbackSuccess={this.callbackSuccess}
                content={this.state.content}
            />
        }
    };

    renderCommunity = () => {
        if (this.state.show_community) {
            return <div>
                {/*<LabelMedium style={{marginTop: '20px', marginBottom: '0'}}>Support staff</LabelMedium>
                <ParagraphSmall style={{margin: '0'}}><Presence status={this.state.creator.presence}/> {this.state.creator.username} {Utils.crownIcon()}</ParagraphSmall>*/}
                <LabelMedium style={{marginTop: '20px', marginBottom: '0'}}>You</LabelMedium>
                <StyledLink className='a_no_line' href={"/account"}><ParagraphSmall style={{margin: '0'}}> <Presence status={'online'}/> {this.state.logged_in_user}</ParagraphSmall></StyledLink>
                <LabelMedium style={{marginTop: '20px', marginBottom: '0'}}>{this.state.content.protocol_community_members}</LabelMedium>
                {this.state.members.map((member, idx) => member.username && member.username !== this.state.logged_in_user && <ParagraphSmall style={{margin: '0', cursor: 'pointer'}} key={idx} onClick={(e) => this.setState({
                    dm_user: member,
                    dm_show: true
                })}><Presence status={member.presence}/> {member.username}</ParagraphSmall>)}
            </div>
        }
    };

    redirectBannedUser = () => {
        if (this.state.isLoaded && this.state.subscription && this.state.subscription.status == 'dead') {
            this.props.history.push(ROUTES.SUBSCRIPTION_STATUS + '/' + this.protocol_id);
        }
    };

    renderDrInfo = () => {
        if (this.state.subscription && this.state.subscription.protocol) {
            return <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}><span>{this.state.subscription.protocol.office_hours}</span></Linkify>
        }
        return null
    };

    renderMetaTags = () => {
        if (this.state.subscription && this.state.subscription.protocol) {
            return <MetaTagsOverride
                page={MetaTagPages.ProtocolHome}
                protocol_name={this.state.subscription.protocol.name}
                protocol_summary={this.state.subscription.protocol.summary}
                protocol_number={this.state.subscription.protocol.number}
                protocol_favicon={this.state.subscription.protocol.favicon_url}
            />
        }

        return null;
    };

    renderDM = () => {
        return <MpsModal
            open={this.state.dm_show}
            callbackCancel={() => this.setState({dm_show: false})}
            overrideModalWidth={'343px'}
            body={
                this.state.dm_user ?
                <FlexGrid flexGridRowGap={"scale200"}>
                    <FlexGridItem>
                        <Avatar
                            name={this.state.dm_user.username}
                            size={AvatarSize.LARGE}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <HeadingSmall style={{margin: this.state.content.dm_username_margins}}><Presence status={this.state.dm_user.presence}/> {this.state.dm_user.username} {this.state.dm_user.username == this.state.logged_in_user ? Utils.crownIcon() : ''}</HeadingSmall>
                    </FlexGridItem>
                    <FlexGridItem>
                        <ParagraphXSmall style={{color: 'gray', margin: '0px'}}>joined {Utils.renderTimeAgo(this.state.dm_user.created_at)}</ParagraphXSmall>
                    </FlexGridItem>
                    <FlexGridItem {...borderBottomProp}></FlexGridItem>
                    <FlexGridItem>
                        <LabelMedium style={{margin: this.state.content.dm_about_margins}}>About</LabelMedium>
                    </FlexGridItem>
                    <FlexGridItem>
                        <ParagraphSmall style={{margin: 0}}>{this.state.dm_user.blurb ? this.state.dm_user.blurb : this.state.dm_user.username + " hasn't written anything."}</ParagraphSmall>
                    </FlexGridItem>
                    <FlexGridItem>
                        <Button
                            size={SIZE.compact}
                            shape={SHAPE.pill}
                            kind={KIND.secondary}
                            disabled={true}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        margin: this.state.content.dm_button_margins
                                    })
                                }
                            }}
                        >Direct message</Button>
                    </FlexGridItem>
                </FlexGrid>
                : null
            }
        />
    };

    setChatTab = (e, idx) => {
        let options = {chat_tab: idx};
        if (idx === 1) {
            options['support_messages_unseen'] = 0
        }

        this.setState(options);
    }

    renderChatRoom = () => {
        if (!this.state.enable_community_features) {
          return null;
        }
        Logger.log("Messages seen: " + this.state.support_messages_unseen);
        if (['XSMALL','SMALL', 'MEDIUM', 'LARGE', 'XLARGE'].includes(Screen.size())) {
            return <Drawer
                isOpen={this.state.show_chat_room}
                onClose={() => this.setState({show_chat_room: false})}
            >
                <ChatRoom
                    room_id={this.protocol_id}
                    username={this.state.logged_in_user}
                    protocol={this.state.subscription.protocol}
                />
            </Drawer>
        } else {
            if (!this.state.show_community) {
                rightLineProps = {};
            } else {
                rightLineProps = {
                    overrides: {
                        Block: {
                            style: ({$theme}) => ({
                                borderRightWidth: '1px',
                                borderRightStyle: 'solid',
                                borderRightColor: '#7f7f7f47',
                                padding: '0px 0px 0px 0px'
                            }),
                        },
                    },
                };
            }
            return <div>
                <FlexGrid flexGridColumnCount={2} {...chatHeaderProps}>
                    <FlexGridItem>
                        <ButtonGroup
                            selected={this.state.chat_tab}
                            size={SIZE.compact}
                            shape={SHAPE.pill}
                            mode={MODE.radio}
                            onClick={(event, index) => {
                                this.setChatTab(event, index);
                            }}
                        >

                            <Button style={{display:"none"}}>Announcements</Button>
                            {
                                this.state.support_messages_unseen > 0 && this.state.chat_tab !== 1
                                ? <Button
                                style={{marginLeft: '4px'}}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            display: 'inline',
                                            marginRight: '4px',
                                        })
                                    }
                                }}
                                endEnhancer={() => <span style={{position: 'relative', display: 'inline'}}>
                                <span style={{
                                    height: '10px',
                                    border: "3px solid #ff4500",
                                    display: 'inline',
                                    position: 'absolute',
                                    top: '-31px',
                                    left: '57px',
                                    padding: "0px 5px 0px 5px",
                                    color: 'white',
                                    backgroundColor: '#ff4500',
                                    borderRadius: '10px'}}></span>
                            </span>}>Messenger</Button>
                                : <Button>Messenger</Button>
                            }
                            <Button style={{display:"none"}} disabled={!['active', 'active_pending_cancel'].includes(this.state.subscription.status)}>Community</Button>
                        </ButtonGroup>
                    </FlexGridItem>
                    <FlexGridItem style={{display:"none"}} {...chatRightButtonsProps}>
                        <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale200'}>
                            <FlexGridItem><Button size={SIZE.compact} shape={SHAPE.circle} kind={KIND.tertiary} onClick={() => this.setState({show_community: !this.state.show_community})}>{this.communityIcon()}</Button></FlexGridItem>
                            <FlexGridItem><Button size={SIZE.compact} shape={SHAPE.circle} kind={KIND.tertiary} >{this.settingsIcon()}</Button></FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>
                </FlexGrid>

                <FlexGrid flexGridColumnCount={this.state.show_community ? 2 : 1} flexGridColumnGap={'scale800'}>
                    <FlexGridItem {...rightLineProps} padding={0}>
                        {['active', 'active_pending_cancel'].includes(this.state.subscription.status)
                            ?
                            <ChatRoom
                                room_id={this.protocol_id}
                                username={this.state.logged_in_user}
                                protocol={this.state.subscription.protocol}
                                parent_height={this.state.containerHeight}
                                show={this.state.chat_tab === 2}
                            />
                            : null
                        }
                        {this.state.chat_tab === 0 ? /*<Announcements
                            room_id={this.protocol_id}
                            username={this.state.logged_in_user}
                            protocol={this.state.subscription.protocol}
                            parent_height={this.state.containerHeight}
                            />*/null : null}
                        {this.state.chat_tab === 1 ? <Chat
                            protocol_id={this.protocol_id}
                            room_id={this.protocol_id}
                            username={this.state.logged_in_user}
                            protocol={this.state.subscription.protocol}
                            parent_height={this.state.containerHeight}
                        /> : null}
                    </FlexGridItem>
                    {this.state.show_community ?
                        <FlexGridItem
                            maxWidth={'180px'}
                            height={this.state.containerHeight - (66 + 28 + 28 + 5) + 'px'}
                            style={{overflowY: 'auto'}}
                        >
                            {this.renderCommunity()}
                        </FlexGridItem> : null
                    }
                </FlexGrid>
            </div>
        }
    };

    renderChatRoomWindow = () => {
         {
            if (!this.state.show_community) {
                rightLineProps = {};
            } else {
                rightLineProps = {
                    overrides: {
                        Block: {
                            style: ({$theme}) => ({
                                borderRightWidth: '1px',
                                borderRightStyle: 'solid',
                                borderRightColor: '#7f7f7f47',
                                padding: '0px 0px 0px 0px'
                            }),
                        },
                    },
                };
            }
            return <div>
                <FlexGrid flexGridColumnCount={2} {...chatHeaderProps}>
                    <FlexGridItem>
                        <ButtonGroup
                            selected={this.state.chat_tab}
                            size={SIZE.compact}
                            shape={SHAPE.pill}
                            mode={MODE.radio}
                            onClick={(event, index) => {
                                this.setChatTab(event, index);
                            }}
                        >
                            <Button>Announcements</Button>
                            <Button disabled={!['active', 'active_pending_cancel'].includes(this.state.subscription.status)}>Community</Button>
                        </ButtonGroup>
                    </FlexGridItem>
                    <FlexGridItem {...chatRightButtonsProps}>
                        <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale200'}>
                            <FlexGridItem><Button size={SIZE.compact} shape={SHAPE.circle} kind={KIND.tertiary} onClick={() => this.setState({show_community: !this.state.show_community})}>{this.communityIcon()}</Button></FlexGridItem>
                            {/*<FlexGridItem><Button size={SIZE.compact} shape={SHAPE.circle} kind={KIND.tertiary} >{this.settingsIcon()}</Button></FlexGridItem>*/}
                        </FlexGrid>
                    </FlexGridItem>
                </FlexGrid>

                <FlexGrid flexGridColumnCount={this.state.show_community ? 2 : 1} flexGridColumnGap={'scale800'}>
                    <FlexGridItem {...rightLineProps} padding={0}>
                        {['active', 'active_pending_cancel'].includes(this.state.subscription.status)
                            ?
                            <ChatRoom
                                room_id={this.protocol_id}
                                username={this.state.logged_in_user}
                                protocol={this.state.subscription.protocol}
                                parent_height={this.state.containerHeight}
                                show={this.state.chat_tab === 1}
                            />
                            : null
                        }
                        {this.state.chat_tab === 0 ? <Announcements
                            room_id={this.protocol_id}
                            username={this.state.logged_in_user}
                            protocol={this.state.subscription.protocol}
                            parent_height={this.state.containerHeight}
                            /> : null}
                    </FlexGridItem>
                    {this.state.show_community ?
                        <FlexGridItem
                            maxWidth={'180px'}
                            height={this.state.containerHeight - (66 + 28 + 28 + 5) + 'px'}
                            style={{overflowY: 'auto'}}
                        >
                            {this.renderCommunity()}
                        </FlexGridItem> : null
                    }
                </FlexGrid>
            </div>
        }
    };

    communityIcon = () => {

        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Community list"}
        showArrow
        returnFocus
        autoFocus
        placement={PLACEMENT.bottomRight}
         >
        <svg
            x="0"
            y="0"
            className="ScIconSVG-sc-1bgeryd-1 hcjcOH"
            viewBox="0 0 20 20"
            width="20px"
        >
            <path
            fill={window.matchMedia("(prefers-color-scheme: dark)").matches ? "#ffffff" : "#000000"}
            fillRule="evenodd"
            d="M7 2a4 4 0 00-1.015 7.87A1.334 1.334 0 014.667 11 2.667 2.667 0 002 13.667V18h2v-4.333c0-.368.298-.667.667-.667A3.32 3.32 0 007 12.047 3.32 3.32 0 009.333 13c.369 0 .667.299.667.667V18h2v-4.333A2.667 2.667 0 009.333 11c-.667 0-1.22-.49-1.318-1.13A4.002 4.002 0 007 2zM5 6a2 2 0 104 0 2 2 0 00-4 0z"
            clipRule="evenodd"
            ></path>
            <path fill={window.matchMedia("(prefers-color-scheme: dark)").matches ? "#ffffff" : "#000000"} d="M14 11.83V18h4v-3.75c0-.69-.56-1.25-1.25-1.25a.75.75 0 01-.75-.75v-.42a3.001 3.001 0 10-2 0z"></path>
        </svg>
        </StatefulTooltip>
    };

    settingsIcon = () => {
        return <StatefulTooltip
        accessibilityType={'tooltip'}
        content={"Settings (under construction)"}
        showArrow
        returnFocus
        autoFocus
         >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            className="ScIconSVG-sc-1bgeryd-1 hcjcOH"
            viewBox="0 0 20 20"
            width="20px"
        >
            <path fill={window.matchMedia("(prefers-color-scheme: dark)").matches ? "#ffffff" : "#000000"} d="M10 8a2 2 0 100 4 2 2 0 000-4z"></path>
            <path
            fill={window.matchMedia("(prefers-color-scheme: dark)").matches ? "#ffffff" : "#000000"}
            fillRule="evenodd"
            d="M9 2h2a2.01 2.01 0 001.235 1.855l.53.22a2.01 2.01 0 002.185-.439l1.414 1.414a2.01 2.01 0 00-.439 2.185l.22.53A2.01 2.01 0 0018 9v2a2.01 2.01 0 00-1.855 1.235l-.22.53a2.01 2.01 0 00.44 2.185l-1.415 1.414a2.01 2.01 0 00-2.184-.439l-.531.22A2.01 2.01 0 0011 18H9a2.01 2.01 0 00-1.235-1.854l-.53-.22a2.009 2.009 0 00-2.185.438L3.636 14.95a2.009 2.009 0 00.438-2.184l-.22-.531A2.01 2.01 0 002 11V9c.809 0 1.545-.487 1.854-1.235l.22-.53a2.009 2.009 0 00-.438-2.185L5.05 3.636a2.01 2.01 0 002.185.438l.53-.22A2.01 2.01 0 009 2zm-4 8l1.464 3.536L10 15l3.535-1.464L15 10l-1.465-3.536L10 5 6.464 6.464 5 10z"
            clipRule="evenodd"
            ></path>
        </svg>
        </StatefulTooltip>
    };

    render = () => {
        this.redirectBannedUser();
        this.redirectStatusChange();

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div style={{marginTop:"66px"}}>
                        {this.renderMetaTags()}
                        {Utils.renderSuccess(this.state.success)}
                        {Utils.renderError(this.state.error)}
                    {
                        this.state.isLoaded ?
                            this.state.subscription && ['active', 'active_pending_cancel'].includes(this.state.subscription.status)
                            ?
                            <FlexGrid
                                flexGridColumnCount={1}
                                justifyContent={'center'}
                            >
                                <FlexGridItem
                                     /*this.state.containerHeight + 'px'*/
                                    style={
                                            {display: 'grid',
                                            flexWrap: 'wrap',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            position:'fixed',
                                            overflowY:'hidden',
                                            height:'87%'
                                            }}
                                >
                                    {this.renderProtocolAttributes()}
                                    {this.renderProtocolOptions()}
                                    {this.renderDM()}
                                </FlexGridItem>
                                <FlexGridItem>
                                    {this.renderChatRoom()}
                                </FlexGridItem>
                            </FlexGrid>
                            : <FlexGrid
                            flexGridColumnCount={1}
                            justifyContent={'center'}
                            >
                            <FlexGridItem style={
                            {display: 'grid',
                            alignContent: 'center',
                            justifyContent: 'center',
                            position:'fixed',
                            overflowY:'hidden',
                            height: '85vh'
                            }}>
                                {this.renderProtocolAttributes()}
                                {this.renderSubscription()}
                            </FlexGridItem></FlexGrid>
                        : <div>
                            {Utils.renderSpace()}
                            {Utils.renderSpace()}
                            <FlexGrid flexGridColumnCount={1}>
                                <FlexGridItem {...itemProps}><Spinner className={"dead_center"} $size={SpinnerSize.large} /></FlexGridItem>
                            </FlexGrid>
                        </div>
                    }
                    {this.presentChat()}
                    {this.presentMeds()}
                    {this.presentDrugstore()}
                    {this.presentLabs()}
                    {this.presentShare()}
                    {this.presentSubscription()}
                    {this.presentCommunity()}
                    {setNoScroll()}
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ProtocolHome);
