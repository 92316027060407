import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Button, SIZE, SHAPE, KIND} from 'baseui/button';
import { ArrowUp } from "baseui/icon";

class Labs extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser => (
                <Button
                    $as={"a"}
                    href={'https://www.ultalabtests.com/partners/clinic/testing/search'}
                    target={'_blank'}
                    kind={KIND.secondary}
                    shape={SHAPE.pill}
                    size={SIZE.default}
                >Get labs ↗</Button>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Labs);
