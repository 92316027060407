export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_RESET = '/password_reset';
export const PASSWORD_CHANGE = '/password_change';
export const HOME = '/';
export const OPS = '/ops';
export const MFA_ENROLL = '/mfa/enroll';

let base_domain = process.env.BASE_DOMAIN;
let dev_domain = "lvh.me";

const DEVELOPMENT_ENV = 'development';
const PRODUCTION_ENV = 'production';
const STAGING_ENV = 'staging';

const DEV_BASE_URL = 'http://ops.' + dev_domain + ':3000';
const PROD_BASE_URL = 'https://ops.' + base_domain;
const STAGING_BASE_URL = 'https://ops.staging.' + base_domain;

const DEV_BASE_URL_API = '';
const PROD_BASE_URL_API = '';
const STAGING_BASE_URL_API = '';

let base_url = PROD_BASE_URL;
let base_url_api = PROD_BASE_URL_API;
if (process.env.RAILS_ENV === DEVELOPMENT_ENV) {
    base_url = DEV_BASE_URL;
    base_url_api = DEV_BASE_URL_API;
} else if (window.location.hostname == 'ops.staging.' + base_domain) {
    base_url = STAGING_BASE_URL;
    base_url_api = STAGING_BASE_URL_API;
}

export const BASE_URL = base_url;
export const BASE_URL_API = base_url_api;
