import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {withAuthentication} from '../../shared/Session'
import MFAEnroll from '../../shared/MFA/enroll';

import Navigation from '../../shared/Navigation';
import Home from '../Home';
import SignInPage from '../../shared/SignIn';
import SignUpPage from '../../shared/SignUp';
import PasswordResetPage from '../../shared/Password/reset';
import NotFound from '../../shared/NotFound';
import {ProtocolView, ProtocolSubscribe} from '../Protocol';
import Shipping from '../Shipping';
import {OrderReview} from '../Order';
import {Drugstore} from '../Drugstore';
import Subscription from '../Subscriptions';
import Dashboard from '../Dashboard';
import Chat from '../Chat';
import SubscriptionStatus from '../Subscriptions/status';
import StatusPending from '../Subscriptions/pending';
import Tasklist from '../Tasklist';
import Account from '../Account';
import History from '../History';
import TransactionDetails from '../History/details';
import ProtocolHome from '../Protocol/home';
import Browse from '../Browse';
import OrderStatus from '../Order/status';
import ProtocolStatus from '../Protocol/status';

import * as ROUTES from '../Constants';

import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, DarkTheme, BaseProvider, styled} from 'baseui';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Screen from "../../shared/Utils/screen";
import Creds from '../Creds';
import Storage from '../../shared/Storage';
import {Layer} from 'baseui/layer';

const engine = new Styletron();

import './app.scss';
import '../../shared/Styles/style.scss';

import {createTheme, createDarkTheme, createLightTheme} from 'baseui';
import { useIdleTimer } from 'react-idle-timer'
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';
import Preview from '../Protocol/preview';
import { ErrorBoundary } from "react-error-boundary";
import { ParagraphLarge } from 'baseui/typography';
import { StyledLink } from 'baseui/link';

const primitives = {
  primaryFontFamily: 'RoobertRegular',
};

const overrides = {
    typography: {
        ParagraphXSmall: {fontFamily: 'RoobertRegular',fontWeight:"normal"},
        ParagraphSmall: {fontFamily: 'RoobertRegular',fontWeight:"normal"},
        ParagraphMedium: {fontFamily: 'RoobertRegular',fontWeight:"normal"},
        ParagraphLarge: {fontFamily: 'RoobertRegular',fontWeight:"normal"},

        LabelXSmall: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        LabelSmall: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        LabelMedium: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        LabelLarge: {fontFamily: 'RoobertMedium',fontWeight:"normal"},

        HeadingXSmall: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        HeadingSmall: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        HeadingMedium: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        HeadingLarge: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        HeadingXLarge: {fontFamily: 'RoobertMedium',fontWeight:"normal"},
        HeadingXXLarge: {fontFamily: 'RoobertMedium',fontWeight:"normal"},

        DisplayLarge: {fontFamily: 'RoobertSemiBold', fontSize: '78px', lineHeight: '68px',fontWeight:"normal"},
        DisplayMedium: {fontFamily: 'RoobertSemiBold', fontSize: '58px', lineHeight: '55px',fontWeight:"normal"},
        DisplaySmall: {fontFamily: 'RoobertSemiBold', fontSize: '38px', lineHeight: '44px',fontWeight:"normal"},
        DisplayXSmall: {fontFamily: 'RoobertSemiBold', fontSize: '20px', lineHeight: '28px',fontWeight:"normal"},
    },
};

/*let theme = createLightTheme(primitives, overrides);
if (['dark'].includes(Storage.get('theme'))) {
    theme = createDarkTheme(primitives, overrides);
} else {
    if ([null, undefined].includes(Storage.get('theme'))) {
        Storage.set('theme', 'light');
    }
}*/

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

/*const setBackgroundColor = () => {
    $(document.body).css("background-color", theme.colors.backgroundPrimary);

    return null;
};*/

const getResponsiveSpacing = () => {
    let css = {};

    if (['XSMALL','SMALL', 'MEDIUM'].includes(Screen.size())) {
        css.marginTop = '0px';
        css.marginRight = [ROUTES.HOME].includes(window.location.pathname) || window.location.pathname.match(/\/protocol\/[0-9]{9}/g) ? '0px' : '16px';
        css.marginBottom = '0px';
        css.marginLeft = [ROUTES.HOME].includes(window.location.pathname) || window.location.pathname.match(/\/protocol\/[0-9]{9}/g) ? '0px' : '16px';
    } else {
        css.marginTop = '0px';
        css.marginRight = '24px';
        css.marginBottom = [ROUTES.HOME, ROUTES.BROWSE, ROUTES.PHYSICIAN_CREDS].includes(window.location.pathname) ? '0px' : '0px';
        css.marginLeft = '24px';
    }

    return css;
};

function App(props) {

    const handleOnIdle = (event) => {
        if(props.firebase.isUserOnline()){
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_idle",
                callbackSuccess: result => {},
                callbackError: error => {
                    Logger.log('. . . . . . Could not mark user idle . . . . . . ');
                }
            });
        }
    }

    const handleOnActive = (event) => {
        if(props.firebase.isUserOnline()) {
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_online",
                callbackSuccess: result => {},
                callbackError: error => {
                    Logger.log('. . . . . . Could not mark user online . . . . . . ');
                }
            });
        }
    }

    const handleOnAction = (event) => {}

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    const onVisibilityChange = (event) => {
      if (document.visibilityState === "visible") {
        if(props.firebase.isUserOnline()) {
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_online",
                callbackSuccess: result => {},
                callbackError: error => {}
            });
        }

      } else {
        Request.get({
          firebase: props.firebase,
          route: ROUTES.BASE_URL_API + "/user_management/user_offline",
          callbackSuccess: result => {},
          callbackError: error => {}
        });
      }
      return "Exiting...";
    };

    const execOnBeforeUnload = (event) => {
      Request.get({
        firebase: props.firebase,
        route: ROUTES.BASE_URL_API + "/user_management/user_offline",
        callbackSuccess: result => {},
        callbackError: error => {}
      });
    };

    const [isDarkMode, setIsDarkMode] = useState(false);

    function Fallback({ error, resetErrorBoundary }) {
        // Call resetErrorBoundary() to reset the error boundary and retry the render.

        return (
          <div role="alert" style={{textAlign:"center"}}>
            <ParagraphLarge>Something went wrong...</ParagraphLarge>
            <ParagraphLarge style={{ color: "red", fontFamily:"bold"}}>{error.message}</ParagraphLarge>
            <ParagraphLarge>Contact support to report this issue:<br/>
            Text <StyledLink href="sms:1-646-389-5550" style={{color:"#00a1ff"}}>646-389-5550</StyledLink><br/>
            Message our <StyledLink href="https://www.reddit.com/r/clinicapp/" target="_blank" style={{color:"#00a1ff"}}>subbreddit</StyledLink><br/>
            Message our <StyledLink href="https://discord.gg/RkK77ajn24" target="_blank" style={{color:"#00a1ff"}}>discord</StyledLink>
            </ParagraphLarge>
          </div>
        );
      }



    useEffect(() => {

        if(props.firebase.isUserOnline()) {
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_online",
                callbackSuccess: result => {},
                callbackError: error => {}
            });

            window.addEventListener('visibilitychange', onVisibilityChange);
            window.addEventListener('pagehide', execOnBeforeUnload);
            window.addEventListener('beforeunload', execOnBeforeUnload);
        } else {
            window.removeEventListener('visibilitychange', onVisibilityChange);
            window.removeEventListener('pagehide', execOnBeforeUnload);
            window.removeEventListener('beforeunload', execOnBeforeUnload);
        }

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(mediaQuery.matches);

        const listener = (event) => setIsDarkMode(event.matches);
        mediaQuery.addEventListener('change', listener);

        return () => {
            mediaQuery.removeEventListener('change', listener);
        };
    }, []);


    const theme = isDarkMode ? createDarkTheme(primitives, overrides) : createLightTheme(primitives, overrides);

    return <StyletronProvider value={engine}>
    <BaseProvider theme={theme}>
    <ErrorBoundary FallbackComponent={Fallback}>
        <div id="div1">
            <Router>
            <FlexGrid
                flexGridColumnCount={1}
            >
                <Layer>
                <FlexGridItem>
                    <Navigation/>
                </FlexGridItem>
                </Layer>

            <FlexGridItem {...itemProps} style={getResponsiveSpacing()}>
                <div id="div2" style={{maxWidth: '570px', width: '100%'}}>
                    <Switch>
                    <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                    <Route exact path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                    <Route exact path={ROUTES.PROTOCOL_VIEW_SINGLE} component={ProtocolView} />
                    <Route exact path={ROUTES.PROTOCOL_SUBSCRIPTION} component={ProtocolSubscribe} />
                    <Route exact path={ROUTES.PROTOCOL_SUBSCRIPTION_REGISTER_ROUTE} component={ProtocolSubscribe} />
                    <Route exact path={ROUTES.PROTOCOL_SUBSCRIPTION_REAPPLY} component={ProtocolSubscribe} />
                    <Route exact path={ROUTES.SHIPPING} component={Shipping} />
                    <Route exact path={ROUTES.SHIPPING_PROTOCOL} component={Shipping} />
                    <Route exact path={ROUTES.SHIPPING_PROTOCOL_ORDER} component={Shipping} />
                    <Route exact path={ROUTES.ORDER_REVIEW} component={OrderReview} />
                    <Route exact path={ROUTES.ORDER_REVIEW_SHOW} component={OrderReview} />
                    <Route exact path={ROUTES.ORDER_STATUS} component={OrderStatus} />
                    <Route exact path={ROUTES.ORDER_STATUS_ID} component={OrderStatus} />
                    <Route exact path={ROUTES.DRUG_STORE} component={Drugstore} />
                    <Route exact path={ROUTES.DRUG_STORE_STANDALONE} component={Drugstore} />
                    <Route exact path={ROUTES.TASK_LIST} component={Tasklist} />
                    <Route exact path={ROUTES.TASK_LIST_DRUG_REQUEST} component={Tasklist} />
                    <Route exact path={ROUTES.MANAGE_SUBSCRIPTION} component={Subscription} />
                    <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
                    <Route exact path={ROUTES.CHAT} component={Chat} />
                    <Route exact path={ROUTES.CHAT_ID} component={Chat} />
                    <Route exact path={ROUTES.SUBSCRIPTION_STATUS} component={SubscriptionStatus} />
                    <Route exact path={ROUTES.SUBSCRIPTION_STATUS_ID} component={SubscriptionStatus} />
                    <Route exact path={ROUTES.PENDING} component={StatusPending} />
                    <Route exact path={ROUTES.PENDING_PROTOCOL_ID} component={StatusPending} />
                    <Route exact path={ROUTES.ACCOUNT} component={Account} />
                    <Route exact path={ROUTES.HISTORY} component={History} />
                    <Route exact path={ROUTES.HISTORY_DETAILS} component={TransactionDetails} />
                    <Route exact path={ROUTES.MFA_ENROLL} component={MFAEnroll} />
                    <Route exact path={ROUTES.PROTOCOL_STATUS} component={ProtocolStatus} />
                    <Route exact path={ROUTES.PROTOCOL_STATUS_ID} component={ProtocolStatus} />
                    </Switch>
                </div>
                </FlexGridItem>

                <FlexGridItem /*style={getResponsiveSpacing()}*/>
                    <Route exact path={ROUTES.PROTOCOL_HOME_ID} component={ProtocolHome} />
                    <Route exact path={ROUTES.HOME} component={Home} />
                    <Route exact path={ROUTES.BROWSE} component={Browse} />
                    <Route exact path={ROUTES.PHYSICIAN_CREDS} component={Creds} />
                    <Route exact path={ROUTES.PROTOCOL_PREVIEW} component={Preview} />
                    <Route exact path={ROUTES.PROTOCOL_PREVIEW_NUMBER} component={Preview} />
                </FlexGridItem>
            </FlexGrid>
            </Router>
            {/*setBackgroundColor()*/}
        </div>
        </ErrorBoundary>
    </BaseProvider>
    </StyletronProvider>

};

export default withAuthentication(App);
