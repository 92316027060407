import React, {Component} from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import Logger from '../Utils/logger';

const withAuthentication = (ComponentToRender) => {
    class WithAuthentication extends Component {
        constructor(props){
            super(props);

            this.state = { authUser: null, mounted: false};
        }

        componentDidMount = () => {
            this.firebaseAuthObserver = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    authUser ? this.setState({authUser, mounted: true}) : this.setState({authUser: null, mounted: true});
                }
            )
        }

        componentWillUnmount = () => {
            this.firebaseAuthObserver();
        }

        render() {
            if (this.state.mounted) {
                return (
                    <AuthUserContext.Provider value={this.state.authUser}>
                        <ComponentToRender {...this.props} />
                    </AuthUserContext.Provider>
                );
            }

            return null;
        }
    }

    return withFirebase(WithAuthentication);
};

export default withAuthentication;
