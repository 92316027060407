import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import InputSelect from '../../shared/Utils/input_select'
import {Button, SHAPE, SIZE, KIND} from 'baseui/button';
import { Input, MaskedInput } from "baseui/input";
import {LabelSmall, ParagraphSmall} from 'baseui/typography';
import { FormControl } from "baseui/form-control";
import MpsModal from '../../shared/Utils/modal';
import {Block} from 'baseui/block';
import { Alert } from "baseui/icon";
import {StatefulTooltip, TRIGGER_TYPE} from 'baseui/tooltip';
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';
import {Conditions, Medications, Allergies} from '../../shared/MedicalSearch';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';

const leftCenterProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const highlightTextProps = {
  overrides: {
      Block: {
        style: ({$theme}) => ({
          color: '#ff5722',
        }),
      },
  },
};

class BackgroundInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show ? this.props.show : false,
            isLoaded: false,
            isLoading: false,
            error: null,
            error_height: null,
            error_weight: null,
            message: null,

            disable_dob: false,
            dob: '',
            disable_gender: false,
            birth_gender: '',
            weight: '',
            height: '',
            conditions: [],
            conditions_add: false,
            allergies: [],
            allergies_add: false,
            medications: [],
            medications_add: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        this.getPatientInfo();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show, ...Utils.clearAllNotifications()});
        }
    };

    getPatientInfo = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_onboarding/load_patient_info",
            callbackSuccess: (result) => this.setPatientInfo(result),
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    savePatientInfo = () => {
        var data = {
            birthdate: this.state.dob ? this.state.dob : '',
            birth_sex: this.state.birth_gender,
            sex: this.state.birth_gender,
            weight: this.state.weight,
            height: this.state.height,
            reported_conditions_attributes: this.state.conditions.filter((val) => (val.short_name && val.short_name.length > 0)),
            reported_medications_attributes: this.state.medications.filter((val) => (val.short_name && val.short_name.length > 0)),
            reported_allergies_attributes: this.state.allergies.filter((val) => (val.short_name && val.short_name.length > 0))
        };

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_onboarding/background_info",
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify(data),
            callbackSuccess: (result) => {
                this.setState({show: false, isLoading: false, ...Utils.clearAllNotifications()});
                // This is called for the caller.
                // Remove the deleted ones so if the user opens up the Background info model and updates it
                // rails codes does not raise an exception because it cannot find the already deleted ones.
                this.setState({
                    conditions: result.conditions,
                    medications: result.medications,
                    allergies: result.allergies
                });

                this.callback();
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    isLoading: false,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    areThereErrors = () => {
        // Do not include the overall error object in this check as it is not automatically updated once the error has been fixed.
        return this.state.error_height != null || this.state.error_weight != null;
    };

    isFormReady = () => {
        return !this.areThereErrors()
            && this.state.dob != ''
            && this.state.birth_gender != ''
            && this.state.weight != ''
            && this.state.height != '';
    };

    setPatientInfo = (patient) => {
        Logger.log("_ _ _ _ _ _ _ _ _ _ Backgroung Information  _ _ _ _ _ _ _ _ _ _ ");
        Logger.log(patient);
        if (!patient['conditions']) {
            patient['conditions'] = [];
        }
        if (!patient['medications']) {
            patient['medications'] = [];
        }
        if (!patient['allergies']) {
            patient['allergies'] = [];
        }

        this.setState({
            isLoaded: true,
            first_name: patient['first_name'] || '',
            last_name: patient['last_name'] || '',
            dob: patient['birthdate'] || '',
            disable_dob: patient['birthdate'] != null,
            birth_gender: patient['birth_sex'] || '',
            disable_gender: patient['birth_sex'] != null,
            weight: patient['weight'] || '',
            height: patient['height'] || '',
            ...Utils.clearAllNotifications()
        }, () => this.callback());

        if (patient['conditions'].length > 0) {
            patient.conditions.map((val) => this.setState((prevState) => (
                {conditions: [
                    ...prevState.conditions,
                    {
                        id: val.id,
                        details: JSON.stringify(val.details),
                        short_name: val.short_name,
                        type: "ReportedCondition"
                    }
                ],
                ...Utils.clearAllNotifications()
                }
            )));
        }

        if (patient['allergies'].length > 0) {
            patient.allergies.map((val) => this.setState(
                (prevState) => ({
                    allergies: [
                        ...prevState.allergies,
                        {
                            id: val.id,
                            details: JSON.stringify(val.details),
                            short_name: val.short_name,
                            type: "ReportedAllergy"
                        }
                    ],
                    ...Utils.clearAllNotifications()
                })
            ));
        }

        if (patient['medications'].length > 0) {
            patient.medications.map((val) => this.setState(
                (prevState) => ({
                    medications: [
                        ...prevState.medications,
                        {
                            id: val.id,
                            details: JSON.stringify(val.details),
                            short_name: val.short_name,
                            type: "ReportedMedication"
                        }
                    ],
                    ...Utils.clearAllNotifications()
                })
            ));
        }
    };

    verifyHeight = (height) => {
        Logger.log(height);
        var err = 'Height must be a number and is limited to 99 inches';
        if (isNaN(height) || parseInt(height, 10) > 99) {
            this.setState({error_height: {message: err}, ...Utils.clearAllNotifications()});
        } else {
            if (this.state.error_height && this.state.error_height.message == err){
                this.setState({error_height: null, ...Utils.clearAllNotifications()});
            }
        }
    };

    verifyWeight = (weight) => {
        var err = 'Weight must be a number and is limited to 999 lbs';
        if (isNaN(weight) || parseInt(weight, 10) > 999) {
            this.setState({error_weight: {message: err}, ...Utils.clearAllNotifications()});
        } else {
            if (this.state.error_weight && this.state.error_weight.message == err){
                this.setState({error_weight: null, ...Utils.clearAllNotifications()});
            }
        }
    };

    handleChange = (event) => {
        Logger.log(event.target.name);
        Logger.log(event.target.value);
        if (event.target.name == 'height') {
            this.verifyHeight(event.target.value);
        } else if (event.target.name == 'weight') {
            this.verifyWeight(event.target.value);
        }

        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications() }, () => Logger.log(this.state));
    };

    handleBlur = (event) => {
        event.preventDefault();
        event.persist(); // Keeps the event as is to be passed on to the next method

        this.handleChange(event);
    };

    // Conitions
    newConditionEntry = () => {
        return { short_name: '', type: 'ReportedCondition'}
    };

    // Medications
    newMedicationEntry = () => {
        return {short_name: '', type: 'ReportedMedication'}
    };

    // Allergies
    newAllergyEntry = () => {
        return {short_name: '', type: 'ReportedAllergy'}
    };

    drawConditions = () => {
        return (
            <div>
                {Utils.renderTitle6('Medical conditions')}
                {Utils.renderSpace()}
                <LabelSmall>Add any diagnosed medical conditions you currently have.</LabelSmall>
                {Utils.renderSpace()}
                {this.renderConditionsToAdd()}
                <Button
                    style={{marginTop: '15px'}}
                    kind={KIND.primary}
                    shape={SHAPE.pill}
                    size={SIZE.compact}
                    onClick={(e) => this.setState({conditions_add: true})}
                >{this.state.conditions.length > 0 ? 'Update conditions' : 'Add condition'}</Button>
            </div>
        );
    };

    drawMedications = () => {
        return (
            <div>
                {Utils.renderTitle6('Current medications')}
                {Utils.renderSpace()}
                <LabelSmall>List all medications you are currently taking, including any supplements.</LabelSmall>
                {Utils.renderSpace()}
                {this.renderMedicationsToAdd()}
                <Button
                    style={{marginTop: '15px'}}
                    kind={KIND.primary}
                    shape={SHAPE.pill}
                    size={SIZE.compact}
                    onClick={(e) => this.setState({medications_add: true})}
                >{this.state.medications.length > 0 ? 'Update medications' : 'Add medication'}</Button>
            </div>
        );
    };

    drawAllergies = () => {
        return (
            <div>
                {Utils.renderTitle6('Drug allergies')}
                {Utils.renderSpace()}
                <LabelSmall>Add any known allergies you have to drugs or drug ingredients.</LabelSmall>
                {Utils.renderSpace()}
                {this.renderAllergiesToAdd()}
                <Button
                    style={{marginTop: '15px'}}
                    kind={KIND.primary}
                    shape={SHAPE.pill}
                    size={SIZE.compact}
                    onClick={(e) => this.setState({allergies_add: true}, () => Logger.log(this.state.allergies))}
                >{this.state.allergies.length > 0 ? 'Update allergies' : 'Add allergy'}</Button>
            </div>
        );
    };

    closeModal = () => {
        this.setState({show: false, ...Utils.clearAllNotifications()});

        this.props.callbackCancelHandler();
    };


    callback = () => {
        this.props.callbackCompleteHandler(this.isFormReady());
    };

    submit = () => {
        this.setState({isLoading: true, ...Utils.clearAllNotifications()});
        this.savePatientInfo();
    };

    dobChangeHandler = (dob) => {
        Logger.log(dob.target.value);
        this.setState({dob: dob ? dob : '', ...Utils.clearAllNotifications()});
    };

    // Conditions
    getConditions = () => {
        return this.state.conditions;
    };

    renderConditionsToAdd = () => {
        return this.state.conditions.map((condition, idx) => condition._destroy || !condition.short_name ? null : <FlexGrid key={idx}>
            <FlexGridItem {...leftCenterProps}><ParagraphSmall {...highlightTextProps} style={{margin: 0}}>{condition.short_name}</ParagraphSmall></FlexGridItem>
        </FlexGrid>);
    };

    addConditions = (new_conditions) => {
        this.setState({
            conditions: new_conditions,
            conditions_add: false
        })
    };

    cancelCondition = () => {
        this.setState({
            conditions_add: false
        })
    }

    // Medications
    getMedications = () => {
        return this.state.medications;
    };

    renderMedicationsToAdd = () => {
        return this.state.medications.map((medication, idx) => medication._destroy || !medication.short_name ? null : <FlexGrid key={idx}>
            <FlexGridItem {...leftCenterProps}><ParagraphSmall {...highlightTextProps} style={{margin: 0}}>{medication.short_name}</ParagraphSmall></FlexGridItem>
        </FlexGrid>);
    };

    addMedications = (new_medications) => {
        this.setState({
            medications: new_medications,
            medications_add: false
        })
    };

    cancelMedication = () => {
        this.setState({
            medications_add: false
        })
    }

    // Allergies
    getAllergies = () => {
        return this.state.allergies;
    };

    renderAllergiesToAdd = () => {
        return this.state.allergies.map((allergy, idx) => allergy._destroy || !allergy.short_name ? null : <FlexGrid key={idx}>
            <FlexGridItem {...leftCenterProps}>
                <ParagraphSmall {...highlightTextProps} style={{margin: 0}}>{allergy.short_name} {/*({typeof allergy.details == "string" ? JSON.parse(allergy.details).severity : allergy.details.severity})*/}</ParagraphSmall>
            </FlexGridItem>
        </FlexGrid>);
    };

    addAllergies = (new_allergies) => {
        this.setState({
            allergies: new_allergies,
            allergies_add: false
        })
    };

    cancelAllergy = () => {
        this.setState({
            allergies_add: false
        })
    }

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <MpsModal
                            open={this.state.show}
                            autoFocus={false}
                            callback={this.submit}
                            callbackCancel={this.closeModal}
                            isLoading={this.state.isLoading}
                            withFooter={true}
                            title={Utils.renderTitleCenter("Complete patient profile")}
                            body={<div>
                                {Utils.renderError(this.state.error)}
                                {Utils.renderError(this.state.error_height)}
                                {Utils.renderError(this.state.error_weight)}
                                {Utils.renderSuccess(this.state.message)}
                                {Utils.renderTitle6('Patient overview')}
                                {Utils.renderSpace()}
                                <FormControl
                                    label={() => "Birthdate (YYYY-MM-DD)"}
                                >
                                    <MaskedInput
                                        name="dob"
                                        inputMode="numeric"
                                        placeholder='yyyy-mm-dd'
                                        mask="9999-99-99"
                                        value={this.state.dob}
                                        onChange={this.handleChange}
                                        disabled={this.state.disable_dob}
                                    />
                                </FormControl>
                                <FormControl
                                    label={() => <span>Biological sex at birth <StatefulTooltip
                                        accessibilityType={'tooltip'}
                                        content={() => (
                                            <Block padding={"10px"} maxWidth={"200px"}>
                                                This is required to send electronic prescriptions within the US and to prevent certain contraindicated drug reactions
                                            </Block>
                                        )}
                                        showArrow
                                    ><Alert style={{transform: "rotate(180deg)"}}/></StatefulTooltip></span>}
                                >
                                    <InputSelect
                                        id="birth_gender"
                                        name="birth_gender"
                                        inputMode="none"
                                        disabled={this.state.disable_gender}
                                        value={this.state.birth_gender}
                                        placeholder="Select biological sex"
                                        options={[
                                            {id: 'Male', label: 'Male'},
                                            {id: 'Female', label: 'Female'},
                                            {id: 'Ambiguous', label: 'Ambiguous'}
                                        ]}
                                        onChange={this.handleChange}
                                    />
                                </FormControl>
                                <FormControl
                                    label={() => "Height (in inches)"}
                                >
                                    <Input
                                        max={99}
                                        name="height"
                                        inputMode="numeric"
                                        value={this.state.height}
                                        onChange={this.handleChange}
                                        error={this.state.error_height != null}
                                    />
                                </FormControl>
                                <FormControl
                                    label={() => "Weight (in lbs)"}
                                >
                                    <Input
                                        max={999}
                                        name="weight"
                                        inputMode="numeric"
                                        value={this.state.weight}
                                        onChange={this.handleChange}
                                        error={this.state.error_weight != null}
                                        />
                                </FormControl>

                                {Utils.renderSpace()}
                                {this.drawConditions()}
                                {Utils.renderSpace()}
                                {Utils.renderSpace()}
                                {Utils.renderSpace()}
                                {this.drawMedications()}
                                {Utils.renderSpace()}
                                {Utils.renderSpace()}
                                {Utils.renderSpace()}
                                {this.drawAllergies()}
                                {this.state.conditions_add ? <Conditions
                                    callback={this.addConditions}
                                    callbackCancel={this.cancelCondition}
                                    showModal={this.state.conditions_add}
                                    items={this.getConditions()}
                                /> : null}

                                {this.state.medications_add ? <Medications
                                    callback={this.addMedications}
                                    callbackCancel={this.cancelMedication}
                                    showModal={this.state.medications_add}
                                    items={this.getMedications()}
                                /> : null}

                                {this.state.allergies_add ? <Allergies
                                    callback={this.addAllergies}
                                    callbackCancel={this.cancelAllergy}
                                    showModal={this.state.allergies_add}
                                    items={this.getAllergies()}
                                /> : null}
                                </div>
                            }
                        />

                    )
                }
            </AuthUserContext.Consumer>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(BackgroundInformation);
