// Add <%= javascript_pack_tag 'Patient' %> to the head of your layout file,
// like app/views/layouts/Patient.html.erb.

import React from 'react'
import ReactDOM from 'react-dom'
import Firebase, {FirebaseContext} from '../components/shared/Firebase';
import App from '../components/patient/App'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>,
    document.getElementById('root'),
  )
})
