import React from 'react';
import { ParagraphLarge } from 'baseui/typography';

const data = {
    en: {
        search_button_content_padding_right_large: '180px',
        search_button_content_padding_right_small: '75px',
        search_result_content_margin: '25px 0px 0px 0px',
        search_result_content_spacing: 'scale0',
        search_result_row_padding: "5px 0px",
        search_result_text_font_size: '18px',
        search_result_text_margin: '10px 0px 10px 18px',
    }
}

const content = (lang) => {
    return data[lang];
};

const textProps = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
            color: $theme.colors.contentPrimary
        }),
      },
    },
};

export const displaySearchContent = (text) => {
    // Displays the data of the search for a protocol
    return <ParagraphLarge {...textProps} style={{margin: data.en.search_result_text_margin, fontSize: data.en.search_result_text_font_size}}>{text}</ParagraphLarge>
}

export default content;
