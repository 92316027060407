import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import ProtocolList from '../Protocol/list';
import {Button, KIND} from 'baseui/button';
import {ButtonGroup, SIZE, SHAPE, MODE} from 'baseui/button-group';
import Utils from '../../shared/Utils';
import {Table, DIVIDER, SIZE as TableSize} from 'baseui/table-semantic';
import {DisplayXSmall, HeadingXLarge, HeadingSmall, HeadingMedium, ParagraphLarge, ParagraphMedium} from 'baseui/typography';
import MpsModal from '../../shared/Utils/modal';
import Screen from '../../shared/Utils/screen';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import {Popover} from 'baseui/popover';
import {StatefulMenu} from 'baseui/menu';
import Logger from '../../shared/Utils/logger';
import content from './content';
import { DisplayCustom1, DisplayCustom2 } from '../../shared/Fonts';
import {Input} from 'baseui/input';
import MetaTagsOverride, {MetaTagPages} from '../../shared/Metatags';
import SearchProtocol from './search_protocol';
import { Search } from 'baseui/icon';
import { StyledLink } from "baseui/link";
import Overview from '../Overview';
import {
    ThemeProvider,
    createTheme,
    darkThemePrimitives
  } from "baseui";

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

const rightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '200px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }),
        },
    },
};

const monoColorTextProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                color: $theme.colors.contentTertiary,
            }),
        },
    },
};

const sortItems = [
    {label: 'popular'},
    {label: 'latest'},
    {label: 'alphabetical'},
];

class Dropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sortOpen: false
        }
    }

    setSortOpen = () => this.setState({sortOpen: !this.state.sortOpen});
    closeSortOpen = (item) => {
        Logger.log(item);
        this.props.callback_func(item.item.label);
        this.setState({sortOpen: false});
    }

    render() {
    return (
        <Popover
            isOpen={this.state.sortOpen}
            onClick={this.setSortOpen}
            content={
            <StatefulMenu
                items={sortItems}
                onItemSelect={(item) => this.closeSortOpen(item)}
            />
            }
        >
            <Button style={{marginLeft: 0}} {...this.props}>{this.props.children}</Button>
        </Popover>
        );
    }
};

class Browse extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            response: {},
            selectedTab: null,
            show_condition: false,
            show_drug: false,
            show_tag: false,
            sortBy: 'popular',
            content: this.getContent(),
            search: '',
            search_show: false,
            showOverview: false
        };

        this.formulary = {};
        this.conditions = {};
        this.tags = {
            '🔑' : ['This protocol is private and requires an invitation to join.'],
            '📟' : ['This protocol utilizes medical devices for disease monitoring.'],
            '🧬' : ['This protocol requires ongoing diagnostics.'],
        };

        Logger.log('Browse has been initiated...');
    }

    componentDidMount = () => {
        Logger.log('Browse: component did mount...');
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home",
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    response: result,
                    ...Utils.clearAllNotifications()
                }, () => this.getFormulary());
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        });
    };

    getContent = () => {
        return content('en');
    };

    getFormulary = () => {
        if (this.state.response && this.state.response.data.protocols) {
            this.state.response.data.protocols.map(protocol => {
                if (protocol.product_categories.length > 0){
                    this.addProductToFormulary(protocol.name, protocol.product_categories[0].products);
                }
                var condition_metadata = protocol.protocol_metadata_items.filter(condition => condition.item_type == 'ReportedCondition');
                this.mapConditionToProtocol(protocol.name, condition_metadata);
            });
        }
    };

    mapConditionToProtocol = (protocol_name, conditions) => {
        conditions.map(condition => this.addCondition(protocol_name, condition.short_name));
    };

    addCondition = (protocol_name, condition) => {
        if (!this.conditions[condition]) {
            this.conditions[condition] = [];
        }

        this.conditions[condition].push(protocol_name);
    };

    prepareMetadataToRender = (data) => {
        Logger.log(data);
        var ret = [];
        var data_keys = Object.keys(data);
        data_keys.sort((a, b) => {return a.toLowerCase() > b.toLowerCase() ? 1 : -1})
        for (var i = 0; i < data_keys.length; i++){
            data[data_keys[i]].sort((a, b) => {return a.toLowerCase() > b.toLowerCase() ? 1 : -1})
            ret.push([data_keys[i], data[data_keys[i]].toString().replaceAll(',', ', ')]);
        }

        return ret;
    };

    addProductToFormulary = (protocol_name, products) => {
        if (Array.isArray(products)){
            products.map(product => this.addDrugToList(protocol_name, product.display_name));
        }
    };

    addDrugToList = (protocol_name, drug_name) => {
        if (!this.formulary[drug_name]) {
            this.formulary[drug_name] = [];
        }

        this.formulary[drug_name].push(protocol_name);
    }

    getProtocols = () => {
        if (!this.state.isLoaded || !this.state.response.data){
            return <Spinner />;
        }

        return <ProtocolList sort={this.state.sortBy} search={this.state.search} protocols={this.state.response.data.protocols}/>;
    };

    showCondition = () => {
        this.setState({show_condition: true, show_drug: false, show_tag: false, ...Utils.clearAllNotifications()});
    };

    showDrug = () => {
        this.setState({show_condition: false, show_drug: true, show_tag: false, ...Utils.clearAllNotifications()});
    };

    showTag = () => {
        this.setState({show_condition: false, show_drug: false, show_tag: true, ...Utils.clearAllNotifications()});
    };

    closeModal = () => {
        this.setState({show_condition: false, show_drug: false, show_tag: false, selectedTab: null, ...Utils.clearAllNotifications()});
    };

    setSelectedTab = (event, index) => {
        this.setState({selectedTab: index, ...Utils.clearAllNotifications()}, () => Logger.log(this.state.selectedTab));
    };
    
    showOverview = () => {
        this.setState({showOverview: true});
    };
    
    overviewCallback = () => {
        Logger.log('Closing the Overview modal...');
        this.setState({showOverview: false});
    };

    renderButtons = () => {
        return (
            <FlexGrid flexGridColumnCount={Screen.size() == 'SMALL' ? 1 : 2}>
                <FlexGridItem>
                    <ButtonGroup
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        mode={MODE.radio}
                        selected={this.state.selectedTab}
                        onClick={(event, index) => {
                            this.setSelectedTab(event, index);
                        }}
                        overrides={{
                            Root: {
                                style: ({$theme}) => ({
                                    maxWidth: 0.95 * window.screen.width + 'px',
                                    overflowX: 'auto',
                                    boxSizing: 'border-box',
                                    padding: '0px',
                                    margin: '0px'
                                }),
                            },
                        }}
                    >
                        <Button style={{marginLeft: 0}} onClick={this.showCondition}>{this.state.content.conditions}</Button>
                        <Button style={{marginLeft: 0}} onClick={this.showDrug}>{this.state.content.drugs}</Button>
                        <Dropdown callback_func={this.sortProtocols}>{this.state.content.sort}</Dropdown>
                    </ButtonGroup>
                </FlexGridItem>
                {Screen.size() !== 'SMALL' && this.state.content.create_protocol_on_run != ''
                ? <FlexGridItem {...rightProps}>
                <Button
                    $as="a"
                    href={ROUTES.RUN_BASE_URL}
                    target="_blank"
                    size={SIZE.compact}
                    kind={KIND.secondary}
                    shape={SHAPE.pill}>{this.state.content.create_protocol_on_run}</Button>
            </FlexGridItem>
            : null
                }
            </FlexGrid>
        );
    };

    sortProtocols = (label) => {
        this.setState({sortBy: label, ...Utils.clearAllNotifications()});
    }
    renderCondition = () => {
        if (this.state.show_condition) {
            const COLUMNS = [this.state.content.condition_col1, this.state.content.condition_col2];

            const DATA = this.prepareMetadataToRender(this.conditions);

            return <MpsModal
                open={this.state.show_condition}
                title={this.state.content.condition_title}
                body={<div>
                        {this.state.content.condition_blurb}
                        <Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/>
                    </div>}
                callbackCancel={this.closeModal}/>
        }
    };


    renderDrug = () => {
        if (this.state.show_drug) {
            const COLUMNS = [this.state.content.therapeutic_col1, this.state.content.therapeutic_col2];

            const DATA = this.prepareMetadataToRender(this.formulary);

            return <MpsModal
                open={this.state.show_drug}
                title={this.state.content.therapeutic_title}
                body={<div>
                    {this.state.content.therapeutic_blurb}
                    <Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/>
                    </div>}
                callbackCancel={this.closeModal}/>
        }
    };

    renderTag = () => {
        if (this.state.show_tag) {
            const COLUMNS = ['Tag', 'Label'];

            const DATA = this.prepareMetadataToRender(this.tags);

            return <MpsModal
                open={this.state.show_tag}
                title='Tag list'
                body={<Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/>}
                callbackCancel={this.closeModal}/>
        }
    };

    renderBannerTitle = () => {
        return this.state.content.banner_title;
    };

    renderBannerMobileTitle = () => {
        return this.state.content.banner_mobile_title;
    };

    renderBannerSubtext = () => {
        return this.state.content.banner_subtext;
    }

    renderBannerMobileSubtext = () => {
        return this.state.content.banner_mobile_subtext;
    };

    renderBannerCallToAction = () => {
        return this.state.content.banner_call_action
    };

    renderBannerMobile = () => {
        return <FlexGrid
                flexGridRowGap={"scale200"}
                margin={this.state.content.banner_mobile_margins}
            >
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <DisplayCustom2 style={{
                        margin: this.state.content.banner_mobile_display_custom_margin,
                        padding: this.state.content.banner_mobile_display_custom_padding,
                        fontSize: this.state.content.banner_mobile_display_custom_font_size,
                        lineHeight: this.state.content.banner_mobile_display_custom_line_height
                    }}>{this.renderBannerMobileTitle()}</DisplayCustom2>
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <ParagraphLarge style={{
                        margin: this.state.content.banner_mobile_subtext_margin,
                        fontSize: this.state.content.banner_mobile_subtext_font_size,
                        lineHeight: this.state.content.banner_mobile_subtext_line_height
                    }}>{this.renderBannerMobileSubtext()}</ParagraphLarge>
                </FlexGridItem>
            </FlexGrid>
    };

    renderBanner = () => {
        return <div id='welcome' style={{
            marginTop: this.state.content.banner_welcome_margin_top,
            backgroundImage: this.state.content.banner_background_image,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
            <FlexGrid
                flexGridRowGap={""}
                margin={this.state.content.banner_margins}
                padding={this.state.content.banner_padding}
            >
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <DisplayCustom1 style={{
                        margin: this.state.content.banner_display_custom_margin,
                        padding: this.state.content.banner_display_custom_padding,
                        fontSize: this.state.content.banner_display_custom_font_size,
                        lineHeight: this.state.content.banner_display_custom_line_height
                    }}>{this.renderBannerTitle()}</DisplayCustom1></FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <ParagraphLarge style={{
                        margin: this.state.content.banner_subtext_margin,
                        fontSize: this.state.content.banner_subtext_font_size,
                        lineHeight: this.state.content.banner_subtext_line_height,
                        maxWidth: this.state.content.banner_subtext_max_width
                    }}>{this.renderBannerSubtext()}</ParagraphLarge>
                </FlexGridItem>
                <FlexGridItem {...centerProps}>{this.renderSearch()}</FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <ParagraphLarge style={{
                        margin: this.state.content.banner_call_action_margin,
                        fontSize: this.state.content.banner_call_action_font_size,
                        lineHeight: this.state.content.banner_call_action_line_height
                    }}>{this.renderBannerCallToAction()}</ParagraphLarge>
                </FlexGridItem>
            </FlexGrid>
        </div>
    };

    closeSearchModal = () => {
        this.setState({search_show: false});
    };

    renderSearchButton = () => {
        return <Button
                kind={KIND.secondary}
                size={SIZE.default}
                overrides={{
                    Root: {
                        style: ({ $theme }) => ({
                            maxWidth: this.state.content.banner_search_max_width,
                            width:'100%',
                            marginLeft: '16px',
                            marginRight:'16px',
                            borderBottomRightRadius: this.state.content.banner_search_search_radius,
                            borderBottomLeftRadius: this.state.content.banner_search_search_radius,
                            borderTopRightRadius: this.state.content.banner_search_search_radius,
                            borderTopLeftRadius: this.state.content.banner_search_search_radius,
                            borderRightWidth: '0px',
                            borderBottomWidth: '0px',
                            borderLeftWidth: '0px',
                            borderTopWidth: '0px',
                            justifyContent: 'flex-start',
                            //paddingRight: Screen.size() == 'SMALL' ? this.state.content.search_button_content_padding_right_small : this.state.content.search_button_content_padding_right_large
                        })
                    },
                    Input: {
                        style: ({ $theme }) => ({
                            paddingLeft: this.state.content.banner_search_padding_left
                        })
                    }
                }}
                value={this.state.search}
                onClick={() => this.setState({search_show: true})}
                placeholder={this.state.content.banner_search_placeholder}
                startEnhancer={() => <Search size={24} />}
            >{this.state.content.banner_search_placeholder}</Button>
    };
    
    renderFindCommunititesButton = () => {
        return <ThemeProvider
                  theme={createTheme(darkThemePrimitives, {
                       colors: {
                          buttonSecondaryFill: "#5f6bff",
                          buttonSecondaryHover: "#3f4eff",
                          buttonSecondaryActive: "#5f6bff"
                                 }
                            })}
                          >
                <Button
                kind={KIND.secondary}
                size={SIZE.default}
                shape={SHAPE.pill}
                value={this.state.search}
                onClick={() => this.setState({search_show: true})}
            >Search by condition, drug, or name</Button>
</ThemeProvider> 
    };

    getResponsiveMargins = () => {
        if (['XSMALL','SMALL', 'MEDIUM'].includes(Screen.size())) {
            return '16px';
        } else {
            return '40px';
        }
    };

    getContentMaxWidthCss = () => {
        let css = {};

        if (Screen.size() == 'XLARGE') {
            css.margin = 'auto';
            css.maxWidth = '1800px';
        } else {
            css.marginBottom = this.getResponsiveMargins();
        }

        return css;
    };

    renderOldBrowse = () => {
        return <div style={this.getContentMaxWidthCss()}>
            <MetaTagsOverride page={MetaTagPages.Browse}/>
            {['SMALL'].includes(Screen.size()) ? this.renderBannerMobile() : this.renderBanner()}
            {this.renderButtons()}
            {Utils.renderSpace()}
            {this.getProtocols()}
            {this.renderCondition()}
            {this.renderDrug()}
        </div>
    };

    renderNewBrowse = () => {
        return <div style={{display:'flex', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'85vh'}}>
                        <FlexGrid
                            flexGridColumnCount={1}
                        >
                            <FlexGridItem {...itemProps}>
                                <HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '10px'}}>Welcome to Clinic!</HeadingSmall>
                            </FlexGridItem>
                            <FlexGridItem {...itemProps}>
                                <ParagraphMedium style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}>Access your memberships, find new clinics & more.</ParagraphMedium>
                            </FlexGridItem>
                            <FlexGridItem {...itemProps}>
                                <Button
                                    kind={KIND.secondary}
                                    shape={SHAPE.pill}
                                    onClick={() => this.setState({search_show: true})}
                                >Clinic search</Button>
                            </FlexGridItem>
                        </FlexGrid>
         </div> 
    };

    renderSearchBrowse = () => {
        if (['XSMALL','SMALL'].includes(Screen.size())) {
            return <div>
            <FlexGrid>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <img height={this.state.content.search_image_height} src={this.state.content.search_image_url} style={{margin: this.state.content.search_image_margin}}/>
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <DisplayCustom2 style={{
                        margin: this.state.content.banner_mobile_display_custom_margin,
                        padding: this.state.content.banner_mobile_display_custom_padding,
                        fontSize: this.state.content.banner_mobile_display_custom_font_size,
                        lineHeight: this.state.content.banner_mobile_display_custom_line_height
                    }}>{this.renderBannerMobileTitle()}</DisplayCustom2>
                </FlexGridItem>
                <FlexGridItem {...centerProps}>{this.renderSearchButton()}</FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <StyledLink href="#" onClick={this.showOverview} style={{color:"#00a1ff"}}>
                        <ParagraphLarge style={{
                        margin: this.state.content.banner_mobile_subtext_margin,
                        fontSize: this.state.content.banner_mobile_subtext_font_size,
                        lineHeight: this.state.content.banner_mobile_subtext_line_height,
                        maxWidth: this.state.content.banner_mobile_subtext_max_width
                    }}
                    {...monoColorTextProps}
                    >{this.renderBannerMobileSubtext()}</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>
            </FlexGrid>
            <Overview show={this.state.showOverview} callback={this.overviewCallback}/>
            </div>;

        } else {

            return<div>
                <FlexGrid>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <img height={this.state.content.search_image_height} src={this.state.content.search_image_url} style={{margin: this.state.content.search_image_margin}}/>
                </FlexGridItem>
            <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                <DisplayCustom1 style={{
                    margin: this.state.content.banner_display_custom_margin,
                    padding: this.state.content.banner_display_custom_padding,
                    fontSize: this.state.content.banner_display_custom_font_size,
                    lineHeight: this.state.content.banner_display_custom_line_height
                }}>{this.renderBannerTitle()}</DisplayCustom1>
            </FlexGridItem>
            <FlexGridItem {...centerProps}>{this.renderSearchButton()}</FlexGridItem>
            <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
               <StyledLink href="#" onClick={this.showOverview} style={{color:"#00a1ff"}}> 
                    <ParagraphLarge style={{
                    margin: this.state.content.banner_subtext_margin,
                    fontSize: this.state.content.banner_subtext_font_size,
                    lineHeight: this.state.content.banner_subtext_line_height,
                    maxWidth: this.state.content.banner_subtext_max_width
                }}
                {...monoColorTextProps}
                >{this.renderBannerSubtext()}</ParagraphLarge>
                </StyledLink>
            </FlexGridItem>
        </FlexGrid>
        <Overview show={this.state.showOverview} callback={this.overviewCallback}/>
        </div>
        }   
    };

renderBrowse = () => {
        if (['XSMALL','SMALL'].includes(Screen.size())) {
            return <div>
            <FlexGrid>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <img height={this.state.content.search_image_height} src={this.state.content.search_image_url} style={{margin: this.state.content.search_image_margin}}/>
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <HeadingMedium style={{
                        margin: this.state.content.banner_mobile_display_custom_margin,
                        padding: this.state.content.banner_mobile_display_custom_padding,
                        fontSize: this.state.content.banner_mobile_display_custom_font_size,
                        lineHeight: this.state.content.banner_mobile_display_custom_line_height
                    }}>{this.renderBannerMobileTitle()}</HeadingMedium>
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <ParagraphMedium style={{
                        margin: this.state.content.banner_mobile_subtext_margin,
                        fontSize: this.state.content.banner_mobile_subtext_font_size,
                        lineHeight: this.state.content.banner_mobile_subtext_line_height,
                        maxWidth: this.state.content.banner_mobile_subtext_max_width
                    }}{...monoColorTextProps}>{this.renderBannerMobileSubtext()}</ParagraphMedium>
                </FlexGridItem>
                <FlexGridItem {...centerProps}>{this.renderFindCommunititesButton()}</FlexGridItem>
            </FlexGrid>
            </div> 
        } else {
            return <div>
            <FlexGrid>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <img height={this.state.content.search_image_height} src={this.state.content.search_image_url} style={{margin: this.state.content.search_image_margin}}/>
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>
                    <HeadingMedium style={{
                        margin: this.state.content.banner_display_custom_margin,
                        padding: this.state.content.banner_display_custom_padding,
                        fontSize: this.state.content.banner_display_custom_font_size,
                        lineHeight: this.state.content.banner_display_custom_line_height
                    }}>{this.renderBannerTitle()}</HeadingMedium>
                </FlexGridItem>
                <FlexGridItem {...centerProps}>{this.renderFindCommunititesButton()}</FlexGridItem>
                </FlexGrid>
                </div>  
                }
            };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => <div>
                    {this.renderNewBrowse()}
                    {this.state.search_show ? <SearchProtocol callbackClose={this.closeSearchModal}/> : null}
                </div>}
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Browse);
