import React from 'react';

const data = {
    en: {
        patient: {
            browse: {
                title: "Clinic.dev | Free clinic builder & hosting for doctors",
                description: "Clinic.dev allows any doctor to build and host their own digital clinic and take payments via subscriptions.",
                favicon: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,q_100,r_192,w_192/v1693331389/clinic-favicon-simple-c_quhqhu.png"
            },
            protocol: {
                title: "Join {protocol_name} on Clinic.dev",
                description: "", // not used
                favicon: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,q_100,r_192,w_192/v1693331389/clinic-favicon-simple-c_quhqhu.png"
            }
        },
        physician: {}
    }
}

const content = (lang, app) => {
    return data[lang][app];
};

export default content;
