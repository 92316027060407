import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import StatusError from './error';
import StatusReject from './reject';
import StatusNothing from './nothing';
import StatusBanned from './banned';
import StatusInactive from './inactive';
import StatusRejectTimeout from './reject_timeout';
import StatusCart from './cart';
import { ScheduleVideoCall } from '../Schedule';
import {Spinner} from '../../shared/Spinner';
import Request from '../../shared/Request';
import SSE from '../../shared/SSE';
import Logger from '../../shared/Utils/logger';

class SubscriptionStatus extends Component {
    constructor(props) {
        super(props);

        this.sse = null;
        this.state = {
            isLoading: false,
            error: null,
            status: null,
            id: null,
            protocol_name: null,
            reject_reason: null,
            protocol_id: null,

            event_status: null
        };

        this.protocol_id = this.props.match.params.protocol_id;
        Logger.log(this.protocol_id);
    }

    componentDidMount = () => {
        if (this.protocol_id){
            Logger.log('Getting status for subscription...');
            this.setState({isLoading: true});

            Request.get({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/subscription_management/status/" + this.protocol_id,
                callbackSuccess: (result) => {
                    this.setState({
                        isLoading: false,
                        status: result.status,
                        protocol_id: result.protocol_id,
                        protocol_name: result.name,
                        reject_reason: result.reject_reason
                    })

                    if (result.new_activity_for_patient) {
                        Request.get({
                            firebase: this.props.firebase,
                            route: ROUTES.BASE_URL_API + "/subscription_management/clear_new_activity_flag/" + this.protocol_id,
                            callbackSuccess: (result) => {},
                            callbackError: (error) => {}
                        })
                    }
                },
                callbackError: (error) => this.setState({
                    isLoading: false,
                    error
                })
            });

            // TODO do a fetch with the token and then make the SSE call
            this.sse = new SSE(this.props.firebase, '/events', this.sseCallback);
        } else {
            this.setState({isLoading: false, status: 'error'});
        }
    };

    componentWillUnmount = () => {
        if (this.sse) {
            this.sse.close();
        }
    };

    sseCallback = (event) => {
        Logger.log('Server Updates CB executing....')
        this.setState({event_status: JSON.parse(event.data)});
    };

    redirectStatusChange = () => {
        if (this.state.event_status) {
            if (this.state.event_status.protocol == this.protocol_id && ['active'].includes(this.state.event_status.subscription)) {
                this.props.history.push(ROUTES.PROTOCOL_HOME + '/' + this.protocol_id);
            } else if (this.state.event_status.protocol == this.protocol_id && 'reapply' == this.state.event_status.subscription) {
                window.location.reload();
            }
        }
    };

    showStatus = () => {
        Logger.log(this.state.status);
        if (!this.state.isLoading) {
            if (this.state.status == 'error') {
                return <StatusError />
            } else if (this.state.status == 'reapply') {
                if (this.state.reject_reason == 'timeout'){
                    return <StatusRejectTimeout protocol_id={this.state.protocol_id} protocol_name={this.state.protocol_name} />
                }
                return <StatusReject protocol_id={this.state.protocol_id} protocol_name={this.state.protocol_name} reject_reason={this.state.reject_reason}/>;
            } else if (this.state.status == 'pending') {
                return <ScheduleVideoCall protocol_id={this.state.protocol_id}/>
            } else if (this.state.status == 'inactive') {
                return <StatusInactive protocol_id={this.state.protocol_id} protocol_name={this.state.protocol_name} />
            } else if (this.state.status == 'dead') {
                return <StatusBanned protocol_name={this.state.protocol_name} reject_reason={this.state.reject_reason}/>
            } else if (this.state.status == 'active' || this.state.status == 'active_pending_cancel') {
                this.props.history.push(ROUTES.PROTOCOL_HOME + '/' + this.state.protocol_id);
            } else if (this.state.status == 'cart') {
                return <StatusCart protocol_id={this.state.protocol_id} protocol_name={this.state.protocol_name} />
            } else {
                return <StatusNothing />
            }
        }

        return <Spinner />;
    };

    render () {
        this.redirectStatusChange();

        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                    <div>
                        {this.showStatus()}
                    </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(SubscriptionStatus);
