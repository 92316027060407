import { ParagraphLarge, LabelLarge} from 'baseui/typography';
import { DisplayCustom2 } from '../../shared/Fonts';
import {StyledLink} from 'baseui/link';
import React from 'react';

const data = {
    en: {
        title: "What's a support server?",
        body: <>
        <img style={{marginTop: "0px", marginBottom: "20px", height:"90px"}} src="https://res.cloudinary.com/mpsh87/image/upload/c_scale,h_118,q_100,dpr_2.0/v1643917769/betaArtboard_1_copy_2_4x_kl2iua.png
"/>
        <ParagraphLarge style={{marginTop: "0px", marginBottom: "20px", lineHeight:"24px"}}>Support servers are new communities created by US-licensed physicians that let you:</ParagraphLarge>
        <ParagraphLarge style={{marginTop: "0px", marginBottom: "20px", lineHeight:"24px"}}>✅ get refills on your meds</ParagraphLarge>
        <ParagraphLarge style={{marginTop: "0px", marginBottom: "20px", lineHeight:"24px"}}>✅ message a doctor</ParagraphLarge>
        <ParagraphLarge style={{marginTop: "0px", marginBottom: "20px", lineHeight:"24px"}}>✅ access private patient chat</ParagraphLarge>
        <ParagraphLarge style={{marginTop: "0px", marginBottom: "20px", lineHeight:"24px"}}>They're available to US-based patients for a low monthly sub on the digital health platform Pharmr.</ParagraphLarge>
        </>
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
