import React, { Component } from "react";
import {HeadingSmall, ParagraphMedium} from 'baseui/typography';
import {StyledLink} from 'baseui/link';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from "../../shared/Utils";
import * as ROUTES from '../Constants';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import Logger from "../../shared/Utils/logger";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {Button, KIND, SHAPE} from 'baseui/button';

const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class ProtocolStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol: null,
            loading: true
        };

        this.protocol_number = this.props.match.params.id;
        Logger.log("This clinic ID: " + this.protocol_number);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/status/" + this.protocol_number,
            callbackSuccess: (result) => this.setState({
                protocol: result.protocol,
                loading: false
            }),
            callbackError: (error) => this.setState({
                loading: false,
                error
            })
        })
    };

    renderIsShutdown = () => {
        return <FlexGrid>
            <FlexGridItem><HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '10px'}}>{this.state.protocol.name} has shut down</HeadingSmall></FlexGridItem>
            <FlexGridItem><ParagraphMedium style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}>Clinics can be shut down for a number a reasons, including: violation of Clinic's <StyledLink href='https://github.com/Pharmr/legal/blob/main/community-guidelines.md' target='_blank' style={{color:"#00a1ff"}}>community guidelines</StyledLink>, the creating physician no longer wants to participate, or due to low activity. Members have been notified of this status change.</ParagraphMedium></FlexGridItem>
            </FlexGrid>
    };

    renderActive = () => {
        return <HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}>This clinic is active.</HeadingSmall>
    };

    renderStatus = () => {
        Logger.log(this.state.protocol);

        if (this.state.loading) {
            return <Spinner />
        }

        if (this.state.protocol) {
            if (this.state.protocol.status === 'active') {
                return this.renderActive();
            } else if (this.state.protocol.status === 'is_shutdown') {
                return this.renderIsShutdown();
            } else {
                return <HeadingSmall style={{textAlign: 'center', marginTop: '0px', marginBottom: '18px'}}>Clinic is {this.state.protocol.status}.</HeadingSmall>
            }
        }
    };

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div style={{display:'flex', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'85vh'}}>
                        {Utils.renderError(this.state.error)}
                        <FlexGrid
                            flexGridColumnCount={1}
                        >
                        <FlexGridItem {...itemProps}>{this.renderStatus()}</FlexGridItem>
                        <FlexGridItem {...itemProps}>
                                <Button
                                    kind={KIND.secondary}
                                    shape={SHAPE.pill}
                                    $as="a"
                                    href={ROUTES.HOME}
                                >Return to home</Button>
                            </FlexGridItem>
                        </FlexGrid>
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ProtocolStatus);
