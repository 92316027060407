import React, {Component} from 'react';
import {Spinner as BaseSpinner, SIZE} from 'baseui/spinner';
import Utils from '../Utils';
import MpsModal from '../../shared/Utils/modal';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';

const spinnerProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

class SpinnerModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            show: true,
        }
    }

    closeModal = () => {
        this.setState({show: false});
    };

    render = () => {
        return (
            <MpsModal
                open={this.state.show}
                autoFocus={false}
                callback={this.submit}
                callbackCancel={this.closeModal}
                title={Utils.renderTitleCenter(this.state.title)}
                body={<FlexGrid>
                        <FlexGridItem {...spinnerProps}><BaseSpinner $size={SIZE.large} /></FlexGridItem>
                    </FlexGrid>}
            />
        )
    }
}

class Spinner extends Component {
    constructor(props){
        super(props);
    }

    render = () => {
        return <FlexGrid>
            <FlexGridItem {...spinnerProps}><BaseSpinner $size={this.props.size ? this.props.size : SIZE.large} /></FlexGridItem>
        </FlexGrid>
    }
}
export const SpinnerSize = {
    SMALL: SIZE.small,
    MEDIUM: SIZE.medium,
    LARGE: SIZE.large,
};

export {Spinner};

export default SpinnerModal;
