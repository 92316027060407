import React from 'react';
import { ParagraphSmall } from "baseui/typography";
import {StyledLink} from 'baseui/link';

const data = {
    "en": {
        onboarding_file_attachment_title: "Uploads",
        drug_request_file_attachment_title: "Uploads",
        file_attachment_note: <ParagraphSmall style={{textAlign: 'center'}}>*Need labs? You can get them <StyledLink href="https://www.findlabtest.com/" target="_blank" animateUnderline style={{color:"#00a1ff"}}>here</StyledLink>.</ParagraphSmall>,
        file_attachment_physician_title: "",
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
