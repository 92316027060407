import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import FormQuestion from './form_question';
import Utils from '../../shared/Utils';
import MpsModal from '../../shared/Utils/modal';
import Logger from '../../shared/Utils/logger';
import Request from '../../shared/Request';

class IntakeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            error: null,
            message: null,
            show: this.props.show ? this.props.show : false,
            protocol: null,
            order_id: props.order_id,
            form_responses: []
        };
        Logger.log("PROPS" + props.order_id)
    }

    componentDidMount = () => {
        this.getProtocol();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show, ...Utils.clearAllNotifications()});
        }
    };

    getProtocol = () => {
        // TODO Update to a route that only gives the required data.

        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home/" + this.props.protocol_id,
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    protocol: result,
                    ...Utils.clearAllNotifications()
                }, () => this.getFormResponses());
            },
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    // To get the history of the last responses is not easily done without some changes to the models.
    // https://github.com/Pharmr/mpshealth/issues/356
    getFormResponses = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_onboarding/get_form_responses/" + this.state.protocol.data.onboarding_form.id + '/' + this.state.order_id,
            callbackSuccess: (result) => {

                let created_responses = this.state.protocol.data.onboarding_form.structure_json.map((created_response) => {
                    let patient_responses = [];
                    if (result && result.form_response && result.form_response.results && result.form_response.results.questionResponses) {
                        patient_responses = result.form_response.results.questionResponses.filter(
                            (patient_response) => patient_response.questionText == created_response.questionText
                        );
                    }

                    if (patient_responses.length > 0) {
                        created_response.responseFlag = patient_responses[0].responseFlag ? patient_responses[0].responseFlag : [];
                        created_response.responseText = patient_responses[0].responseText;
                    } else {
                        created_response.responseFlag = [];
                        created_response.responseText = [];
                    }

                    return created_response;
                });

                this.setState({form_responses: created_responses, ...Utils.clearAllNotifications()}, () => this.callback());
            },
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    saveFormResponses = () => {

        const data = new FormData();
        data.append('question_responses', JSON.stringify(this.state.form_responses));
        data.append('form_id', this.state.protocol.data.onboarding_form.id);
        data.append('order_id', this.state.order_id);
        data.append('protocol_id', this.props.protocol_id);

        this.props.firebase.currentUser().getIdToken(false).then((token) => {
            fetch(
                ROUTES.BASE_URL_API + "/patient_onboarding/question_responses",
                {
                    headers: {
                        "authorization" : `Bearer ${token}`,
                    },
                    method: 'POST',
                    body: data
                }
            ).then(res => res.json()
            ).catch((error) => {
                this.setState({error, ...Utils.clearAllNotificationsExceptError()});
            })
        });
    };

    handleOnboardingFormChangeForIndex = (index, value) => {
        this.setState((prevState) => {
            let new_responses = [...prevState.form_responses];
            if (value.responseText.length > 0) {
                new_responses[index] = value;
            }

            return {
                form_responses: new_responses,
                ...Utils.clearAllNotifications()
            }
        });
    };

    closeModal = () => {
        this.setState({show: false, ...Utils.clearAllNotifications()});

        this.props.callbackCancelHandler();
    };

    callback = () => {
        if (this.isFormNotReady()) {
            this.props.callbackCompleteHandler(false);
        } else {
            this.props.callbackCompleteHandler(true);
        }
    };

    submit = () => {
        this.saveFormResponses();
        this.setState({show: false, ...Utils.clearAllNotifications()}); // Close modal but do not call the callback

        // This is called for the caller.
        this.callback();
    };

    ////////////////////////////////// Render Methods //////////////////////////////////
    isFormNotReady = () => {
        if (!this.state.isLoaded || !this.state.protocol) {
            return true;
        }

        return (
            this.state.form_responses.filter(val => val
                && ['single', 'multi'].includes(val.questionType)
                && val.responseText.length == 0
            ).length > 0
        )
    };

    buildOnboardingForm = () => {
        if (!this.state.isLoaded || !this.state.protocol) {
            return 'Loading...';
        }
        if (this.state.protocol.data.onboarding_form && this.state.protocol.data.onboarding_form.structure_json) {
            return this.state.protocol.data.onboarding_form.structure_json.map(
                (question, idx) => <FormQuestion
                    index={idx}
                    key={idx}
                    question={question}
                    questionType={question.questionType}
                    question_response={this.state.form_responses[idx]}
                    handleChange={this.handleOnboardingFormChangeForIndex}
                />
            );
        } else {
            return <div> No Form Found </div>;
        }
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <MpsModal
                            open={this.state.show}
                            autoFocus={false}
                            callback={this.submit}
                            callbackCancel={this.closeModal}
                            withFooter={true}
                            title={Utils.renderTitleCenter("Onboarding questionnaire")}
                            body={
                                <div>
                                    {this.buildOnboardingForm()}
                                </div>
                            } />
                    )
                }
            </AuthUserContext.Consumer>
        );
    };
}



const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(IntakeForm);
