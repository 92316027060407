import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import content from './content';
import { withFirebase } from '../Firebase';
import Logger from '../Utils/logger';

class MetaTagsOverride extends Component {
    constructor(props) {
        super(props)

        this.state = {
            home_url: "https://clinic.dev",
            page: this.props.page,
            protocol_number: this.props.protocol_number,
            protocol_name: this.props.protocol_name,
            protocol_summary: this.props.protocol_summary,
            protocol_favicon: this.props.protocol_favicon,
            content: content('en', this.props.firebase.isPatient() ? "patient" : "physician")
        }
    }

    renderMetaTags = () => {
        if (this.state.page == MetaTagPages.Browse) {
            return <MetaTags>
                <title>{this.state.content.browse.title}</title>
                <meta data-rh="true" name="description" content={this.state.content.browse.description} />
                <meta data-rh="true" property="og:description" content={this.state.content.browse.description} />
                <meta data-rh="true" property="og:title" content={this.state.content.browse.title} />
                <meta data-rh="true" property="og:image" content={this.state.content.browse.favicon} />
                <meta data-rh="true" property="og:url" content={this.state.home_url + "/browse"} />
                <meta data-rh="true" property="og:type" content="website" />
            </MetaTags>
        } else if ([MetaTagPages.Protocol, MetaTagPages.ProtocolHome].includes(this.state.page)) {
            const title = this.state.content.protocol.title.replaceAll('{protocol_name}', this.state.protocol_name);
            return <MetaTags>
                <title>{title}</title>
                <meta data-rh="true" name="description" content={this.state.protocol_summary} />
                <meta data-rh="true" property="og:description" content={this.state.protocol_summary} />
                <meta data-rh="true" property="og:title" content={title}/>
                <meta data-rh="true" property="og:image" content={this.state.home_url + this.state.protocol_favicon} />
                <meta data-rh="true" property="og:url" content={this.state.home_url + this.state.page + '/' + this.state.protocol_number} />
                <meta data-rh="true" property="og:type" content="website" />
            </MetaTags>
        } else if (this.state.page == MetaTagPages.ProtocolPreview) {
            return <MetaTags>
                <title>{this.state.protocol_name}</title>
                <meta data-rh="true" name="description" content={this.state.protocol_summary} />
                <meta data-rh="true" property="og:description" content={this.state.protocol_summary} />
                <meta data-rh="true" property="og:title" content={this.state.protocol_name}/>
                <meta data-rh="true" property="og:image" content={this.state.home_url + this.state.protocol_favicon} />
                <meta data-rh="true" property="og:url" content={this.state.home_url + this.state.page + '/' + this.state.protocol_number} />
                <meta data-rh="true" property="og:type" content="website" />
            </MetaTags>
        } else {
            return null;
        }
    };

    render = () => {
        return this.renderMetaTags();
    };
}

export const MetaTagPages = {
    Browse: '/browse',
    Protocol: '/protocol',
    ProtocolHome: '/protocol/home',
    ProtocolPreview: '/preview',
};

export default withFirebase(MetaTagsOverride);
