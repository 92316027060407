import React, {Component, Fragment} from 'react';
import {AuthUserContext} from '../../shared/Session'
import {withRouter} from 'react-router-dom';
import * as PHYSICIAN_ROUTES from '../../physician/Constants';
import * as PATIENT_ROUTES from '../../patient/Constants';
import {withFirebase} from '../../shared/Firebase';
import { Button, SHAPE, SIZE, KIND} from "baseui/button";
import {ParagraphLarge, ParagraphMedium, LabelLarge, LabelMedium, LabelSmall, HeadingXSmall, HeadingSmall, HeadingXLarge, HeadingMedium, DisplayLarge, DisplayMedium, HeadingLarge, ParagraphSmall} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Browse from '../Browse';
import Screen from "../../shared/Utils/screen";
import Logger from '../../shared/Utils/logger';
import {Spinner} from '../../shared/Spinner';
import PhysicianExplainer from '../PhysicianExplainer';
import { StyledLink } from "baseui/link";
import MpsModal from '../../shared/Utils/modal';
import content from "./content";
import { Search, Overflow, ArrowRight, Plus} from "baseui/icon";
import '../../shared/Styles/style.scss';
import SearchProtocol from '../../patient/Browse/search_protocol';
import ReportIssue from '../../shared/ReportIssue';
import Contact from '../../shared/Contact';
import {StatefulMenu, OptionProfile} from 'baseui/menu';
import {StatefulPopover, PLACEMENT, TRIGGER_TYPE,} from 'baseui/popover';

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};


const centerProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const endProps = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const startProps = {
    display: 'flex',
    justifyContent: 'flex-start',
};

const greyTextProps = {
    overrides: {
        Block: {
          style: ({$theme}) => ({
            color: $theme.colors.contentTertiary
          }),
        },
    },
  };

  const grey2TextProps = {
    overrides: {
        Block: {
          style: ({$theme}) => ({
            color: $theme.colors.primary400
          }),
        },
    },
  };

const primaryBProps = {
overrides: {
    Block: {
        style: ({$theme}) => ({
        color: $theme.colors.primaryB
        }),
    },
},
};

const footerProps = {
overrides: {
    Block: {
        style: ({$theme}) => ({
        position:"sticky",
        bottom:"0",
        backgroundColor: $theme.colors.backgroundSecondary,
        width:"100%",
        marginTop:"25px",
        paddingTop:"25px",
        paddingBottom:"25px"
        }),
    },
},
};

const headerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
            position:"sticky",
            top:"0",
            backgroundColor: "transparent",
            width:"100%",
            maxWidth:"768px",
            marginTop:"10px",
            marginBottom:"40px",
            marginRight:"16px",
            marginLeft:"16px"
            //paddingTop:"25px",
            //paddingBottom:"25px"
            }),
        },
    },
    };

class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            items: [{
                title: 'Clinic owners/staff',
                subtitle: 'Access your admin panel',
                //body: '*Run your practice on Clinic.dev',
                imgUrl: 'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1693950876/provider-dot_cmvjcv.png',
                href: PHYSICIAN_ROUTES.BASE_URL + PHYSICIAN_ROUTES.SIGN_IN,
                
              },
              {
                title: 'Patients',
                subtitle: 'Access your dashboard',
                //body: '*Manage your health on Clinic.dev',
                imgUrl: 'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1693950876/patient-dot_i1nk7d.png',
                href: PATIENT_ROUTES.SIGN_IN,
                
              }
              ],
            loaded: false,
            showSearchProtocol: false,
            showReportIssue: false,
            showContact: false,
            parentHeight: window.innerHeight - 47 ,// minus nav height
        }
    }


    componentDidMount = () => {
        Logger.log('Location Prop:');
        Logger.log(this.props.location);
        if (this.props.location.state && this.props.location.state.action == 'refresh') {
            Logger.log('Time to refresh page...')
            window.history.replaceState({}, '');
            window.location.reload();
            window.scrollTo(0, 0);
        } else {
            Logger.log('Page has been refreshed...');
            this.setState({loaded: true});
            window.scrollTo(0, 0);
        }

        window.addEventListener("resize", () => this.setState({parentHeight: window.innerHeight - 47}));

        document.body.addEventListener('touchmove', function(event) {
            console.log(event.source);
            //if (event.source == document.body)
              event.preventDefault();
          }, false);
    };

    showPhysicianExplainer = () => {
        Logger.log('Setting to show PhysicianExplainer');
        this.setState({showPhysicianExplainer: true});
    };

    closePhysicianExplainer = () => {
        this.setState({showPhysicianExplainer: false});
    };

    showProtocolSearch = (event) => {
        event.preventDefault();

        this.setState({showSearchProtocol: true});
    };

    hideProtocolSearch = () => {
        this.setState({showSearchProtocol: false});
        this.props.callback();
    };

    showReportIssue = () => {
        this.setState({showReportIssue: true});
    };

    closeReportIssue = () => {
        this.setState({showReportIssue: false});
        this.props.closeCallback();
    };

    showContact = () => {
        this.setState({showContact: true});
    };

    closeContact = () => {
        this.setState({showContact: false});
        this.props.closeCallback();
    };

    renderCTAButtons = () => {
        return <FlexGrid flexGridColumnCount={1} gridRowGap={"scale300"} maxWidth="570px" marginBottom={Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ? "14px" : "18px"} marginTop={"0px"}>
        <FlexGridItem {...centerProps}>
                        <Button
                                shape={SHAPE.pill}
                                size={SIZE.large}
                                kind={KIND.primary}
                                $as="a"
                                href={PHYSICIAN_ROUTES.BASE_URL}
                                startEnhancer={() => <Plus size={18} />}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            maxWidth: '570px',
                                            width: '100%',
                                            paddingTop: '20px',
                                            paddingBottom: '20px',
                                            marginRight: '16px',
                                            marginLeft: '16px'
                                        })
                                    },
                                    StartEnhancer: {
                                        style: ({ $theme }) => ({
                                            marginRight: '0px'
                                        })
                                    }
                                }}
                            >Build&nbsp;a&nbsp;clinic</Button>
        </FlexGridItem>
        <FlexGridItem {...centerProps}>
                        <Button
                                shape={SHAPE.pill}
                                size={SIZE.large}
                                kind={KIND.secondary}
                                href="#"
                                onClick={this.showProtocolSearch}
                                startEnhancer={() => <Search size={22} />}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            maxWidth: '570px',
                                            width: '100%',
                                            paddingTop: '20px',
                                            paddingBottom: '20px',
                                            marginRight: '16px',
                                            marginLeft: '16px'
                                        })
                                    },
                                    StartEnhancer: {
                                        style: ({ $theme }) => ({
                                            marginRight: '3px',
                                            transform: "rotate(90deg)"
                                        })
                                    }
                                }}
                            >Explore&nbsp;clinics</Button>
        </FlexGridItem>
    </FlexGrid>
    };

    renderHeader = () => {
        return <FlexGrid {...headerProps} flexGridColumnCount={1}>
        <FlexGridItem {...endProps}>
            <Button 
                        kind={KIND.secondary} 
                        shape={SHAPE.pill} 
                        size={SIZE.compact} 
                        style={{marginRight: '8px'}} 
                        onClick={() => this.setState({showReportIssue: true})}
                        >Support
            </Button>
            <StatefulPopover
            content={
                <StatefulMenu
                items={this.state.items}
                onItemSelect={({ item }) => {
                    // Handle item selection, e.g., navigate to the item's href
                    window.location.href = item.href;
                  }}
                overrides={{
                  List: {
                    style: {
                      //width: 'fit-content',
                      padding: '10px 0 10px 0'
                    },
                  },
                  Option: {
                    component: OptionProfile,
                    props: {
                      getProfileItemLabels: ({
                        title,
                        subtitle,
                        //body,
                      }) => ({
                        title,
                        subtitle,
                        //body,
                      }),
                      getProfileItemImg: (item) => item.imgUrl,
                      getProfileItemImgText: (item) => item.title,
                      overrides: {
                        ListItemProfile: {
                            style: {
                                padding: '0 14px 0 0'
                              },
                        },
                        ProfileImgContainer: {
                            style: {
                                width: '50px',
                                height: '50px'
                              },
                        },
                        ProfileImg: {
                            style: {
                                width: '24px',
                                height: '24px'
                              },
                        },
                        ProfileLabelsContainer: {
                            style: {
                                marginTop:'5px',
                                marginLeft:'0px'
                              },
                        },
                        ProfileTitle: {},
                        ProfileSubtitle: {},
                        ProfileBody: {}
                      },
                    },
                  },
                }}
              />
            }
            accessibilityType={'tooltip'}
            placement={PLACEMENT.auto}
          >
            <Button 
                        kind={KIND.primary} 
                        shape={SHAPE.pill} 
                        size={SIZE.compact} 
                        startEnhancer={() => <ArrowRight size={18} />}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    paddingLeft: "8px"
                                })
                            },
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                  marginRight: '0px'
                                })
                            }
                        }}
                        //onClick={() => this.setState({showSignInMenu: true})}
                        >Login
                    </Button>
            </StatefulPopover>
        </FlexGridItem>
    </FlexGrid>
    };

    renderFooter = () => {
        return <FlexGrid {...footerProps} flexGridColumnCount={1}>
        <FlexGridItem {...centerProps}>
            <StyledLink href={"#"} onClick={this.showPhysicianExplainer} className="a_no_line">
                <ParagraphLarge style={{margin: '0px 10px 0px 10px'}}>About</ParagraphLarge>
            </StyledLink>
            <StyledLink href={"#"} onClick={this.showContact} className="a_no_line">
                <ParagraphLarge style={{margin: '0px 10px 0px 10px'}}>Security</ParagraphLarge>
            </StyledLink>
            <StyledLink href={"#"} onClick={this.showReportIssue} className="a_no_line">
                <ParagraphLarge style={{margin: '0px 10px 0px 10px'}}>Support</ParagraphLarge>
            </StyledLink>
            <StyledLink href={"https://github.com/Pharmr/legal"} target="_blank" className="a_no_line">
                <ParagraphLarge style={{margin: '0px 10px 0px 10px'}}>Legal</ParagraphLarge>
            </StyledLink>
        </FlexGridItem>
    </FlexGrid>
    };


    homePage = () => {
        return (
            <FlexGrid flexGridColumnCount={1} marginTop={'0px'}>
                <FlexGridItem {...centerProps}>
                    {this.renderHeader()}
                </FlexGridItem>
                <FlexGridItem {...centerProps}>
                {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <img height={'52'} width={''} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? 'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_4.0,h_52,q_100/v1693520826/logo-main-dark_u7jhfm.png' : 'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_4.0,h_52,q_100/v1693520825/logo-main-light_odamos.png'} alt='Clinic'/>
                    :
                    <img height={'72'} width={''} src={window.matchMedia("(prefers-color-scheme: dark)").matches ? 'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_4.0,h_72,q_100/v1693520826/logo-main-dark_u7jhfm.png' : 'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_4.0,h_66,q_100/v1693520825/logo-main-light_odamos.png'} alt='Clinic'/>}
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{marginLeft:'16px', marginRight:'16px'}}>
                    {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <HeadingXSmall {...greyTextProps} style={{margin:'10px auto 16px auto', fontSize:"24px", lineHeight:"30px", textAlign: "center"}}
                        >The internet's doctor's office.<br/>Build your clinic today!
                    </HeadingXSmall>
                    :
                    <HeadingSmall {...greyTextProps} style={{margin:'12px auto 20px auto', fontSize:"28px", lineHeight:"34px", textAlign: "center", maxWidth:'768px', width:'100%'}}
                        >The internet's doctor's office.<br/>Build your clinic today!
                    </HeadingSmall>
                    }
                </FlexGridItem>
                {/*<FlexGridItem {...centerProps}>
                    {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <HeadingXLarge style={{margin:0, fontSize:"30px", lineHeight:"30px", letterSpacing:"0px", textAlign: "center", fontFamily:"RoobertSemibold"}}
                            >Clinic.dev
                    </HeadingXLarge>
                    :
                    <HeadingXLarge style={{margin:0, fontSize:"42px", lineHeight:"36px", letterSpacing:"-1px", textAlign: "center", fontFamily:"RoobertSemibold"}}
                            >Clinic.dev
                    </HeadingXLarge>
                    }
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{marginLeft:'16px', marginRight:'16px'}}>
                    {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <HeadingXLarge {...greyTextProps} style={{margin:'9px auto 0px auto', fontSize:"30px", lineHeight:"30px", letterSpacing:"-1px", textAlign: "center"}}
                        >Internet clinic builder for doctors
                    </HeadingXLarge>
                    :
                    <HeadingXLarge {...greyTextProps} style={{margin:'12px auto 0px auto', fontSize:"42px", lineHeight:"42px", letterSpacing:"-1px", textAlign: "center"}}
                        >Internet clinic builder for doctors
                    </HeadingXLarge>
                    }
                </FlexGridItem>
                <FlexGridItem {...centerProps} style={{marginLeft:'16px', marginRight:'16px'}}>
                    {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <HeadingXSmall {...grey2TextProps} style={{margin:'12px auto 22px auto', fontSize:"18px", lineHeight:"20px", textAlign: "center"}}
                        >Note: This site is in public beta. Everything works but expect bugs, blank pages and hangs. If you have <StyledLink href={"#"} onClick={this.showReportIssue} style={{color:"#00a1ff"}} className="a_no_line">issues</StyledLink>, post them to our community or contact our SMS support number. For healthcare providers, access our v1 builder <StyledLink href={PHYSICIAN_ROUTES.BASE_URL + PHYSICIAN_ROUTES.SIGN_UP} target="_blank" style={{color:"#00a1ff"}} className="a_no_line">here</StyledLink>. To learn more about the platform, click <StyledLink href={"#"} onClick={this.showPhysicianExplainer} style={{color:"#00a1ff"}} className="a_no_line">here</StyledLink>.
                    </HeadingXSmall>
                    :
                    <HeadingSmall {...grey2TextProps} style={{margin:'14px auto 22px auto', lineHeight:"26px", textAlign: "center", maxWidth:'768px', width:'100%'}}
                        >Note: This site is in public beta. Everything works but expect bugs, blank pages and hangs. If you have <StyledLink href={"#"} onClick={this.showReportIssue} style={{color:"#00a1ff"}} className="a_no_line">issues</StyledLink>, post them to our community or contact our SMS support number. For healthcare providers, access our v1 builder <StyledLink href={PHYSICIAN_ROUTES.BASE_URL + PHYSICIAN_ROUTES.SIGN_UP} target="_blank"  style={{color:"#00a1ff"}} className="a_no_line">here</StyledLink>. To learn more about the platform, click <StyledLink href={"#"} onClick={this.showPhysicianExplainer} style={{color:"#00a1ff"}} className="a_no_line">here</StyledLink>.
                    </HeadingSmall>
                    }
                </FlexGridItem>*/}
                <FlexGridItem {...centerProps}>
                    {this.renderCTAButtons()}
                </FlexGridItem>
                {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                <FlexGridItem {...centerProps}>
                    <div id='demo-player' className='demo-player'>
                        <video
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        src='https://res.cloudinary.com/mpsh87/video/upload/ac_none,c_scale,q_auto,vc_h264,w_700/v1686603094/RPReplay_Final1686599725_z6rh4c.mp4'
                        type="video/mp4"/>
                    </div>
                </FlexGridItem>
                :
                <FlexGridItem {...centerProps}>
                    <div id='demo-player' className='demo-player'>
                        <video
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        src='https://res.cloudinary.com/mpsh87/video/upload/ac_none,c_scale,q_auto,vc_h264,w_600/v1694210085/messenger-1_vnyu1v.mp4'
                        type="video/mp4"
                        />
                    </div><div id='demo-player' className='demo-player' style={{marginLeft:'10px', marginRight:'10px'}}>
                        <video
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        src='https://res.cloudinary.com/mpsh87/video/upload/ac_none,c_scale,q_auto,vc_h264,w_700/v1686603094/RPReplay_Final1686599725_z6rh4c.mp4'
                        type="video/mp4"
                        />
                    </div>
                    <div id='demo-player' className='demo-player'>
                        <video
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                        src='https://res.cloudinary.com/mpsh87/video/upload/ac_none,c_scale,q_auto,vc_h264,w_500/v1694210096/check-in-1_lguh69.mp4'
                        type="video/mp4"
                        />
                    </div>
                </FlexGridItem>
                }
                <FlexGridItem {...centerProps}>
                    {this.renderFooter()}
                </FlexGridItem>
                <PhysicianExplainer show={this.state.showPhysicianExplainer} callback={this.closePhysicianExplainer}/>
                <ReportIssue show={this.state.showReportIssue} callback={this.closeReportIssue}/>
                <Contact show={this.state.showContact} callback={this.closeContact}/>
                {this.state.showSearchProtocol ? <SearchProtocol callbackClose={this.hideProtocolSearch}/> : null}
            </FlexGrid>
        )
    }

    render(){
        return (
            <AuthUserContext.Consumer>
                {isUserLoggedIn => isUserLoggedIn && isUserLoggedIn.emailVerified && isUserLoggedIn.multiFactor.enrolledFactors && isUserLoggedIn.multiFactor.enrolledFactors.length > 0 ? (this.state.loaded ? <Browse/> : <Spinner />) : this.homePage()}
            </AuthUserContext.Consumer>
        )
    }
};

export default withRouter(withFirebase(Home));
