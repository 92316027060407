export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PASSWORD_RESET = '/password_reset';
export const PASSWORD_CHANGE = '/password_change';
export const PROTOCOLS = '/protocols';
export const PROTOCOLS_VIEW = '/protocols/view';
export const PROTOCOLS_VIEW_ID = '/protocols/view/:id';
export const PROTOCOLS_NEW = '/protocols/new';
export const PROTOCOLS_EDIT = '/protocols/edit';
export const PROTOCOLS_EDIT_ID = '/protocols/edit/:id';
export const MFA_ENROLL = '/mfa/enroll';

let base_domain = process.env.BASE_DOMAIN;
let dev_domain = "lvh.me"

const DEVELOPMENT_ENV = 'development';
const PRODUCTION_ENV = 'production';
const STAGING_ENV = 'staging';

const DEV_BASE_URL = 'http://run.' + dev_domain + ':3000';
const PROD_BASE_URL = 'https://run.' + base_domain;
const STAGING_BASE_URL = 'https://run.staging.' + base_domain;

const DEV_BASE_URL_API = 'http://runapi.' + dev_domain + ':3000/api/v1';
const PROD_BASE_URL_API = 'https://runapi.' + base_domain + '/api/v1';
const STAGING_BASE_URL_API = 'https://runapi.staging.' + base_domain + '/api/v1';

let base_url = PROD_BASE_URL;
let base_url_api = PROD_BASE_URL_API;
if (process.env.RAILS_ENV === DEVELOPMENT_ENV) {
    base_url = DEV_BASE_URL;
    base_url_api = DEV_BASE_URL_API;
} else if (window.location.hostname === 'run.staging.' + base_domain) {
    base_url = STAGING_BASE_URL;
    base_url_api = STAGING_BASE_URL_API;
}

export const BASE_URL = base_url;
export const BASE_URL_API = base_url_api;
