import { ParagraphSmall } from 'baseui/typography';
import React, {Component} from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { AuthUserContext, withAuthorization } from '../Session';
import HistoryMessage from './history_message';
import {Spinner, SpinnerSize} from '../Spinner';
import Logger from '../Utils/logger';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        }
    }
}

class History extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
        this.audio = new Audio('https://res.cloudinary.com/mpsh87/video/upload/v1646255037/pharmr-notification_kdapbu.mp3');

        this.color_index = 0;
        this.colors = [
            '#00ff7f',
            '#8a2be2',
            '#1e90ff',
            '#ff7f50',
            '#daa520',
            '#008000',
            '#2e8b57',
            '#9acd32',
            '#ff69b4',
            '#0000ff',
            '#5f9ea0',
            '#ff0000',
            '#ff4500',
            '#e31e1e',
            '#b22222',
            '#db15de',
            '#ffcbfd',
            '#5d5da2',
            '#da5fac',
            '#fff882',
            '#bf94ff',
            '#ffc35e',
            '#00ff12',
            '#d2691e',
            '#ede4be',
            '#ccffff',
            '#00fcaf',
            '#e2f200',
            '#ff82f6',
            '#d91775',
            '#0f7d53',
            '#9fff00',
            '#ff8d00'
        ];

        this.history_attributes = {};
        this.last_username = '';
        this.last_scroll_position = -1;

        this.state = {
            socket: this.props.socket,
            username: this.props.username,
            new_message: this.props.new_message,
            parent_height: this.props.parent_height,
            chat_height: this.calcHeight(this.props.parent_height),
            chat_history: [],
            usernames: [],
            loaded: false,
            show: this.props.show,
        }
    }

    componentDidMount = () => {
        this.state.socket.on('messages', data => {
            this.setState({
                chat_history: data.messages.sort((a, b) => {return a.time_sent > b.time_sent ? 1 : -1}),
                usernames: data.usernames,
                loaded: true
            }, () => {
                if(this.props.users) {
                    this.props.users(data.usernames);
                }
                this.executeScroll();
            })
        });

        this.state.socket.on('new-message', data => {
            this.playSoundOnMention(data);

            this.setState({chat_history: this.state.chat_history.concat([data])});
        })

        this.state.socket.on('user-count', data => {
            if (this.state.usernames.indexOf(data.username)) {
                this.setState({usernames: this.state.usernames.concat([data.username])}, () => {
                    if(this.props.users) {
                        this.props.users(this.state.usernames);
                    }
                });
            }
        });

        this.myRef.current.addEventListener("scroll", () => this.setScrollingPosition());
    };

    setScrollingPosition = () => {
        if (this.myRef.current.scrollTop + this.myRef.current.offsetHeight === this.myRef.current.scrollHeight) {
            this.last_scroll_position = -1;
        } else {
            this.last_scroll_position = this.myRef.current.scrollTop;
        }
    };

    playSoundOnMention = (data) => {
        if(data.message.toLowerCase().includes('@' + this.state.username.toLowerCase())){
            this.audio.play();
        }
    };

    // Header: 73px
    // Typing indicator: 22px
    // Input Message: 44px
    // 12 bottom margin
    // Additional space: 24px
    calcHeight = (parent_height) => parent_height - (61 + 48 + 24 - 6);

    componentDidUpdate = (prevProps) => {
        if (this.props.new_message !== prevProps.new_message) {
            if (this.props.new_message.sent === false) {
                this.setState({chat_history: this.state.chat_history.concat([this.props.new_message])}, () => this.scrollToBottom());
            } else {
                this.setState({new_message: this.props.new_message}, () => this.scrollToBottom());
            }
        }

        if (this.props.parent_height !== prevProps.parent_height) {
            this.setState({parent_height: this.props.parent_height, chat_height: this.calcHeight(this.props.parent_height)})
        }

        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show}, () => {
                if (this.props.show && this.myRef.current) {
                    this.myRef.current.addEventListener("scroll", () => this.setScrollingPosition());    
                }

                this.executeScroll();
            });
        } else {
            this.executeScroll();
        }
    }

    getColor = () => {
        const color = this.colors[this.color_index];
        
        //colors = ['1', '2', '3',] length = 3
        //idx = 0 return '1' mv idx => 1
        //idx = 1 return '2' mv idx => 2
        //idx = 2 return '3' mv idx => 0 since 2 >= colors.length - 1
        if (this.color_index >= (this.colors.length - 1)) {
            this.color_index = 0;
        } else {
            this.color_index += 1;
        }

        return color;
    };

    executeScroll = () => {
        if (this.myRef.current) {
            if(this.last_scroll_position >= 0) {
                this.myRef.current.scrollTo({
                    top: this.last_scroll_position,
                    left: 0,
                    behavior: 'auto'
                })
            } else {
                this.myRef.current.scroll({
                    top: this.myRef.current.scrollHeight,
                    left: 0,
                    behavior: 'auto'
                });
            }
        }
    }

    scrollToBottom = () => {
        if (this.myRef.current) {
            this.myRef.current.scroll({
                top: this.myRef.current.scrollHeight,
                left: 0,
                behavior: 'auto'
            });
        }
    };

    renderMessage = (message) => {
        let show_user = message.username != this.last_username;
        if (show_user) {
            this.last_username = message.username;
        }

        if (this.history_attributes[message.username] && message.time_sent - this.history_attributes[message.username].last_sent > 300000) {
            show_user = true;
        }

        if (!this.history_attributes[message.username]) {
            this.history_attributes[message.username] = {color: this.getColor(), last_sent: message.time_sent};
        } else {
            this.history_attributes[message.username]['last_sent'] = message.time_sent;
        }

        return <HistoryMessage
            data={message}
            color={this.history_attributes[message.username].color}
            username={this.state.username}
            show_user={show_user}
            usernames={this.state.usernames}
        />
    };

    render = () => {
        if (!this.state.show) {
            return null;
        }

        this.last_username = '';

        return <AuthUserContext.Consumer>
            {authUser => (
                <div style={{overflowY: 'auto', height: this.state.chat_height + 'px'}} ref={this.myRef}>
                    <FlexGrid flexGridRowGap={'scale0'}>
                        
                        {this.state.chat_history && this.state.chat_history.map((message, idx) => <FlexGridItem key={idx}>{this.renderMessage(message)}</FlexGridItem>)}
                        <FlexGridItem><ParagraphSmall></ParagraphSmall></FlexGridItem>

                        {this.state.loaded ? this.state.chat_history.length == 0
                            ? <FlexGridItem style={{paddingTop: '15px'}} {...centerProps}><ParagraphSmall margin={'0px 0px 0px 0px'}>No messages found</ParagraphSmall></FlexGridItem>
                            : null

                            : <FlexGridItem style={{paddingTop: this.state.chat_height/2 + 'px'}}><Spinner size={SpinnerSize.LARGE}/></FlexGridItem>
                        }
                    </FlexGrid>
                </div>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(History);
