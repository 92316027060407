import React, {Component} from 'react';
import { Input } from "baseui/input";
import { AuthUserContext, withAuthorization } from '../Session';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Screen from '../../shared/Utils/screen';
import ArrowUp from 'baseui/icon/arrow-up';
import Utils from '../../shared/Utils';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { protocol } from 'socket.io-client';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        }
    }
}

const sendButtonProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '50px'
            }),
        }
    }
}

class Message extends Component {
    constructor(props) {
        super(props);

        this.state = {
            room: this.props.room,
            socket: this.props.socket,
            username: this.props.username,
            protocol: this.props.protocol,
            message: '',
            show: this.props.show
        }

        this.lastTyping = null;
        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        if (Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL'){
            // Prevent the keyboard on iOS from closing once the send button is clicked.
            this.myRef.current.addEventListener("touchend", (e) => {
                e.preventDefault();
                this.sendMessage();
            });
            
            document.addEventListener('focusout', function(e) {window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })});
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.socket !== prevProps.socket) {
            this.setState({socket: this.props.socket});
        }

        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    }

    messageChanged = (event) => {
        const msgLength = this.state.message.length;
        this.setState({message: event.target.value}, () => {
            if(this.state.message.length > 0) {
                this.state.socket.emit('typing', {socket_id: this.state.socket.id})
                if (this.lastTyping != null) {
                    clearTimeout(this.lastTyping);
                }

                this.lastTyping = setTimeout(() => {
                    this.state.socket.emit('stop typing', {socket_id: this.state.socket.id})
                }, 3000);
            } else {
                // Message in total deleted.
                if (this.state.message.length != msgLength) {
                    this.state.socket.emit('stop typing', {socket_id: this.state.socket.id})
                }
            }
        });
    }

    sendMessage = (event) => {
        const message_time = Date.now();
        const new_message = {
            room: this.state.room,
            time_sent: message_time,
            username: this.state.username,
            message: this.state.message,
            sent: true
        };
        this.setState({message: ''});

        this.state.socket.emit('new-message', {
            socket_id: this.state.socket.id,
            message: new_message
        });

        this.state.socket.emit('stop typing', {socket_id: this.state.socket.id});

        if (this.props.new_message) {
            this.props.new_message(new_message);
        }
    };

    handleKeyDown = (event) => {
        switch (event.keyCode) {
          // Enter
            case 13: {
                if (!this.state.message) return;
                this.sendMessage();
                return;
            }
        }
    };

    render = () => {
        if(!this.state.show) {
            return null;
        }

        return <AuthUserContext.Consumer>
            {authUser => (
                <FlexGrid flexGridColumnCount={Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ? 2 : 1}>
                <FlexGridItem {...centerProps}>
                    <Input
                        value={this.state.message}
                        onChange={this.messageChanged}
                        placeholder={"Message #patientchat"}
                        type="text"
                        autoFocus={false}
                        maxLength={500}
                        overrides={{
                            Input: {
                                props: {
                                    onKeyDown: this.handleKeyDown,
                                },
                            },
                            Root: {
                                style: ({ $theme }) => ({
                                    borderTopStyle: 'none',
                                    borderRightStyle: 'none',
                                    borderBottomStyle: 'none',
                                    borderLeftStyle: 'none',
                                    borderTopColor: 'none',
                                    borderRightColor: 'none',
                                    borderBottomColor: 'none',
                                    borderLeftColor: 'none',
                                })
                            }
                        }}
                    />
                </FlexGridItem>

                {
                    Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ? <FlexGridItem {...sendButtonProps}>
                    <Button
                        kind={KIND.primary}
                        size={SIZE.compact}
                        shape={SHAPE.circle}
                        ref={this.myRef}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({})
                            }
                        }}
                    >
                        <ArrowUp size={32} />
                    </Button>
                </FlexGridItem> : null
                }
            </FlexGrid>




            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Message);
