import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';
import { FlexGrid, FlexGridItem, FLexGridItem } from 'baseui/flex-grid';
import content from './content';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { Button, SIZE, SHAPE, KIND } from 'baseui/button';
import { ThemeProvider, createTheme, darkThemePrimitives} from "baseui";
import { StyledLink } from 'baseui/link';
import Utils from '../../shared/Utils';

const memberProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                color: $theme.colors.contentTertiary
            })
        }
    }
}

const centerProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const boxProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                maxWidth: content('en').protocol_preview_max_width,
                borderWidth: content('en').protocol_preview_border_width,
                borderStyle: content('en').protocol_preview_border_style,
                borderColor: $theme.colors.borderOpaque,
                borderRadius: content('en').protocol_preview_border_radius,
                padding: content('en').protocol_preview_padding,
            })
        }
    }
}

class Preview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol_number: this.props.match.params.number,
            protocol: null,
            members: 0,
            members_online: 0,
            error: null,
            content: content('en')
        };
    }

    componentDidMount = () => {
        this.getProtocol();
    };

    getProtocol = () => {
        Request.get({
            firebase: null,
            route: ROUTES.BASE_URL_API + "/patient_home/preview/" + this.state.protocol_number,
            callbackSuccess: result => {
                Logger.log(result)
                this.setState({
                protocol: result.protocol,
                members: result.members,
                members_online: result.members_online
            })},
            callbackError: error => this.setState({
                error: {message: "Unable to retrieve information about protocol."}
            })
        })
    };

    render = () => {
        if (this.state.error) {
            return Utils.renderError(this.state.error);
        }

        return this.state.protocol && <FlexGrid>
            <FlexGridItem height={"95vh"} {...centerProps}>
                <FlexGrid {...boxProps} {...centerProps} style={{
                marginLeft:'16px', 
                marginRight:'16px'}}>
                    <FlexGridItem {...centerProps}>
                        {this.state.content.protocol_preview_title}
                    </FlexGridItem>
                    <FlexGridItem {...centerProps}>
                        <Favicon
                            favicon_url={this.state.protocol.favicon_url}
                            size={FaviconSize.MEDIUM}
                            badge_type={this.state.protocol.badge_type}
                            status={this.state.protocol.status}
                            rejecting_new_subscriptions={this.state.protocol.rejecting_new_subscriptions}
                        />
                    </FlexGridItem>
                    <FlexGridItem {...centerProps}>
                        <HeadingMedium margin={this.state.content.protocol_preview_name_margin}>{this.state.protocol.name}</HeadingMedium>
                    </FlexGridItem>
                    <FlexGridItem {...centerProps}>
                        <ParagraphMedium margin={this.state.content.protocol_preview_members_margin} {...memberProps}>
                            {this.state.members} members
                        </ParagraphMedium>
                    </FlexGridItem>
                    <FlexGridItem {...centerProps}>
                    <Button
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        paddingLeft: this.state.content.protocol_preview_button_padding_left,
                                        paddingRight: this.state.content.protocol_preview_button_padding_right,
                                    })
                                }
                            }}
                            kind={KIND.primary}
                            size={SIZE.default}
                            shape={SHAPE.default}
                            $as={'a'}
                            href={ROUTES.BASE_URL + '/protocol/' + this.state.protocol_number}
                        >{this.state.content.protocol_preview_button_title}</Button>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    };
}

export default Preview;
