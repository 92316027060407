import React, {Component} from 'react';
import { Combobox } from "baseui/combobox";
import Request from '../Request';
import { FormControl } from "baseui/form-control";
import Utils from '../Utils';
import Logger from '../Utils/logger';
import { Spinner, SpinnerSize } from '../Spinner';
import { minute } from 'javascript-time-ago/gradation';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import MpsModal from '../Utils/modal';
import { ParagraphSmall } from 'baseui/typography';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button'
import Delete from 'baseui/icon/delete';

const NUM_RESULT = 0;
const ARRAY_CODES = 1;
const DATA_OBJ = 2;
const OPTION_NAMES = 3;
const NO_MATCHES_FOUND = 'No matches found';
const NO_MATCHES_FOUND_IDX = -1;
const TYPE = "ReportedCondition";

const DEFAULT_ROUTE = 'https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?ef=primary_name,consumer_name,key_id,icd10cm_codes,icd10cm,term_icd9_code,term_icd9_text,word_synonyms,synonyms,info_link_data&authenticity_token=&terms=';
const MAX_ROUTE = 'https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?ef=primary_name,consumer_name,key_id,icd10cm_codes,icd10cm,term_icd9_code,term_icd9_text,word_synonyms,synonyms,info_link_data&authenticity_token=&maxList=&terms=';
const MORE_OPTIONS = " more options";

const spinnerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '50px',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

const narrowRightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '70px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const highlightTextProps = {
  overrides: {
      Block: {
        style: ({$theme}) => ({
          color: '#ff5722',
        }),
      },
  },
};

class Conditions extends Component {
    constructor(props) {
        super(props);

        this.searchField = React.createRef();

        this.state = {
            showModal: this.props.showModal,
            error: null,
            searching: false,
            search_term: '',
            search_more: false,
            search_results: [],
            selected_items: this.props.items ? this.getItems(this.props.items) : []
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.showModal !== prevProps.showModal) {
            this.setState({showModal: this.props.showModal});
        }
    };

    getItems = (items) => {
        // Make a deep copy of the items so it does not get updated directly on the parent
        return this.props.items.map(item => JSON.parse(JSON.stringify(item)));
    };

    search = () => {
        if (this.state.search_term.length >= 0) {
            this.setState({searching: true});

            let route = (this.state.search_more ? MAX_ROUTE : DEFAULT_ROUTE) + encodeURI(this.state.search_term);

            Request.get({
                firebase: null,
                headers: {},
                route: route,
                callbackSuccess: res => this.setState({
                    search_results: res,
                    search_more: false,
                    searching: false,
                    ...Utils.clearAllNotifications()
                }, () => this.setSelected()),
                callbackError: error => {
                    this.setState({error, searching: false});
                    Logger.log("Error Searching:");
                    Logger.log(error);
                }
            })
        } else {
            this.setState({
                search_results: [],
                search_more: false,
                searching: false,
                ...Utils.clearAllNotifications()
            });
        }
    };

    setSelected = () => {
        // Sometimes some searches return more than 1 result.
        // Searching for "Abdominal Pain" returns 7 results:
        //    "Abdominal pain", "Epigastric pain", "Flank pain", "Suprapubic pain", "Colic - infantile", "Abdominal colic", "Flatulence (gas)"
        // it never returns only one result even if you type the whole name.
        if (this.state.search_results[NUM_RESULT] >= 1 && this.state.search_results[DATA_OBJ].consumer_name.includes(this.state.search_term))
        {

            let selectedItemsUpdated = this.state.selected_items;
            if (!this.isDuplicate(this.state.search_term)){
                var details = {};
                var result_index = this.state.search_results[DATA_OBJ].consumer_name.indexOf(this.state.search_term);
                Object.keys(this.state.search_results[DATA_OBJ]).forEach(key => {
                    details[key] = this.state.search_results[DATA_OBJ][key][result_index];
                });
                selectedItemsUpdated.push({
                    short_name: this.state.search_term,
                    details: JSON.stringify(details),
                    type: TYPE
                });
            }

            this.setState({
                search_term: '',
                search_results: [],
                selected_items: selectedItemsUpdated
            });
        }
    };

    isDuplicate = (val) => {

        Logger.log("Checking duplicates...");
        Logger.log(val);
        Logger.log(this.state.selected_items);

        const res = this.state.selected_items.filter(item => item.short_name == val && item._destroy == undefined)
        return res.length > 0;
    };

    setSearchTerm = (val) => {
        if (val == '') {
            this.setState({
                search_term: val,
                search_results: [],
                ...Utils.clearAllNotifications()
            });
        } else if (val == NO_MATCHES_FOUND){
            this.setState({
                search_term: '',
                search_results: [],
                ...Utils.clearAllNotifications()
            });
        } else if (val.includes(MORE_OPTIONS)) {
            // Need to refocus the drop down.
            this.setState({
                search_term: this.state.search_term,
                search_more: true,
                ...Utils.clearAllNotifications()
            }, () => {
                this.search();
                this.setFocusOnSearch();
            });
        } else {
            this.setState({
                search_term: val,
                ...Utils.clearAllNotifications()
            }, () => {
                this.search();
            });
        }
    };

    renderSearchResults = () => {
        if (this.state.search_results && this.state.search_results[NUM_RESULT] > 0){
            if (this.state.search_results[NUM_RESULT] > this.state.search_results[ARRAY_CODES].length) {
                let max_option_data = this.state.search_results[DATA_OBJ].consumer_name.concat([this.state.search_results[NUM_RESULT].toString() + MORE_OPTIONS]);
                Logger.log(max_option_data);

                return max_option_data.map((suggestion, idx) => {
                    var result = {};
                    result.label = suggestion;
                    result.id = idx;

                    return result;
                })
            } else {
                return this.state.search_results[DATA_OBJ].consumer_name.map((suggestion, idx) => {
                    var result = {};
                    result.label = suggestion;
                    result.id = idx;

                    return result;
                });
            }
        } else if (this.state.search_term.length > 0){
            var result = {};
            result.label = NO_MATCHES_FOUND;
            result.id = NO_MATCHES_FOUND_IDX;
            return [result];
        } else {
            return this.state.search_results;
        }
    };

    setFocusOnSearch = () => {
        if (this.searchField.current) {
            setTimeout(() => {
                this.searchField.current.blur();
                this.searchField.current.focus();
            }, 50);
        }
    };

    renderSearchField = () => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem>
                <Combobox
                    overrides={{
                        Input: {
                            props: {
                                placeholder: 'Search medical condition...',
                                autoComplete: "new-password"
                            },
                        },
                    }}
                    value={this.state.search_term}
                    onChange={nextValue => this.setSearchTerm(nextValue)}
                    options={this.renderSearchResults()}
                    mapOptionToString={option => option.label}
                    autocomplete="new-password"
                    inputRef={this.searchField}
                />
            </FlexGridItem>
            <FlexGridItem {...spinnerProps}>
                {this.state.searching ? <Spinner size={SpinnerSize.SMALL}/> : null}
            </FlexGridItem>
        </FlexGrid>
    };

    deleteCondition = (id) => {
        let updatedSelectedItems = this.state.selected_items;
        if (updatedSelectedItems[id].id) {
            // Update with destroy
            updatedSelectedItems[id]._destroy = true;
        } else {
            // Remove it. It is not in DB
            updatedSelectedItems.splice(id, 1);
        }

        this.setState({
            selected_items: updatedSelectedItems,
            ...Utils.clearAllNotifications(),
        }, () => Logger.log(this.state.selected_items));
    }

    renderSelectedConditions = () => {
        return this.state.selected_items.map((condition, idx) => condition._destroy ? null : <FlexGrid flexGridColumnCount={2} key={idx}>
                <FlexGridItem><ParagraphSmall {...highlightTextProps} style={{margin: 0}}>{condition.short_name}</ParagraphSmall></FlexGridItem>
                <FlexGridItem {...narrowRightProps}><Button onClick={(event) => this.deleteCondition(idx)} shape={SHAPE.circle} kind={KIND.tertiary} size={SIZE.compact}>
                    <Delete size={24} />
                </Button></FlexGridItem>
            </FlexGrid>);
    };

    closeModal = () => {
        this.setState({
            selected_items: []
        });

        if (this.props.callbackCancel) {
            this.props.callbackCancel();
        }
    }

    updateParent = () => {
        if (this.props.callback){
            this.props.callback(this.state.selected_items);
        }
    };

    renderModal = () => {
        return <MpsModal
            open={this.state.showModal}
            autoFocus={false}
            callback={this.updateParent}
            callbackCancel={this.closeModal}
            withFooter={true}
            callbackButton={"Done"}
            title={Utils.renderTitleCenter('Medical condition lookup')}
            body={<div>
                {Utils.renderError(this.state.error)}
                <FlexGrid flexGridRowGap={"scale600"}>
                    <FlexGridItem>
                        {this.renderSearchField()}
                    </FlexGridItem>
                    <FlexGridItem>
                        {this.renderSelectedConditions()}
                    </FlexGridItem>
                </FlexGrid>
            </div>}
        />
    };

    render = () => {
        return this.state.showModal && this.renderModal();
    }
}

// TODO maybe add some authentication around this component..
export default Conditions;
