import { HeadingSmall, ParagraphMedium} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {Button, KIND, SHAPE, SIZE} from 'baseui/button';
import React from 'react';

const itemProps = {
  
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const greyTextProps = {
    overrides: {
        Block: {
          style: ({$theme}) => ({
            color: $theme.colors.contentTertiary
          }),
        },
    },
};

const data = {
    en: {
        title: "Problem with the app?",
        body: <>
        <FlexGrid flexGridColumnCount={1} flexGridRowGap={"scale400"} style={{marginTop: '24px', marginBottom: '24px'}}>
            <FlexGridItem {...itemProps}>
                <Button
                    $as="a"
                    href="sms:1-646-389-5550"
                    size={SIZE.default}
                    shape={SHAPE.pill}
                    kind={KIND.secondary}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                width:"100%",
                                height:"30px"
                            })
                        }
                    }}
                    >Text 646-389-5550
                </Button>
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
                <Button
                    $as="a"
                    href="https://www.reddit.com/r/clinicapp/"
                    target="_blank"
                    size={SIZE.default}
                    shape={SHAPE.pill}
                    kind={KIND.primary}
                    overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    width:"100%",
                                    height:"30px",
                                    color: "white",
                                    backgroundColor: "#ff3903",
                                    ":hover": {
                                        backgroundColor: "#ec5228"
                                    },
                                    ":focus": {
                                        backgroundColor: "#f56e49"
                                    },
                                    ":active": {
                                        backgroundColor: "#f56e49"
                                    },
                                
                                })
                            }
                        }}
                    >Clinic.dev subreddit
                </Button>
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
                <Button
                    $as="a"
                    href="https://discord.gg/RkK77ajn24"
                    target="_blank"
                    size={SIZE.default}
                    shape={SHAPE.pill}
                    kind={KIND.primary}
                    overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    width:"100%",
                                    height:"30px",
                                    color: "white",
                                    backgroundColor: "#566aea",
                                    ":hover": {
                                        backgroundColor: "#001dff"
                                    },
                                    ":focus": {
                                        backgroundColor: "#6d7ae3"
                                    },
                                    ":active": {
                                        backgroundColor: "#6d7ae3"
                                    },
                                
                                })
                            }
                        }}
                    >Clinic.dev discord server
                </Button>
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
                <ParagraphMedium style={{margin:"10px 0px 0px 0px", color:"gray"}}>Medical emergency call 911.</ParagraphMedium>
            </FlexGridItem>
        </FlexGrid>
    </>
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
