import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { Combobox } from "baseui/combobox";
import { Input } from "baseui/input";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Utils from '../Utils';
import Request from '../Request';
import States from '../Utils/states';
import Logger from '../Utils/logger';

class AddressPicker extends Component {
    constructor(props){
        super(props);

        this.streetField = React.createRef();

        this.state = {
            street: '',
            street_more: '',
            secondary: '',
            city: '',
            state: '',
            zipcode: '',
            street_results: null
        };
    }

    findMatches = () => {
        if (this.state.street.length >= 8) {
            let route = "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=85859052062428709&search="+ encodeURI(this.state.street) +"&selected=" + encodeURI(this.state.street_more); 

            Request.get({
                firebase: null,
                headers: {},
                route: route,
                callbackSuccess: res => {
                    this.setState({
                        street_results: res,
                        ...Utils.clearAllNotifications()
                    }
                    , () => {
                        this.updateStreet();
                        if (this.state.street_more) {
                            // Re-list options for more results.
                            this.setFocusOnStreet()
                        }
                    }
                    )
                },
                callbackError: error => {
                    if (this.props.callbackError) {
                        this.props.callbackError({message: "Something went wrong. Error message " + error.error.errors[0].message + ". Please contact customer service."});
                    }
                }
            })
        } else {
            this.setState({street_results: null,  ...Utils.clearAllNotifications()});

            if (this.props.callbackError) {
                this.props.callbackError(null);
            }
        }
    };

    updateStreet = () => {
        if (this.state.street_results 
            && this.state.street_results.suggestions 
            && (this.state.street_results.suggestions.length == 1 || this.allSameAddress(this.state.street_results.suggestions))
            ) {

                if (this.state.street_results.suggestions.length == 1) {
                    if (this.state.street == this.compileAddress(this.state.street_results.suggestions[0])) {
                        var full_address = {
                            street: this.state.street_results.suggestions[0].street_line,
                            secondary: this.state.street_results.suggestions[0].secondary,
                            city: this.state.street_results.suggestions[0].city,
                            state: this.state.street_results.suggestions[0].state,
                            zipcode: this.state.street_results.suggestions[0].zipcode,
                            ...Utils.clearAllNotifications()
                        };
                        this.setState(full_address, () => {this.updateParent(); this.setFocusOffStreet()});
                    }
                } else{
                    // Multiple same addresses
                    this.state.street_results.suggestions.map(suggestion => {
                        if (this.compileAddress(suggestion) == this.state.street) {
                            var full_address = {
                                street: suggestion.street_line,
                                secondary: suggestion.secondary,
                                city: suggestion.city,
                                state: suggestion.state,
                                zipcode: suggestion.zipcode,
                                ...Utils.clearAllNotifications()
                            };
                            this.setState(full_address, () => this.updateParent());
                        }
                    });
                }
        }
    };

    allSameAddress = (suggestions) => {
        if (suggestions.length == 0) {
            return false;
        }

        const addr = suggestions[0].street_line;

        return suggestions.every((address) => address.street_line == addr);
    };

    renderResults = () => {
        if (this.state.street_results && this.state.street_results.suggestions && this.state.street_results.suggestions.length > 0){
            return this.state.street_results.suggestions.map((suggestion, idx) => {
                var result = {};
                result.label = this.compileAddress(suggestion);
                result.id = idx;

                return result;
            });
        }

        return [];
    };

    compileAddress = (dict) => {
        return dict.street_line + (dict.secondary != '' ? ' ' + dict.secondary : '') + (dict.entries > 1 ? ' ('+ dict.entries + ' more entries)' : '') + ' ' + dict.city + ' ' + dict.state + ' ' + dict.zipcode;
    };

    setStreet = (val) => {
        if (val == '') {
            this.setState({
                street: val,
                secondary: '',
                city: '',
                state: '',
                zipcode: '',
                street_results: null,
                ...Utils.clearAllNotifications()
            }, () => {this.updateParent()});
        } else {
            if (val.includes(' more entries)')) {
                let forMoreEntries = val.replaceAll(' more entries', '');
                this.setState({
                    street_more: forMoreEntries,
                    ...Utils.clearAllNotifications()
                }, () => {
                    this.findMatches();
                    this.updateParent();
                    this.setFocusOnStreet()
                });
            } else {
                this.setState({
                    street: val,
                    street_more: '',
                    ...Utils.clearAllNotifications()
                }, () => {this.findMatches(); this.updateParent();});
            }
        }
    };
 
    setFocusOnStreet = () => {
        if (this.streetField.current) {
            setTimeout(() => {
                this.streetField.current.blur();
                this.streetField.current.focus();
            }, 50);
        }
    };

    setFocusOffStreet = () => {
        if (this.streetField.current) {
            this.streetField.current.blur();
        }
    };

    renderForm = () => {
        return <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale800" flexGridRowGap="scale300">
            <FlexGridItem>
                <Combobox
                    overrides={{
                        Input: {
                            props: {
                                placeholder: 'Enter street address',
                                autoComplete: "new-password"
                            },
                        },
                    }}
                    value={this.state.street || ''}
                    onChange={nextValue => this.setStreet(nextValue)}
                    options={this.renderResults()}
                    mapOptionToString={option => option.label}
                    autocomplete="new-password"
                    inputRef={this.streetField}
                />
            </FlexGridItem>
            <FlexGridItem>
                <Input
                    autoComplete="new-password"
                    placeholder="Apt, suite, unit, building, floor, etc."
                    name='secondary'
                    value={this.state.secondary || ''}
                    onChange={newVal => this.setState({secondary: newVal.target.value, ...Utils.clearAllNotifications()}, () => this.updateParent())}/></FlexGridItem>
            <FlexGridItem>
                <Input
                    autoComplete="new-password"
                    placeholder="City"
                    name='city'
                    value={this.state.city || ''}
                    onChange={newVal => this.setState({city: newVal.target.value, ...Utils.clearAllNotifications()}, () => this.updateParent())}/></FlexGridItem>
            <FlexGridItem>
                <States
                    value={this.state.state}
                    onChange={(newState) => this.setState({state: newState[0].id, ...Utils.clearAllNotifications()}, () => this.updateParent())}
                />
                </FlexGridItem>
            <FlexGridItem>
                <Input
                    autoComplete="new-password"
                    placeholder="Zipcode"
                    name='zipcode'
                    value={this.state.zipcode || ''}
                    onChange={newVal => this.setState({zipcode: newVal.target.value, ...Utils.clearAllNotifications()}, () => this.updateParent())}/></FlexGridItem>
        </FlexGrid>
    };

    updateParent = () => {
        if (this.props.callback){
            this.props.callback({
                street_line: this.state.street,
                secondary: this.state.secondary,
                city: this.state.city,
                state: this.state.state,
                zipcode: this.state.zipcode
            });
        }
    };
    render() {

        return (
            <AuthUserContext.Consumer>
                {authUser => this.renderForm()}
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(AddressPicker);
