import React, {Component} from 'react';
import content from './content';

class SubscriptionError extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: content("en")
        };
    }

    render () {
        return (
            this.state.content.error_description
        );
    }
}

export default SubscriptionError;
