import React, {Component} from 'react';
import * as ROUTES from '../../physician/Constants';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Favicon, {FaviconSize} from '../Favicon';
import { HeadingLarge, HeadingMedium, ParagraphSmall, DisplayXSmall, HeadingXXLarge } from 'baseui/typography';
import { StyledLink } from "baseui/link";
import Utils from '../Utils';
import { AuthUserContext, withAuthorization } from '../Session';
import {Spinner, SpinnerSize} from '../Spinner';
import Request from '../Request';
import Logger from '../Utils/logger';
import Screen from '../Utils/screen';
import {StatefulTooltip, TRIGGER_TYPE, PLACEMENT} from 'baseui/tooltip';
import {Block} from 'baseui/block';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class PhysicianClinics extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            success: null,
            info: null,
            isLoaded: false,
            protocols: null
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_protocol/my_protocols",
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    protocols: result
                })
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            }
        })
    };

    renderProtocols = () => {
        if (!this.state.isLoaded){
            return <Spinner size={SpinnerSize.SMALL} />;
        }

        Logger.log('My protocols')
        Logger.log(this.state.protocols);
        if (this.state.protocols){
            this.state.protocols.sort((a, b) => {return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1});
            return <FlexGrid style={{display:'flex', flexWrap:'nowrap'}}>
                {this.state.protocols.map((item, key) => this.renderProtocol(item))}
                </FlexGrid>
        }
    };

    renderProtocol = (protocol) => {
        return <div key={protocol.id}>
            
            <StyledLink
                className="a_no_line"
                href={(protocol.status == 'draft'
                    ? ROUTES.PROTOCOLS_EDIT
                    : ROUTES.PROTOCOLS_VIEW) + '/' + protocol.number}>
                        <FlexGridItem flexDirection={'row'}>
                                <Favicon
                                    favicon_url={protocol.favicon_url}
                                    size={FaviconSize.SMALL}
                                    badge_type={protocol.badge_type}
                                    status={protocol.status}
                                    rejecting_new_subscriptions={protocol.rejecting_new_subscriptions}
                                    style={{marginRight:'8px', marginTop:'3px'}}
                                    showBadge={false}
                                /></FlexGridItem>
            </StyledLink>
        </div>
    }

    render(){
        return <AuthUserContext.Consumer>
            {authUser =>
                <div>
                    {Utils.renderError(this.state.error)}
                    {Utils.renderSuccess(this.state.success)}
                    {Utils.renderInfo(this.state.info)}
                    {this.renderProtocols()}
                </div>
            }
        </AuthUserContext.Consumer>
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PhysicianClinics);
