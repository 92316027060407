import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { StatefulTooltip } from "baseui/tooltip";
import Utils from '../../shared/Utils';
import {Block} from 'baseui/block';
import { LabelLarge, HeadingSmall, LabelMedium, ParagraphSmall } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { Alert } from "baseui/icon";

const addRemoveProps = {
    maxWidth: '92px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
};


class Reorder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultProducts: {},
            selectedProducts: []
        };

        this.addProductToState();
        this.preselectDrugs(this.props.order_on_cart);
    }

    addProductToState = () => {
        for (var i = 0; i < this.props.prescriptions.length; i++) {
            if (this.canBeReordered(this.props.prescriptions[i])) {
                this.addVariantToState(this.props.prescriptions[i]);
            }
        }
    };

    canBeReordered = (prescription) => {
        return prescription.variant // Only show drugs prescribed from our formulary
            && prescription.refills_remaining > 0
            && prescription.pharmacy_id != null;
    };

    preselectDrugs = (order_on_cart) => {
        if (order_on_cart.is_reorder && Object.keys(order_on_cart).length > 0) {
            // Update the reorder drugstore
            order_on_cart.line_items.map(line_item => {
                if (line_item.variant) {
                    const variant_name = line_item.variant.change_drug_id + '_' + line_item.variant.id;
                    this.addProduct(variant_name);
                }
            });
        }
    };

    addVariantToState = (prescription) => {
        var fulfiller = this.getFulfillment(prescription.pharmacy_id, prescription.variant.fulfillment_prices);
        if (!fulfiller) {
            return null;
        }

        this.state.defaultProducts[prescription.variant.change_drug_id + '_' + prescription.variant.id] = {
            product_name: prescription.drug_name,
            variant_id: prescription.variant.id,
            quantity: 1,
            price: fulfiller.price,
            fulfiller_id: fulfiller.id,
            pharmacy_id: fulfiller.pharmacy_id
        };
    };

    getFulfillment = (pharmacy_id, fulfillment_prices) => {
        for (var i = 0; i < fulfillment_prices.length; i++) {
            if (fulfillment_prices[i].pharmacy_id == pharmacy_id) {
                return fulfillment_prices[i];
            }
        }

        return null;
    };

    addProduct = (name) => {
        this.state.selectedProducts.push(name);
        this.props.results[name] = this.state.defaultProducts[name];
        this.props.notify();
    };

    addSelectedProduct = (event) => {
        this.addProduct(event.target.name);
    };

    removeSelectedProduct = (event) => {
        // Add product and fullfiller
        this.state.selectedProducts = this.state.selectedProducts.filter((value) => value != event.target.name);
        delete this.props.results[event.target.name];
        this.props.notify();
    };

    renderReorderTitle = () => {
        return <LabelLarge>Reorder</LabelLarge>
    };

    drawVariant = (prescription, fulfiller, idx) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem>
                <FlexGrid
                    flexGridColumnCount={1}
                    flexGridColumnGap={'scale200'}
                    flexGridRowGap={'scale200'}
                >
                    <FlexGridItem><LabelMedium>{prescription.drug_name}</LabelMedium></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{margin: '0px', color: 'grey'}}>Rx detail <StatefulTooltip
                        accessibilityType={'tooltip'}
                        content={() => (
                        <Block padding={"10px"}>
                            <div>Quantity: {prescription.variant.quantity}</div>
                            <div>Price: {fulfiller ? Utils.renderDollarAmount(fulfiller.price) : 'Price not found.'}</div>
                            <div>Pharmacy: {fulfiller ? fulfiller.pharmacy.name : 'Pharmacy not found'}</div>
                            <div>Refills Remaining: {prescription.refills_remaining}</div>
                        </Block>
                        )}
                        showArrow
                    ><Alert/></StatefulTooltip></ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem {...addRemoveProps}>
                <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        style={this.state.selectedProducts.includes(prescription.variant.change_drug_id + '_' + prescription.variant.id)? {display: 'none'} : {}}
                        name={prescription.variant.change_drug_id + '_' + prescription.variant.id}
                        disabled={this.props.disable}
                        onClick={this.addSelectedProduct}>Add
                    </Button>
                    <Button
                        kind={KIND.primary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        style={this.state.selectedProducts.includes(prescription.variant.change_drug_id + '_' + prescription.variant.id)? {} : {display: 'none'}}
                        name={prescription.variant.change_drug_id + '_' + prescription.variant.id}
                        onClick={this.removeSelectedProduct}>Remove
                    </Button>
            </FlexGridItem>
        </FlexGrid>
    };

    renderDrugOptions = () => {
        return <FlexGrid
            flexGridColumnCount={1}
            flexGridColumnGap={'scale600'}
            flexGridRowGap={'scale800'}
        >
            
            {
                Object.keys(this.state.defaultProducts).length > 0
                ? this.props.prescriptions.map(
                    (prescription, idx) => 
                        this.canBeReordered(prescription)
                        ? <FlexGridItem key={idx}>{this.drawVariant(
                            prescription,
                            this.getFulfillment(prescription.pharmacy_id, prescription.variant.fulfillment_prices),
                            idx
                        )}</FlexGridItem>
                        : '')
                : ''
            }
        </FlexGrid>
    };

    render() {
        return(
            <AuthUserContext.Consumer>
                {authUser => Object.keys(this.state.defaultProducts).length > 0 ? <div>
                    {Utils.renderSpace()}
                    {Utils.renderSpace()}
                    {this.renderReorderTitle()}
                    {Utils.renderSpace()}
                    {this.renderDrugOptions()}
                    </div> : ''
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Reorder);
