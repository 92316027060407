import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import PersonalInfo from './personal';
import PasswordChange from '../../shared/Password/change';
import ListCreditCards from '../PaymentMethod/credit_cards';
import CreditCard from '../PaymentMethod/credit_card';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import Logger from '../../shared/Utils/logger';
import {Username} from '../../shared/Users';
import Theme from './theme';
import Blurb from './blurb';

class Account extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            success: null,

            patient: null,
            newCard: false,
            preferred_card: '',
            cards: []
        };
    }

    componentDidMount = () => {
        this.getPatient();
        window.scrollTo(0, 0);
    };

    getPatient = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/user_management",
            callbackSuccess: (result) => {
                this.setState({
                    patient: result,
                    preferred_card: (result && result.preferred_payment_method_id ? result.preferred_payment_method_id.toString() : ''),
                    ...Utils.clearAllNotifications()
                });
            },
            callbackError: (error) => {
                this.setState({
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        })
    };

    loadCards = (newCards) => {
        var newCardsArray = Array.isArray(newCards) ? newCards : [newCards]
        this.setState(prevState => ({
            cards: newCardsArray.concat(prevState.cards),
            ...Utils.clearAllNotifications()
        }), () => {Array.isArray(newCards) ? false : this.newCardSelected(newCards.id)});
    };

    newCardSelected = (value) => {
        this.setState({preferred_card: value, ...Utils.clearAllNotifications()}, this.updatePreferredCard);
    };

    updatePreferredCard = () => {
        if (this.state.preferred_card == '') {
            return;
        }

        const data = new FormData();
        data.append('id', this.state.preferred_card);
        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_payment/set_preferred_card",
            body: data,
            callbackSuccess: (result) => this.setState({
                success: {message: 'Preferred card updated successfully.'},
                ...Utils.clearAllNotificationsExceptSuccess()
            }),
            callbackError: (error) => this.setState({
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    getPatientName = () => {
        if (this.state.patient && this.state.patient.first_name && this.state.patient.last_name) {
            return this.state.patient.first_name + ' ' + this.state.patient.last_name;
        }

        return '';
    };

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div style={{overscrollBehaviorY:'contain', marginTop:'98px', marginBottom:'40px'}}>
                    {Utils.renderError(this.state.error)}
                    {Utils.renderSuccess(this.state.success, 9000)}
                    {this.state.patient && Object.keys(this.state.patient).length > 0 ?
                    <div>
                        {Utils.renderTitleCenter("Account")}
                        <Username username={this.state.patient.username} />
                        {Utils.renderSpace()}
                        <PersonalInfo
                            email={this.state.patient.email}
                            name={this.getPatientName()}
                            birthdate={this.state.patient.birthdate}
                            niceuuid={this.state.patient.number}/>
                        {Utils.renderSpace()}
                        {Utils.renderSpace()}
                        <PasswordChange />
                        {Utils.renderSpace()}
                        <ListCreditCards title={'Subscription payment method'} cards={this.state.cards} loadCards={this.loadCards} selected_card={this.state.preferred_card} newCardCallback={this.newCardSelected}/>
                        {Utils.renderSpace()}
                        <CreditCard title='Please provide your payment method' callback={this.loadCards}/>
                        {Utils.renderSpace()}
                    </div>
                    : <Spinner />}
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    );
}
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Account);
