import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import MpsModal, {Size as ModalSize} from '../../shared/Utils/modal';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../../shared/Request';
import content, {displaySearchContent} from './content';
import {Input} from 'baseui/input';
import { StyledLink } from 'baseui/link';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import Screen, {Size as ScreenSize} from '../../shared/Utils/screen';
import Truncate from 'react-truncate';
import {ParagraphLarge,LabelMedium, HeadingXSmall, HeadingMedium} from 'baseui/typography';
import '../../shared/Styles/style.scss';
import {ThemeProvider, createTheme, darkThemePrimitives} from "baseui";

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};

const topLinnersDoNothing = {
    overrides: {
      Block: {
        style: ({$theme}) => ({}),
      },
    },
};

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const favProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                marginTop: '5px',
                maxWidth: '36px',
            }),
        },
    },
};

const greyTextProps = {
  overrides: {
      Block: {
        style: ({$theme}) => ({
          color: $theme.colors.primary400,
        }),
      },
  },
};

const autoOverride = {
    Root: {
         style: ({ $theme }) => ({
             //zIndex: 5050
         })
       },
 };

class SearchProtocol extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            protocols: [],
            show_search: true,
            content: this.getContent(),
            search: '',
            search_results: []
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_home",
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    protocols: result.data.protocols,
                    ...Utils.clearAllNotifications()
                });
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                });
            }
        });
    };

    getContent = () => {
        return content('en');
    };

    closeModal = () => {
        this.setState({show_search: false});
        this.props.callbackClose();
    };

    renderSearchField = () => {
        return <Input
            autoFocus={false}
            placeholder="Find by name, drug or diagnosis" 
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        maxWidth: this.state.content.banner_search_max_width,
                        marginTop: '20px',
                    })
                },
                Input: {
                    style: ({ $theme }) => ({
                        paddingLeft: this.state.content.banner_search_padding_left,
                        //backgroundColor: $theme.colors.backgroundAlwaysLight,
                        //color: $theme.colors.black,
                        //caretColor: $theme.colors.black,
                    })
                }
            }}
            value={this.state.search}
            onChange={e => this.setState({search: e.target.value})}
            onFocus={event => document.getElementById('show_hide').style.display='none'}
        />
    };
    
    renderSearchFieldBody = () => {
        return <div id="show_hide">
                    <ParagraphLarge style={{marginTop: "150px", marginBotton: "24px", textAlign:"center", maxWidth:"360px", marginLeft:"auto", marginRight:"auto"}}></ParagraphLarge>
        </div>
    };
    search = () => {
        let results = [];
        const search_target = this.state.search.toLowerCase();

        this.state.protocols.forEach((protocol, idx) => {
            if(this.searchByName(protocol, search_target)
                || this.searchByDrug(protocol, search_target)
                || this.searchByCondition(protocol, search_target)
                || this.searchByNumber(protocol, search_target)
            ) {
                if (results.length > 0) {
                    results.push(<FlexGridItem {...contentProps} {...topLinners} key={idx} padding={this.state.content.search_result_row_padding}>
                        <FlexGrid flexGridColumnCount={2}>
                            <FlexGridItem {...favProps}>
                            <StyledLink key={idx} href={ROUTES.BASE_URL + "/protocol/" + protocol.number} className="a_no_line">
                                <Favicon
                                    favicon_url={protocol.favicon_url}
                                    size={FaviconSize.SMALL}
                                    badge_type={protocol.badge_type}
                                    status={protocol.status}
                                    rejecting_new_subscriptions={protocol.rejecting_new_subscriptions}
                                />
                                </StyledLink>
                            </FlexGridItem>
                            <FlexGridItem>
                                <StyledLink key={idx} href={ROUTES.BASE_URL + "/protocol/" + protocol.number} className="a_no_line">
                                    {displaySearchContent(<Truncate lines={1} width={Screen.size() == [ScreenSize.xsmall||ScreenSize.small] ? 220 : 398}>{protocol.name + ' • ' + protocol.summary}</Truncate>)}
                                </StyledLink>
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>)
                } else {
                    results.push( <FlexGridItem {...contentProps} {...topLinnersDoNothing} key={idx} padding={this.state.content.search_result_row_padding}>
                        <FlexGrid flexGridColumnCount={2}>
                            <FlexGridItem {...favProps}>
                                <Favicon
                                    favicon_url={protocol.favicon_url}
                                    size={FaviconSize.SMALL}
                                    badge_type={protocol.badge_type}
                                    status={protocol.status}
                                    rejecting_new_subscriptions={protocol.rejecting_new_subscriptions}
                                />
                            </FlexGridItem>
                            <FlexGridItem>
                                <StyledLink key={idx} href={ROUTES.BASE_URL + "/protocol/" + protocol.number} className="a_no_line">
                                    {displaySearchContent(<Truncate lines={1} width={Screen.size() == [ScreenSize.xsmall||ScreenSize.small] ? 220 : 398}>{protocol.name + ' • ' + protocol.summary}</Truncate>)}
                                </StyledLink>
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>)
                }
            }
        })

        return results;
    };

    searchByName = (protocol, search) => {
        return protocol.name.toLowerCase().includes(search)
    };
    searchByDrug = (protocol, search) => {
        return protocol.product_categories.length > 0 && protocol.product_categories[0].products.filter(product => product.display_name.toLowerCase().includes(search)).length > 0;
    };

    searchByCondition = (protocol, search) => {
        return protocol.protocol_metadata_items.filter(condition => condition.short_name && condition.short_name.toLowerCase().includes(search)).length > 0;
    };

    searchByNumber = (protocol, search) => {
        return protocol.number == search;
    };

    renderSearchResults = () => {
        return <FlexGrid
            flexGridColumnCount={1}
            flexGridRowGap={this.state.content.search_result_content_spacing}
            margin={this.state.content.search_result_content_margin}
        >
            {this.state.search && this.search()}
        </FlexGrid>
    };

    render() {
        return <MpsModal
            open={this.state.show_search}
            title={null}
            body={<div style={['MEDIUM','LARGE','XLARGE'].includes(Screen.size()) ? {height: "85vh"} : {minHeight: "75vh"}}>
                {this.renderSearchField()}
                {this.renderSearchResults()}
                {this.renderSearchFieldBody()}
            </div>}
            callbackCancel={this.closeModal}
            overrides={autoOverride}
            overrideSize={['XSMALL','SMALL'].includes(Screen.size()) ? ModalSize.full : null}
            scrollableBody={['MEDIUM','LARGE','XLARGE'].includes(Screen.size())}
        />
    }
}

export default SearchProtocol;
