import React from 'react';
import {LabelLarge, ParagraphLarge, ParagraphSmall} from 'baseui/typography';
import { StyledLink } from "baseui/link";
import * as ROUTES from '../../patient/Constants';

const data = {
    en: {
        formulary_title: "Frequently prescribed list",
        formulary_paragraph_1: "The following therapeutics are available for request by {protocol_name} subscribers.",
        formulary_paragraph_2: "*Clinic is not responsible for prior authorization approvals.",
        subscription_title: "What it costs",
        subscription_paragraph_1: "{billing_price}/month until cancelled.",
        //TODO make these values dynamic
        subscription_paragraph_1_subtext: "Platform fees of $1.50 per Rx & $1 application fee to join.",
        subscription_paragraph_2: "*Providers on Clinic don't accept insurance.",

        protocol_home_meds_title: "My prescriptions",
        protocol_home_meds_paragraph_1: "A list of your prescriptions will show up here.",
        protocol_home_drugstore_title: "drugstore",
        protocol_home_drugstore_paragraph_1: <span>Request scripts from our frequently prescribed list. If approved, they‘ll be sent to a pharmacy of your choosing. $1.50 per Rx.</span>,
        protocol_home_labs_title: "Get labs",
        protocol_home_labs_paragraph_1: "Order labs through our partner Ulta Labs. You must visit a Quest testing center to complete this process.",
        protocol_home_chat_title: "Message the doctor",
        protocol_home_chat_paragraph_1: "Send questions to the server's doctor here. We'll text you any replies at the verified number below. *If this is an emergency, call 911!",
        protocol_home_subscription_title: "Membership",
        protocol_home_fee_description_subscription: "*Platform fees of $1.50 per Rx are calculated and billed once monthly along with your membership. Next bill date is {subscription-bill-date}.",
        protocol_home_fee_description_free: "*Platform fees of $1.50 per Rx are calculated and billed once monthly. Next bill date is {subscription-bill-date}.",
        protocol_home_subscription_restart: "Restart your membership to {protocol_name}. By proceeding, you’ll be charged {billing_price} each month on your preferred payment method until canceled.",
        protocol_home_subscription_cancel: "Your membership will remain active until the end of the current billing period. Any Rx fees associated with your membership will be billed on the last day of your current subscription. Once cancelled, you can no longer access this clinic.",
        protocol_home_subscription_cancel_rejecting_subs: "Your membership will remain active until the end of the current billing period. Any Rx fees associated with your membership will be billed on the last day of your current subscription. Once cancelled, you can no longer access this clinic.",
        subscription_table_membership: '{billing_price}/month until cancelled.',
        subscription_free_clinic: 'This is a free clinic provided by the doctor.',
        subscription_table_meds: 'Pay at the pharmacy. Use cash or insurance.',

        protocol_view_description: "About this clinic",
        protocol_view_eligibility: "Who can join",
        protocol_view_conditions: "Supported conditions",
        protocol_view_risks: "Risks & alternatives",
        protocol_view_availability: "Available in these states",

        protocol_home_meds: "My\u00A0scripts",
        protocol_home_drugstore: "Drugstore",
        protocol_home_chat: "Support",
        protocol_home_labs: "Labs",
        protocol_home_subscription: "My\u00A0membership",
        protocol_home_community: "Community",

        protocol_home_share_title:"Share and support",
        protocol_home_share_paragraph:<span></span>,

        protocol_community_title: "community",
        protocol_community_creator: "Creator",
        protocol_community_members: "Other members",
        protocol_community_description: <ParagraphSmall style={{marginTop: '0', marginBottom: '15px'}}>Welcome to the community! See who's online, chat with the group & more! To change or update your username, visit your <StyledLink href={ROUTES.ACCOUNT} className="a_no_line" style={{color:"#00a1ff"}}>Accounts</StyledLink> page.</ParagraphSmall>,

        protocol_preview_max_width: "343px",
        protocol_preview_padding: "36px",
        protocol_preview_border_width: "1px",
        protocol_preview_border_style: "solid",
        protocol_preview_border_radius: "20px",

        protocol_preview_title: <ParagraphLarge style={{lineHeight: "18px", margin:"0px 0px 20px 0px"}}>You've been invited to...</ParagraphLarge>,
        protocol_preview_button_title: "Accept invite",
        protocol_preview_button_padding_left: "70px",
        protocol_preview_button_padding_right: "70px",
        protocol_preview_name_margin: '5px 0px 2px 0px',
        protocol_preview_members_margin: '0px 0px 18px 0px',

        dm_username_margins: '12px 0px 4px 0px',
        dm_about_margins: '0px 0px 5px 0px',
        dm_button_margins: '35px 0px 10px 0px',

        protocol_view_rules_intro_title: "Community rules",
        protocol_view_rules_intro_body: <span>All of Clinic's Terms and Community Guidelines apply<br/>
                                              🔗 <StyledLink href="https://github.com/Pharmr/legal/blob/main/terms-of-use.md" target="_blank" rel="noopener noreferrer" style={{color:"#00a1ff"}}>Terms of use</StyledLink><br/>
                                              🔗 <StyledLink href="https://github.com/Pharmr/legal/blob/main/community-guidelines.md" target="_blank" rel="noopener noreferrer" style={{color:"#00a1ff"}}>Community guidelines</StyledLink><br/>
                                              Important! By participating in this community, you agree to adhere to these rules at all times.</span>,
        protocol_view_rules_section_1_title: "#Support channel rules",
        protocol_view_rules_section_1_body: <span><ol style={{listStylePosition: "inside", paddingLeft:"0px"}}><li>This is a support channel only! For urgent issues, call 911!</li>
                                             <li>The #Support channel is for medical assistance ONLY. No chit chat or vague questions.</li>
                                             <li>Be super descriptive about your current issue(s). The more details the better.</li>
                                             <li>Vague queries will be ignored, it takes too long to go back and forth.</li>
                                             <li>If your issue is not relevant to current care, I may refer you to another doctor.</li>
                                             <li>If you make drug requests that appear out of context, send a message explaining why.</li>
                                             <li>The #Support channel is not for requesting X refills on Y drug. Use the drugstore!</li>
                                             <li>If I don't respond to your message within a few days, try rephrasing and send again.</li>
                                             <li>We currently don't prescribe any schedule meds so don't ask.</li>
                                             <li>Be respectful–everyone's issue is important, and everyone wants immediate results.</li></ol></span>,
        protocol_view_rules_section_2_title: "Drug request rules",
        protocol_view_rules_section_2_body: <span><ol style={{listStylePosition: "inside", paddingLeft:"0px"}}><li>Don't request anything you don't intend to take.</li>
                                             <li>Requests with 3 therapeutics will be automatically rejected.</li>
                                             <li>We won't prescribe anything off formulary so don't ask.</li>
                                             <li>Wait for any current requests to be reviewed before making new ones.</li>
                                             <li>If you make a request by mistake, message us on #Support.</li>
                                             <li>Don't complain about rejected requests. If we request more info, please provide it.</li>
                                             <li>Requests older than 6 days will be automatically rejected & must be re-requested.</li>
                                             <li>Any mistakes on dose, pharmacy or form let us know immediately!</li></ol></span>,
        protocol_view_rules_section_3_title: "#Group channel rules",
        protocol_view_rules_section_3_body: <span><ol style={{listStylePosition: "inside", paddingLeft:"0px"}}><li>#Group is a community where patients share their experiences living with their condition.</li>
                                             <li>Any advice provided by patients should be understood as just that - advice.</li>
                                             <li>The server's staff or Clinic are not responsible for any advice provided herein.</li>
                                             <li>It's up to you to determine what is and is not useful.</li>
                                             <li>You should not take medical advice from other members, only qualified support staff.</li>
                                             <li>No advertising/fundraising.</li>
                                             <li>No surveys or other research.</li>
                                             <li>Be civil. Don't say things you wouldn't say in a face-to-face conversation. Avoid gratuitous negativity.</li>
                                             <li>No racism/sexism/homophobia/transphobia/etc. This is a safe space.</li>
                                             <li>Stay on topic.</li>
                                             <li>SFW media only.</li></ol></span>,
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
