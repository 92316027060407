import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import {Button, KIND, SHAPE, SIZE} from 'baseui/button';
import {HeadingSmall, HeadingXSmall, LabelLarge, LabelMedium, ParagraphLarge, ParagraphMedium, ParagraphSmall} from 'baseui/typography';
import MpsModal from '../../shared/Utils/modal';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {Spinner} from '../../shared/Spinner';
import Request from '../../shared/Request';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '120px',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'center',
                justifyContent: 'flex-end'
            }),
        },
    },
};

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class Subscription extends Component {
    constructor(props){
        super(props);

        this.protocol_id = this.props.protocol_id;

        this.state = {
            changeCounter: 0,
            isLoading: true,
            activating_subscription: false,
            protocol_subscription: null,
            showModal: false,
            showReactivateModal: false
        }
    }

    componentDidMount = () => {
        this.getProtocolSubscription();
    };

    getProtocolSubscription = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/subscription_management/" + this.protocol_id,
            callbackSuccess: (result) => this.setState({
                isLoading: false,
                protocol_subscription: result,
            }),
            callbackError: (error) => {
                this.props.callbackError({message: error.message});
                this.setState({isLoading: false});
            }
        })
    };

    activateSubscription = () => {
        this.setState({isLoading: true, activating_subscription: true});

        const data = new FormData();

        data.append('id', this.state.protocol_subscription.id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/subscription_management/activate",
            body: data,
            callbackSuccess: (result) => {
                this.setState({
                    isLoading: false,
                    protocol_subscription: result,
                    activating_subscription: false,
                    showReactivateModal: false});
                this.props.callbackSuccess({message: 'Your subscription has been reactivated'});

                // Refresh page
                window.location.reload();
            },
            callbackError: (error) => {
                this.props.callbackError({message: error.message || error.error.message});
                this.setState({isLoading: false, activating_subscription: false});
            }
        })
    };

    deactivateSubscription = (subscription_id) => {
        this.setState({isLoading: true});
        const data = new FormData();

        data.append('id', subscription_id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/subscription_management/deactivate",
            body: data,
            callbackSuccess: (result) => {
                this.setState({isLoading: false, protocol_subscription: result});
                this.props.callbackSuccess({message: 'Your subscription has been cancelled'});
            },
            callbackError: (error) => {
                this.props.callbackError({message: error.message});
                this.setState({
                    isLoading: false
                })
            }
        })
    };

    renderStatus = () => {
        if (this.state.protocol_subscription.status == 'inactive') {
            return 'Not active';
        } else if (this.state.protocol_subscription.status == 'dead') {
            return 'Banned';
        } else if (this.state.protocol_subscription.status == 'active_pending_cancel') {
            return 'Your subscription will cancel on ' + Utils.render_mm_dd_yyyy_date(this.state.protocol_subscription.next_bill_date)
        }
    };

    renderSubscription = () => {
        if (this.state.protocol_subscription.status == 'active_pending_cancel') 
        {
        return <div>
          <HeadingXSmall style={{marginTop: '20px', marginBottom: '15px'}}>{this.props.content.protocol_home_subscription_title}</HeadingXSmall>
        </div>
        } else {
        return <div>
          <HeadingXSmall style={{marginTop: '20px', marginBottom: '15px'}}>{this.props.content.protocol_home_subscription_title}</HeadingXSmall>
          <FlexGrid flexGridColumnCount={1}>
          <FlexGridItem><HeadingSmall style={{margin: '0px'}}>{this.state.protocol_subscription.protocol.billing_price == 0 ? this.props.content.subscription_free_clinic : this.props.content.subscription_paragraph_1.replaceAll('{billing_price}', Utils.renderDollarAmount(this.state.protocol_subscription.protocol.billing_price))}</HeadingSmall></FlexGridItem>
          <FlexGridItem><ParagraphSmall>{this.state.protocol_subscription.protocol.billing_price == 0
            ? this.props.content.protocol_home_fee_description_free.replaceAll('{subscription-bill-date}', Utils.render_mm_dd_yyyy_date(this.state.protocol_subscription.next_bill_date))
            : this.props.content.protocol_home_fee_description_subscription.replaceAll('{subscription-bill-date}', Utils.render_mm_dd_yyyy_date(this.state.protocol_subscription.next_bill_date))
            }</ParagraphSmall></FlexGridItem>
          </FlexGrid>
        </div>
        }
    };

    renderInactiveSubscription = () => {
        if (this.state.protocol_subscription.protocol.rejecting_new_subscriptions) {
            return <ParagraphSmall>{this.state.protocol_subscription.protocol.name}, cannot be reactivated at this time.</ParagraphSmall>
        } else {
            return <ParagraphSmall></ParagraphSmall>
        }
    };

    renderSubscriptionButton = () => {
        if (this.state.protocol_subscription.status == 'active') {
            return <Button kind={KIND.secondary} size={SIZE.default} shape={SHAPE.pill} onClick={this.showModal}>{this.state.protocol_subscription.protocol.billing_price == 0 ? "Leave Clinic" : "Cancel Membership"}</Button>;
        } else if ((this.state.protocol_subscription.status == 'inactive' || this.state.protocol_subscription.status == 'active_pending_cancel') &&
            !this.state.protocol_subscription.protocol.rejecting_new_subscriptions) {
            return <Button id={this.state.protocol_subscription.id} isLoading={this.state.isLoading} kind={KIND.primary} shape={SHAPE.pill} size={SIZE.default} onClick={this.handleReactivation}>Restart subscription</Button>;
        } else {
            return '';
        }
    };

    closeModal = () => {
        this.setState({showModal: false});
    };

    showModal = () => {
        this.setState({showModal: true});
    };

    handleDeactivation = (event) => {
        this.closeModal();
        this.deactivateSubscription(this.state.protocol_subscription.id);
    };

    handleReactivation = (event) => {
        this.showReactivateModal();
    };

    showReactivateModal = () => {
        this.setState({showReactivateModal: true});
    };

    closeReactivateModal = () => {
        this.setState({showReactivateModal: false});
    };

    cancelPromptContent = () => {
        if (this.state.protocol_subscription.protocol.rejecting_new_subscriptions) {
            return this.props.content.protocol_home_subscription_cancel_rejecting_subs;
        } else {
            return this.props.content.protocol_home_subscription_cancel;
        }
    }

    renderChickenMessage = () => {

        return this.state.showModal ? <MpsModal
        open={this.state.showModal}
        autoFocus={false}
        callback={this.handleDeactivation}
        callbackCancel={this.closeModal}
        withFooter={true}
        callbackButton={"Cancel membership"}
        callbackCancelButton={'Go back'}
        title={Utils.renderTitleCenter('Cancel membership?')}
        body={<ParagraphMedium>{this.cancelPromptContent()}</ParagraphMedium>} /> : ''
    };

    reactivateChickenMessage = () => {

        return this.state.showReactivateModal ? <MpsModal
        open={this.state.showReactivateModal}
        autoFocus={false}
        callback={this.activateSubscription}
        callbackCancel={this.closeReactivateModal}
        withFooter={true}
        callbackButton={"Restart membership"}
        callbackCancelButton={'Cancel'}
        title={Utils.renderTitleCenter('Restart your membership?')}
        isLoading={this.state.activating_subscription}
        body={<ParagraphMedium>{this.props.content.protocol_home_subscription_restart.replaceAll('{protocol_name}', this.state.protocol_subscription.protocol.name).replaceAll('{billing_price}', Utils.renderDollarAmount(this.state.protocol_subscription.protocol.billing_price))}</ParagraphMedium>} /> : ''
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <div>
                            {this.state.protocol_subscription ?
                                ['active', 'active_pending_cancel'].includes(this.state.protocol_subscription.status)
                                ?
                                    <div>
                                        {this.renderSubscription()}
                                        {this.renderSubscriptionButton()}
                                        {this.renderChickenMessage()}
                                        {this.reactivateChickenMessage()}
                                    </div>
                                : <div  style={{display: "flex", justifyContent: "center", alignItems:"center", textAlign:'center'}}>
                                    {this.renderInactiveSubscription()}
                                    {this.renderSubscriptionButton()}
                                    {this.reactivateChickenMessage()}
                                </div>
                            : <div>
                                {Utils.renderSpace()}
                                {Utils.renderSpace()}
                                <FlexGrid flexGridColumnCount={1}>
                                    <FlexGridItem {...itemProps}><Spinner /></FlexGridItem>
                                </FlexGrid>
                            </div>}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Subscription);
