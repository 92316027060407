import * as PatientRoutes from '../../patient/Constants';
import * as RunRoutes from '../../physician/Constants';
import * as OpsRoutes from '../../admin/Constants';
import Logger from '../Utils/logger';

export const SIGN_UP = '/signup';
export const MFA_ENROLL = '/mfa/enroll';
export const SIGN_IN = '/signin';
export const PASSWORD_RESET = '/password_reset';
export const PASSWORD_CHANGE = '/password_change';

let base_domain = process.env.BASE_DOMAIN;
let dev_domain = 'lvh.me';

const DEV_APP_NAME = dev_domain;
const STAGING_APP_NAME = 'staging.' + base_domain;
const PROD_APP_NAME = base_domain;
const WWW_PROD_APP_NAME = 'www.' + base_domain;

// TODO Should be set by ENV vars during build
const APP_SETTNGS = {
    ['ops.' + DEV_APP_NAME]: {
        tenant_id: 'admin-vu0wd',
        base_url: OpsRoutes.BASE_URL,
        base_url_api: OpsRoutes.BASE_URL_API,
        api_key: "AIzaSyDRn4j8t3KrzGAjbSN_nNkpZDThohEnOs8",
        auth_domain: "dev-mpshealth-webapp.firebaseapp.com",
        home_url: OpsRoutes.HOME,
        create_user_on_signup: false,
        email_new_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: "http://127.0.0.1:3011"
    },
    ['run.' + DEV_APP_NAME]: {
        tenant_id: 'physician-4kecz',
        base_url: RunRoutes.BASE_URL,
        base_url_api: RunRoutes.BASE_URL_API,
        api_key: "AIzaSyDRn4j8t3KrzGAjbSN_nNkpZDThohEnOs8",
        auth_domain: "dev-mpshealth-webapp.firebaseapp.com",
        home_url: RunRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: "http://127.0.0.1:3011"
    },
    [DEV_APP_NAME]: {
        tenant_id: 'patient-ddheg',
        base_url: PatientRoutes.BASE_URL,
        base_url_api: PatientRoutes.BASE_URL_API,
        api_key: "AIzaSyDRn4j8t3KrzGAjbSN_nNkpZDThohEnOs8",
        auth_domain: "dev-mpshealth-webapp.firebaseapp.com",
        home_url: PatientRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-dev-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: "http://127.0.0.1:3011"
    },
    ['ops.' + STAGING_APP_NAME]: {
        tenant_id: 'admin-q4sra',
        base_url: OpsRoutes.BASE_URL,
        base_url_api: OpsRoutes.BASE_URL_API,
        api_key: "AIzaSyBCgO0b_k6MpJ9VtNIzpWUjw15nYki_ZYY",
        auth_domain: "staging-mpshealth-webapp.firebaseapp.com",
        home_url: OpsRoutes.HOME,
        create_user_on_signup: false,
        email_new_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${STAGING_APP_NAME}`
    },
    ['run.' + STAGING_APP_NAME]: {
        tenant_id: 'physician-xa4md',
        base_url: RunRoutes.BASE_URL,
        base_url_api: RunRoutes.BASE_URL_API,
        api_key: "AIzaSyBCgO0b_k6MpJ9VtNIzpWUjw15nYki_ZYY",
        auth_domain: "staging-mpshealth-webapp.firebaseapp.com",
        home_url: RunRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${STAGING_APP_NAME}`
    },
    [STAGING_APP_NAME]: {
        tenant_id: 'patient-ngswg',
        base_url: PatientRoutes.BASE_URL,
        base_url_api: PatientRoutes.BASE_URL_API,
        api_key: "AIzaSyBCgO0b_k6MpJ9VtNIzpWUjw15nYki_ZYY",
        auth_domain: "staging-mpshealth-webapp.firebaseapp.com",
        home_url: PatientRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-staging-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${STAGING_APP_NAME}`
    },
    ['ops.' + PROD_APP_NAME]: {
        tenant_id: 'admin-x1rvi',
        base_url: OpsRoutes.BASE_URL,
        base_url_api: OpsRoutes.BASE_URL_API,
        api_key: "AIzaSyAR7D5POYDPqs_6RGCNlkJcpkj9RwdO66M",
        auth_domain: "production-mpshealth-webapp.firebaseapp.com",
        home_url: OpsRoutes.HOME,
        create_user_on_signup: false,
        email_new_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${PROD_APP_NAME}`
    },
    ['run.' + PROD_APP_NAME]: {
        tenant_id: 'physician-n87fv',
        base_url: RunRoutes.BASE_URL,
        base_url_api: RunRoutes.BASE_URL_API,
        api_key: "AIzaSyAR7D5POYDPqs_6RGCNlkJcpkj9RwdO66M",
        auth_domain: "production-mpshealth-webapp.firebaseapp.com",
        home_url: RunRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${PROD_APP_NAME}`
    },
    [PROD_APP_NAME]: {
        tenant_id: 'patient-qrfue',
        base_url: PatientRoutes.BASE_URL,
        base_url_api: PatientRoutes.BASE_URL_API,
        api_key: "AIzaSyAR7D5POYDPqs_6RGCNlkJcpkj9RwdO66M",
        auth_domain: "production-mpshealth-webapp.firebaseapp.com",
        home_url: PatientRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${PROD_APP_NAME}`
    },
    [WWW_PROD_APP_NAME]: {
        tenant_id: 'patient-qrfue',
        base_url: PatientRoutes.WWW_BASE_URL,
        base_url_api: PatientRoutes.BASE_URL_API,
        api_key: "AIzaSyAR7D5POYDPqs_6RGCNlkJcpkj9RwdO66M",
        auth_domain: "production-mpshealth-webapp.firebaseapp.com",
        home_url: PatientRoutes.HOME,
        create_user_on_signup: true,
        email_new_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewVerificationCode",
        email_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyUserCode",
        enroll_new_phone_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/sendNewMFAVerificationText",
        phone_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyMFATextCode",

        reset_password_send_email_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendEmailVerification",
        reset_password_email_code_verificaiton_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetEmailVerificationCode",
        reset_password_send_text_code_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetPasswordSendTextVerification",
        reset_password_text_code_verification_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/verifyPasswordResetTextVerificationCode",
        reset_password_url: "https://us-central1-production-mpshealth-webapp.cloudfunctions.net/resetUserPassword",
        chat_room_url: `https://chat.${PROD_APP_NAME}`
    }
};

// TODO Check for any security issues with using the hostname attribute
const host = window.location.hostname;

export const PATIENT_ROUTES = PatientRoutes;
export const BASE_URL = APP_SETTNGS[host].base_url;
export const BASE_URL_API = APP_SETTNGS[host].base_url_api;
export const FIREBASE_TENANT_ID = APP_SETTNGS[host].tenant_id;
export const FIREBASE_API_KEY = APP_SETTNGS[host].api_key;
export const FIREBASE_AUTH_DOMAIN = APP_SETTNGS[host].auth_domain;
export const HOME = APP_SETTNGS[host].home_url;
export const CREATE_USER_ON_SIGNUP = APP_SETTNGS[host].create_user_on_signup;
export const EMAIL_NEW_CODE_URL = APP_SETTNGS[host].email_new_code_url;
export const EMAIL_CODE_VERIFICATION_URL = APP_SETTNGS[host].email_code_verification_url;
export const ENROLL_NEW_PHONE_CODE_URL = APP_SETTNGS[host].enroll_new_phone_code_url;
export const PHONE_CODE_VERIFICATION_URL = APP_SETTNGS[host].phone_code_verification_url;

export const RESET_PASSWORD_SEND_EMAIL_CODE_URL = APP_SETTNGS[host].reset_password_send_email_code_url;
export const RESET_PASSWORD_EMAIL_CODE_VERIFICATION_URL = APP_SETTNGS[host].reset_password_email_code_verificaiton_url;
export const RESET_PASSWORD_SEND_TEXT_CODE_URL = APP_SETTNGS[host].reset_password_send_text_code_url;
export const RESET_PASSWORD_TEXT_CODE_VERIFICATION_URL = APP_SETTNGS[host].reset_password_text_code_verification_url;
export const RESET_PASSWORD_URL = APP_SETTNGS[host].reset_password_url;

export const CHAT_ROOM_URL = APP_SETTNGS[host].chat_room_url;

Logger.log('App Settings: ');
Logger.log('Base URL: ' + BASE_URL);
Logger.log('Base URL API: ' + BASE_URL_API);
Logger.log('Tenant ID: ' +  FIREBASE_TENANT_ID);
Logger.log('Home: ' + HOME);
Logger.log('Chat Room URL: ' + CHAT_ROOM_URL);
