import React, {Component} from 'react';
import * as PATIENT_ROUTES from '../../patient/Constants';
import { StyledLink } from "baseui/link";
import {ParagraphLarge} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import content from './content';
import Contact from '../../shared/Contact';
import ReportIssue from '../../shared/ReportIssue';
import SearchProtocol from '../../patient/Browse/search_protocol';
import PhysicianExplainer from '../../patient/PhysicianExplainer';

const contentProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const topLinners = {
    overrides: {
      Block: {
        style: ({$theme}) => ({
          borderTop: '1px solid ' + $theme.colors.mono400
        }),
      },
    },
};

class UnAuthNavPatientOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showContact: false,
            showReportIssue: false,
            showPhysicianExplainer: false,
            content: this.getContent()
        };
    }

    getContent = () => {
        return content('en').patient;
    };

    showContact = () => {
        this.setState({showContact: true});
    };

    closeContact = () => {
        this.setState({showContact: false});
        this.props.closeCallback();
    };

    showPhysicianExplainer = () => {
        this.setState({showPhysicianExplainer: true});
    };

    closePhysicianExplainer = () => {
        this.setState({showPhysicianExplainer: false});
        this.props.closeCallback();
    };

    showReportIssue = () => {
        this.setState({showReportIssue: true});
    };

    closeReportIssue = () => {
        this.setState({showReportIssue: false});
        this.props.closeCallback();
    };

    closeOptions = () => {
        this.props.closeCallback();
    };
    
    showProtocolSearch = (event) => {
        event.preventDefault();

        this.setState({showSearchProtocol: true});
    };
    
    hideProtocolSearch = () => {
        this.setState({showSearchProtocol: false});
        this.props.callback();
    };

    render() {
        return <div>
            <FlexGrid flexGridColumnCount={1} flexGridRowGap={this.state.content.content_spacing} margin={this.state.content.content_margin}>
                {/*<FlexGridItem {...contentProps}>
                    <StyledLink href={PATIENT_ROUTES.HOME} onClick={this.callback} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.home}</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>
                <FlexGridItem {...contentProps} {...topLinners}>
                    <StyledLink href={""} onClick={this.showProtocolSearch} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.browse}</ParagraphLarge>
                    </StyledLink>
    </FlexGridItem>*/}
                <FlexGridItem {...contentProps} >
                    <StyledLink href={"#"} onClick={this.showPhysicianExplainer} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>About</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>
                <FlexGridItem {...contentProps} {...topLinners}>
                    <StyledLink href={"#"} onClick={this.showContact} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Security</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>
                <FlexGridItem {...contentProps} {...topLinners}>
                    <StyledLink href={PATIENT_ROUTES.HOME} onClick={this.callback} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Home</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>
                {/*<FlexGridItem {...contentProps} {...topLinners}>
                    <StyledLink href={"#"} onClick={this.showReportIssue} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Product support</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>
                <FlexGridItem {...contentProps} {...topLinners}>
                    <StyledLink href={'https://clinicdev.notion.site/Clinic-dev-user-guide-6709682413d748b6a6a8b09fd088292d?pvs=4'} target="_blank" onClick={this.closeOptions} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>{this.state.content.help}</ParagraphLarge>
                    </StyledLink>
                </FlexGridItem>=
                <FlexGridItem {...contentProps} {...topLinners}>
                    <StyledLink href={"https://github.com/Pharmr/legal"} target="_blank" onClick={this.closeOptions} className="a_no_line">
                        <ParagraphLarge style={{margin: '10px 0px', fontSize: '20px'}}>Legal repo ↗</ParagraphLarge>
                    </StyledLink>
</FlexGridItem>*/}
            </FlexGrid>
            {this.state.showSearchProtocol ? <SearchProtocol callbackClose={this.hideProtocolSearch}/> : null}
            <Contact show={this.state.showContact} callback={this.closeContact}/>
            <ReportIssue show={this.state.showReportIssue} callback={this.closeReportIssue}/>
            <PhysicianExplainer show={this.state.showPhysicianExplainer} callback={this.closePhysicianExplainer}/>
        </div>
    }
};

export default UnAuthNavPatientOptions;
