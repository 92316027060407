import React, {Component} from 'react';
import {AuthUserContext} from '../Session'
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import SignOutButton from '../SignOut';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Screen from '../Utils/screen';
import Utils from '../Utils';
import { withFirebase } from '../Firebase';
import MpsModal from '../Utils/modal';
import PatientNav from './patient_nav';
import PhysicianNav from './physician_nav';
import PhysicianClinics from './physician_clinics';
import {HeadingMedium, ParagraphLarge} from 'baseui/typography';
import * as PATIENT_ROUTES from '../../patient/Constants';
import * as PHYSICIAN_ROUTES from '../../physician/Constants';
import { StyledLink } from "baseui/link";
import {FocusOnce} from 'baseui/modal';
import Storage from '../Storage';
import PatientProtocolNavigation from './patient_protocols';
import PhysicianProtocolNavigation from './physician_clinics';
import content from './content';
import { ThemeProvider, createTheme, darkThemePrimitives } from "baseui";
import { LogoFontBold, LogoFontSemibold, OutlineScreen, OutlineMonoMed } from '../../shared/Fonts';
import UnAuthNav from './unauth_nav';
import '../Styles/style.scss';
import { ReproUltra } from '../../shared/Fonts';
import { Search, Overflow } from "baseui/icon";
import SearchProtocol from '../../patient/Browse/search_protocol';
import PersistentNotifications from '../PersistentNotifications';

const signInOutProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'max-content',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: '8px'
            }),
        },
    },
};

const subscriptionProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                overflowX: 'auto',
                //paddingLeft: '8px',
                marginRight: '8px'
            }),
        },
    },
};

const logoProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'max-content',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: '8px',
                paddingRight: '8px'
            }),
        },
    },
};

const noLogoProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'max-content',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: '8px'
            }),
        },
    },
};

const searchProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const navBarStyling = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                backgroundColor:$theme.colors.backgroundTertiary,
                borderRadius: '100px',
                height: '50px',
                alignItems: 'center',
                maxWidth:['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)' : '570px',
                width: '100%',
                position: 'relative',
                top: ['XSMALL'].includes(Screen.size()) ? '4px' :'16px',
                marginRight: '16px',
                marginLeft: '16px',

            }),
        },
    },
};

const navBarPhysStyling = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                backgroundColor:$theme.colors.backgroundTertiary,
                borderRadius: '100px',
                height: '50px',
                alignItems: 'center',
                maxWidth: '570px',
                width: '100%',
                marginTop: '20px'
            }),
        },
    },
};

function Icon() {
    return Utils.logo();
};

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showSearchProtocol: false,
            content: this.getContent()
        };
    }

    getContent = () => {
        return this.props.firebase.isPatient() ? content('en').patient : content('en').physician;
    };

    getResponsiveSpacing = () => {
        if (['SMALL', 'MEDIUM'].includes(Screen.size())) {
            return '3px 16px';
        } else {
            return '3px 24px';
        }
    };

    getCss = () => {
        let css = {};
        //css.padding = this.getResponsiveSpacing();
        //css["backgroundColor"] = "red";
        //css["opacity"] = "50%";
        //css["boxShadow"] = this.state.content.navigation_box_shadow;
        css["position"] = 'fixed';
        //css["webkitBackfaceVisibility"]= 'hidden';
        css["top"] = '0';
        css["left"] = '0';
        css["right"] = '0';
        css["display"] = 'flex';
        css["justifyContent"] = 'center';

        return css;
    };

    showProtocolSearch = (event) => {
        event.preventDefault();

        this.setState({showSearchProtocol: true});
    };

    hideProtocolSearch = () => {
        this.setState({showSearchProtocol: false});
        this.props.callback();
    };

    renderNavBar = () => {
        if (this.props.firebase.isPatient()) {
         return <div style={this.getCss()}>
              <FlexGrid {...navBarStyling} flexGridColumnCount={2}>
              <FlexGridItem>
                  <FlexGrid flexGridColumnCount={2}>
                        <FlexGridItem {...noLogoProps}>
                            {null}
                        </FlexGridItem>
                      <FlexGridItem {...subscriptionProps}>
                          <PatientProtocolNavigation />
                      </FlexGridItem>
                  </FlexGrid>
              </FlexGridItem>
              <FlexGridItem {...signInOutProps}>
                      <Button
                          kind={KIND.secondary}
                          shape={SHAPE.circle}
                          size={SIZE.compact}
                          onClick={() => this.setState({showModal: true})}
                      >
                          <Overflow style={{transform: "rotate(90deg)"}} size={24} />
                      </Button>
              </FlexGridItem>
          </FlexGrid>
          {this.state.showSearchProtocol ? <SearchProtocol callbackClose={this.hideProtocolSearch}/> : null}
      </div>
      } else {
        return  <div style={{display:'flex', justifyContent:'center'}}>
          <FlexGrid {...navBarPhysStyling} flexGridColumnCount={2}>
              <FlexGridItem>
                  <FlexGrid flexGridColumnCount={2}>
                        <FlexGridItem {...noLogoProps}>
                            {null}
                        </FlexGridItem>
                      <FlexGridItem {...subscriptionProps}>
                      <PhysicianClinics />
                      </FlexGridItem>
                  </FlexGrid>
              </FlexGridItem>
              <FlexGridItem {...signInOutProps}>
                      <Button
                          kind={KIND.secondary}
                          shape={SHAPE.circle}
                          size={SIZE.compact}
                          onClick={() => this.setState({showModal: true})}
                      >
                          <Overflow style={{transform: "rotate(90deg)"}} size={24} />
                      </Button>
              </FlexGridItem>
          </FlexGrid>
          <PersistentNotifications/>
          {this.state.showSearchProtocol ? <SearchProtocol callbackClose={this.hideProtocolSearch}/> : null}
      </div>

      }
  };

    closeModal = () => {this.setState({showModal: false})};

    renderNavBarOptions = () => {
        return <MpsModal
            open={this.state.showModal}
            title={null}
            callbackCancel={this.closeModal}
            overrideModalWidth={'343px'}
            body={this.props.firebase.isPatient() ? <PatientNav callback={this.closeModal}/> : <PhysicianNav callback={this.closeModal}/>}
        />
    };

    render(){
        return(
            <AuthUserContext.Consumer>
                {isUserLoggedIn => isUserLoggedIn && isUserLoggedIn.multiFactor.enrolledFactors.length > 0
                ? <div>
                    {this.renderNavBar()}
                    {this.renderNavBarOptions()}
                  </div>
                : this.props.firebase.isPatient() &&  window.location.pathname.match(/\/protocol\/[0-9]{9}/g) ?  <UnAuthNav />  : null}
            </AuthUserContext.Consumer>
        );
    }
};

export default withFirebase(Navigation);
