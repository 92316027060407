import React, {Component} from 'react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import HistoryMessage from './history_message';
import {Spinner, SpinnerSize} from '../../shared/Spinner';
import * as ROUTES from '../Constants';
import Request from '../../shared/Request';
import {ParagraphSmall} from 'baseui/typography';
import Screen from '../../shared/Utils/screen';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        }
    }
}

const flexOne = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                flexGrow: 1,
            }),
        },
    },
};

class History extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.color_index = 0;
        this.bot_color = "#FFC107";
        this.colors = [
            '#E91E63',
            '#00BCD4',
            '#ca7eff',
            '#ff4500',
            '#008000',
            '#2e8b57',
            '#9acd32',
            '#ff69b4',
            '#0000ff',
            '#5f9ea0',
            '#ff0000',
            '#ff4500',
            '#e31e1e',
            '#b22222',
            '#db15de',
            '#ffcbfd',
            '#5d5da2',
            '#da5fac',
            '#fff882',
            '#bf94ff',
            '#ffc35e',
            '#00ff12',
            '#d2691e',
            '#ede4be',
            '#ccffff',
            '#00fcaf',
            '#e2f200',
            '#ff82f6',
            '#d91775',
            '#0f7d53',
            '#9fff00',
            '#ff8d00'
        ];

        this.history_attributes = {};
        this.last_username = '';
        this.messageInterval = null;
        this.last_scroll_position = -1;

        this.state = {
            protocol: this.props.protocol,
            username: this.props.username,
            new_message: this.props.new_message,
            parent_height: this.props.parent_height,
            chat_height: this.calcHeight(this.props.parent_height),
            chat_history: [],
            usernames: [],
            loaded: false,
            messages_unseen: false
        }
    }

    componentWillUnmount = () => clearInterval(this.messageInterval)

    componentDidMount = () => {
        this.getMessages();
        this.getMessagesInInterval();

        this.myRef.current.addEventListener("scroll", () => this.setScrollingPosition());
    }

    getMessages = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_messages/chat/" + this.state.protocol.number,
            callbackSuccess: result => {
                let all_messages =  result.patient_messages.concat(result.provider_messages, result.bot_notifications);
                all_messages.filter(message => message !== undefined);
                all_messages.map(message => {
                    if (message.provider){ // Provider message
                        message['username'] = message.provider.username;
                        message['message'] = message.message_for_patient;
                        message['file_upload_url'] = message.file_upload_url;
                        message['file_upload_filename'] = message.file_upload_filename;
                    } else if (message.message) { // Bot notificaitons.
                        message['username'] = "Clinic";
                        if (message.message.startsWith('Clinic: ')) {
                            message['message'] = message.message.substring('Clinic: '.length)
                        }
                    } else {
                        message['username'] = message.patient.username;
                        message['message'] = message.body;
                        message['file_upload_url'] = message.file_upload_url;
                        message['file_upload_filename'] = message.file_upload_filename;
                    }

                    return message;
                });

                this.setState({loaded: true, chat_history: all_messages.sort((a, b) => {return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1})}, () => this.executeScroll())

                this.markProviderMessagesSeen();
                this.markBotNotificationsSeen();
            },
            callbackError: error => this.setState({loaded: true, error: error})
        });
    }

    markProviderMessagesSeen = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_messages/provider_messages_seen/" + this.state.protocol.number,
            callbackSuccess: result => {},
            callbackError: error => this.setState({loaded: true, error: error})
        });
    };

    markBotNotificationsSeen = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_messages/bot_notifications_seen/" + this.state.protocol.number,
            callbackSuccess: result => {},
            callbackError: error => this.setState({loaded: true, error: error})
        });
    };

    getMessagesInInterval = () => {
        this.messageInterval = setInterval(this.getMessages, 10000);
    };

    // Header: 73px
    // Typing indicator: 22px
    // Input Message: 44px
    // 12 bottom margin
    // Additional space: 24px
    calcHeight = (parent_height) => parent_height - (61 + 48 + 24 - 6);

    componentDidUpdate = (prevProps) => {
        if (this.props.new_message !== prevProps.new_message) {
            this.setState({chat_history: this.state.chat_history.concat([this.props.new_message])}, () => this.scrollToBottom());
        }

        if (this.props.parent_height !== prevProps.parent_height) {
            let options = {parent_height: this.parentCalcHeight, chat_height: this.calcHeight(this.parentCalcHeight)};

            if (Screen.size() == 'SMALL') {
                options = {}; // Do not resize for small screens... TODO: do the same for medium size screens?
            }

            this.setState(options, () => this.executeScroll())
        }
    }

    // TODO Push to a its own class.
    getColor = () => {
        const color = this.colors[this.color_index];

        if (this.color_index >= (this.colors.length - 1)) {
            this.color_index = 0;
        } else {
            this.color_index += 1;
        }

        return color;
    };

    // TODO push scrolling to its own class.
    setScrollingPosition = () => {
        if (this.myRef.current.scrollTop + this.myRef.current.offsetHeight === this.myRef.current.scrollHeight) {
            this.last_scroll_position = -1;
        } else {
            this.last_scroll_position = this.myRef.current.scrollTop;
        }
    };

    executeScroll = () => {
        if (this.myRef.current) {
            if(this.last_scroll_position >= 0) {
                this.myRef.current.scrollTo({
                    top: this.last_scroll_position,
                    left: 0,
                    behavior: 'auto'
                })
            } else {
                this.myRef.current.scroll({
                    top: this.myRef.current.scrollHeight,
                    left: 0,
                    behavior: 'auto'
                });
            }
        }
    }

    scrollToBottom = () => {
        if (this.myRef.current) {
            this.myRef.current.scroll({
                top: this.myRef.current.scrollHeight,
                left: 0,
                behavior: 'auto'
            });
        }
    };

    renderMessage = (message) => {
        let show_user = message.username != this.last_username;
        if (show_user) {
            this.last_username = message.username;
        }

        if (this.history_attributes[message.username]) {
            const thisMessageDate = isNaN(message.created_at) ? new Date(message.created_at).getTime() : message.created_at;
            const lastMessageDate = isNaN(this.history_attributes[message.username].last_sent) ? new Date(this.history_attributes[message.username].last_sent).getTime() : this.history_attributes[message.username].last_sent;

            if (thisMessageDate - lastMessageDate > 300000) {
                show_user = true;
            }
        }

        if (!this.history_attributes[message.username]) {
            if (["Clinic"].includes(message.username)) {
                this.history_attributes[message.username] = {color: this.bot_color, last_sent: message.created_at};
            } else {
                this.history_attributes[message.username] = {color: this.getColor(), last_sent: message.created_at};
            }
        } else {
            this.history_attributes[message.username]['last_sent'] = message.created_at;
        }

        return <HistoryMessage
            data={message}
            color={this.history_attributes[message.username].color}
            username={this.state.username}
            show_user={show_user}
            usernames={this.state.usernames}
        />
    };

    render = () => {
        this.last_username = '';

        return <AuthUserContext.Consumer>
            {authUser => (
                <div style={{overflowY: 'auto', height: this.state.chat_height + 'px'}} ref={this.myRef}>
                    <FlexGrid flexGridRowGap={'scale0'} {...flexOne}>
                        {this.state.chat_history && this.state.chat_history.map((message, idx) => <FlexGridItem key={idx}>{this.renderMessage(message)}</FlexGridItem>)}
                        <FlexGridItem><ParagraphSmall></ParagraphSmall></FlexGridItem>

                        {this.state.loaded ? this.state.chat_history.length == 0
                            ? <FlexGridItem style={{paddingTop: '15px'}} {...centerProps}><ParagraphSmall margin={'0px 0px 0px 0px'}>No messages found</ParagraphSmall></FlexGridItem>
                            : null

                            : <FlexGridItem style={{paddingTop: '15px'}}><Spinner size={SpinnerSize.LARGE}/></FlexGridItem>
                        }
                    </FlexGrid>
                </div>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(History);
