import React from 'react';
import { HeadingXSmall, HeadingSmall, HeadingMedium, HeadingLarge, ParagraphMedium, ParagraphLarge, MonoHeadingXSmall } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { ThemeProvider, createTheme, darkThemePrimitives} from "baseui";
import { OracleVariable, GintoVariable } from '../../shared/Fonts';
import Screen from '../../shared/Utils/screen';
import {Block} from 'baseui/block';
import SlideShow from '../../shared/SlideShow';
import TypingEffect from '../../shared/TypingEffect';


const greyTextProps = {
  overrides: {
      Block: {
        style: ({$theme}) => ({
          color: $theme.colors.primary400,
        }),
      },
  },
};

const linkStyling = {
  overrides: {
      Block: {
        style: ({$theme}) => ({
          height: '70px',
          width: '500px',
          backgroundColor: $theme.colors.negative,
          borderRadius: '50px',
          }),
      },
  },
};


const data = {
    en: {
        home_tag: <div style={{display:'grid', justifyContent:'center'}}> 
                    <div style={{position:'inherit', marginTop: '40px', marginLeft:'auto', marginRight:'auto', height:'325px'}}>
                      <img style={{width:'200px', marginLeft:'auto', marginRight:'auto'}} src='https://res.cloudinary.com/dxjnpv5we/image/upload/c_scale,dpr_2.0,w_250/v1680709763/Screen_Shot_2023-04-05_at_11.47.16_AM_a2vtah.png'/>
                    </div>
                  </div>,
        home_title: <div><OracleVariable 
                            style={{fontSize:'48px', lineHeight: '48px', marginTop:'60px', textAlign: "center", fontFeatureSettings: ' "calt" 1, "liga" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss06" 1'}}
                                >Try <span className='oracle-variable-text' style={{textAlign: "center", letterSpacing: '-1px', fontWeight:700}}
                                >Clinic<sup style={{fontSize:'16px', letterSpacing: '-1px',}}>TM</sup></span>
                          </OracleVariable>
                    </div>,
        home_subtitle: <div><ParagraphLarge style={{marginTop:'0px', marginBottom:'0px', textAlign: "center", lineHeight:'24px', maxWidth: '400px'}}
                              >Create your own private online clinic & earn income from subscribers.
                            </ParagraphLarge>
                        </div>,
        home_bottom_text: <div><HeadingMedium style={{marginTop: '20px', marginRight:"auto", marginBottom: '20px', marginLeft:"auto", textAlign: 'center', display:'flex', flexWrap: 'wrap', alignItems:'center', justifyContent:'center', fontWeight:'700', letterSpacing:'.5px'}}>
        clinic.dev/<span style={{color:'#CBCBCB'}}>yourpractice</span>
      </HeadingMedium></div>,
        home_tag_mobile: '',
        home_title_mobile:<div><OracleVariable 
                                style={{fontSize:'48px', lineHeight: '48px', marginTop:'60px', textAlign: "center", letterSpacing: '-3px'}}
                                  >Introducing
                              </OracleVariable>
                              <div className='oracle-variable-text'>
                              <OracleVariable style={{fontSize:'64px', lineHeight: '60px', textAlign: "center", letterSpacing: '-3px', fontWeight:700}}
                                  >Clinic<sup style={{fontSize:'18px', letterSpacing: '-1px',}}>TM</sup>
                              </OracleVariable>
                              </div>
                              <HeadingXSmall style={{marginTop:'8px', marginBottom:'8px', textAlign: "center"}}
                                  >Your own online clinic. In one simple link.
                              </HeadingXSmall>
                          </div>,
        home_subtitle_mobile: <div style={{display:'grid', justifyContent:'center'}}> 
                                <div style={{marginTop:'35px', marginBottom:'10px', marginLeft:'auto', marginRight:'auto'}} ><SlideShow
                                        images={[
                                          'https://res.cloudinary.com/dxjnpv5we/image/upload/c_scale,h_80/v1680020225/Screen_Shot_2023-03-28_at_12.13.02_PM_pzcksw.png',
                                          'https://res.cloudinary.com/mpsh87/image/upload/c_scale,h_88,dpr_2.0/v1662081230/hero4Artboard_55_8x_qp66bf.png',
                                          'https://res.cloudinary.com/mpsh87/image/upload/v1672086702/Multiavatar-Arkadion_uoqekz.png',
                                          'https://res.cloudinary.com/mpsh87/image/upload/c_scale,h_88,q_80,r_88,dpr_2.0/v1662145658/giphy.gif_jpw8qm.gif',
                                          'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_88,r_88/v1675721426/IMG_7031_k4emqo_fppj5k.png',
                                          'https://res.cloudinary.com/mpsh87/image/upload/c_scale,h_88,q_100,dpr_2.0/v1662080310/bball_mxj7y5.gif',
                                          'https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_88,q_100/v1662237337/original-5a221276234f95bb9deec4cbe061f71b_m7juus_aubenk.png'
                                        ]}
                                        height={'80px'}
                                        width={'auto'}
                                        timeout={5000}
                                    /></div>
                                <HeadingXSmall className='link-styling-mobile' style={{marginTop: '0px', marginRight:"auto", marginBottom: '35px', marginLeft:"auto", textAlign: 'center', display:'flex', flexWrap: 'wrap', alignItems:'center', justifyContent:'center'}}>
                                  clinic.dev/<span style={{fontWeight:'700', letterSpacing:'.5px'}}>yourpractice</span>
                                </HeadingXSmall>
                              </div>,
        home_bottom_text_mobile:"",


        ticker: [
            {text: "", emoji: ""},
            {text: "", emoji: ""},
            {text: "", emoji: ""},
            {text: "", emoji: ""},
            {text: "", emoji: ""},
            {text: "", emoji: ""},
            {text: "", emoji: ""}

        ],

        // SlideShow
        images_margins: '0px 0px 18px 0px',// Top, Right, Bottom, Left
        slide_timeout: 4000,
        images_width: Screen.size() == 'SMALL' ? 'auto' : 'auto',
        images_height: Screen.size() == 'SMALL' ? '130px' : '150px',
        images: [
        ],

        how_it_works_text: 'How it works',
        providers_text: 'Providers',
        providers_link: "https://run.clinic.dev",
        legal_text: "",
        legal_link: "",
        contact_text: "Contact"
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
