
class Storage {

    static set = (key, val) => {
        localStorage.setItem(key, val);
    };

    static get = (key) => {
        return localStorage.getItem(key);
    }

    static remove = (key) => {
        localStorage.removeItem(key);
    }

    static clear = () => {
        localStorage.clear();
    }
}

export default Storage;
