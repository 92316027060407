import React, {Component} from 'react';
import { LabelLarge } from 'baseui/typography';

class OracleThin extends Component {
    render = () => {
        return <LabelLarge overrides={{
            Block: {
                style: ({$theme}) => ({
                    color: $theme.colors.primary
                })
            }
        }}

        style={{
            fontFamily: 'OracleThin',
            fontSize: "72px",
            lineHeight: "72px",
            ...this.props.style
        }}>
            {this.props.children}
        </LabelLarge>
    };
}

export default OracleThin;
