import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import { LabelLarge, LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { StyledLink } from "baseui/link";
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';
import {Spinner} from '../../shared/Spinner';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '80px',
                display: 'flex',
                flexGrow: 0,
                justifyContent: 'flex-end',
                alignItems: 'start',
            }),
        },
    },
};

class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            history_items: [],
            error: null
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/history",
            callbackSuccess: (result) => {
                Logger.log(result);
                this.setState({
                isLoaded: true,
                history_items: result,
                ...Utils.clearAllNotifications()
            })},
            callbackError: (error) => this.setState({
                isLoaded: true,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    orderNumber = (item) => {
        return item.order && item.order.number;
    };

    paymentNumber = (item) => {
        return item.number || item.id;
    };

    getDate = (item) => {
        return new Date(item['paid_at'] || item['created_at']).toLocaleString();
    };

    renderTransactions = () => {
        if (!this.state.isLoaded) {
            return <Spinner />;
        } else {
            if (Array.isArray(this.state.history_items) && this.state.history_items.length > 0) {
                return <FlexGrid
                    flexGridColumnCount={1}
                    flexGridRowGap={'scale800'}
                >
                    {this.state.history_items.map(
                        (transaction, idx) => <FlexGridItem key={idx}>
                            <StyledLink
                                href={ROUTES.HISTORY + '/' + this.orderNumber(transaction) + '/' + this.paymentNumber(transaction)}
                                className="a_no_line"
                            >
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                                    <FlexGridItem>
                                        <LabelLarge>{this.getDate(transaction)}</LabelLarge>
                                    </FlexGridItem>
                                    <FlexGridItem {...narrowItemProps}>
                                        <LabelLarge style={{margin: 0}}>{Utils.renderDollarAmount(transaction.amount)}</LabelLarge>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <ParagraphMedium style={{margin: 0, color: 'grey'}}>{this.paymentNumber(transaction)}</ParagraphMedium>
                                    </FlexGridItem>
                                    <FlexGridItem {...narrowItemProps}>
                                        <ParagraphMedium style={{color: 'green', margin: 0}}>{transaction.transaction_type == 'credit' ? "Refund" : null}</ParagraphMedium>
                                    </FlexGridItem>
                                </FlexGrid>
                            </StyledLink>
                        </FlexGridItem>)}
                </FlexGrid>
            } else {
                return <ParagraphMedium style={{textAlign: 'center'}}>No transactions found.</ParagraphMedium>;
            }
        }
    };

    render () {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <div style={{marginTop:'98px', marginBottom:'40px'}}>
                            {Utils.renderTitleCenter("Transaction History")}
                            {Utils.renderSpace()}
                            {Utils.renderError(this.state.error)}
                            {this.renderTransactions()}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(History);
