import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import { StyledLink } from "baseui/link";
import Check from 'baseui/icon/check';
import Utils from '../../shared/Utils';
import {HeadingMedium, LabelSmall, ParagraphMedium} from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { Button, SHAPE, SIZE, KIND} from 'baseui/button';
import Request from '../../shared/Request';
import { Tag, KIND as TagKind} from "baseui/tag";
import Logger from '../../shared/Utils/logger';


const checkedItemProps = {
    maxWidth: '48px',
    minHeight: '40px',
    alignItems: 'center',
    justifyContent: 'flex-end',
};

const itemPropsLeft = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
};

class ListCreditCards extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,

            selected_card: this.props.selected_card ? this.props.selected_card : '',
            cards: this.props.cards
        };
        Logger.log("List Credit Cards loaded...");
    }

    componentDidMount = () => {
        Logger.log("List Credit Cards component did mount...");
        this.getCards();
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.cards) !== JSON.stringify(this.props.cards)) {
            this.setState({cards: this.props.cards, ...Utils.clearAllNotifications()});
        }
        if (this.props.selected_card !== prevProps.selected_card) {
            this.setState({selected_card: this.props.selected_card, ...Utils.clearAllNotifications()});
        }
    }

    getCards = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/patient_payment",
            callbackSuccess: (result) => this.props.loadCards(result),
            callbackError: (error) => this.setState({
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    callback = (val) => {
        this.props.newCardCallback && this.props.newCardCallback(val);
    };

    changeEventHandler = (event) => {
        event.preventDefault();

        this.setState({[event.target.name]: event.target.value, ...Utils.clearAllNotifications()});

        Logger.log("Selected CCards event value;");
        Logger.log(event.target.value);
        this.callback(event.target.value);
    };

    showStatus = (card) => {
        if ('declined' == card.status) {
            return <Tag closeable={false} kind={TagKind.red}>
                Declined
                </Tag>
        }
        if ('expired' == card.status) {
            return <Tag closeable={false} kind={TagKind.red}>
                Expired
                </Tag>
        }
    };

    showPaymentMethods = () => {
        if (this.state.cards && this.state.cards.length > 0) {
            return <FlexGrid
                flexGridColumnCount={1}
                flexGridRowGap={'scale400'}
            >
                {
                    this.state.cards.map((card, idx) => <FlexGridItem key={idx}>
                        <StyledLink
                            href='#'
                            onClick={(event) => {event.target.value = card.id; this.changeEventHandler(event)}}
                            className="a_no_line">
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                                    <FlexGridItem {...itemPropsLeft}>{card.full_name} - {card.card_type.toUpperCase()} {card.last_four_digits} ({card.expiry_month}/{card.expiry_year}) {this.showStatus(card)}</FlexGridItem>
                                    <FlexGridItem {...checkedItemProps}>{card.id == this.state.selected_card
                                        ? <Button kind={KIND.secondary} shape={SHAPE.circle} size={SIZE.compact}><Check size={32} color="#22b573"/></Button>
                                        : ''}</FlexGridItem>
                                </FlexGrid>
                        </StyledLink>
                    </FlexGridItem>)}
            </FlexGrid>
        }
    };

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div>
                        {Utils.renderError(this.state.error)}
                        {Utils.renderSpace()}
                        {Utils.renderTitle6(this.props.title)}
                        {Utils.renderSpace()}
                        {this.showPaymentMethods()}
                    </div>
                )
            }
        </AuthUserContext.Consumer>
    );
}
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ListCreditCards);
