import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import { StyledLink } from "baseui/link";
import * as ROUTES from '../Constants';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {HeadingMedium, LabelMedium, ParagraphMedium, HeadingXSmall, ParagraphSmall, DisplayXSmall} from 'baseui/typography';
import Logger from '../../shared/Utils/logger';
import Utils from '../../shared/Utils';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import Screen from '../../shared/Utils/screen';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '50px',
                display: 'flex',
                flexGrow: 0,
                justifyContent: 'flex-end'
            }),
        },
    },
};

const upperProps = {
    overrides: {
        Block: {
            style: ({$there}) => ({
                minHeight: '115px'
            })
        }
    }
}

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }),
        },
    },
};

class ProtocolList extends Component {
    constructor(props){
        super(props);

        this.state = {
            sort: this.props.sort ? this.props.sort : null
        }

        Logger.log(this.props.protocols);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sort !== prevProps.sort) {
            this.setState({sort: this.props.sort, ...Utils.clearAllNotifications()});
        }
    };

    renderTags = (protocol) => {
        if (protocol.tag_list.length > 0) {
            return protocol.tag_list.join(' ');
        }
    };

    renderProtocol = (protocol, index) => {
        return <div
            className="Grid-item"
            key={index}
        >
            <StyledLink className="a_no_line" href={ROUTES.PROTOCOL_VIEW + '/' + protocol.number}>
                <FlexGrid
                    flexGridColumnCount={1}
                    padding='45px 22px 45px 22px'
                >
                    <FlexGridItem {...centerProps}>
                        <Favicon
                            favicon_url={protocol.favicon_url}
                            size={FaviconSize.MEDIUM}
                            badge_type={protocol.badge_type}
                            status={protocol.status}
                            rejecting_new_subscriptions={protocol.rejecting_new_subscriptions}
                        />
                    </FlexGridItem>
                    <FlexGridItem {...centerProps}><DisplayXSmall style={{margin: 0, textAlign:'center'}}>{protocol.name}</DisplayXSmall></FlexGridItem>
                    <FlexGridItem {...centerProps}><ParagraphSmall style={{color: '#999999', margin: 0}}>{protocol.subscriber_count} members</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </StyledLink>
        </div>
    };

    sortProtocols = () => {
        if (this.state.sort == 'alphabetical') {
            this.props.protocols.sort((a, b) => {return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1})
        } else if (this.state.sort == 'popular') {
            this.props.protocols.sort((a, b) => {return a.subscriber_count > b.subscriber_count ? -1 : 1});
        } else if (this.state.sort == 'latest') {
            this.props.protocols.sort((a, b) => {return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1});
        }
    };

    isSearchedByDrug = (protocol) => {
        let result = false;

        if (protocol.product_categories && protocol.product_categories.length > 0 && protocol.product_categories[0].products){
            result = protocol.product_categories[0].products.some(drug => drug.name && drug.name.toLowerCase().includes(this.props.search.toLowerCase()));
        }

        return result;
    };

    isSearchedByCondition = (protocol) => {
        return protocol.protocol_metadata_items.some(condition => condition.short_name && condition.short_name.toLowerCase().includes(this.props.search.toLowerCase()))
    };

    isProtocolSearched = (protocol) => {
        if (!this.props.search) {
            return protocol;
        }

        if (protocol.name.toLowerCase().includes(this.props.search.toLowerCase())) {
            return protocol;
        } else if (protocol.creator.username && protocol.creator.username.toLowerCase().includes(this.props.search.toLowerCase())){
            return protocol;
        } else if(this.isSearchedByDrug(protocol)) {
            return protocol;
        } else if (this.isSearchedByCondition(protocol)) {
            return protocol;
        } else {
            return false;
        }
    };

    listProtocols = () => {
        // sort by: name, latest, popular
        this.sortProtocols();
        return <div className="Grid" style={{gridGap: ['SMALL', 'MEDIUM'].includes(Screen.size()) ? "16px" : "18px"}}>{this.props.protocols.map((protocol, index) => this.isProtocolSearched(protocol) ? this.renderProtocol(protocol, index) : null)}</div>;
    }

    render() {
        return (
            <AuthUserContext.Consumer>{authUser => (
                <div>
                    {this.listProtocols()}
                </div>
            )}</AuthUserContext.Consumer>
        )
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ProtocolList);
