import React from 'react';
import { ParagraphMedium } from "baseui/typography";

const data = {
    en: {
        create_rx_request: "Prescription request",
        drug_therapy_description: <ParagraphMedium style={{margin: 0}}></ParagraphMedium>
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
